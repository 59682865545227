// IMPORTS
// --------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import PolicyStyles from './styles';
import DynamicContent from '../DynamicContent';

/**
 * CATALOG COMPONENT
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
// eslint-disable-next-line no-unused-vars
const Policy = props => {
  const { token, match, dynamicPageRead, doReadDynamicPage, doClearDynamicPage } = props;

  const { page } = match?.params;

  return (
    <PolicyStyles>
      {
        {
          // Catalog Views
          tos: <DynamicContent key={`tos`} token={token} contentKey={`tos`} dynamicPageRead={dynamicPageRead} doReadDynamicPage={doReadDynamicPage} doClearDynamicPage={doClearDynamicPage} />,
          return: <DynamicContent key={`return`} token={token} contentKey={`return`} dynamicPageRead={dynamicPageRead} doReadDynamicPage={doReadDynamicPage} doClearDynamicPage={doClearDynamicPage} />,
          privacy: (
            <DynamicContent key={`privacy`} token={token} contentKey={'privacy'} dynamicPageRead={dynamicPageRead} doReadDynamicPage={doReadDynamicPage} doClearDynamicPage={doClearDynamicPage} />
          ),
          shipping: (
            <DynamicContent key={`shipping`} token={token} contentKey={`shipping`} dynamicPageRead={dynamicPageRead} doReadDynamicPage={doReadDynamicPage} doClearDynamicPage={doClearDynamicPage} />
          ),
        }[page]
      }
    </PolicyStyles>
  );
};

// PROPS TYPES
// --------------------------------------------------------
Policy.defaultProps = {
  view: '',
};

Policy.propTypes = {
  doClearDynamicPage: PropTypes.func,
  doReadDynamicPage: PropTypes.func,
  dynamicPageRead: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
};

// EXPORTS
// --------------------------------------------------------
// Export
export default Policy;
