// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  READ_FIREBASETOKEN_REQUEST,
  READ_FIREBASETOKEN_SUCCESS,
  READ_FIREBASETOKEN_FAILURE,
  UPDATE_FIREBASETOKEN_REQUEST,
  UPDATE_FIREBASETOKEN_SUCCESS,
  UPDATE_FIREBASETOKEN_FAILURE,
  CREATE_FIREBASETOKEN_REQUEST,
  CREATE_FIREBASETOKEN_SUCCESS,
  CREATE_FIREBASETOKEN_FAILURE,
  DELETE_FIREBASETOKEN_REQUEST,
  DELETE_FIREBASETOKEN_SUCCESS,
  DELETE_FIREBASETOKEN_FAILURE,
} from './types';

// Actions
import { readFirebaseToken, createFirebaseToken, updateFirebaseToken, deleteFirebaseToken } from './actions';

/**
 * Function that handles getting a single FirebaseToken
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadFirebaseToken({ token, id }) {
  try {
    const response = yield call(readFirebaseToken, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_FIREBASETOKEN_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_FIREBASETOKEN_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetFirebaseToken() {
  yield takeLatest(READ_FIREBASETOKEN_REQUEST, workerReadFirebaseToken);
}

/**
 * Function that handles creating a single FirebaseToken
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateFirebaseToken({ token, payload }) {
  // console.log('TOKK: ' + token);
  try {
    const response = yield call(createFirebaseToken, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_FIREBASETOKEN_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_FIREBASETOKEN_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateFirebaseToken() {
  yield takeLatest(CREATE_FIREBASETOKEN_REQUEST, workerCreateFirebaseToken);
}

/**
 * Function that handles updating a single FirebaseToken
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateFirebaseToken({ token, id, payload }) {
  try {
    const response = yield call(updateFirebaseToken, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_FIREBASETOKEN_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_FIREBASETOKEN_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateFirebaseToken() {
  yield takeLatest(UPDATE_FIREBASETOKEN_REQUEST, workerUpdateFirebaseToken);
}

/**
 * Function that handles deleting a single FirebaseToken
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteFirebaseToken({ token, id }) {
  try {
    const response = yield call(deleteFirebaseToken, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_FIREBASETOKEN_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_FIREBASETOKEN_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteFirebaseToken() {
  yield takeLatest(DELETE_FIREBASETOKEN_REQUEST, workerDeleteFirebaseToken);
}
