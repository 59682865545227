import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Alert, LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import FormUtil from '../../../utils/FormUtil';
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import CustomerSelector from '../../../components/CustomerSelector';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import Swal from 'sweetalert2';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import 'moment-timezone';

const AutomationPage = props => {
  const {
    user,
    token,
    match,
    history,
    //PURCHASEORDERAUTOMATION
    doCreatePurchaseOrderAutomation,
    doReadPurchaseOrderAutomation,
    doGetPurchaseOrderAutomations,
    purchaseOrderAutomationList,
    purchaseOrderAutomationRead,
    purchaseOrderAutomationCreate,
    doPurchaseOrderAutomationReset,
    purchaseOrderAutomationTrigger,
    setPurchaseOrderAutomationTrigger,

    //NOTIFICATIONCAMPAIGN
    doCreateNotificationCampaign,
    doReadNotificationCampaign,
    doGetNotificationCampaigns,
    notificationCampaignList,
    notificationCampaignRead,
    notificationCampaignCreate,
    doNotificationCampaignReset,
    notificationCampaignTrigger,
    setNotificationCampaignTrigger,
    doGetCustomers,
    customers,
  } = props;

  // Extend dayjs with the plugins
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const formatDateToSLCTime = dateString => {
    // Set the default timezone to use
    dayjs.tz.setDefault('America/Denver');

    // Parse and format the date in the given time zone
    return dayjs.tz(dateString, 'America/Denver').format('YYYY-MM-DD hh:mm A');
  };

  const { page, pageid } = match?.params;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notificationTriggerList, setNotificationTriggerList] = useState(false);
  const [purchaseOrderAutomationValues, setPurchaseOrderAutomationValues] = React.useState({
    start_date: moment()
      .subtract(3, 'months')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  });

  const [notificationCampaignValues, setNotificationCampaignValues] = React.useState({
    // start_date: moment().format('YYYY-MM-DDTHH:mm'), // This should include the time, but check how it's updated later
    start_date: moment().format('MM/DD/YYYY hh:mm A'), // This should include the time, but check how it's updated later
    title: '',
    body: '',
    url: '/catalog',
  });

  const [purchaseOrderAutomationValidation, setPurchaseOrderAutomationValidation] = React.useState({
    start_date: {
      validated: false,
      valid: true,
      errors: [],
    },
    end_date: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  const [notificationCampaignValidation, setNotificationCampaignValidation] = React.useState({
    title: {
      validated: false,
      valid: true,
      errors: [],
    },
    body: {
      validated: false,
      valid: true,
      errors: [],
    },
    url: {
      validated: false,
      valid: true,
      errors: [],
    },
    start_date: {
      validated: false,
      valid: true,
      errors: [],
    },
    // user_ids: {
    //   validated: false,
    //   valid: true,
    //   errors: [],
    // },
  });

  const handlePurchaseOrderAutomationChange = prop => event => {
    setPurchaseOrderAutomationValues({ ...purchaseOrderAutomationValues, [prop]: event.target.value });
  };

  const handleNotificationCampaignChange = prop => event => {
    if (event?.target != null) {
      // This path is for regular inputs
      setNotificationCampaignValues({ ...notificationCampaignValues, [prop]: event.target.value });
    } else {
      // This path is for the DateTimePicker, which uses Dayjs objects
      const formattedDate = event.format('MM/DD/YY hh:mm A');
      // console.log(formattedDate);
      setNotificationCampaignValues({ ...notificationCampaignValues, [prop]: formattedDate });
    }
    // if (event?.target != null) {
    //   // This path is for regular inputs
    // } else {
    //   // This path is for the DateTimePicker, which uses Dayjs objects
    //   const formattedDate = event.format('MM/DD/YYYY hh:mm');
    //   console.log(formattedDate);
    //   setNotificationCampaignValues({ ...notificationCampaignValues, [prop]: formattedDate });
    // }
  };
  const [pageNum, setPageNum] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangeRowsPerPage = event => {
    let query = '';
    let direction = '';
    let order = '';
    let limit = event.target.value;
    let page = 0;
    doGetNotificationCampaigns({ token, query, direction, order, limit, page });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNum(0);
  };

  const handleChangePage = (event, newPage) => {
    let query = '';
    let direction = '';
    let order = '';
    let limit = rowsPerPage;
    let page = newPage;
    doGetNotificationCampaigns({ token, query, direction, order, limit, page });
    setPageNum(newPage);
  };

  useEffect(() => {
    doPurchaseOrderAutomationReset();
    doNotificationCampaignReset();
  }, []);

  useEffect(() => {
    if (!purchaseOrderAutomationList.fetching && !purchaseOrderAutomationList.data && !purchaseOrderAutomationList.errors) {
      doGetPurchaseOrderAutomations({ token });
    }
  }, [purchaseOrderAutomationList, token]);

  useEffect(() => {
    if (!notificationCampaignList.fetching && !notificationCampaignList.data && !notificationCampaignList.errors) {
      let query = '';
      let direction = '';
      let order = 'desc';
      let limit = rowsPerPage;
      let page = pageNum;
      doGetNotificationCampaigns({ token, query, direction, order, limit, page });
    }
  }, [notificationCampaignList, pageNum, rowsPerPage, token]);

  useEffect(() => {
    if (!purchaseOrderAutomationCreate.fetching && purchaseOrderAutomationCreate.data) {
      doPurchaseOrderAutomationReset();
    }
  }, [purchaseOrderAutomationCreate, token]);

  useEffect(() => {
    if (!notificationCampaignCreate.fetching && notificationCampaignCreate.data) {
      doNotificationCampaignReset();
      setNotificationCampaignValues({
        start_date: moment().format('MM/DD/YYYY hh:mm A'), // This should include the time, but check how it's updated later
        title: '',
        body: '',
        url: '/catalog',
      });
      setSelectedUsers([]);
      Swal.fire({
        icon: 'success',
        text: 'Notification Campaign Created',
        showConfirmButton: false,
        timer: 3500,
        didClose: function() {
          history.push('/catalog/automation/notificationcampaigns');
        },
      });
    }
  }, [notificationCampaignCreate, token]);

  useEffect(() => {
    if (purchaseOrderAutomationTrigger) {
      setPurchaseOrderAutomationTrigger(false);
      setPurchaseOrderAutomationValidation(FormUtil.clearValidation(purchaseOrderAutomationValidation));

      if (purchaseOrderAutomationValues?.start_date === '') {
        setPurchaseOrderAutomationValidation(FormUtil.addError(purchaseOrderAutomationValidation, 'start_date', 'Start date is blank!'));
      }
      if (purchaseOrderAutomationValues?.end_date === '') {
        setPurchaseOrderAutomationValidation(FormUtil.addError(purchaseOrderAutomationValidation, 'end_date', 'End date is blank!'));
      }

      // Backend API Call.
      if (FormUtil.validate(purchaseOrderAutomationValidation)) {
        let payload = {
          start_date: purchaseOrderAutomationValues?.start_date
            ? purchaseOrderAutomationValues?.start_date
            : moment()
                .subtract(3, 'months')
                .format('YYYY-MM-DD'),
          end_date: purchaseOrderAutomationValues?.end_date ? purchaseOrderAutomationValues?.end_date : moment().format('YYYY-MM-DD'),
        };
        doCreatePurchaseOrderAutomation({ token, payload });
      }
    }
  }, [purchaseOrderAutomationTrigger, purchaseOrderAutomationValidation]);

  useEffect(() => {
    if (notificationCampaignTrigger) {
      // console.log('NOTIFICATION CAMPAIGN');
      setNotificationCampaignTrigger(false);
      setNotificationCampaignValidation(FormUtil.clearValidation(notificationCampaignValidation));

      if (notificationCampaignValues?.start_date === '') {
        setNotificationCampaignValidation(FormUtil.addError(notificationCampaignValidation, 'start_date', 'Start date is blank!'));
      }
      if (notificationCampaignValues?.title === '') {
        setNotificationCampaignValidation(FormUtil.addError(notificationCampaignValidation, 'title', 'Title is blank!'));
      }
      if (notificationCampaignValues?.body === '') {
        setNotificationCampaignValidation(FormUtil.addError(notificationCampaignValidation, 'body', 'Body is blank!'));
      }
      if (selectedUsers.length >= 1) {
        // console.log(selectedUsers);
        // Backend API Call.
        if (FormUtil.validate(notificationCampaignValidation)) {
          let payload = {
            start_date: notificationCampaignValues?.start_date,
            title: notificationCampaignValues?.title,
            body: notificationCampaignValues?.body,
            url: notificationCampaignValues?.url,
            user_ids: selectedUsers.map(function(obj) {
              return obj.user.id;
            }),
          };
          // console.log(payload);
          doCreateNotificationCampaign({ token, payload });
        }
      }
    }
  }, [notificationCampaignTrigger, notificationCampaignValidation, selectedUsers]);
  // console.log(pageid);
  //console.log(notificationCampaignValues.start_date);
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} md={12} lg={12}>
        {
          {
            undefined:
              user && user?.role && user?.role?.name === 'admin' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography align="left" variant="h4" sx={{ fontWeight: 'bold', textAlign: `center` }}>
                      Automation
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <LoadingButton
                          loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                          onClick={() => {
                            history.push('/catalog/automation/purchaseorders');
                          }}
                          size={`large`}
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          sx={{ textTransform: 'none' }}
                        >
                          Purchase Orders
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={4}>
                        <LoadingButton
                          loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                          onClick={() => {
                            history.push('/catalog/automation/notificationcampaigns');
                          }}
                          size={`large`}
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          sx={{ textTransform: 'none' }}
                        >
                          Notification Campaigns
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Alert severity="error">Permission Denied</Alert>
              ),
            notificationcampaigns: (
              <div>
                {{
                  undefined:
                    user && user?.role && user?.role?.name === 'admin' ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography align="left" variant="h4" sx={{ fontWeight: 'bold', textAlign: `center` }}>
                            Notification Campaigns
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <LoadingButton
                            loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                            onClick={() => {
                              history.push('/catalog/automation');
                            }}
                            size={`large`}
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            sx={{ textTransform: 'none' }}
                          >
                            Go Back
                          </LoadingButton>
                        </Grid>
                        <Grid item xs={4}>
                          <LoadingButton
                            loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                            onClick={() => {
                              history.push('/catalog/automation/notificationcampaigns/create');
                            }}
                            size={`large`}
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            sx={{ textTransform: 'none' }}
                          >
                            Create Campaign
                          </LoadingButton>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={12}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Start Date</TableCell>
                                  <TableCell style={{ textAlign: `center` }}>Notification</TableCell>
                                  {/*<TableCell style={{ textAlign: `center` }}>Body</TableCell>*/}
                                  <TableCell style={{ textAlign: `center` }}>Status</TableCell>
                                  {/*<TableCell style={{ textAlign: `center` }}>Started</TableCell>*/}
                                  {/*<TableCell style={{ textAlign: `center` }}>Complete</TableCell>*/}
                                  {/*<TableCell style={{ textAlign: `center` }}>Campaign Start Date</TableCell>*/}
                                  {/*<TableCell style={{ textAlign: `center` }}>Campaign End Date</TableCell>*/}
                                  <TableCell style={{ textAlign: `center` }}>User Count</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {!notificationCampaignList.fetching && notificationCampaignList?.data && notificationCampaignList?.data?.length >= 1 ? (
                                  notificationCampaignList?.data.map(function(item) {
                                    return (
                                      <TableRow key={item?.id}>
                                        <TableCell>{formatDateToSLCTime(item?.start_date)}</TableCell>
                                        <TableCell style={{ textAlign: `center` }}>
                                          <p style={{ margin: 0, fontWeight: `bold` }}>{item?.title}</p>
                                          <p style={{ margin: 0 }}>{item?.body}</p>
                                        </TableCell>
                                        <TableCell style={{ textAlign: `center` }}>
                                          {item?.complete ? (
                                            <Chip label="Complete" color="success" />
                                          ) : item?.started ? (
                                            <Chip label="Started" color="warning" />
                                          ) : (
                                            <Chip label="Pending" color="primary" />
                                          )}
                                        </TableCell>
                                        {/*<TableCell style={{ textAlign: `center` }}>{item?.complete}</TableCell>*/}
                                        {/*<TableCell style={{ textAlign: `center` }}>{item?.campaign_started}</TableCell>*/}
                                        {/*<TableCell style={{ textAlign: `center` }}>{item?.campaign_ended}</TableCell>*/}
                                        <TableCell style={{ textAlign: `center` }}>{item?.notification_campaign_users_count}</TableCell>
                                      </TableRow>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td>There is no data</td>
                                  </tr>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            component="div"
                            count={notificationCampaignList?.pagination?.count ? parseInt(notificationCampaignList?.pagination?.count) : 0}
                            page={
                              notificationCampaignList?.pagination?.page && notificationCampaignList?.pagination?.count && notificationCampaignList?.pagination?.count >= 1
                                ? notificationCampaignList?.pagination?.page
                                : 0
                            }
                            onPageChange={handleChangePage}
                            rowsPerPage={notificationCampaignList?.pagination?.limit ? notificationCampaignList?.pagination?.limit : 10}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Alert severity="error">Permission Denied</Alert>
                    ),
                  create:
                    user && user?.role && user?.role?.name === 'admin' ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography align="left" variant="h4" sx={{ fontWeight: 'bold', textAlign: `center` }}>
                            Notification Campaigns
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Stack component="form" noValidate spacing={3} style={{ marginBottom: 15 }}>
                            <FormControl>
                              {/*<TextField*/}
                              {/*  id="date"*/}
                              {/*  label="Start Date"*/}
                              {/*  type="datetime-local"*/}
                              {/*  value={purchaseOrderAutomationValues.start_date}*/}
                              {/*  onChange={handleNotificationCampaignChange('start_date')}*/}
                              {/*  // defaultValue={notificationCampaignValues.start_date}*/}
                              {/*  InputLabelProps={{*/}
                              {/*    shrink: true,*/}
                              {/*  }}*/}
                              {/*/>*/}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  label="Start Date"
                                  value={dayjs(notificationCampaignValues.start_date)}
                                  // defaultValue={dayjs(purchaseOrderAutomationValues.start_date)}
                                  onChange={handleNotificationCampaignChange('start_date')}
                                  renderInput={params => <TextField {...params} />}
                                  // date={}
                                />
                              </LocalizationProvider>
                            </FormControl>
                            <FormControl>
                              <TextField
                                id="title"
                                label="Title"
                                type="text"
                                value={notificationCampaignValues.title}
                                onChange={handleNotificationCampaignChange('title')}
                                // defaultValue={startDate}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                id="body"
                                label="Body"
                                type="text"
                                value={notificationCampaignValues.body}
                                onChange={handleNotificationCampaignChange('body')}
                                // defaultValue={startDate}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                id="url"
                                label="Url"
                                type="text"
                                value={notificationCampaignValues.url}
                                onChange={handleNotificationCampaignChange('url')}
                                // defaultValue={startDate}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                            <CustomerSelector token={token} customers={customers} doGetCustomers={doGetCustomers} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
                          </Stack>
                          <Grid container spacing={1}>
                            <Grid item xs={3}>
                              <LoadingButton
                                loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                                onClick={() => {
                                  history.push('/catalog/automation/notificationcampaigns');
                                }}
                                size={`large`}
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                sx={{ textTransform: 'none' }}
                              >
                                Go Back
                              </LoadingButton>
                            </Grid>
                            <Grid item xs={9}>
                              {' '}
                              <LoadingButton
                                loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                                onClick={() => {
                                  setNotificationCampaignTrigger(true);
                                }}
                                loading={notificationCampaignCreate.fetching || notificationCampaignList.fetching}
                                disabled={notificationCampaignCreate.fetching || notificationCampaignList.fetching}
                                size={`large`}
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                sx={{ textTransform: 'none' }}
                              >
                                Schedule Notification Campaign
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Alert severity="error">Permission Denied</Alert>
                    ),
                  // Add other cases as needed
                }[pageid] || <div>Default Content or Page Not Found</div>}
              </div>
            ),
            purchaseorders:
              user && user?.role && user?.role?.name === 'admin' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography align="left" variant="h4" sx={{ fontWeight: 'bold', textAlign: `center` }}>
                      Purchase Order Automation
                    </Typography>
                  </Grid>
                  {!purchaseOrderAutomationList.fetching && purchaseOrderAutomationList?.data && purchaseOrderAutomationList?.data?.length === 0 ? (
                    <Grid item xs={12} md={12} lg={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Stack component="form" noValidate spacing={3}>
                            <FormControl>
                              <TextField
                                id="date"
                                label="Start Date"
                                type="date"
                                value={purchaseOrderAutomationValues.start_date}
                                onChange={handlePurchaseOrderAutomationChange('start_date')}
                                // defaultValue={startDate}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                id="date"
                                label="End Date"
                                type="date"
                                value={purchaseOrderAutomationValues.end_date}
                                onChange={handlePurchaseOrderAutomationChange('end_date')}
                                // defaultValue={startDate}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                            <LoadingButton
                              loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                              onClick={() => {
                                setPurchaseOrderAutomationTrigger(true);
                              }}
                              loading={purchaseOrderAutomationCreate.fetching || purchaseOrderAutomationList.fetching}
                              disabled={purchaseOrderAutomationCreate.fetching || purchaseOrderAutomationList.fetching}
                              size={`large`}
                              variant="contained"
                              color="primary"
                              fullWidth={true}
                              sx={{ textTransform: 'none' }}
                            >
                              Schedule Purchase Orders
                            </LoadingButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : purchaseOrderAutomationList.fetching ? (
                    <Grid item xs={12} md={12} lg={12}>
                      <Skeleton animation="wave" style={{ width: `100%` }} />
                      <Skeleton animation="wave" style={{ width: `100%` }} />
                      <Skeleton animation="wave" style={{ width: `100%` }} />
                      <Skeleton animation="wave" style={{ width: `100%` }} />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={12} lg={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Alert severity="warning">Purchase Order Automation is Pending... Please wait till the current automation is done.</Alert>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <LoadingButton
                            loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                            onClick={() => {
                              history.push('/catalog/automation');
                            }}
                            size={`large`}
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            sx={{ textTransform: 'none' }}
                          >
                            Back to automations
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Alert severity="error">Permission Denied</Alert>
              ),
          }[page]
        }
      </Grid>
    </Grid>
  );
};

AutomationPage.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string,
  //PurchaseOrderAutomation
  doCreatePurchaseOrderAutomation: PropTypes.func,
  doGetPurchaseOrderAutomations: PropTypes.func,
  doReadPurchaseOrderAutomation: PropTypes.func,
  doPurchaseOrderAutomationReset: PropTypes.func,
  purchaseOrderAutomationList: PropTypes.object,
  purchaseOrderAutomationRead: PropTypes.object,
  purchaseOrderAutomationCreate: PropTypes.object,
  purchaseOrderAutomationTrigger: PropTypes.bool,
  setPurchaseOrderAutomationTrigger: PropTypes.func,
  //PurchaseOrderAutomation
  doCreateNotificationCampaign: PropTypes.func,
  doGetNotificationCampaigns: PropTypes.func,
  doReadNotificationCampaign: PropTypes.func,
  doNotificationCampaignReset: PropTypes.func,
  notificationCampaignList: PropTypes.object,
  notificationCampaignRead: PropTypes.object,
  notificationCampaignCreate: PropTypes.object,
  notificationCampaignTrigger: PropTypes.bool,
  setNotificationCampaignTrigger: PropTypes.func,
  doGetCustomers: PropTypes.func,
  customers: PropTypes.object,
};

export default AutomationPage;
