// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_CATEGORY_REQUEST,
  LIST_CATEGORY_SUCCESS,
  LIST_CATEGORY_FAILURE,
  READ_CATEGORY_REQUEST,
  READ_CATEGORY_SUCCESS,
  READ_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
} from './types';

// Actions
import { getCategories, getCategory, createCategory, updateCategory, deleteCategory } from './actions';

/**
 * Function that handles listing categories.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetCategories({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getCategories, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_CATEGORY_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_CATEGORY_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetCategories() {
  yield takeLatest(LIST_CATEGORY_REQUEST, workerGetCategories);
}

/**
 * Function that handles getting a single category.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetCategory({ token, id }) {
  try {
    const response = yield call(getCategory, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_CATEGORY_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_CATEGORY_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetCategory() {
  yield takeLatest(READ_CATEGORY_REQUEST, workerGetCategory);
}

/**
 * Function that handles creating a single category.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateCategory({ token, payload }) {
  try {
    const response = yield call(createCategory, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_CATEGORY_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_CATEGORY_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateCategory() {
  yield takeLatest(CREATE_CATEGORY_REQUEST, workerCreateCategory);
}

/**
 * Function that handles updating a single category.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateCategory({ token, id, payload }) {
  try {
    const response = yield call(updateCategory, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_CATEGORY_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_CATEGORY_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateCategory() {
  yield takeLatest(UPDATE_CATEGORY_REQUEST, workerUpdateCategory);
}

/**
 * Function that handles deleting a single category.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteCategory({ token, id }) {
  try {
    const response = yield call(deleteCategory, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_CATEGORY_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_CATEGORY_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteCategory() {
  yield takeLatest(DELETE_CATEGORY_REQUEST, workerDeleteCategory);
}
