// IMPORTS
import { combineReducers } from 'redux';

// REDUCERS
import appReducer from '../containers/App/reducers';
import authReducer from '../containers/Auth/reducers';
import userReducer from '../containers/User/reducers';
import assetReducer from '../containers/Asset/reducers';
import productReducer from '../containers/Product/reducers';
import orderReducer from '../containers/Order/reducers';
import savedPricingReducer from '../containers/SavedPricing/reducers';
import customerReducer from '../containers/Customer/reducers';
import cartItemReducer from '../containers/CartItem/reducers';
import categoryReducer from '../containers/Category/reducers';
import saleOrderReducer from '../containers/SaleOrder/reducers';
import cartReducer from '../containers/Cart/reducers';
import userContactReducer from '../containers/UserContact/reducers';
import authorizeReducer from '../containers/Authorize/reducers';
import dynamicPageReducer from '../containers/DynamicPage/reducers';
import purchaseOrderAutomationReducer from '../containers/PurchaseOrderAutomation/reducers';
import bluepayReducer from '../containers/Bluepay/reducers';
import firebaseTokenReducer from '../containers/FirebaseToken/reducers';
import notificationCampaignReducer from '../containers/NotificationCampaign/reducers';

// MAIN ROOT REDUCER
/**
 * Main root reducer
 * @param {Object} history redux history
 * @returns {Reducer<CombinedState<unknown>>} all reducers in one main root reducer
 */

const rootReducer = combineReducers({
  appReducer,
  authReducer,
  userReducer,
  assetReducer,
  productReducer,
  orderReducer,
  customerReducer,
  savedPricingReducer,
  cartItemReducer,
  categoryReducer,
  userContactReducer,
  authorizeReducer,
  dynamicPageReducer,
  purchaseOrderAutomationReducer,
  bluepayReducer,
  firebaseTokenReducer,
  notificationCampaignReducer,
  saleOrderReducer,
  cartReducer,
});

// EXPORTS
export default rootReducer;
