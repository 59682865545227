// Import types
import { LIST_NOTIFICATION_CAMPAIGN_REQUEST, READ_NOTIFICATION_CAMPAIGN_REQUEST, CREATE_NOTIFICATION_CAMPAIGN_REQUEST, NOTIFICATION_CAMPAIGN_RESET } from './types';

// Export operations
/**
 * Dispatch for getting a specific Firebase Token.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doGetNotificationCampaigns = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_NOTIFICATION_CAMPAIGN_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});
/**
 * Dispatch for getting a specific Firebase Token.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadNotificationCampaign = ({ token, id }) => ({
  type: READ_NOTIFICATION_CAMPAIGN_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating a Notification Campaign.
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doCreateNotificationCampaign = ({ token, payload }) => ({
  type: CREATE_NOTIFICATION_CAMPAIGN_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for resetting customer reducer
 * @param {Object} {  }
 * @return {Object} Dispatch
 */
export const doNotificationCampaignReset = () => ({
  type: NOTIFICATION_CAMPAIGN_RESET,
});
