// Dependencies
import * as React from 'react';
import { CreditCard, Ach, PaymentForm } from 'react-square-web-payments-sdk';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const SPaymentForm = props => {
  const { saleOrderCreate, user, cart, doConfirmSaleOrder, paymentType, addressObj, token, saleOrderConfirm, saleOrderRead } = props;
  const [accountHolderName, setAccountHolderName] = useState(user?.firstname != null ? user?.firstname + ' ' + user?.lastname : '');
  const [cartTotal, setCartTotal] = useState(cart && cart?.data && cart?.data ? cart?.data?.cart_total : `0`);
  const getAddressLine = addressData => {
    let arr = [];
    if (addressData?.company_name) {
      arr.push(addressData?.company_name);
    }
    if (addressData?.address_line_1) {
      arr.push(addressData?.address_line_1);
    }
    if (addressData?.address_line_2) {
      arr.push(addressData?.address_line_2);
    }
    if (addressData?.city) {
      arr.push(addressData?.city);
    }
    if (addressData?.state) {
      arr.push(addressData?.state);
    }
    if (addressData?.zipcode) {
      arr.push(addressData?.zipcode);
    }
    return arr;
  };

  useEffect(() => {
    if (cartTotal !== (cart && cart?.data && cart?.data ? cart?.data?.cart_total : `0`)) {
      setCartTotal(cart && cart?.data && cart?.data ? cart?.data?.cart_total : `0`);
    }
  }, [cart, cartTotal]);

  return (
    <PaymentForm
      applicationId={window.SQUARE_APP_ID}
      cardTokenizeResponseReceived={(squareToken, buyer) => {
        // console.log(squareToken);
        // console.log(buyer);
        if (!saleOrderCreate?.fetching && !saleOrderRead?.fetching) {
          let payload = {
            square_token: squareToken.token,
            sale_order_id: saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id,
            source: paymentType,
          };
          doConfirmSaleOrder({ token, payload });
        }
      }}
      createVerificationDetails={() => {
        return {
          amount: String(cartTotal),
          /* collected from the buyer */
          billingContact: {
            addressLines: addressObj && getAddressLine(addressObj),
            familyName: user?.firstname,
            givenName: user?.lastname,
            city: addressObj?.city,
            email: user && user?.email ? user?.email : null,
            // countryCode: 'USA',
          },
          currencyCode: 'USD',
          intent: 'CHARGE',
        };
      }}
      /**
       * Identifies the location of the merchant that is taking the payment.
       * Obtained from the Square Application Dashboard - Locations tab.
       */
      locationId={window.SQUARE_LOCATION_ID}
    >
      <Grid container>
        {
          {
            card: (
              <Grid xs={12}>
                <Grid container>
                  <Grid xs={12}>
                    <div style={{ position: `relative`, width: `100%` }}>
                      <div style={{ width: `100%` }}>
                        {saleOrderConfirm.fetching ? (
                          <LoadingButton
                            loading={saleOrderConfirm?.fetching}
                            disabled={saleOrderConfirm?.fetching}
                            loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={64} />}
                            size={`large`}
                            variant="contained"
                            color="primary"
                            style={{ width: `100%` }}
                            fullWidth={true}
                          >
                            <CreditCardIcon />
                          </LoadingButton>
                        ) : (
                          <CreditCard disabled={saleOrderConfirm?.fetching} />
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ),
            ach: (
              <Grid xs={12}>
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <div style={{ marginBottom: 10 }}>
                      <TextField label="Account Holder Name" value={accountHolderName} fullWidth variant="outlined" onChange={event => setAccountHolderName(event.target.value)} />
                    </div>
                  </Grid>
                  <Grid xs={12}>
                    <Ach
                      accountHolderName={accountHolderName}
                      transactionId={'12345'}
                      redirectURI="https://localhost:3000/catalog/payment"
                      callbacks={{
                        bankIncomeInsightsCompleted(event) {
                          //console.log(event);
                        },
                        closeOauth(event) {
                          //console.log(event);
                        },
                        error(event) {
                          //console.log('ERROR');
                          //console.log(event);
                        },
                        openMyPlaid(event) {
                          //console.log('OPEN_PLAID');
                          // console.log(event);
                        },
                        searchInstitution(event) {
                          //console.log('SEARCH_INSTITUTION');
                          //console.log(event);
                        },
                        selectInstitution(event) {
                          //console.log('SELECT_INSTITUTION');
                          //console.log(event);
                        },
                        submitCredentials(event) {
                          //console.log('SUBMIT_CREDS');
                          //console.log(event);
                        },
                        open(event) {
                          //console.log('OPEN');
                          //console.log(event);
                        },
                        handoff(event) {
                          //console.log('HANDOFF');
                          //console.log(event);
                        },
                        failOauth(event) {
                          //console.log('FAIL_OAUTH');
                          //console.log(event);
                        },
                        exit(event) {
                          //console.log('EXIT');
                          //console.log(event);
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ),
          }[paymentType]
        }
      </Grid>
    </PaymentForm>
  );
};
// PROPS TYPES
// --------------------------------------------------------
SPaymentForm.propTypes = {
  history: PropTypes.object,
  cart: PropTypes.object,
  quantityChanged: PropTypes.func,
  totalItems: PropTypes.func,
  token: PropTypes.string,
  paymentType: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  contactCreate: PropTypes.object,
  contactDelete: PropTypes.object,
  contactUpdate: PropTypes.object,
  contactList: PropTypes.object,
  addressObj: PropTypes.object,
  saleOrderCreate: PropTypes.object,
  saleOrderConfirm: PropTypes.object,
  saleOrderVerify: PropTypes.object,
  saleOrderRead: PropTypes.object,
  doResetSaleOrder: PropTypes.func,
  doVerifySaleOrder: PropTypes.func,
  doConfirmSaleOrder: PropTypes.func,
  doCreateUserContact: PropTypes.func,
  doDeleteUserContact: PropTypes.func,
  doGetUserContacts: PropTypes.func,
  doClearUserContact: PropTypes.func,
  doUpdateUserContact: PropTypes.func,
  doReadCart: PropTypes.func,
  setDeliverToAddressId: PropTypes.func,
  setInvoiceToAddressId: PropTypes.func,
  deliverToAddressId: PropTypes.number,
  invoiceToAddressId: PropTypes.number,
};
export default SPaymentForm;
