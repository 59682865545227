import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

const DynamicContent = props => {
  const { token, contentKey, dynamicPageRead, doReadDynamicPage, doClearDynamicPage } = props;
  const [dynamicData, setDynamicData] = React.useState(null);
  const [dynamicRequest, setDynamicRequest] = React.useState(false);

  useEffect(() => {
    if (!dynamicPageRead.fetching && !dynamicRequest) {
      setDynamicRequest(true);
      let id = contentKey;
      doReadDynamicPage({ token, id });
    }
  }, [token, contentKey, dynamicPageRead, dynamicRequest, setDynamicRequest]);

  return dynamicPageRead && dynamicPageRead?.data && dynamicPageRead?.data?.length && dynamicPageRead?.data?.find(item => item.key === contentKey) != null ? (
    <div dangerouslySetInnerHTML={{ __html: dynamicPageRead?.data?.find(item => item.key === contentKey)?.content }} />
  ) : (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Skeleton animation="wave" style={{ width: `100%` }} />
        <Skeleton animation="wave" style={{ width: `100%` }} />
        <Skeleton animation="wave" style={{ width: `100%` }} />
        <Skeleton animation="wave" style={{ width: `100%` }} />
      </Grid>
    </Grid>
  );
};

DynamicContent.propTypes = {
  token: PropTypes.string,
  contentKey: PropTypes.string,
  width: PropTypes.number,
  dynamicPageRead: PropTypes.object,
  doReadDynamicPage: PropTypes.func,
  doClearDynamicPage: PropTypes.func,
};

export default DynamicContent;
