// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_USERCONTACT_REQUEST,
  LIST_USERCONTACT_SUCCESS,
  LIST_USERCONTACT_FAILURE,
  READ_USERCONTACT_REQUEST,
  READ_USERCONTACT_SUCCESS,
  READ_USERCONTACT_FAILURE,
  UPDATE_USERCONTACT_REQUEST,
  UPDATE_USERCONTACT_SUCCESS,
  UPDATE_USERCONTACT_FAILURE,
  CREATE_USERCONTACT_REQUEST,
  CREATE_USERCONTACT_SUCCESS,
  CREATE_USERCONTACT_FAILURE,
  DELETE_USERCONTACT_REQUEST,
  DELETE_USERCONTACT_SUCCESS,
  DELETE_USERCONTACT_FAILURE,
} from './types';

// Actions
import { getUserContacts, getUserContact, createUserContact, updateUserContact, deleteUserContact } from './actions';

/**
 * Function that handles listing users
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetUserContacts({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getUserContacts, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_USERCONTACT_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_USERCONTACT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetUserContacts() {
  yield takeLatest(LIST_USERCONTACT_REQUEST, workerGetUserContacts);
}

/**
 * Function that handles getting a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetUserContact({ token, id }) {
  try {
    const response = yield call(getUserContact, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_USERCONTACT_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_USERCONTACT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetUserContact() {
  yield takeLatest(READ_USERCONTACT_REQUEST, workerGetUserContact);
}

/**
 * Function that handles creating a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateUserContact({ token, payload }) {
  try {
    const response = yield call(createUserContact, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_USERCONTACT_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_USERCONTACT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateUserContact() {
  yield takeLatest(CREATE_USERCONTACT_REQUEST, workerCreateUserContact);
}

/**
 * Function that handles updating a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateUserContact({ token, id, payload }) {
  try {
    const response = yield call(updateUserContact, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_USERCONTACT_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_USERCONTACT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateUserContact() {
  yield takeLatest(UPDATE_USERCONTACT_REQUEST, workerUpdateUserContact);
}

/**
 * Function that handles deleting a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteUserContact({ token, id }) {
  try {
    const response = yield call(deleteUserContact, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_USERCONTACT_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_USERCONTACT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteUserContact() {
  yield takeLatest(DELETE_USERCONTACT_REQUEST, workerDeleteUserContact);
}
