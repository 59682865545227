// Import types
import { CLEAR_DYNAMICPAGE_REQUEST, READ_DYNAMICPAGE_REQUEST } from './types';

// Export operations
/**
 * Dispatch for getting a dynamic page.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadDynamicPage = ({ token, id, public_page = false }) => ({
  type: READ_DYNAMICPAGE_REQUEST,
  token,
  id,
  public_page,
});

/**
 * Clears the reducer
 * @return {Object} Dispatch
 */
export const doClearDynamicPage = () => ({
  type: CLEAR_DYNAMICPAGE_REQUEST,
});
