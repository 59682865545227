import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Skeleton, TableFooter, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import FormatUtil from '../../../utils/FormatUtil';
import Typography from '@mui/material/Typography';
import { SALEORDER_RESET } from '../../../containers/SaleOrder/types';
import Chip from '@mui/material/Chip';
import { Alert } from '@mui/lab';

const BasketPage = props => {
  const {
    token,
    history,
    cart,
    doDeleteCartItem,
    quantityChanged,
    totalItems,
    doEmptyCart,
    doReadCart,
    doResetSaleOrder,
    width,
    cartItemUpdate,
    cartItemCreate,
    user,
    cartItemLoading,
    isAndroidDevice,
  } = props;
  // const [deleteCartItemRequest, setDeleteCartItemRequest] =
  //
  // useEffect(() => {
  //   if (cart && !cart.fetching && cart.data) {
  //     let id = cart?.data?.id;
  //     doReadCart({ token, id });
  //   }
  // }, [token, cart]);
  return (
    <Grid item xs={12} md={12} lg={12}>
      {user?.role?.name === 'sales' ? (
        <div style={{ marginTop: 10 }} key={`sales-reps-not-allowed`}>
          <Alert severity="warning">Sales reps are not allowed to place orders. Impersonate a customer before trying to process a cart transaction.</Alert>
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table size={`small`}>
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={2} style={{ padding: width < 600 ? 5 : 15 }}>
                  <Typography style={{ fontSize: 20, fontWeight: `bold` }} variant="h1">
                    Your Basket
                  </Typography>
                </TableCell>
                <TableCell align="right" colSpan={width < 600 ? 2 : 5} style={{ padding: width < 600 ? 5 : 15 }}>
                  <Tooltip title="Empty your Basket">
                    <Button
                      variant="contained"
                      color="primary"
                      size={width < 600 ? 'small' : `medium`}
                      disabled={cart && !cart?.data}
                      style={{ marginBottom: 5 }}
                      onClick={event => {
                        let id = cart && cart?.data ? cart?.data?.id : null;
                        doEmptyCart({ token, id });
                        doResetSaleOrder(SALEORDER_RESET);
                      }}
                    >
                      {isAndroidDevice() ? 'Empty' : 'Empty Basket'}
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: `bold`,
                    fontSize: width < 600 ? 10 : 16,
                    padding: width < 600 ? 5 : 15,
                  }}
                >
                  Code
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: `bold`,
                    fontSize: width < 600 ? 10 : 16,
                    padding: width < 600 ? 5 : 15,
                    display: width < 600 ? 'none' : 'block',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: `bold`,
                    fontSize: width < 600 ? 10 : 16,
                    padding: width < 600 ? 5 : 15,
                  }}
                >
                  Qty.
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: `bold`,
                    fontSize: width < 600 ? 10 : 16,
                    padding: width < 600 ? 5 : 15,
                  }}
                >
                  Unit $
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: `bold`,
                    fontSize: width < 600 ? 10 : 16,
                    padding: width < 600 ? 5 : 15,
                    display: width < 600 ? 'none' : 'table-cell',
                  }}
                >
                  Total $
                </TableCell>
                <TableCell align="center" style={{ fontWeight: `bold` }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {cart && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length ? (
                cart?.data?.cart_items.map(cartItem => (
                  <TableRow key={cartItem?.product_variant ? cartItem?.product_variant?.variant_code : cartItem?.product?.product_code}>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: width < 600 ? 12 : 16,
                        padding: width < 600 ? 5 : 15,
                        paddingRight: 0,
                      }}
                    >
                      {width < 600 && cartItem?.product_variant ? (
                        <div>
                          <div
                            style={{
                              marginBottom: 3,
                              padding: 2,
                            }}
                          >
                            {cartItem?.product_variant && cartItem?.product_variant?.variant_name}
                          </div>
                          <div style={{ marginBottom: 3, padding: 2 }}>{cartItem?.product_variant.variant_code}</div>
                          <div style={{ marginBottom: 5, position: `relative`, left: -5, padding: 2 }}>
                            {cartItem?.product_variant &&
                              cartItem?.product_variant?.product_attributes.map(attribute => {
                                // eslint-disable-next-line react/jsx-key
                                return <Chip key={`attribute-${attribute?.id}`} label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                              })}
                          </div>
                          <div style={{ fontWeight: `bold`, marginBottom: 5, padding: 2 }}>
                            Stock:{' '}
                            {cartItem?.product_variant && cartItem?.product_variant?.quantity >= 1 ? (
                              <span style={{ color: `green` }}>{cartItem?.product_variant?.quantity}</span>
                            ) : (
                              <span style={{ color: `red` }}>Not In Stock</span>
                            )}
                          </div>
                        </div>
                      ) : cartItem?.product_variant ? (
                        cartItem?.product_variant?.variant_code
                      ) : (
                        cartItem?.product?.product_code
                      )}
                      {}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 16,
                        padding: width < 600 ? 5 : 15,
                        display: width < 600 ? 'none' : 'table-cell',
                      }}
                    >
                      {cartItem?.product_variant ? `${cartItem?.product_variant?.variant_name}` : cartItem?.product?.product_name}
                      <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                        {cartItem?.product_variant &&
                          cartItem?.product_variant?.product_attributes.map(attribute => {
                            // eslint-disable-next-line react/jsx-key
                            return <Chip key={`attribute-${attribute?.id}`} label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                          })}
                      </div>
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: 16, minWidth: 80, padding: width < 600 ? 5 : 15 }}>
                      <TextField
                        className={`center-input`}
                        inputProps={{ style: { width: width < 600 ? (width < 400 ? `50px` : `75px`) : `100px` } }}
                        size={`small`}
                        sx={{ width: '50%' }}
                        disabled={cartItemCreate.fetching || cartItemUpdate.fetching || cart?.data?.checkout_override || cart?.fetching || cartItemLoading}
                        defaultValue={cartItem?.quantity}
                        onBlur={event => quantityChanged(event, cartItem.product, cartItem.product_variant)}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: width < 600 ? 10 : 16, padding: width < 600 ? 5 : 15 }}>
                      ${cartItem?.product_variant ? cartItem?.item_total : cartItem?.product?.unit_price}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: width < 600 ? 10 : 16,
                        padding: width < 600 ? 5 : 15,
                        display: width < 600 ? 'none' : 'table-cell',
                      }}
                    >
                      ${cartItem?.product_variant ? FormatUtil.numberWithCommas(cartItem?.item_total * cartItem?.quantity) : FormatUtil.numberWithCommas(cartItem?.item_total * cartItem?.quantity)}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Remove Item">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          size={width < 600 ? 'small' : 'medium'}
                          disabled={cartItemCreate.fetching || cartItemUpdate.fetching || Boolean(cart?.data?.checkout_override)}
                          onClick={() => {
                            if (!cart?.data?.checkout_override) {
                              let id = cartItem?.id;
                              doDeleteCartItem({ token, id });
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={`basket-loading`}>
                  <TableCell align="center" colSpan={width < 600 ? 4 : 6}>
                    <p>There is currently nothing in the cart.</p>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={width < 600 ? 1 : 3} />
                <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  Total ({totalItems(cart)} Items)
                </TableCell>
                <TableCell align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  ${cart && cart?.data && cart?.data ? FormatUtil.numberWithCommas(parseFloat(cart?.data?.cart_total)) : 0}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: `bold` }} />
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="right" colSpan={width < 600 ? 5 : 6} style={{ padding: 15 }}>
                  <Tooltip title="Proceed to Checkout">
                    <Button
                      variant="contained"
                      color="primary"
                      size={width < 600 ? 'small' : `medium`}
                      onClick={() => {
                        history.push('/catalog/checkout');
                      }}
                      disabled={!(cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1)}
                      endIcon={<ArrowForwardIcon />}
                    >
                      Checkout
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

// PROPS TYPES
// --------------------------------------------------------
BasketPage.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  cart: PropTypes.object,
  doDeleteCartItem: PropTypes.func,
  doEmptyCart: PropTypes.func,
  doReadCart: PropTypes.func,
  quantityChanged: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  cartItemCreate: PropTypes.object,
  cartItemUpdate: PropTypes.object,
  totalItems: PropTypes.func,
  token: PropTypes.string,
  width: PropTypes.number,
  cartItemLoading: PropTypes.bool,
};

export default BasketPage;
