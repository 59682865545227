// Import types
import { LIST_CATEGORY_REQUEST, READ_CATEGORY_REQUEST, UPDATE_CATEGORY_REQUEST, CREATE_CATEGORY_REQUEST, DELETE_CATEGORY_REQUEST, CATEGORY_RESET } from './types';
import { CARTITEM_RESET } from '../CartItem/types';

// Export operations
/**
 * Dispatch for getting categories.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetCategories = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_CATEGORY_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for getting a specific category.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doGetCategory = ({ token, id }) => ({
  type: READ_CATEGORY_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating a category.
 * @param {Object} { token: string, payload: { category_header: string, name: string, active: string, show_navigation: string, preview_images: string } }
 * @return {Object} Dispatch
 */
export const doCreateCategory = ({ token, payload }) => ({
  type: CREATE_CATEGORY_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for updating a category.
 * @param {Object} { token: string, payload: { category_header: string, name: string, active: string, show_navigation: string, preview_images: string } }
 * @return {Object} Dispatch
 */
export const doUpdateCategory = ({ token, id, payload }) => ({
  type: UPDATE_CATEGORY_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for deleting a specific category.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doDeleteCategory = ({ token, id }) => ({
  type: DELETE_CATEGORY_REQUEST,
  token,
  id,
});

export const doResetCategories = () => ({
  type: CATEGORY_RESET,
});
