export const LIST_USERCONTACT_REQUEST = 'LIST_USERCONTACT_REQUEST';
export const LIST_USERCONTACT_SUCCESS = 'LIST_USERCONTACT_SUCCESS';
export const LIST_USERCONTACT_FAILURE = 'LIST_USERCONTACT_FAILURE';
export const READ_USERCONTACT_REQUEST = 'READ_USERCONTACT_REQUEST';
export const READ_USERCONTACT_SUCCESS = 'READ_USERCONTACT_SUCCESS';
export const READ_USERCONTACT_FAILURE = 'READ_USERCONTACT_FAILURE';
export const UPDATE_USERCONTACT_REQUEST = 'UPDATE_USERCONTACT_REQUEST';
export const UPDATE_USERCONTACT_SUCCESS = 'UPDATE_USERCONTACT_SUCCESS';
export const UPDATE_USERCONTACT_FAILURE = 'UPDATE_USERCONTACT_FAILURE';
export const CREATE_USERCONTACT_REQUEST = 'CREATE_USERCONTACT_REQUEST';
export const CREATE_USERCONTACT_SUCCESS = 'CREATE_USERCONTACT_SUCCESS';
export const CREATE_USERCONTACT_FAILURE = 'CREATE_USERCONTACT_FAILURE';
export const DELETE_USERCONTACT_REQUEST = 'DELETE_USERCONTACT_REQUEST';
export const DELETE_USERCONTACT_SUCCESS = 'DELETE_USERCONTACT_SUCCESS';
export const DELETE_USERCONTACT_FAILURE = 'DELETE_USERCONTACT_FAILURE';
export const CLEAR_USERCONTACT = 'CLEAR_USERCONTACT';
