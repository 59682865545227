// Imports
import axios from 'axios';

// Confi

// Export actions
export function login(loginData) {
  const payload = {};
  if (loginData.email.indexOf('@') > -1) {
    payload.email = loginData.email;
    payload.password = loginData.password;
  } else {
    payload.username = loginData.email;
    payload.password = loginData.password;
  }

  return axios({
    method: 'post',
    url: `${window.API_URL}/auth/login`,
    data: { ...payload },
    withCredentials: true,
  });
}

export function me({ token }) {
  return axios({
    method: 'get',
    url: `${window.API_URL}/auth/me`,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function logout({ token, redirect }) {
  return axios({
    method: 'get',
    url: `${window.API_URL}/auth/logout`,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function forgotPassword(forgotData) {
  const payload = {};
  if (forgotData.email.indexOf('@') > -1) {
    payload.email = forgotData.email;
  } else {
    payload.username = forgotData.email;
  }
  return axios({
    method: 'post',
    url: `${window.API_URL}/auth/forgotpassword`,
    data: { ...payload },
  });
}

export function unsubscribe({ email }) {
  return axios({
    method: 'post',
    url: `${window.API_URL}/auth/unsubscribe`,
    data: { email },
  });
}

export function passwordReset({ password, password_confirmation, token, validator }) {
  return axios({
    method: 'post',
    url: `${window.API_URL}/auth/passwordreset`,
    data: { password, password_confirmation, token, validator },
  });
}

export function customerActivation({ password, password_confirmation, token, validator }) {
  return axios({
    method: 'post',
    url: `${window.API_URL}/auth/customer/activation`,
    data: { password, password_confirmation, token, validator },
  });
}

export function salesRepActivation({ password, password_confirmation, token, validator }) {
  return axios({
    method: 'post',
    url: `${window.API_URL}/auth/salesrep/activation`,
    data: { password, password_confirmation, token, validator },
  });
}

export function impersonate({ token, user_id }) {
  return axios({
    method: 'post',
    url: `${window.API_URL}/auth/impersonate`,
    data: { user_id },
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function register(payload) {
  return axios({
    method: 'post',
    url: `${window.API_URL}/auth/register`,
    data: { ...payload },
    withCredentials: true,
  });
}
