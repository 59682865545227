// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import VerificationStyles from './styles';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

/**
 * VERIFICATION COMPONENT
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
// eslint-disable-next-line no-unused-vars
const Verification = props => {
  const { token, history, match, user, doUpdateUser, doMe, userUpdate } = props;

  const [verificationRequest, setVerificationRequest] = useState(false);
  const [resetRequest, setResetRequest] = useState(false);

  useEffect(() => {
    if (resetRequest && !userUpdate?.fetching) {
      setResetRequest(false);
      doMe({ token });
    }
  }, [resetRequest, token, userUpdate]);

  useEffect(() => {
    if (verificationRequest && user) {
      setVerificationRequest(false);
      let id = user?.id;
      let payload = {};
      payload['age_verified'] = true;
      doUpdateUser({ token, id, payload });
      setResetRequest(true);
    }
  }, [verificationRequest, user, token]);

  return (
    <VerificationStyles>
      <Container disableGutters={true} className={`container-body`} style={{ position: 'relative', top: 0 }}>
        <div className={'verification-body'} key={`verification-body`} style={{ textAlign: 'center', padding: 20 }}>
          <h1>Age Verification</h1>
          <p>The products and services on this website are intended for adults who are 21 and older.</p>
          <p>By entering this website, you certify that you are of the legal smoking age in the state in which you reside.</p>
          <LoadingButton
            // loading={}
            loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
            variant="contained"
            color="primary"
            onClick={() => {
              setVerificationRequest(true);
            }}
            endIcon={<ArrowRightIcon />}
            // disabled={!(cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1) || paymentPageUpdateLoading || cart?.data?.checkout_override}
          >
            I am 21 or older
          </LoadingButton>
        </div>
      </Container>
    </VerificationStyles>
  );
};

// PROPS TYPES
// --------------------------------------------------------
Verification.defaultProps = {
  view: '',
};

Verification.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  userUpdate: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
  doUpdateUser: PropTypes.func,
  doMe: PropTypes.func,
};

// EXPORTS
// --------------------------------------------------------
// Export
export default Verification;
