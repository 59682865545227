import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Link, TableFooter } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert, LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';

const SavedPricingPage = props => {
  const {
    user,
    token,
    match,
    history,
    doReadCustomer,
    doCustomerReset,
    doSavedPricingReset,
    customerRead,
    doGetSavedPricing,
    savedPricings,
    savedPricingUpdate,
    doUpdateAllSavedPricing,
    doUpdatePricingModel,
    width,
  } = props;
  const { page } = match?.params;

  const [savedPricingModel, setSavedPricingModel] = useState([]);

  const [savePricingRequest, setSavePricingRequest] = useState(false);
  const [badSavedPricing, setBadSavedPricing] = useState([]);
  const [productFilter, setProductFilter] = React.useState('');
  const [lowestPriceVisible, setLowestPriceVisible] = React.useState(false);
  useEffect(() => {
    if (savedPricingUpdate?.complete) {
      doUpdatePricingModel({ update: { complete: false } });
      Swal.fire({
        icon: 'success',
        text: 'Product pricing for this customer has been saved.',
        showConfirmButton: false,
        timer: 3500,
        // didClose: function() {},
      });
    }
  }, [savedPricingUpdate]);

  useEffect(() => {
    if (savePricingRequest && savedPricings?.data && !savedPricings?.fetching) {
      setSavePricingRequest(false);
      let badValidation = false;
      let badArray = [];
      let payload = {
        customer_id: parseInt(page),
        saved_pricing: savedPricings.data.map(item => {
          if (parseFloat(item?.item_total) < parseFloat(item.lowest_price.lowest_total)) {
            badValidation = true;
            badArray.push(item.id);
          }
          return { product_id: item?.product_id, item_total: parseFloat(item?.item_total) };
        }),
      };
      if (!badValidation) {
        setBadSavedPricing([]);
        doUpdateAllSavedPricing({ token, payload });
      } else {
        setBadSavedPricing(badArray);
        Swal.fire({
          icon: 'error',
          text: 'One of your products is below the minimum threshold',
          timer: 3500,
          // didClose: function() {},
        });
      }
    }
  }, [token, savePricingRequest, savedPricings]);

  useEffect(() => {
    if (savedPricings?.data && !savedPricings?.fetching) {
      setSavedPricingModel(savedPricings?.data);
    }
  }, [token, match]);

  useEffect(() => {
    doUpdatePricingModel({ list: { data: savedPricingModel } });
  }, [savedPricingModel]);

  useEffect(() => {
    if (match?.params?.page) {
      let id = match?.params?.page;
      doReadCustomer({ token, id });
    }
  }, [token, match]);

  useEffect(() => {
    if (match?.params?.page) {
      let id = match?.params?.page;
      let query = productFilter;
      let direction = 'asc';
      let order = 'product.category_id';
      let limit = 1000;
      let page = 1;
      doGetSavedPricing({ token, id, query, direction, order, limit, page });
    }
  }, [token, match, productFilter]);

  useEffect(() => {
    if ((user && user?.role && user?.role?.name === 'sales') || (user && user?.role && user?.role?.name === 'admin')) {
    } else {
      history.push('/catalog');
    }
  }, [user]);

  return (
    <Grid container spacing={1.5}>
      {(user && user?.role && user?.role?.name === 'sales') || (user && user?.role && user?.role?.name === 'admin') ? (
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Button
                    color="primary"
                    fullWidth={true}
                    variant="contained"
                    onClick={() => {
                      history.push('/catalog/customers');
                    }}
                  >
                    Go Back
                  </Button>

                  <Typography align="left" variant="h4" sx={{ fontWeight: 'bold' }}>
                    {customerRead?.data?.user?.company_name}
                  </Typography>
                  <Typography align="left" variant="h4" sx={{ fontWeight: 'bold' }}>
                    Saved Pricing
                  </Typography>
                </Breadcrumbs>
                <Box style={{ float: `right` }}>
                  <Button
                    color="primary"
                    style={{ color: 'black' }}
                    sx={{ position: 'relative' }}
                    onClick={() => {
                      setLowestPriceVisible(!lowestPriceVisible);
                    }}
                  >
                    <ChangeHistoryIcon fontSize={`large`} />
                    {lowestPriceVisible ? (
                      <VisibilityIcon
                        fontSize={'8'}
                        sx={{
                          position: 'absolute',
                          top: '58.33%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                size={'small'}
                variant="outlined"
                label={'Search'}
                onChange={event => {
                  setProductFilter(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Table aria-label="spanning table">
                <TableHead>
                  {/* Ordered Items Headers */}
                  <TableRow>
                    <TableCell align="left" width={'10%'} style={{ fontWeight: `bold`, display: width < 600 ? 'none' : 'table-cell' }}>
                      Category
                    </TableCell>
                    <TableCell align="left" width={'50%'} style={{ fontWeight: `bold` }}>
                      Product
                    </TableCell>
                    <TableCell align="center" width={width < 600 ? '25%' : `10%`} style={{ fontWeight: `bold` }}>
                      {lowestPriceVisible ? 'Lowest Price' : 'Starting Price'}
                    </TableCell>
                    <TableCell align="center" width={width < 600 ? '25%' : `10%`} style={{ fontWeight: `bold` }}>
                      Saved Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {savedPricingModel && savedPricings?.data && !savedPricings?.fetching ? (
                    savedPricings?.data.map(savedPrice => (
                      <TableRow key={`savedpricing-${savedPrice?.id}`}>
                        <TableCell align="left" width={'10%'} style={{ fontWeight: `bold`, display: width < 600 ? 'none' : 'table-cell' }}>
                          {savedPrice?.product?.category?.category_name}
                        </TableCell>
                        <TableCell align="left" width={'50%'} style={{ fontWeight: `bold` }}>
                          {width < 600 ? (
                            <div>
                              <div>{savedPrice?.product?.category?.category_name}</div>
                              <div>{savedPrice?.product?.product_name}</div>
                            </div>
                          ) : (
                            savedPrice?.product?.product_name
                          )}
                        </TableCell>
                        <TableCell align="center" width={width < 600 ? '25%' : `10%`} style={{ fontWeight: `bold` }}>
                          {width < 600 ? (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <div>
                                {lowestPriceVisible ? <div>{savedPrice?.lowest_price?.lowest_total}</div> : <div>{savedPrice?.product?.unit_price}</div>}
                                {/*<div style={{ color: `red` }}>{parseFloat(item.lowest_price.lowest_total).toFixed(2)}</div>*/}
                              </div>
                            </Box>
                          ) : (
                            // <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>{lowestPriceVisible ? <div>{savedPrice?.lowest_price?.lowest_total}</div> : <div>{savedPrice?.product?.unit_price}</div>}</div>
                          )}
                        </TableCell>
                        {/*<TableCell align="center" width={`10%`} style={{ fontWeight: `bold`, display: width < 600 ? 'none' : 'table-cell' }}>*/}
                        {/*  {parseFloat(item.lowest_price.lowest_total).toFixed(2)}*/}
                        {/*</TableCell>*/}
                        <TableCell align="center" width={width < 600 ? '25%' : `10%`} style={{ fontWeight: `bold` }}>
                          <TextField
                            error={!!(badSavedPricing && badSavedPricing?.find(item => item === savedPrice?.id))}
                            size={`small`}
                            className={`center-input`}
                            style={{ textAlign: `center` }}
                            label="Saved Price"
                            value={savedPrice?.item_total}
                            onChange={event => {
                              //console.log(event);
                              let packet = savedPricings?.data.map(item => {
                                if (item.id === savedPrice?.id) {
                                  return { ...item, item_total: event.target.value };
                                }
                                return item;
                              });
                              if (packet) {
                                setSavedPricingModel(packet);
                              }

                              //console.log(savedPricingModel);
                              // savedPricings?.data.filter(item => (item.id = savedPrice?.id))[0].item_total = event.target.value;
                            }}
                            // onChange={handleChange('city')}
                            // placeholder={`City`}
                            variant="outlined"
                            fullWidth={true}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center" width={'100%'} style={{ fontWeight: `bold` }}>
                        Loading
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={4} align="center" width={'100%'} style={{ fontWeight: `bold` }}>
                      <LoadingButton
                        loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                        loading={savedPricingUpdate?.fetching}
                        disabled={savedPricingUpdate?.fetching}
                        size={`large`}
                        color="primary"
                        fullWidth={true}
                        variant="contained"
                        onClick={() => {
                          setSavePricingRequest(true);
                        }}
                      >
                        <Typography align="center" variant="h5" sx={{ fontWeight: 'bold' }}>
                          Update Saved Pricing
                        </Typography>
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Alert severity="error">Permission Denied</Alert>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

SavedPricingPage.propTypes = {
  savedPricings: PropTypes.object,
  customerRead: PropTypes.object,
  savedPricingUpdate: PropTypes.object,
  customers: PropTypes.object,
  user: PropTypes.object,
  width: PropTypes.number,
  match: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string,
  doUpdatePricingModel: PropTypes.func,
  doSavedPricingReset: PropTypes.func,
  doGetSavedPricing: PropTypes.func,
  doReadCustomer: PropTypes.func,
  doCustomerReset: PropTypes.func,
  doUpdateAllSavedPricing: PropTypes.func,
};

export default SavedPricingPage;
