// Imports

// Order Action Types.
import { LIST_CUSTOMER_PAYMENT_METHODS_REQUEST, LIST_CUSTOMER_PAYMENT_METHODS_FAILURE, LIST_CUSTOMER_PAYMENT_METHODS_SUCCESS, CLEAR_CUSTOMER_PAYMENT_METHODS } from './types';

// Initial Reducer States.
const initialState = {
  bluepayCustomerPaymentMethodList: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
};

/**
 * Order Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const bluepayReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CUSTOMER_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        bluepayCustomerPaymentMethodList: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_CUSTOMER_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        bluepayCustomerPaymentMethodList: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_CUSTOMER_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        bluepayCustomerPaymentMethodList: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };

    case CLEAR_CUSTOMER_PAYMENT_METHODS:
      return {
        ...state,
        bluepayCustomerPaymentMethodList: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    default:
      return state;
  }
};

// Exports
export default bluepayReducer;
