import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Checkbox, Skeleton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const AddressSelectorElement = props => {
  const {
    token,
    match,
    contactData,
    contactList,
    contactCreate,
    contactUpdate,
    contactDelete,
    doGetUserContacts,
    doCreateUserContact,
    doClearUserContact,
    doUpdateUserContact,
    setContactData,
    setAddressEditModalOpen,
    doDeleteUserContact,
    handleSelection,
    currentSelection,
  } = props;

  const { page } = match?.params;

  useEffect(() => {
    if (
      (!contactList.fetching && !contactList.data) ||
      (!contactCreate?.fetching && contactCreate?.data) ||
      (!contactUpdate?.fetching && contactUpdate?.data) ||
      (!contactDelete?.fetching && contactDelete?.success)
    ) {
      doClearUserContact();
      let query = '';
      let direction = '';
      let order = '';
      let limit = 100;
      let page = 1;
      doGetUserContacts({ token, query, direction, order, limit, page });
    }
  }, [doGetUserContacts, doClearUserContact, contactDelete, contactCreate, contactUpdate]);

  return (
    <Grid item xs={12} md={12} lg={12}>
      {contactList && !contactList?.fetching && !contactCreate?.fetching && !contactUpdate?.fetching ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: `bold`, width: 50 }}>
                  Primary
                </TableCell>
                <TableCell align="left" style={{ fontWeight: `bold` }}>
                  Address
                </TableCell>
                <TableCell align="right" style={{ fontWeight: `bold`, width: 100 }} />
                <TableCell align="right" style={{ fontWeight: `bold`, width: 100 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {contactList &&
                !contactList?.fetching &&
                contactList?.data &&
                contactList?.data.map(row => (
                  <TableRow selected={currentSelection() === 0 ? (row.enabled ? true : false) : row.id === currentSelection()} key={'address' + row.id}>
                    <TableCell align="left" style={{ width: 50 }}>
                      <Checkbox label={``} defaultChecked={Boolean(row.enabled)} disabled={true} size="small" />
                    </TableCell>
                    <TableCell align="left">
                      {row.company_name} <br />
                      {row.address_line_1} {row.address_line_2} <br /> {row.city} {row.state} {row.zipcode}
                    </TableCell>

                    <TableCell align="right" style={{ width: 100 }}>
                      <IconButton
                        variant={'contained'}
                        size="small"
                        onClick={() => {
                          setContactData(row);
                          setAddressEditModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right" style={{ width: 150 }}>
                      <Button
                        variant={'contained'}
                        size="small"
                        onClick={() => {
                          setContactData(row);
                          handleSelection(row.id);
                        }}
                      >
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Grid>
        </Grid>
      )}
      {/*<TablePagination component="div" count={rows.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />*/}
    </Grid>
  );
};

AddressSelectorElement.propTypes = {
  token: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  contactData: PropTypes.object,
  user: PropTypes.object,
  contactCreate: PropTypes.object,
  contactUpdate: PropTypes.object,
  contactDelete: PropTypes.object,
  contactList: PropTypes.object,
  doCreateUserContact: PropTypes.func,
  doGetUserContacts: PropTypes.func,
  doClearUserContact: PropTypes.func,
  doUpdateUserContact: PropTypes.func,
  setContactData: PropTypes.func,
  setAddressEditModalOpen: PropTypes.func,
  doDeleteUserContact: PropTypes.func,
  handleSelection: PropTypes.func,
  currentSelection: PropTypes.func,
};

export default AddressSelectorElement;
