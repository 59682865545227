export const LIST_NOTIFICATION_CAMPAIGN_REQUEST = 'LIST_NOTIFICATION_CAMPAIGN_REQUEST';
export const LIST_NOTIFICATION_CAMPAIGN_SUCCESS = 'LIST_NOTIFICATION_CAMPAIGN_SUCCESS';
export const LIST_NOTIFICATION_CAMPAIGN_FAILURE = 'LIST_NOTIFICATION_CAMPAIGN_FAILURE';
export const READ_NOTIFICATION_CAMPAIGN_REQUEST = 'READ_NOTIFICATION_CAMPAIGN_REQUEST';
export const READ_NOTIFICATION_CAMPAIGN_SUCCESS = 'READ_NOTIFICATION_CAMPAIGN_SUCCESS';
export const READ_NOTIFICATION_CAMPAIGN_FAILURE = 'READ_NOTIFICATION_CAMPAIGN_FAILURE';
export const UPDATE_NOTIFICATION_CAMPAIGN_REQUEST = 'UPDATE_NOTIFICATION_CAMPAIGN_REQUEST';
export const UPDATE_NOTIFICATION_CAMPAIGN_SUCCESS = 'UPDATE_NOTIFICATION_CAMPAIGN_SUCCESS';
export const UPDATE_NOTIFICATION_CAMPAIGN_FAILURE = 'UPDATE_NOTIFICATION_CAMPAIGN_FAILURE';
export const CREATE_NOTIFICATION_CAMPAIGN_REQUEST = 'CREATE_NOTIFICATION_CAMPAIGN_REQUEST';
export const CREATE_NOTIFICATION_CAMPAIGN_SUCCESS = 'CREATE_NOTIFICATION_CAMPAIGN_SUCCESS';
export const CREATE_NOTIFICATION_CAMPAIGN_FAILURE = 'CREATE_NOTIFICATION_CAMPAIGN_FAILURE';
export const DELETE_NOTIFICATION_CAMPAIGN_REQUEST = 'DELETE_NOTIFICATION_CAMPAIGN_REQUEST';
export const DELETE_NOTIFICATION_CAMPAIGN_SUCCESS = 'DELETE_NOTIFICATION_CAMPAIGN_SUCCESS';
export const DELETE_NOTIFICATION_CAMPAIGN_FAILURE = 'DELETE_NOTIFICATION_CAMPAIGN_FAILURE';
export const NOTIFICATION_CAMPAIGN_RESET = 'NOTIFICATION_CAMPAIGN_RESET';
