import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal } from '@mui/material';

/*
  - Requirements for Modal Usage in target location -

  const [customModalOpen, setCustomModalOpen] = React.useState(false);
  const handleCustomModalOpen = () => setCustomModalOpen(true);
  const handleCustomModalClose = () => setCustomModalOpen(false);


  - Example of Modal Usage -

  <CustomModal
    isOpen={customModalOpen}
    handleClose={handleCustomModalClose}

    title={
      <Grid item xs={12} align={'center'}>
        <Typography variant={'h4'}>Test Custom Modal Title</Typography>
        <hr />
      </Grid>
    }

    body={
      <Grid item xs={12}>
        Test Child
      </Grid>
    }

    actions={
      <Grid item xs={12}>
        <hr />
        <Stack justifyContent={'center'} spacing={0.5} direction={'row'}>
          <Button variant={'contained'}>
            <Typography sx={{ textTransform: 'none' }}>Test Action 1</Typography>
          </Button>
          <Button variant={'contained'}>
            <Typography sx={{ textTransform: 'none' }} onClick={handleCustomModalClose}>
              Close
            </Typography>
          </Button>
        </Stack>
      </Grid>
    }
  />
*/

export const CustomModal = ({ props, isOpen, handleClose, title, body, actions, maxWidth = `600px` }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: maxWidth,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
  };
  return (
    <Modal open={isOpen} onClose={handleClose}>
      {/* Modal Container */}
      <Grid container sx={style}>
        {/* Title Container  */}
        <Grid container>{title}</Grid>

        {/* Body Container */}
        <Grid container pt={2}>
          {body}
        </Grid>

        {/* Actions Container */}
        <Grid container pt={2}>
          {actions}
        </Grid>
      </Grid>
    </Modal>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.element.isRequired,
  body: PropTypes.element.isRequired,
  actions: PropTypes.element,
  maxWidth: PropTypes.string,
};
