export const READ_FIREBASETOKEN_REQUEST = 'READ_FIREBASETOKEN_REQUEST';
export const READ_FIREBASETOKEN_SUCCESS = 'READ_FIREBASETOKEN_SUCCESS';
export const READ_FIREBASETOKEN_FAILURE = 'READ_FIREBASETOKEN_FAILURE';
export const UPDATE_FIREBASETOKEN_REQUEST = 'UPDATE_FIREBASETOKEN_REQUEST';
export const UPDATE_FIREBASETOKEN_SUCCESS = 'UPDATE_FIREBASETOKEN_SUCCESS';
export const UPDATE_FIREBASETOKEN_FAILURE = 'UPDATE_FIREBASETOKEN_FAILURE';
export const CREATE_FIREBASETOKEN_REQUEST = 'CREATE_FIREBASETOKEN_REQUEST';
export const CREATE_FIREBASETOKEN_SUCCESS = 'CREATE_FIREBASETOKEN_SUCCESS';
export const CREATE_FIREBASETOKEN_FAILURE = 'CREATE_FIREBASETOKEN_FAILURE';
export const DELETE_FIREBASETOKEN_REQUEST = 'DELETE_FIREBASETOKEN_REQUEST';
export const DELETE_FIREBASETOKEN_SUCCESS = 'DELETE_FIREBASETOKEN_SUCCESS';
export const DELETE_FIREBASETOKEN_FAILURE = 'DELETE_FIREBASETOKEN_FAILURE';
export const FIREBASETOKEN_RESET = 'FIREBASETOKEN_RESET';
