// Import types
import { LIST_CUSTOMER_REQUEST, READ_CUSTOMER_REQUEST, UPDATE_CUSTOMER_REQUEST, CREATE_CUSTOMER_REQUEST, DELETE_CUSTOMER_REQUEST, CUSTOMER_RESET } from './types';

// Export operations
/**
 * Dispatch for getting a list of Customers.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetCustomers = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_CUSTOMER_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for getting a specific Customer.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadCustomer = ({ token, id }) => ({
  type: READ_CUSTOMER_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating a Customer.
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doCreateCustomer = ({ token, payload }) => ({
  type: CREATE_CUSTOMER_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for updating a Customer.
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doUpdateCustomer = ({ token, id, payload }) => ({
  type: UPDATE_CUSTOMER_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for deleting a specific Customer.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doDeleteCustomer = ({ token, id }) => ({
  type: DELETE_CUSTOMER_REQUEST,
  token,
  id,
});

/**
 * Dispatch for resetting customer reducer
 * @param {Object} {  }
 * @return {Object} Dispatch
 */
export const doCustomerReset = () => ({
  type: CUSTOMER_RESET,
});
