// Imports
import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';

// Types
import { READ_DYNAMICPAGE_REQUEST, READ_DYNAMICPAGE_SUCCESS, READ_DYNAMICPAGE_FAILURE } from './types';

// Actions
import { readDynamicPage } from './actions';

/**
 * Function that handles getting a specific dynamic page
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadDynamicPage({ token, id }) {
  try {
    const response = yield call(readDynamicPage, {
      token,
      id,
    });

    const { data } = response.data;
    yield put({
      type: READ_DYNAMICPAGE_SUCCESS,
      data,
    });
  } catch (error) {
    console.error('Error in workerReadDynamicPage:', error);
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_DYNAMICPAGE_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadDynamicPage() {
  yield takeEvery(READ_DYNAMICPAGE_REQUEST, workerReadDynamicPage);
}
