// Imports
import styled from 'styled-components';

// Main Component
const CatalogStyles = styled.div`
  position: absolute;
  width: 100%;
  height: ${props => (props?.fireTheme ? `250vh` : '150vh')};
  top: 0px;
  //height: 100vh;
  background: ${props => (props?.fireTheme ? '#0d0d0d' : '#dedede')};
  //background: rgba(0, 0, 0, 0.87);
  //background-color: #000;

  .logo {
    -webkit-filter: ${props => (props?.fireTheme ? 'invert(1)' : '')};
    filter: ${props => (props?.fireTheme ? 'invert(1)' : '')};
  }

  .approved-payments {
    -webkit-filter: ${props => (props?.fireTheme ? 'invert(1)' : '')};
    filter: ${props => (props?.fireTheme ? 'invert(1)' : '')};
  }

  @media (min-width: 1200px) {
    .MuiContainer-root {
      max-width: 1550px !important;
    }
  }
  // height: 100vh;
  // overlay-y: scroll;
  .catalog-body {
    background-color: ${props => (props?.fireTheme ? '#0d0d0d' : '#fff')};
    color: ${props => (props?.fireTheme ? '#fff' : '#000')};
    //line-height: 2.5rem;
    //background-color: #fff !important;
    // position: relative;
    // top: 50px;
    position: relative;
    top: 0;
  }
  .Mui-focused {
    color: #000 !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000 !important;
    border-width: 2px !important;
  }
  .dashboard-navbar {
    height: 50px !important;
  }
  .MuiToolbar-regular {
    min-height: 50px !important;
  }
  .MuiIconButton-root {
    border-radius: 0;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .cart-nav {
      display: none;
    }
    .cart-mobile-nav {
      display: block;
    }
    .search-nav {
      display: none;
    }
    .search-mobile-nav {
      display: block;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .cart-nav {
      display: none;
    }
    .cart-mobile-nav {
      display: block;
    }
    .search-nav {
      display: none;
    }
    .search-mobile-nav {
      display: block;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .cart-nav {
      display: none;
    }
    .cart-mobile-nav {
      display: block;
    }
    .search-nav {
      display: none;
    }
    .search-mobile-nav {
      display: block;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .cart-nav {
      display: block;
    }
    .cart-mobile-nav {
      display: none;
    }
    .search-nav {
      display: block;
    }
    .search-mobile-nav {
      display: none;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .cart-nav {
      display: block;
    }
    .cart-mobile-nav {
      display: none;
    }
    .search-nav {
      display: block;
    }
    .search-mobile-nav {
      display: none;
    }
  }
`;

// Export
export default CatalogStyles;
