export const LIST_USER_REQUEST = 'LIST_USER_REQUEST';
export const LIST_USER_SUCCESS = 'LIST_USER_SUCCESS';
export const LIST_USER_FAILURE = 'LIST_USER_FAILURE';
export const READ_USER_REQUEST = 'READ_USER_REQUEST';
export const READ_USER_SUCCESS = 'READ_USER_SUCCESS';
export const READ_USER_FAILURE = 'READ_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_DELETE_USER_REQUEST = 'UPDATE_DELETE_USER_REQUEST';
export const UPDATE_DELETE_USER_SUCCESS = 'UPDATE_DELETE_USER_SUCCESS';
export const UPDATE_DELETE_USER_FAILURE = 'UPDATE_DELETE_USER_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_UPDATE_USER = 'CLEAR_UPDATE_USER';
