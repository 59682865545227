// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_ORDER_REQUEST,
  LIST_ORDER_SUCCESS,
  LIST_ORDER_FAILURE,
  READ_ORDER_REQUEST,
  READ_ORDER_SUCCESS,
  READ_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
} from './types';

// Actions
import { getOrders, getOrder, createOrder, updateOrder, deleteOrder } from './actions';

/**
 * Function that handles listing Orders.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetOrders({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getOrders, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_ORDER_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_ORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetOrders() {
  yield takeLatest(LIST_ORDER_REQUEST, workerGetOrders);
}

/**
 * Function that handles getting a specific Order.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetOrder({ token, id }) {
  try {
    const response = yield call(getOrder, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_ORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_ORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetOrder() {
  yield takeLatest(READ_ORDER_REQUEST, workerGetOrder);
}

/**
 * Function that handles creating a Order.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateOrder({ token, payload }) {
  try {
    const response = yield call(createOrder, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_ORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_ORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateOrder() {
  yield takeLatest(CREATE_ORDER_REQUEST, workerCreateOrder);
}

/**
 * Function that handles updating a specific Order.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateOrder({ token, id, payload }) {
  try {
    const response = yield call(updateOrder, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_ORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_ORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateOrder() {
  yield takeLatest(UPDATE_ORDER_REQUEST, workerUpdateOrder);
}

/**
 * Function that handles deleting a specific Order.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteOrder({ token, id }) {
  try {
    const response = yield call(deleteOrder, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_ORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_ORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteOrder() {
  yield takeLatest(DELETE_ORDER_REQUEST, workerDeleteOrder);
}
