// Imports

// action types
import {
  LIST_USERCONTACT_REQUEST,
  LIST_USERCONTACT_SUCCESS,
  LIST_USERCONTACT_FAILURE,
  READ_USERCONTACT_REQUEST,
  READ_USERCONTACT_SUCCESS,
  READ_USERCONTACT_FAILURE,
  UPDATE_USERCONTACT_REQUEST,
  UPDATE_USERCONTACT_SUCCESS,
  UPDATE_USERCONTACT_FAILURE,
  CREATE_USERCONTACT_REQUEST,
  CREATE_USERCONTACT_SUCCESS,
  CREATE_USERCONTACT_FAILURE,
  DELETE_USERCONTACT_REQUEST,
  DELETE_USERCONTACT_SUCCESS,
  DELETE_USERCONTACT_FAILURE,
  CLEAR_USERCONTACT,
} from './types';

// reducer with initial state
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
    success: false,
  },
};

// User reducer
/**
 * Main user reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const userContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USERCONTACT_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_USERCONTACT_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_USERCONTACT_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case READ_USERCONTACT_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case READ_USERCONTACT_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case READ_USERCONTACT_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case UPDATE_USERCONTACT_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UPDATE_USERCONTACT_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case UPDATE_USERCONTACT_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case CREATE_USERCONTACT_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CREATE_USERCONTACT_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case CREATE_USERCONTACT_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case DELETE_USERCONTACT_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          success: false,
        },
      };
    case DELETE_USERCONTACT_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          success: true,
        },
      };
    case DELETE_USERCONTACT_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          success: false,
        },
      };
    case CLEAR_USERCONTACT:
      return initialState;
    default:
      return state;
  }
};

// Exports
export default userContactReducer;
