// Imports
import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import MaintenanceStyles from './styles';

// Main Component
/**
 * Main Maintenance Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Maintenance = props => {
  const { history } = props;

  return (
    <MaintenanceStyles>
      <Container>
        <Grid container>
          <Paper
            style={{
              padding: 50,
              margin: 'auto',
              marginTop: 50,
              marginBottom: 50,
              maxWidth: 600,
              textAlign: 'center',
              borderRadius: 0,
            }}
          >
            <div style={{ padding: 20 }}>
              <div
                style={{ textAlign: 'center', padding: 30, paddingTop: 0 }}
                onClick={() => {
                  history.push('/');
                }}
              >
                <img style={{ width: '100%' }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo.png`} alt="logo" />
              </div>
            </div>
            <div style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: 20 }}>We'll Be Back Soon!</div>
            <div>Our site is currently undergoing scheduled maintenance. We appreciate your patience and apologize for any inconvenience.</div>
          </Paper>
        </Grid>
      </Container>
    </MaintenanceStyles>
  );
};

// Property Types
Maintenance.propTypes = {
  history: PropTypes.object,
};

// Export
export default Maintenance;
