export const LIST_SAVEDPRICING_REQUEST = 'LIST_SAVEDPRICING_REQUEST';
export const LIST_SAVEDPRICING_SUCCESS = 'LIST_SAVEDPRICING_SUCCESS';
export const LIST_SAVEDPRICING_FAILURE = 'LIST_SAVEDPRICING_FAILURE';
export const READ_SAVEDPRICING_REQUEST = 'READ_SAVEDPRICING_REQUEST';
export const READ_SAVEDPRICING_SUCCESS = 'READ_SAVEDPRICING_SUCCESS';
export const READ_SAVEDPRICING_FAILURE = 'READ_SAVEDPRICING_FAILURE';
export const UPDATE_SAVEDPRICING_REQUEST = 'UPDATE_SAVEDPRICING_REQUEST';
export const UPDATE_SAVEDPRICING_SUCCESS = 'UPDATE_SAVEDPRICING_SUCCESS';
export const UPDATE_SAVEDPRICING_FAILURE = 'UPDATE_SAVEDPRICING_FAILURE';
export const UPDATEALL_SAVEDPRICING_REQUEST = 'UPDATEALL_SAVEDPRICING_REQUEST';
export const UPDATEALL_SAVEDPRICING_SUCCESS = 'UPDATEALL_SAVEDPRICING_SUCCESS';
export const UPDATEALL_SAVEDPRICING_FAILURE = 'UPDATEALL_SAVEDPRICING_FAILURE';
export const SAVEDPRICING_RESET = 'SAVEDPRICING_RESET';
export const SAVEDPRICING_MODEL = 'SAVEDPRICING_MODEL';
