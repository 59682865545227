// Imports

// Dynamic Action Types.
import { READ_DYNAMICPAGE_REQUEST, READ_DYNAMICPAGE_SUCCESS, READ_DYNAMICPAGE_FAILURE, CLEAR_DYNAMICPAGE_REQUEST } from './types';

// Initial Reducer States.
const initialState = {
  read: {
    fetching: false,
    errors: null,
    data: [],
  },
};

/**
 * Dynamic Page Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const dynamicPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DYNAMICPAGE_REQUEST:
      return {
        ...state,
        read: {
          fetching: false,
        },
      };
    case READ_DYNAMICPAGE_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          data: state.read.data,
        },
      };
    case READ_DYNAMICPAGE_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          data: state.read.data !== undefined ? [...state.read.data, action.data] : [action.data], // Append new data
        },
      };
    case READ_DYNAMICPAGE_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
        },
      };

    default:
      return state;
  }
};

// Exports
export default dynamicPageReducer;
