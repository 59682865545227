// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_SAVEDPRICING_REQUEST,
  LIST_SAVEDPRICING_SUCCESS,
  LIST_SAVEDPRICING_FAILURE,
  READ_SAVEDPRICING_REQUEST,
  READ_SAVEDPRICING_SUCCESS,
  READ_SAVEDPRICING_FAILURE,
  UPDATE_SAVEDPRICING_REQUEST,
  UPDATE_SAVEDPRICING_SUCCESS,
  UPDATE_SAVEDPRICING_FAILURE,
  CREATE_SAVEDPRICING_REQUEST,
  CREATE_SAVEDPRICING_SUCCESS,
  CREATE_SAVEDPRICING_FAILURE,
  DELETE_SAVEDPRICING_REQUEST,
  DELETE_SAVEDPRICING_SUCCESS,
  DELETE_SAVEDPRICING_FAILURE,
  UPDATEALL_SAVEDPRICING_FAILURE,
  UPDATEALL_SAVEDPRICING_SUCCESS,
  UPDATEALL_SAVEDPRICING_REQUEST,
} from './types';

// Actions
import { getSavedPricing, updateSavedPricing, readSavedPricing, updateAllSavedPricing } from './actions';

/**
 * Function that handles listing Saved Pricing.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetSavedPricing({ token, id, query, direction, order, limit, page }) {
  try {
    const response = yield call(getSavedPricing, {
      token,
      id,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_SAVEDPRICING_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_SAVEDPRICING_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetSavedPricing() {
  yield takeLatest(LIST_SAVEDPRICING_REQUEST, workerGetSavedPricing);
}

/**
 * Function that handles getting a specific Saved Pricing.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadSavedPricing({ token, id }) {
  try {
    const response = yield call(readSavedPricing, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_SAVEDPRICING_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_SAVEDPRICING_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadSavedPricing() {
  yield takeLatest(READ_SAVEDPRICING_REQUEST, workerReadSavedPricing);
}

/**
 * Function that handles updating a specific Saved Pricing.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateSavedPricing({ token, id, payload }) {
  try {
    const response = yield call(updateSavedPricing, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_SAVEDPRICING_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_SAVEDPRICING_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateSavedPricing() {
  yield takeLatest(UPDATE_SAVEDPRICING_REQUEST, workerUpdateSavedPricing);
}

/**
 * Function that handles updating a specific Saved Pricing.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateAllSavedPricing({ token, payload }) {
  try {
    const response = yield call(updateAllSavedPricing, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATEALL_SAVEDPRICING_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATEALL_SAVEDPRICING_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateAllSavedPricing() {
  yield takeLatest(UPDATEALL_SAVEDPRICING_REQUEST, workerUpdateAllSavedPricing);
}
