// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_ASSET_REQUEST,
  LIST_ASSET_SUCCESS,
  LIST_ASSET_FAILURE,
  READ_ASSET_REQUEST,
  READ_ASSET_SUCCESS,
  READ_ASSET_FAILURE,
  CREATE_ASSET_REQUEST,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_FAILURE,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
} from './types';

// Actions
import { getAssets, getAsset, createAsset, deleteAsset } from './actions';

/**
 * Function that handles listing assets
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetAssets({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getAssets, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_ASSET_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_ASSET_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetAssets() {
  yield takeLatest(LIST_ASSET_REQUEST, workerGetAssets);
}

/**
 * Function that handles getting a single asset
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetAsset({ token, id }) {
  try {
    const response = yield call(getAsset, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_ASSET_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_ASSET_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetAsset() {
  yield takeLatest(READ_ASSET_REQUEST, workerGetAsset);
}

/**
 * Function that handles creating a single asset
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateAsset({ token, payload, onProgress }) {
  try {
    const response = yield call(createAsset, {
      token,
      payload,
      onProgress,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_ASSET_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_ASSET_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateAsset() {
  yield takeLatest(CREATE_ASSET_REQUEST, workerCreateAsset);
}

/**
 * Function that handles deleting a single asset
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteAsset({ token, id }) {
  try {
    const response = yield call(deleteAsset, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_ASSET_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_ASSET_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteAsset() {
  yield takeLatest(DELETE_ASSET_REQUEST, workerDeleteAsset);
}
