// Import types
import { LIST_PURCHASEORDERAUTOMATION_REQUEST, READ_PURCHASEORDERAUTOMATION_REQUEST, CREATE_PURCHASEORDERAUTOMATION_REQUEST, PURCHASEORDERAUTOMATION_RESET } from './types';

// Export operations
/**
 * Dispatch for getting a list of Purchase Order Automationss.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetPurchaseOrderAutomations = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_PURCHASEORDERAUTOMATION_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for getting a specific Customer.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadPurchaseOrderAutomation = ({ token, id }) => ({
  type: READ_PURCHASEORDERAUTOMATION_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating a Customer.
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doCreatePurchaseOrderAutomation = ({ token, payload }) => ({
  type: CREATE_PURCHASEORDERAUTOMATION_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for resetting customer reducer
 * @param {Object} {  }
 * @return {Object} Dispatch
 */
export const doPurchaseOrderAutomationReset = () => ({
  type: PURCHASEORDERAUTOMATION_RESET,
});
