import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '@mui/material/Button';
import { CardMedia, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';

const CatalogPage = props => {
  const { history, categoryList } = props;

  return (
    <Grid container spacing={1}>
      {window.CATALOG_BANNER ? (
        <Card sx={{ maxWidth: `100%`, width: `100%` }}>
          <CardMedia component="img" height="140" style={{ width: `100%` }} image={window.CATALOG_BANNER_IMAGE} alt="Alpina Banner" />
        </Card>
      ) : (
        ''
      )}

      {/*{window.CATALOG_BANNER === 1 && (*/}
      {/*  <Grid key={'banner'} item xs={12}>*/}
      {/*    <Image src={window.CATALOG_BANNER_IMAGE} bgColor="inherit" height={`300px`} width={`100%`} fit={`fill`} duration="350" easing={'ease-in'} />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      {categoryList && categoryList.data && !categoryList.fetching ? (
        categoryList.data.map(category => (
          <Grid key={category.id} item xs={12} md={4} lg={3}>
            <Stack>
              <Button
                onClick={() => {
                  history.push('/catalog/' + category.url);
                  setTimeout(function() {
                    window.scrollTo(0, 0);
                  }, 30);
                }}
              >
                <Typography variant="subtitle1" style={{ textTransform: 'none', fontWeight: 'bold', color: 'black' }}>
                  {category.category_name}
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  history.push('/catalog/' + category.url);
                  setTimeout(function() {
                    window.scrollTo(0, 0);
                  }, 30);
                }}
              >
                {category ? (
                  <img
                    style={{
                      width: 250,
                      height: 250,
                    }}
                    alt={category.category_name}
                    src={window.S3_ENABLED ? `https://${window.AWS_BUCKET}.s3.amazonaws.com${category.asset.url}` : `${window.API_URL}/asset/${category.asset?.id}/file`}
                  />
                ) : (
                  <Skeleton variant="rectangular" width={250} height={250} />
                )}
              </Button>
            </Stack>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}></Grid>
      )}
    </Grid>
  );
};
// PROPS TYPES
// --------------------------------------------------------
CatalogPage.propTypes = {
  history: PropTypes.object,
  categoryList: PropTypes.object,
};
export default CatalogPage;
