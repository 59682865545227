// Imports
import axios from 'axios';

// Cart Export Actions

/**
 * Get a list of BluePay customer payment methods
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getCustomerPaymentMethods = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/bluepay/customerpaymentmethods${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
