// Import types
import { READ_USER_PAYMENT_PROFILE_REQUEST, READ_USER_GATEWAY_PROFILE_REQUEST, LIST_USER_PAYMENT_PROFILE_REQUEST, LIST_USER_GATEWAY_PROFILE_REQUEST, CLEAR_USER_GATEWAY_PROFILE } from './types';

// Export operations
/**
 * Dispatch for a list of User Gateway Profiles
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetUserGatewayProfiles = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_USER_GATEWAY_PROFILE_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for getting a specific User Gateway Profile
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadUserGatewayProfile = ({ token, id }) => ({
  type: READ_USER_GATEWAY_PROFILE_REQUEST,
  token,
  id,
});

/**
 * Dispatch for a list of User Payment Profiles
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetUserPaymentProfiles = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_USER_PAYMENT_PROFILE_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for getting a specific User Payment Profile
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadUserPaymentProfile = ({ token, id }) => ({
  type: READ_USER_PAYMENT_PROFILE_REQUEST,
  token,
  id,
});

/**
 * Dispatch for getting a specific User Payment Profile
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doClearUserGatewayProfile = () => ({
  type: CLEAR_USER_GATEWAY_PROFILE,
});
