// Imports
import axios from 'axios';

// Export actions
/**
 * Main request to get all users
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getUsers = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/users${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Main request to get a single user
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const getUser = ({ token, id }) => {
  const params = {
    id,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/users${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Main request to create new user
 * @param {Object} { token: string, payload: {firstname: string, lastname: string, email: string} }
 * @return {AxiosPromise} Http request
 */
export const createUser = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/user`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Main request to update existing user
 * @param {Object} { token: string, payload: { firstname: string, lastname: string, email: string, password: string, password_confirmation: string } }
 * @return {AxiosPromise} Http request
 */
export const updateUser = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/user/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Main request to update existing user
 * @param {Object} { token: string, payload: { firstname: string, lastname: string, email: string, password: string, password_confirmation: string } }
 * @return {AxiosPromise} Http request
 */
export const updateDeleteUser = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/user/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete user
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const deleteUser = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/user/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
