// IMPORTS
import axios from 'axios';
import { LOGOUT_REQUEST } from '../containers/Auth/types';

export default {
  // eslint-disable-next-line no-unused-vars
  setupInterceptors: ({ store, history }) => {
    axios.interceptors.response.use(
      res => res,
      error => {
        if (error.response.status === 401) {
          store.dispatch({
            type: LOGOUT_REQUEST,
            redirect: history.location.pathname,
          });
        }
        return Promise.reject(error);
      },
    );
  },
};
