// IMPORTS
// --------------------------------------------------------
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations
import {
  doLogin,
  doLoginReset,
  doRegisterReset,
  doForgotPassword,
  doPasswordReset,
  doRegister,
  doForgotPasswordReset,
  doPasswordResetReset,
  doActivation,
  doActivationReset,
  doCustomerActivation,
  doSalesRepActivation,
  doUnsubscribe,
  doSetSite,
} from './operations';

import Login from '../../views/Auth/Login';
import PasswordReset from '../../views/Auth/PasswordReset';
import Register from '../../views/Auth/Register';
import ForgotPassword from '../../views/Auth/ForgotPassword';
import Activation from '../../views/Auth/Activation';
import Unsubscribe from '../../views/Auth/Unsubscribe';
import { doResetCart } from '../Cart/operations';
import { doResetCartItem } from '../CartItem/operations';
import { doResetSaleOrder } from '../SaleOrder/operations';
import { doSavedPricingReset } from '../SavedPricing/operations';
import { doResetCategories } from '../Category/operations';
import { doClearUserContact } from '../UserContact/operations';
import { withRouter } from 'react-router-dom';

// AUTH CONTAINER
// --------------------------------------------------------
/**
 * AuthContainer
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
const AuthContainer = props => {
  const {
    match,
    history,
    login,
    register,
    forgotPassword,
    passwordReset,
    unsubscribe,
    activation,
    doLogin,
    doRegister,
    doLoginReset,
    doRegisterReset,
    doForgotPassword,
    doUnsubscribe,
    doPasswordReset,
    doCustomerActivation,
    doSalesRepActivation,
    doActivationReset,
    doForgotPasswordReset,
    doUnsubscribeReset,
    doPasswordResetReset,
    doResetCart,
    doResetCartItem,
    doResetSaleOrder,
    doSavedPricingReset,
    doResetCategories,
    doClearUserContact,
    setWebsiteSelectorId,
    websiteSelectorId,
    doSetSite,
  } = props;

  const { view } = match?.params;

  return {
    undefined: (
      <Login
        match={match}
        history={history}
        login={login}
        doLogin={doLogin}
        doLoginReset={doLoginReset}
        doResetCart={doResetCart}
        doResetCartItem={doResetCartItem}
        doResetSaleOrder={doResetSaleOrder}
        doSavedPricingReset={doSavedPricingReset}
        doResetCategories={doResetCategories}
        doClearUserContact={doClearUserContact}
        setWebsiteSelectorId={setWebsiteSelectorId}
        websiteSelectorId={websiteSelectorId}
        doSetSite={doSetSite}
      />
    ),
    login: (
      <Login
        history={history}
        doResetCart={doResetCart}
        doResetCartItem={doResetCartItem}
        doResetSaleOrder={doResetSaleOrder}
        doSavedPricingReset={doSavedPricingReset}
        login={login}
        doLogin={doLogin}
        doLoginReset={doLoginReset}
        doResetCategories={doResetCategories}
        doClearUserContact={doClearUserContact}
        setWebsiteSelectorId={setWebsiteSelectorId}
        doSetSite={doSetSite}
      />
    ),
    signup: <Register history={history} register={register} doRegister={doRegister} doRegisterReset={doRegisterReset} />,
    forgotpassword: <ForgotPassword history={history} forgotPassword={forgotPassword} doForgotPassword={doForgotPassword} doForgotPasswordReset={doForgotPasswordReset} />,
    unsubscribe: <Unsubscribe history={history} unsubscribe={unsubscribe} doUnsubscribe={doUnsubscribe} doUnsubscribeReset={doUnsubscribeReset} match={match} />,
    activation: (
      <Activation
        match={match}
        history={history}
        activation={activation}
        doCustomerActivation={doCustomerActivation}
        doSalesRepActivation={doSalesRepActivation}
        doActivationReset={doActivationReset}
      />
    ),
    passwordreset: <PasswordReset match={match} history={history} passwordReset={passwordReset} doPasswordReset={doPasswordReset} doPasswordResetReset={doPasswordResetReset} />,
  }[view];
};

// REDUX
// --------------------------------------------------------
/**
 * Maps Redux State To Component
 * @param {Object} state main redux state
 * @returns {Object} Redux Reducer States
 */
const mapStateToProps = state => ({
  site: state.authReducer.site,
  token: state.authReducer.token,
  login: state.authReducer.login,
  register: state.authReducer.register,
  forgotPassword: state.authReducer.forgotPassword,
  passwordReset: state.authReducer.passwordReset,
  activation: state.authReducer.activation,
  unsubscribe: state.authReducer.unsubscribe,
});

/**
 * Maps Redux Actions To Component
 * @param {*} dispatch main redux dispatch event
 * @returns {{}} Redux Dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogin,
      doRegister,
      doPasswordReset,
      doForgotPassword,
      doLoginReset,
      doRegisterReset,
      doForgotPasswordReset,
      doCustomerActivation,
      doSalesRepActivation,
      doActivationReset,
      doPasswordResetReset,
      doUnsubscribe,
      doResetCart,
      doResetCartItem,
      doResetSaleOrder,
      doSavedPricingReset,
      doResetCategories,
      doClearUserContact,
      doSetSite,
    },
    dispatch,
  );

// PROPS TYPES
// --------------------------------------------------------
AuthContainer.defaultProps = {
  view: '',
};

AuthContainer.propTypes = {
  doLogin: PropTypes.func,
  doRegister: PropTypes.func,
  doPasswordReset: PropTypes.func,
  doForgotPassword: PropTypes.func,
  doUnsubscribe: PropTypes.func,
  doCustomerActivation: PropTypes.func,
  doSalesRepActivation: PropTypes.func,
  doActivationReset: PropTypes.func,
  doLoginReset: PropTypes.func,
  doRegisterReset: PropTypes.func,
  doForgotPasswordReset: PropTypes.func,
  doClearUserContact: PropTypes.func,
  doUnsubscribeReset: PropTypes.func,
  doPasswordResetReset: PropTypes.func,
  doResetCartItem: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  doSavedPricingReset: PropTypes.func,
  doResetCategories: PropTypes.func,
  doResetCart: PropTypes.func,
  login: PropTypes.object,
  register: PropTypes.object,
  forgotPassword: PropTypes.object,
  activation: PropTypes.object,
  passwordReset: PropTypes.object,
  unsubscribe: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  view: PropTypes.string,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
