import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Checkbox, TablePagination } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';

const OrdersPage = props => {
  const { history, doGetSaleOrders, saleOrders, token, doResetSaleOrder, width } = props;

  // useEffect(() => {
  //   doResetSaleOrder(SALEORDER_RESET);
  // });
  useEffect(() => {
    if (!saleOrders?.fetching && !saleOrders?.data && !saleOrders?.errors) {
      let query = '';
      let direction = '';
      let order = '';
      let limit = 10;
      let page = 1;
      doGetSaleOrders({ token, query, direction, order, limit, page });
    }
  }, [saleOrders, token]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    let query = '';
    let direction = '';
    let order = '';
    let limit = 10;
    let page = newPage;
    doGetSaleOrders({ token, query, direction, order, limit, page });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    let query = '';
    let direction = '';
    let order = '';
    let limit = event.target.value;
    let page = 0;
    doGetSaleOrders({ token, query, direction, order, limit, page });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatus = saleOrder => {
    let confirmed = saleOrder?.confirmed;
    let canceled = saleOrder?.canceled;
    let invoiceConfirmed = saleOrder?.invoice_confirmed;
    let registeredPayment = saleOrder?.payment_registered;
    if (canceled) {
      return <Chip size={`small`} label={`Canceled`} color="error" />;
    }
    if (!confirmed || !invoiceConfirmed || !registeredPayment) {
      return <Chip size={`small`} label={`Pending Payment`} color="error" />;
    }
    return <Chip size={`small`} label={`Confirmed`} color="success" />;
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container spacing={0}>
          {/*<Grid item xs={12} md={6} lg={6} p={0.5}>*/}
          {/*  <FormControl sx={{ float: 'left', padding: 0.5 }}>*/}
          {/*    <InputLabel htmlFor="grouped-native-select">Show</InputLabel>*/}
          {/*    <Select native defaultValue="All except cancelled" label="Show" size={`small`}>*/}
          {/*      <option aria-label="All except cancelled" value="All except cancelled">*/}
          {/*        All except cancelled*/}
          {/*      </option>*/}
          {/*      <option aria-label="New" value="Confirmed">*/}
          {/*        Confirmed*/}
          {/*      </option>*/}
          {/*      <option aria-label="Invoiced" value="Pending Payment">*/}
          {/*        Pending Payment*/}
          {/*      </option>*/}
          {/*      <option aria-label="Canceled" value="Canceled">*/}
          {/*        Canceled*/}
          {/*      </option>*/}
          {/*    </Select>*/}
          {/*  </FormControl>*/}
          {/*  <FormControl sx={{ float: 'left', padding: 0.5 }}>*/}
          {/*    <TextField id="outlined-basic" label="Search orders" size={`small`} variant="outlined" />*/}
          {/*  </FormControl>*/}
          {/*  <FormControl sx={{ float: 'left', padding: 0.5 }}>*/}
          {/*    <Button variant="contained" size={'medium'}>*/}
          {/*      Go*/}
          {/*    </Button>*/}
          {/*  </FormControl>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={6}>*/}
          {/*  <div style={{ float: `right`, padding: 0.5 }}>*/}
          {/*    <Button*/}
          {/*      variant="contained"*/}
          {/*      onClick={() => {*/}
          {/*        history.push('/catalog/export_orders');*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Export*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <hr />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block-cell',
                      md: 'block-cell',
                      lg: 'block-cell',
                    },
                  }}
                  style={{ fontWeight: `bold` }}
                >
                  #
                </TableCell>
                <TableCell align="left" style={{ fontWeight: `bold` }}>
                  {width < 600 ? `Info` : `Date`}
                </TableCell>
                {/*<TableCell align="center" style={{ fontWeight: `bold` }}>*/}
                {/*  {width < 600 ? 'Info' : `Price`}*/}
                {/*</TableCell>*/}
                <TableCell align="center" style={{ fontWeight: `bold` }}>
                  Total Price
                </TableCell>
                <TableCell />
                <TableCell style={{ display: width < 600 ? 'none' : 'block-cell' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {saleOrders && saleOrders?.data && saleOrders?.data.length ? (
                saleOrders?.data &&
                saleOrders?.data?.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      align="left"
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'block-cell',
                          md: 'block-cell',
                          lg: 'block-cell',
                        },
                      }}
                    >
                      SALE-{row?.id}
                    </TableCell>
                    <TableCell align="left">
                      {width < 600 ? (
                        <div>
                          <div>{moment(row?.created_at).format('YYYY/MM/DD')}</div>
                          <div>{getStatus(row)}</div>
                        </div>
                      ) : (
                        moment(row?.created_at).format('YYYY/MM/DD hh:mm a')
                      )}
                    </TableCell>
                    <TableCell align="center">${row?.cart?.cart_total}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant={'contained'}
                        onClick={() => {
                          history.push('/catalog/saleorder/' + row.id);
                        }}
                      >
                        <Typography sx={{ textTransform: 'none' }}>{width < 600 ? `SALE-${row?.id}` : `Order #${row.id} Order`}</Typography>
                      </Button>
                    </TableCell>
                    <TableCell align="right" style={{ display: width < 600 ? 'none' : 'block-cell' }}>
                      {getStatus(row)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={`orders-na`}>
                  <TableCell style={{ textAlign: `center` }} colSpan={6}>
                    <p style={{ fontWeight: `bold` }}>No Orders Available</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/*<TablePagination component="div" count={rows.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />*/}
        <TablePagination
          component="div"
          count={saleOrders?.pagination?.count ? parseInt(saleOrders?.pagination?.count) : 0}
          page={saleOrders?.pagination?.page && saleOrders?.pagination?.count && saleOrders?.pagination?.count >= 1 ? saleOrders?.pagination?.page : 0}
          onPageChange={handleChangePage}
          rowsPerPage={saleOrders?.pagination?.limit ? saleOrders?.pagination?.limit : 10}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

OrdersPage.propTypes = {
  token: PropTypes.string,
  history: PropTypes.object,
  saleOrders: PropTypes.object,
  doGetSaleOrders: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  width: PropTypes.number,
};

export default OrdersPage;
