// Import types
// Cart Item Action Types
import {
  LIST_SALEORDER_REQUEST,
  UPDATE_SALEORDER_REQUEST,
  CREATE_SALEORDER_REQUEST,
  DELETE_SALEORDER_REQUEST,
  READ_SALEORDER_REQUEST,
  CONFIRM_SALEORDER_REQUEST,
  VERIFY_SALEORDER_REQUEST,
  CANCEL_SALEORDER_REQUEST,
} from './types';

// Export operations
/**
 * Dispatch for getting Sale Orders.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetSaleOrders = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_SALEORDER_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for reading a Sale Order.
 * @param {Object} { token: string, id: integer }
 * @return {Object} Dispatch
 */
export const doReadSaleOrder = ({ token, id }) => ({
  type: READ_SALEORDER_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating a Sale Order.
 * @param {Object} { token: string, payload: { cart_id : integer, invoice_contact_id : integer, deliver_contact_id : integer, notes: string } }
 * @return {Object} Dispatch
 */
export const doCreateSaleOrder = ({ token, payload }) => ({
  type: CREATE_SALEORDER_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for confirming a Sale Order.
 * @param {Object} { token: string, payload: { sale_order_id : integer, square_token : string } }
 * @return {Object} Dispatch
 */
export const doConfirmSaleOrder = ({ token, payload }) => ({
  type: CONFIRM_SALEORDER_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for verify a Sale Order.
 * @param {Object} { token: string, payload: { sale_order_id : integer, square_token : string } }
 * @return {Object} Dispatch
 */
export const doVerifySaleOrder = ({ token, payload }) => ({
  type: VERIFY_SALEORDER_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for cancel a Sale Order.
 * @param {Object} { token: string, payload: { sale_order_id : integer } }
 * @return {Object} Dispatch
 */
export const doCancelSaleOrder = ({ token, payload }) => ({
  type: CANCEL_SALEORDER_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for updating a Sale Order.
 * @param {Object} { token: string, id: integer, payload: { invoice_contact_id : integer, deliver_contact_id : integer, notes: string } }
 * @return {Object} Dispatch
 */
export const doUpdateSaleOrder = ({ token, id, payload }) => ({
  type: UPDATE_SALEORDER_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for deleting a Sale Order.
 * @param {Object} { token: string, id: integer }
 * @return {Object} Dispatch
 */
export const doDeleteSaleOrder = ({ token, id }) => ({
  type: DELETE_SALEORDER_REQUEST,
  token,
  id,
});

export const doResetSaleOrder = k => {
  return { type: k };
};
