// Imports
import styled from 'styled-components';
import Grid from '@mui/material/Grid';

// Main Component
const FireProductStyles = styled(Grid)`
  z-index: 99;
  .MuiOutlinedInput-input {
    background: #fff;
  }
  .Mui-disabled {
    color: rgba(229, 229, 229, 0.4) !important;
    box-shadow: none;
    background-color: rgb(231 231 231 / 12%);
  }
  .MuiTableContainer-root {
    background-color: #0d0d0d;
  }
  .MuiTable-root {
    border: 1px solid beige;
    border-radius: 0 !important;
  }
  .MuiTableCell-root {
    color: #fff;
  }
  .fire-attribute-chip {
    color: #fff;
  }
  .fire-chip {
    color: #0d0d0d;
    background-color: white;
  }
  .object-cover {
    -o-object-fit: cover;
    object-fit: cover;
  }
  .w-full {
    width: 100%;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  //.min-h-\\[460px\\] {
  //  min-height: 460px;
  //}
  img,
  video {
    max-width: 100%;
    height: auto;
  }
`;

// Export
export default FireProductStyles;
