import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { TablePagination, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import PropTypes from 'prop-types';
import OrdersItemsPage from '../OrdersItemsPage';

const OrdersExportPage = props => {
  const { history } = props;
  return (
    <Grid container>
      <Grid item xs={6} md={6} lg={6}>
        <Typography align="left" variant="h4" sx={{ fontWeight: 'bold' }}>
          Export Orders
        </Typography>
      </Grid>
      <Grid align="right" item xs={6} md={6} lg={6}>
        <Tooltip title="Back to Orders">
          <Button
            variant="contained"
            onClick={() => {
              history.push('/catalog/orders');
            }}
          >
            Back to Orders
          </Button>
        </Tooltip>
      </Grid>

      <Grid align="left" item xs={6} md={6} lg={6} pt={2} pb={2}>
        <Stack spacing={2}>
          {/* Status*/}
          <FormControl sx={{ float: 'left' }}>
            <InputLabel htmlFor="grouped-native-select">Status</InputLabel>
            <Select native defaultValue="All except cancelled" label="Status" size={`small`}>
              <option aria-label="All except cancelled" value="All except cancelled">
                All except cancelled
              </option>
              <option aria-label="New" value="New">
                New
              </option>
              <option aria-label="Invoiced" value="Invoiced">
                Invoiced
              </option>
              <option aria-label="Released" value="Released">
                Released
              </option>
              <option aria-label="PreOrder" value="PreOrder">
                PreOrder
              </option>
              <option aria-label="Part Fulfilled" value="Part Fulfilled">
                Part Fulfilled
              </option>
              <option aria-label="Fulfilled" value="Fulfilled">
                Fulfilled
              </option>
              <option aria-label="Canceled" value="Canceled">
                Canceled
              </option>
            </Select>
          </FormControl>

          {/* Date*/}
          <FormControl sx={{ float: 'left' }}>
            <InputLabel htmlFor="grouped-native-select">Date</InputLabel>
            <Select native defaultValue="Created" label="Date" size={`small`}>
              <option aria-label="Created" value="Created">
                Created
              </option>
              <option aria-label="Filled" value="Filled">
                Filled
              </option>
              <option aria-label="Shipped" value="Shipped">
                Shipped
              </option>
              <option aria-label="Canceled" value="Canceled">
                Canceled
              </option>
            </Select>
          </FormControl>

          {/* Between Dates */}
          <Stack align="center" direction={'row'} spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                id="datetime-local"
                label="From"
                type="datetime-local"
                defaultValue="2021-10-24T10:30"
                sx={{ width: '49%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                id="datetime-local"
                label="To"
                type="datetime-local"
                defaultValue="2021-10-24T10:30"
                sx={{ width: '49%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
          </Stack>

          {/* Export Type*/}
          <FormControl sx={{ float: 'left' }}>
            <InputLabel htmlFor="grouped-native-select">Export Type</InputLabel>
            <Select native defaultValue="Detail" label="Date" size={`small`}>
              <option aria-label="Detail" value="Detail">
                Detail
              </option>
              <option aria-label="Detail" value="Detail">
                Detail
              </option>
              <option aria-label="Detail" value="Detail">
                Detail
              </option>
              <option aria-label="Detail" value="Detail">
                Detail
              </option>
            </Select>
          </FormControl>

          {/* Export Orders Button*/}
          <Tooltip title="Export Orders">
            <Button variant="contained">Export Orders</Button>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );
};

OrdersExportPage.propTypes = {
  history: PropTypes.object,
};

export default OrdersExportPage;
