import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { CustomModal } from '../CustomModal';
import FormUtil from '../../../utils/FormUtil';

export const ContactUsModal = ({ props, isOpen, handleClose }) => {
  // Request State Variables
  const [saveRequest, setSaveRequest] = React.useState(false);

  const [values, setValues] = React.useState({
    message: '',
  });

  const [validation, setValidation] = React.useState({
    message: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  useEffect(() => {
    if (saveRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setSaveRequest(false);
      if (values?.message === '') {
        setValidation(FormUtil.addError(validation, 'message', 'The message box is blank!'));
      }

      // Backend API Call.
      if (FormUtil.validate(validation)) {
        // TODO: Backend APi call goes here to save the new Customer Address data.
      }
    }
  }, [validation, saveRequest]);

  const onSubmitForm = event => {
    event.preventDefault();
    setSaveRequest(true);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <CustomModal
      props={props}
      isOpen={isOpen}
      handleClose={handleClose}
      /* Modal Title Section */
      title={
        <Grid item xs={12} align={'center'}>
          <Typography variant={'h4'}>Contact Us</Typography>
        </Grid>
      }
      /* Modal Body Section */
      body={
        <Grid item xs={12}>
          {/* TODO: HtmlEditor causes an error when trying to resize it. Using TextField for now. */}
          {/*<HtmlEditor />*/}
          <TextField
            required
            multiline
            rows={5}
            fullWidth={true}
            label={'Message'}
            variant={'outlined'}
            defaultValue={values.message}
            value={values.message}
            onChange={handleChange('message')}
            error={FormUtil.renderError(validation, 'message')}
            helperText={FormUtil.renderHelperText(validation, 'message')}
          />
        </Grid>
      }
      /* Modal Actions Section */
      actions={
        <Grid item xs={12}>
          <Button xs={4} fullWidth variant={'contained'} onClick={onSubmitForm} loading={props.fetching} disabled={props.fetching}>
            <Typography sx={{ textTransform: 'none' }}>Send Message</Typography>
          </Button>
        </Grid>
      }
    />
  );
};

ContactUsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
