// Import types
import { READ_FIREBASETOKEN_REQUEST, UPDATE_FIREBASETOKEN_REQUEST, CREATE_FIREBASETOKEN_REQUEST, DELETE_FIREBASETOKEN_REQUEST, FIREBASETOKEN_RESET } from './types';

// Export operations

/**
 * Dispatch for getting a specific Firebase Token.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadFirebaseToken = ({ token, id }) => ({
  type: READ_FIREBASETOKEN_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating a Firebase Token.
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doCreateFirebaseToken = ({ token, payload }) => ({
  type: CREATE_FIREBASETOKEN_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for updating a Customer.
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doUpdateFirebaseToken = ({ token, id, payload }) => ({
  type: UPDATE_FIREBASETOKEN_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for deleting a specific Customer.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doDeleteFirebaseToken = ({ token, id }) => ({
  type: DELETE_FIREBASETOKEN_REQUEST,
  token,
  id,
});

/**
 * Dispatch for resetting customer reducer
 * @param {Object} {  }
 * @return {Object} Dispatch
 */
export const doFirebaseTokenReset = () => ({
  type: FIREBASETOKEN_RESET,
});
