// Imports

// action types
import {
  LIST_PRODUCT_REQUEST,
  LIST_PRODUCT_SUCCESS,
  LIST_PRODUCT_FAILURE,
  READ_PRODUCT_REQUEST,
  READ_PRODUCT_SUCCESS,
  READ_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
} from './types';

// Reducer Initial States.
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
};

/**
 * Product Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PRODUCT_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_PRODUCT_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_PRODUCT_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_PRODUCT_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_PRODUCT_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    default:
      return state;
  }
};

// Exports
export default productReducer;
