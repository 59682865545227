// IMPORTS
import { all, fork } from 'redux-saga/effects';
import {
  watcherRegister,
  watcherLogin,
  watcherLogout,
  watcherMe,
  watcherForgotPassword,
  watcherPasswordReset,
  watcherCustomerActivation,
  watcherSalesRepActivation,
  watcherImpersonate,
  watcherUnsubscribe,
} from '../containers/Auth/sagas';
import { watcherGetUsers, watcherGetUser, watcherCreateUser, watcherUpdateUser, watcherDeleteUser, watcherUpdateDeleteUser } from '../containers/User/sagas';
import { watcherCreateAsset, watcherDeleteAsset, watcherGetAsset, watcherGetAssets } from '../containers/Asset/sagas';
import { watcherCreateProduct, watcherDeleteProduct, watcherGetProduct, watcherGetProducts, watcherUpdateProduct } from '../containers/Product/sagas';
import { watcherCreateOrder, watcherDeleteOrder, watcherGetOrder, watcherGetOrders, watcherUpdateOrder } from '../containers/Order/sagas';
import { watcherReadSavedPricing, watcherGetSavedPricing, watcherUpdateSavedPricing, watcherUpdateAllSavedPricing } from '../containers/SavedPricing/sagas';
import { watcherCreateCustomer, watcherDeleteCustomer, watcherReadCustomer, watcherGetCustomers, watcherUpdateCustomer } from '../containers/Customer/sagas';
import { watcherCreateFirebaseToken, watcherDeleteFirebaseToken, watcherReadFirebaseToken, watcherUpdateFirebaseToken } from '../containers/FirebaseToken/sagas';
import { watcherCreateCartItem, watcherDeleteCartItem, watcherGetCartItems, watcherUpdateCartItem } from '../containers/CartItem/sagas';
import { watcherGetCategories, watcherGetCategory, watcherCreateCategory, watcherUpdateCategory, watcherDeleteCategory } from '../containers/Category/sagas';
import { watcherCreateCart, watcherDeleteCart, watcherDuplicateCart, watcherEmptyCart, watcherReadCart, watcherReadLastCart, watcherRefreshCart, watcherUpdateCart } from '../containers/Cart/sagas';
import { watcherCreateUserContact, watcherDeleteUserContact, watcherGetUserContact, watcherGetUserContacts, watcherUpdateUserContact } from '../containers/UserContact/sagas';
import {
  watcherCancelSaleOrder,
  watcherConfirmSaleOrder,
  watcherCreateSaleOrder,
  watcherDeleteSaleOrder,
  watcherGetSaleOrders,
  watcherReadSaleOrder,
  watcherUpdateSaleOrder,
  watcherVerifySaleOrder,
} from '../containers/SaleOrder/sagas';
import { watcherGetUserGatewayProfiles, watcherGetUserPaymentProfiles, watcherReadUserGatewayProfile, watcherReadUserPaymentProfile } from '../containers/Authorize/sagas';
import { watcherReadDynamicPage } from '../containers/DynamicPage/sagas';
import { watcherCreatePurchaseOrderAutomation, watcherGetPurchaseOrderAutomations, watcherReadPurchaseOrderAutomation } from '../containers/PurchaseOrderAutomation/sagas';
import { watcherGetCustomerPaymentMethods } from '../containers/Bluepay/sagas';
import { watcherCreateNotificationCampaign, watcherGetNotificationCampaigns, watcherListNotificationCampaigns, watcherReadNotificationCampaign } from '../containers/NotificationCampaign/sagas';

// SAGAS
// MAIN ROOT SAGA
/**
 * Main root saga
 * @returns {void}
 */
function* rootSaga() {
  yield all(
    [
      watcherLogin,
      watcherMe,
      watcherLogout,
      watcherRegister,
      watcherForgotPassword,
      watcherPasswordReset,
      watcherCustomerActivation,
      watcherSalesRepActivation,
      watcherImpersonate,
      watcherUnsubscribe,

      // Users
      watcherGetUsers,
      watcherGetUser,
      watcherCreateUser,
      watcherUpdateUser,
      watcherUpdateDeleteUser,
      watcherDeleteUser,

      // User Contacts
      watcherGetUserContacts,
      watcherGetUserContact,
      watcherCreateUserContact,
      watcherUpdateUserContact,
      watcherDeleteUserContact,

      //Sale Orders
      watcherGetSaleOrders,
      watcherReadSaleOrder,
      watcherCreateSaleOrder,
      watcherConfirmSaleOrder,
      watcherVerifySaleOrder,
      watcherUpdateSaleOrder,
      watcherDeleteSaleOrder,
      watcherCancelSaleOrder,

      // Assets
      watcherGetAssets,
      watcherGetAsset,
      watcherCreateAsset,
      watcherDeleteAsset,

      // Products
      watcherGetProducts,
      watcherGetProduct,
      watcherCreateProduct,
      watcherUpdateProduct,
      watcherDeleteProduct,

      // Orders
      watcherGetOrders,
      watcherGetOrder,
      watcherCreateOrder,
      watcherUpdateOrder,
      watcherDeleteOrder,

      // Customers
      watcherGetCustomers,
      watcherReadCustomer,
      watcherCreateCustomer,
      watcherUpdateCustomer,
      watcherDeleteCustomer,

      // Saved Pricing
      watcherReadSavedPricing,
      watcherGetSavedPricing,
      watcherUpdateSavedPricing,
      watcherUpdateAllSavedPricing,

      // Cart Items
      watcherGetCartItems,
      watcherCreateCartItem,
      watcherUpdateCartItem,
      watcherDeleteCartItem,

      // Categories
      watcherGetCategories,
      watcherGetCategory,
      watcherCreateCategory,
      watcherUpdateCategory,
      watcherDeleteCategory,

      // Carts
      watcherReadCart,
      watcherCreateCart,
      watcherDeleteCart,
      watcherEmptyCart,
      watcherUpdateCart,
      watcherRefreshCart,
      watcherReadLastCart,

      // Authorize
      watcherGetUserGatewayProfiles,
      watcherReadUserGatewayProfile,
      watcherGetUserPaymentProfiles,
      watcherReadUserPaymentProfile,

      watcherCreateFirebaseToken,
      watcherCreateNotificationCampaign,
      watcherGetNotificationCampaigns,

      //Dynamic Pages
      watcherReadDynamicPage,

      //Purchase Order Automations
      watcherGetPurchaseOrderAutomations,
      watcherReadPurchaseOrderAutomation,
      watcherCreatePurchaseOrderAutomation,

      watcherGetCustomerPaymentMethods,

      watcherReadNotificationCampaign,

      watcherDuplicateCart,
    ].map(fork),
  );
}

// EXPORTS
export default rootSaga;
