export const LIST_CATEGORY_REQUEST = 'LIST_CATEGORY_REQUEST';
export const LIST_CATEGORY_SUCCESS = 'LIST_CATEGORY_SUCCESS';
export const LIST_CATEGORY_FAILURE = 'LIST_CATEGORY_FAILURE';
export const READ_CATEGORY_REQUEST = 'READ_CATEGORY_REQUEST';
export const READ_CATEGORY_SUCCESS = 'READ_CATEGORY_SUCCESS';
export const READ_CATEGORY_FAILURE = 'READ_CATEGORY_FAILURE';
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const CATEGORY_RESET = 'CATEGORY_RESET';
