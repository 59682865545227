// Imports
import storage from 'redux-persist/lib/storage';
// Sale Order Action Types
import {
  LIST_SALEORDER_REQUEST,
  LIST_SALEORDER_SUCCESS,
  LIST_SALEORDER_FAILURE,
  UPDATE_SALEORDER_REQUEST,
  UPDATE_SALEORDER_SUCCESS,
  UPDATE_SALEORDER_FAILURE,
  CREATE_SALEORDER_REQUEST,
  CREATE_SALEORDER_SUCCESS,
  CREATE_SALEORDER_FAILURE,
  DELETE_SALEORDER_REQUEST,
  DELETE_SALEORDER_SUCCESS,
  DELETE_SALEORDER_FAILURE,
  SALEORDER_RESET,
  READ_SALEORDER_SUCCESS,
  READ_SALEORDER_FAILURE,
  READ_SALEORDER_REQUEST,
  CONFIRM_SALEORDER_REQUEST,
  CONFIRM_SALEORDER_SUCCESS,
  CONFIRM_SALEORDER_FAILURE,
  VERIFY_SALEORDER_REQUEST,
  VERIFY_SALEORDER_SUCCESS,
  VERIFY_SALEORDER_FAILURE,
  CANCEL_SALEORDER_FAILURE,
  CANCEL_SALEORDER_SUCCESS,
  CANCEL_SALEORDER_REQUEST,
  SALEORDER_LIST_RESET,
  SALEORDER_CANCEL_RESET,
  SALEORDER_CREATE_RESET,
  SALEORDER_READ_RESET,
  SALEORDER_CONFIRM_RESET,
} from './types';
import { persistReducer } from 'redux-persist';

// Initial Reducer States.
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  confirm: {
    fetching: false,
    errors: null,
    data: null,
  },
  verify: {
    fetching: false,
    errors: null,
    data: null,
  },
  cancel: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
};

const persistConfig = {
  key: 'saleOrder',
  storage,
  whitelist: ['create', 'read'],
};

/**
 * Sale Order Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SALEORDER_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_SALEORDER_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_SALEORDER_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_SALEORDER_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UPDATE_SALEORDER_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case UPDATE_SALEORDER_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case READ_SALEORDER_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case READ_SALEORDER_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case READ_SALEORDER_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case CREATE_SALEORDER_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CREATE_SALEORDER_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case CREATE_SALEORDER_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };

    case CONFIRM_SALEORDER_REQUEST:
      return {
        ...state,
        confirm: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CONFIRM_SALEORDER_SUCCESS:
      return {
        ...state,
        confirm: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case CONFIRM_SALEORDER_FAILURE:
      return {
        ...state,
        confirm: {
          fetching: false,
          errors: action.error,
          data: null,
          success: false,
        },
      };
    case CANCEL_SALEORDER_REQUEST:
      return {
        ...state,
        cancel: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CANCEL_SALEORDER_SUCCESS:
      return {
        ...state,
        cancel: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case CANCEL_SALEORDER_FAILURE:
      return {
        ...state,
        cancel: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case VERIFY_SALEORDER_REQUEST:
      return {
        ...state,
        verify: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case VERIFY_SALEORDER_SUCCESS:
      return {
        ...state,
        verify: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case VERIFY_SALEORDER_FAILURE:
      return {
        ...state,
        verify: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case DELETE_SALEORDER_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          complete: false,
        },
      };
    case DELETE_SALEORDER_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          complete: true,
        },
      };
    case DELETE_SALEORDER_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          complete: false,
        },
      };
    case SALEORDER_CANCEL_RESET:
      return {
        ...state,
        cancel: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case SALEORDER_CREATE_RESET:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case SALEORDER_READ_RESET:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case SALEORDER_LIST_RESET:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case SALEORDER_CONFIRM_RESET:
      return {
        ...state,
        confirm: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case SALEORDER_RESET:
      return {
        ...state,
        confirm: {
          fetching: false,
          errors: null,
          data: null,
        },
        read: {
          fetching: false,
          errors: null,
          data: null,
        },
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
        update: {
          fetching: false,
          errors: null,
          data: null,
        },
        delete: {
          fetching: false,
          errors: null,
          complete: false,
        },
        list: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
        },
        cancel: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    default:
      return state;
  }
};

const saleOrderReducer = persistReducer(persistConfig, reducer);

// Exports
export default saleOrderReducer;
