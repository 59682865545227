import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { LinearProgress } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const FireLoadingButton = props => {
  const { width, setEnter, enter, setVerificationRequest } = props;
  const [loadingEnter, setLoadingEnter] = useState(true); // Set loading to true initially
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer;

    if (loadingEnter) {
      timer = setInterval(() => {
        setProgress(prevProgress => (prevProgress >= 100 ? 100 : prevProgress + 10));
      }, 1000); // Simulating a 10-second loading process with 10 steps
    } else {
      setProgress(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [loadingEnter]);

  useEffect(() => {
    // Simulate the asynchronous action after login
    setTimeout(() => {
      setLoadingEnter(false);
      // Additional actions after loading is complete
    }, 10000); // Simulating a 10-second delay after login
  }, []); // Empty dependency array to run once after component mounts

  return (
    <LoadingButton
      onClick={() => {
        // setEnter(true);
        setVerificationRequest(true);
      }}
      loading={loadingEnter}
      style={{ fontSize: width < 431 ? 15 : 25, position: 'relative', border: `2px white solid`, borderRadius: 0 }}
      // className={`ad-btn`}
      variant="contained"
      color="primary"
      endIcon={<ArrowRightIcon />}
    >
      <div style={{ position: 'absolute', top: 0, left: 0, width: `${progress}%`, height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 1 }} />
      ENTER NOW
    </LoadingButton>
  );
};

export default FireLoadingButton;
