// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Cart Action Types
import {
  READ_CART_REQUEST,
  READ_CART_SUCCESS,
  READ_CART_FAILURE,
  CREATE_CART_REQUEST,
  CREATE_CART_SUCCESS,
  CREATE_CART_FAILURE,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  DELETE_CART_FAILURE,
  EMPTY_CART_SUCCESS,
  EMPTY_CART_FAILURE,
  EMPTY_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  UPDATE_CART_REQUEST,
  REFRESH_CART_SUCCESS,
  REFRESH_CART_FAILURE,
  REFRESH_CART_REQUEST,
  READ_LAST_CART_FAILURE,
  READ_LAST_CART_SUCCESS,
  READ_LAST_CART_REQUEST,
  DUPLICATE_CART_FAILURE,
  DUPLICATE_CART_SUCCESS,
  DUPLICATE_CART_REQUEST,
} from './types';

// Actions
import { readCart, createCart, deleteCart, emptyCart, updateCart, refreshCart, readLastCart, duplicateCart } from './actions';

/**
 * Function that handles getting a specific Cart.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadCart({ token, id }) {
  try {
    const response = yield call(readCart, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_CART_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_CART_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadCart() {
  yield takeLatest(READ_CART_REQUEST, workerReadCart);
}

/**
 * Function that handles getting a specific Cart.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDuplicateCart({ token, id }) {
  try {
    const response = yield call(duplicateCart, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DUPLICATE_CART_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DUPLICATE_CART_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDuplicateCart() {
  yield takeLatest(DUPLICATE_CART_REQUEST, workerDuplicateCart);
}

/**
 * Function that handles getting a specific Cart.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadLastCart({ token, id }) {
  try {
    const response = yield call(readLastCart, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_LAST_CART_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_LAST_CART_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadLastCart() {
  yield takeLatest(READ_LAST_CART_REQUEST, workerReadLastCart);
}

/**
 * Function that handles refreshing a specific Cart.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerRefreshCart({ token, id }) {
  try {
    const response = yield call(refreshCart, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: REFRESH_CART_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: REFRESH_CART_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherRefreshCart() {
  yield takeLatest(REFRESH_CART_REQUEST, workerRefreshCart);
}

/**
 * Function that handles creating a specific Cart.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateCart({ token, payload }) {
  try {
    const response = yield call(createCart, { token, payload });

    const { data } = response.data;

    yield put({
      type: CREATE_CART_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_CART_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateCart() {
  yield takeLatest(CREATE_CART_REQUEST, workerCreateCart);
}

/**
 * Function that handles creating a specific Cart.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateCart({ token, id, payload }) {
  try {
    const response = yield call(updateCart, { token, id, payload });

    const { data } = response.data;

    yield put({
      type: UPDATE_CART_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_CART_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateCart() {
  yield takeLatest(UPDATE_CART_REQUEST, workerUpdateCart);
}

/**
 * Function that handles deleting a specific Cart.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteCart({ token, id }) {
  try {
    const response = yield call(deleteCart, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_CART_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_CART_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteCart() {
  yield takeLatest(DELETE_CART_REQUEST, workerDeleteCart);
}

/**
 * Function that handles emptying a specific Cart.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerEmptyCart({ token, id }) {
  try {
    const response = yield call(emptyCart, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: EMPTY_CART_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: EMPTY_CART_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherEmptyCart() {
  yield takeLatest(EMPTY_CART_REQUEST, workerEmptyCart);
}
