// Imports
import axios from 'axios';

// Export actions
/**
 * Get a list of Purchase Order Automations.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getPurchaseOrderAutomations = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/purchaseorderautomations${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a specific PurchaseOrderAutomation.
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const readPurchaseOrderAutomation = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/customer/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create an PurchaseOrderAutomation.
 * @param {Object} { token: string, payload: { start_date: date, end_date: date } }
 * @return {AxiosPromise} Http request
 */
export const createPurchaseOrderAutomation = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/purchaseorderautomation`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });
