// Imports
import styled from 'styled-components';

// Main Component
const MaintenanceStyles = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  background: #fafafa;
`;

// Export
export default MaintenanceStyles;
