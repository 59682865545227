// Property Types
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import PasswordResetStyles from './styles';
import FormUtil from '../../../utils/FormUtil';
import EasyErrors from '../../../utils/EasyErrors';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';

/**
 * FORGOTPASSWORD VIEW
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const ForgotPassword = props => {
  // Props
  const { doForgotPassword, doForgotPasswordReset, forgotPassword, history } = props;

  const [forgotPasswordRequest, setForgotPasswordRequest] = React.useState(false);

  const [values, setValues] = React.useState({
    email: '',
  });

  const [validation, setValidation] = React.useState({
    email: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    setForgotPasswordRequest(true);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setForgotPasswordRequest(true);
    }
  };

  useEffect(() => {
    if (forgotPasswordRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setForgotPasswordRequest(false);
      if (values?.email === '') {
        setValidation(FormUtil.addError(validation, 'email', 'Email is blank'));
      }
      if (FormUtil.validate(validation)) {
        let email = values.email;
        doForgotPassword({ email });
      }
    }
  }, [validation, forgotPasswordRequest]);

  useEffect(() => {
    if (!forgotPassword.fetching && forgotPassword.data) {
      Swal.fire({
        icon: 'success',
        text: 'Password reset email has been sent. Please check your email and click the link to change your password.',
        showConfirmButton: false,
        timer: 3500,
        didClose: function() {
          doForgotPasswordReset();
          history.push('/auth/login');
        },
      });
    }
  }, [forgotPassword]);

  return (
    <PasswordResetStyles>
      <Container>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Paper style={{ padding: 10, margin: 'auto', maxWidth: 600, textAlign: 'center', borderRadius: 0 }}>
            <div style={{ padding: 20 }}>
              <div
                style={{ textAlign: `center`, padding: 30, paddingTop: 0 }}
                onClick={() => {
                  history.push('/');
                }}
              >
                <img style={{ width: `100%` }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo.png`} alt={`Logo`} />
              </div>
              <div>{EasyErrors.render(forgotPassword?.errors)}</div>
              <div>
                {' '}
                <p align={`left`}>Enter the username or email associated with your account, and we’ll email you a link to reset your password.</p>
              </div>
              <form style={{ flexGrow: 1 }} noValidate autoComplete="off">
                <FormGroup style={{ marginBottom: 15 }}>
                  <TextField
                    error={FormUtil.renderError(validation, 'email')}
                    helperText={FormUtil.renderHelperText(validation, 'email')}
                    label="Username"
                    value={values.email}
                    onChange={handleChange('email')}
                    placeholder={`Username`}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: 15 }}>
                  <Grid container>
                    <Grid item xs={6} md={6}>
                      <Button
                        size={`large`}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push('/auth/login');
                        }}
                      >
                        Go Back
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <LoadingButton
                        onClick={onSubmitForm}
                        loading={forgotPassword?.fetching}
                        loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                        disabled={forgotPassword?.fetching}
                        size={`large`}
                        variant="contained"
                        color="primary"
                        fullWidth={false}
                      >
                        Reset
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </FormGroup>
              </form>
            </div>
          </Paper>
        </Grid>
      </Container>
    </PasswordResetStyles>
  );
};

ForgotPassword.propTypes = {
  doForgotPassword: PropTypes.func,
  doForgotPasswordReset: PropTypes.func,
  forgotPassword: PropTypes.object,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
};

// Export
export default ForgotPassword;
