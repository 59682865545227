import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TableCell, TextField, Typography } from '@mui/material';
import { CustomModal } from '../CustomModal';
import FormUtil from '../../../utils/FormUtil';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { doGetCustomers } from '../../../containers/Customer/operations';
import CircularProgress from '@mui/material/CircularProgress';

export const CustomerSelectorModal = props => {
  const { isOpen, handleClose, history, token, doGetCustomers, customers, impersonate, doImpersonate, doClearUserContact, doResetSaleOrder, doResetCart, isAndroidDevice } = props;
  // Request State Variables

  const [impersonateId, setImpersonateId] = React.useState(false);
  const [impersonateRequest, setImpersonateRequest] = React.useState(false);

  const [customerFilter, setCustomerFilter] = React.useState('');

  useEffect(() => {
    if (isOpen && token) {
      let query = customerFilter;
      let direction = '';
      let order = '';
      let limit = 100;
      let page = 1;
      doGetCustomers({ token, query, direction, order, limit, page });
    }
  }, [customerFilter, token, isOpen]);

  useEffect(() => {
    if (impersonateRequest) {
      handleClose();
      setImpersonateRequest(false);
      doImpersonate({ token, user_id: impersonateId });
    }
  }, [impersonateRequest, impersonateId]);

  // useEffect(() => {
  //   setFilteredList(customerList.filter(item => item.includes(customerFilter)));
  // }, [customerFilter, filteredList, setFilteredList]);

  // const customerList = ['High Connections', 'Bruces Best Stuff', 'Becky Hash Alot', 'A Whole Lotta Stuff', 'Should OF Picked Me', 'I GOT THE BEST STUFF', 'Holy Moly Vaping Shop'];
  // console.log(customerList);
  return (
    <CustomModal
      props={props}
      isOpen={isOpen}
      handleClose={handleClose}
      /* Modal Title Section */
      title={
        <Grid item xs={12} align={'center'}>
          <Typography variant={'h4'}>Select Customer</Typography>
        </Grid>
      }
      /* Modal Body Section */
      body={
        <Grid item xs={12} style={{ height: isAndroidDevice() ? 300 : 500, overflowY: `scroll` }}>
          <Table>
            <TableBody style={{ minHeight: isAndroidDevice() ? 300 : 500, maxHeight: 500, overflowY: `scroll` }}>
              {customers?.fetching ? (
                <TableRow hover={true} style={{ maxHeight: 50 }}>
                  <TableCell align={`center`} colSpan={2}>
                    <CircularProgress style={{ color: `#000` }} color="inherit" size={50} />
                  </TableCell>
                </TableRow>
              ) : (
                (customers?.data ? customers?.data : []).map(item => (
                  <TableRow hover={true} style={{ maxHeight: 50 }} key={`customer-${item?.id}`}>
                    <TableCell>{item?.user?.company_name ? `${item?.user?.company_name}` : `${item?.user?.firstname} ${item?.user?.lastname}`}</TableCell>
                    <TableCell align={`right`}>
                      <Button
                        size={`small`}
                        variant={'contained'}
                        onClick={() => {
                          setImpersonateId(item?.user?.id);
                          setImpersonateRequest(true);

                          // doImpersonate({ user_id: item?.user?.id });
                        }}
                      >
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Grid>
      }
      /* Modal Actions Section */
      actions={
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              label={'Search'}
              onChange={event => {
                // console.log('FILTERING');
                setCustomerFilter(event.target.value);
                // setFilteredList(
                //   customers?.data?.filter(item => {
                //     return (item?.user?.company_name ? `(${item?.user?.company_name}) ${item?.user?.firstname} ${item?.user?.lastname}` : `${item?.user?.firstname} ${item?.user?.lastname}`).includes(
                //       event.target.value,
                //     );
                //   }),
                // );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button xs={4} fullWidth variant={'contained'}>
              <Typography
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  history.push('/catalog/customers/create');
                  handleClose();
                }}
              >
                Create Customer
              </Typography>
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

CustomerSelectorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.object,
  token: PropTypes.string,
  doGetCustomers: PropTypes.func,
  doImpersonate: PropTypes.func,
  customers: PropTypes.object,
  impersonate: PropTypes.object,
  doClearUserContact: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  doResetCart: PropTypes.func,
};
