// Imports
import axios from 'axios';

// Export actions

/**
 * Get a specific Firebase Token.
 * @param {Object} { token: string, user_id: number}
 * @return {AxiosPromise} Http request
 */
export const readFirebaseToken = ({ token, user_id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/firebasetoken/${user_id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create a Firebase Token.
 * @param {Object} { token: string, payload: { } }
 * @return {AxiosPromise} Http request
 */
export const createFirebaseToken = ({ token, payload }) => {
  axios({
    method: 'post',
    url: `${window.API_URL}/firebasetoken`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });
};
/**
 * Update a Customer.
 * @param {Object} { token: string, payload: { } }
 * @return {AxiosPromise} Http request
 */
export const updateFirebaseToken = ({ token, user_id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/firebasetoken/${user_id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete a Customer.
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const deleteFirebaseToken = ({ token, user_id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/firebasetoken/${user_id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
