// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Catalog from '../../components/Catalog';
import { doLogout, doMe, doLoginReset, doImpersonate, doResetImpersonate } from '../Auth/operations';
import { doCreateCart, doDuplicateCart, doEmptyCart, doOverrideCart, doReadCart, doReadLastCart, doRefreshCart, doResetCart, doResetCartUpdate, doUpdateCart } from '../Cart/operations';
import { doGetCategories, doResetCategories } from '../Category/operations';
import { doCreateCartItem, doDeleteCartItem, doLoadingOnCartItems, doLoadingOffCartItems, doResetCartItem, doUpdateCartItem } from '../CartItem/operations';
import { doClearUserContact, doCreateUserContact, doDeleteUserContact, doGetUserContacts, doUpdateUserContact } from '../UserContact/operations';
import { doCancelSaleOrder, doConfirmSaleOrder, doCreateSaleOrder, doGetSaleOrders, doReadSaleOrder, doResetSaleOrder, doUpdateSaleOrder, doVerifySaleOrder } from '../SaleOrder/operations';
import { doClearUpdateUser, doClearUser, doUpdateDeleteUser, doUpdateUser } from '../User/operations';
import { doUpdateSavedPricing, doSavedPricingReset, doGetSavedPricing, doReadSavedPricing, doUpdatePricingModel, doUpdateAllSavedPricing } from '../SavedPricing/operations';
import { doCreateCustomer, doCustomerReset, doGetCustomers, doReadCustomer } from '../Customer/operations';
import { SALEORDER_RESET } from '../SaleOrder/types';
import Swal from 'sweetalert2';
import { doClearUserGatewayProfile, doGetUserGatewayProfiles, doGetUserPaymentProfiles, doReadUserGatewayProfile, doReadUserPaymentProfile } from '../Authorize/operations';
import { doClearDynamicPage, doReadDynamicPage } from '../DynamicPage/operations';
import { doCreatePurchaseOrderAutomation, doReadPurchaseOrderAutomation, doGetPurchaseOrderAutomations, doPurchaseOrderAutomationReset } from '../PurchaseOrderAutomation/operations';
import { doClearCustomerPaymentMethods, doGetCustomerPaymentMethods } from '../Bluepay/operations';
import { doCreateFirebaseToken, doReadFirebaseToken, doUpdateFirebaseToken } from '../FirebaseToken/operations';
import { doCreateNotificationCampaign, doGetNotificationCampaigns, doNotificationCampaignReset, doReadNotificationCampaign } from '../NotificationCampaign/operations';

/**
 * CATALOG CONTAINER
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
const CatalogContainer = props => {
  const {
    token,
    user,
    impersonate,
    match,
    history,
    categoryList,
    contactList,
    contactCreate,
    contactUpdate,
    contactDelete,
    saleOrderCreate,
    saleOrderConfirm,
    saleOrderVerify,
    saleOrderCancel,
    saleOrderUpdate,
    saleOrderRead,
    saleOrders,
    customerCreate,
    customers,
    customerRead,
    cart,
    cartUpdate,
    cartItemCreate,
    cartItemUpdate,
    cartItemDelete,
    doReadCart,
    doDuplicateCart,
    doClearUser,
    doMe,
    doLogout,
    doImpersonate,
    doResetImpersonate,
    doCustomerReset,
    doGetCategories,
    doResetCategories,
    doCreateSaleOrder,
    doConfirmSaleOrder,
    doVerifySaleOrder,
    doCancelSaleOrder,
    doUpdateSaleOrder,
    doResetSaleOrder,
    doRefreshCart,
    doCreateCart,
    doOverrideCart,
    doCreateCartItem,
    doUpdateCartItem,
    doDeleteCartItem,
    doResetCartItem,
    doLoadingOffCartItems,
    doLoadingOnCartItems,
    doGetSaleOrders,
    doReadSaleOrder,
    doGetUserContacts,
    doCreateUserContact,
    doUpdateUserContact,
    doDeleteUserContact,
    doClearUserContact,
    doReadCustomer,
    doGetCustomers,
    doCreateCustomer,
    doReadLastCart,
    doClearDynamicPage,
    doReadDynamicPage,
    dynamicPageRead,
    //SAVED PRICING
    doGetSavedPricing,
    doReadSavedPricing,
    doUpdateSavedPricing,
    doUpdateAllSavedPricing,
    doUpdatePricingModel,
    savedPricingRead,
    savedPricings,
    savedPricingUpdate,
    doUpdateUser,
    doResetCart,
    doEmptyCart,
    doUpdateCart,
    doResetCartUpdate,
    doSavedPricingReset,
    userUpdate,
    cartItemLoading,
    gateway,
    doReadUserGatewayProfile,
    doClearUserGatewayProfile,
    //PURCHASEORDERAUTOMATION
    doCreatePurchaseOrderAutomation,
    doReadPurchaseOrderAutomation,
    doGetPurchaseOrderAutomations,
    doPurchaseOrderAutomationReset,
    purchaseOrderAutomationList,
    purchaseOrderAutomationRead,
    purchaseOrderAutomationCreate,
    doCreateNotificationCampaign,
    doReadNotificationCampaign,
    doGetNotificationCampaigns,
    doNotificationCampaignReset,
    notificationCampaignList,
    notificationCampaignRead,
    notificationCampaignCreate,
    cartCreate,
    bluepayCustomerPaymentMethodList,
    doGetCustomerPaymentMethods,
    doClearCustomerPaymentMethods,
    userUpdateDelete,
    doUpdateDeleteUser,
    doClearUpdateUser,
    doReadFirebaseToken,
    doCreateFirebaseToken,
    firebaseTokenCreate,
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [searchEvent, setSearchEvent] = useState(null);

  const [internalSearchEventQueue, setInternalSearchEventQueue] = useState([]);

  const [internalProductQueue, setInternalProductQueue] = useState([]);
  const [internalProductVariantQueue, setInternalProductVariantQueue] = useState([]);

  const [internalProduct, setInternalProduct] = useState(null);
  const [internalProductVariant, setInternalProductVariant] = useState(null);

  const [purchaseOrderAutomationTrigger, setPurchaseOrderAutomationTrigger] = useState(false);
  const [notificationCampaignTrigger, setNotificationCampaignTrigger] = useState(false);

  const [focused, setFocused] = useState(false);

  let stopExec = false;

  useEffect(() => {
    if (cartCreate && !cartCreate?.fetching && internalSearchEventQueue.length >= 1 && Array.isArray(internalSearchEventQueue)) {
      const delayDebounceFn = setTimeout(() => {
        internalQuantityQueueChanged(internalSearchEventQueue, internalProductQueue, internalProductVariantQueue);
        setInternalSearchEventQueue([]);
        setInternalProductQueue([]);
        setInternalProductVariantQueue([]);
      }, window.QUANTITY_TIMEOUT);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [cart, cartCreate, internalSearchEventQueue, internalProductVariantQueue, internalProductQueue]);

  useEffect(() => {
    if (cart && cart?.data) {
      stopExec = false;
    }
  }, [cart]);

  const internalQuantityQueueChanged = (eventQueue, productQueue, productVariantQueue) => {
    if (eventQueue.length >= 1 && Array.isArray(eventQueue)) {
      eventQueue.map((event, index) => {
        // Perform some operation on each element of the array
        // let value = eventQueue[index].target.value;
        let value = event?.target?.value;
        let product = productQueue[index];
        let productVariant = productVariantQueue[index];
        if (product == null) {
          doLoadingOffCartItems();
          return;
        }
        if (product?.multiple) {
          if (parseInt(value) !== 0 && value !== '') {
            if (value <= product?.multiple_amount) {
              value = product?.multiple_amount;
              event.target.value = product?.multiple_amount;
            } else {
              if (value % product?.multiple_amount === 0) {
              } else {
                // Swal.fire({
                //   icon: 'error',
                //   html: '<p>Product needs to be a multiple of ' + product?.multiple_amount + '.</p><p> We set the quantity to ' + product?.multiple_amount + '</p>',
                // });
                doLoadingOffCartItems();
                if (value % product?.multiple_amount >= 5) {
                  event.target.value = value - (value % product?.multiple_amount) + product?.multiple_amount;
                  value = value - (value % product?.multiple_amount) + product?.multiple_amount;
                } else {
                  event.target.value = value - (value % product?.multiple_amount);
                  value = value - (value % product?.multiple_amount);
                }
                // console.log(value % product?.multiple_amount);

                // return;
              }
            }
          }
        }

        if (productVariant) {
          if (productVariant?.quantity <= value) {
            event.target.value = productVariant?.quantity;
            value = productVariant?.quantity;
          }
        } else if (product) {
          if (product?.quantity <= value) {
            event.target.value = product?.quantity;
            value = product?.quantity;
          }
        }
        if (stopExec) {
          return;
        }
        if (cart && !cart?.data) {
          stopExec = true;
        }
        if (cart && !cart.fetching && !cart.data) {
          let payload = {
            user_id: user?.id,
            cart_items: productVariantQueue.map((it, index) => {
              let value = eventQueue[index]?.target?.value;
              let product = productQueue[index];
              let productVariant = productVariantQueue[index];
              if (product?.multiple) {
                if (parseInt(value) !== 0 && value !== '') {
                  if (value <= product?.multiple_amount) {
                    value = product?.multiple_amount;
                    eventQueue[index].target.value = product?.multiple_amount;
                  } else {
                    if (value % product?.multiple_amount === 0) {
                    } else {
                      // Swal.fire({
                      //   icon: 'error',
                      //   html: '<p>Product needs to be a multiple of ' + product?.multiple_amount + '.</p><p> We set the quantity to ' + product?.multiple_amount + '</p>',
                      // });
                      doLoadingOffCartItems();
                      if (value % product?.multiple_amount >= 5) {
                        eventQueue[index].target.value = value - (value % product?.multiple_amount) + product?.multiple_amount;
                        value = value - (value % product?.multiple_amount) + product?.multiple_amount;
                      } else {
                        eventQueue[index].target.value = value - (value % product?.multiple_amount);
                        value = value - (value % product?.multiple_amount);
                      }
                      // console.log(value % product?.multiple_amount);

                      // return;
                    }
                  }
                }
              }

              if (productVariant) {
                if (productVariant?.quantity <= value) {
                  eventQueue[index].target.value = productVariant?.quantity;
                  value = productVariant?.quantity;
                }
              } else if (product) {
                if (product?.quantity <= value) {
                  eventQueue[index].target.value = product?.quantity;
                  value = product?.quantity;
                }
              }

              return {
                product_id: productVariant.product_id,
                product_variant_id: productVariant.id,
                quantity: value,
              };
            }),
          };
          // doLoadingOnCartItems();
          doCreateCart({ token, payload });
          return;
          // doLoadingOffCartItems();
        } else if (
          (parseInt(value) === 0 || value === '' || value === null) &&
          cart &&
          !cart.fetching &&
          cart.data &&
          cart.data.cart_items &&
          cart.data.cart_items.length &&
          cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id)
        ) {
          let cartItem = cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id);
          let id = cartItem.id;
          // doLoadingOnCartItems();
          doDeleteCartItem({ token, id });
          // doLoadingOffCartItems();
        } else if (
          cart &&
          cart.data &&
          cart.data.cart_items &&
          cart.data.cart_items.length &&
          cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id)
        ) {
          let cartItem = cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id);
          let id = cartItem.id;
          let quantity = cartItem.quantity;
          if (Number(quantity) === Number(value)) {
            doLoadingOffCartItems();
            return;
          }
          let payload = {
            quantity: Number(value),
          };
          // doLoadingOnCartItems();
          doUpdateCartItem({ token, id, payload });
          // doLoadingOffCartItems();
        } else {
          if (parseInt(value) === 0 || value === '' || value === null) {
            doLoadingOffCartItems();
          } else {
            if (cart?.data?.id) {
              let payload = {
                cart_id: cart?.data?.id,
                product_id: product.id,
                product_variant_id: productVariant.id,
                quantity: Number(value),
              };
              // doLoadingOnCartItems();
              doCreateCartItem({ token, payload });
            } else {
              doLoadingOffCartItems();
            }

            // doLoadingOffCartItems();
          }
        }
      });
      setInternalSearchEventQueue([]);
      setInternalProductQueue([]);
      setInternalProductVariantQueue([]);
    }
  };

  const internalQuantityChanged = (event, value, product, productVariant) => {
    if (product == null) {
      doLoadingOffCartItems();
      return;
    }
    if (product?.multiple) {
      if (parseInt(value) !== 0 && value !== '') {
        if (value <= product?.multiple_amount) {
          value = product?.multiple_amount;
          event.target.value = product?.multiple_amount;
        } else {
          if (value % product?.multiple_amount === 0) {
          } else {
            // Swal.fire({
            //   icon: 'error',
            //   html: '<p>Product needs to be a multiple of ' + product?.multiple_amount + '.</p><p> We set the quantity to ' + product?.multiple_amount + '</p>',
            // });
            doLoadingOffCartItems();
            if (value % product?.multiple_amount >= 5) {
              event.target.value = value - (value % product?.multiple_amount) + product?.multiple_amount;
              value = value - (value % product?.multiple_amount) + product?.multiple_amount;
            } else {
              event.target.value = value - (value % product?.multiple_amount);
              value = value - (value % product?.multiple_amount);
            }
            // console.log(value % product?.multiple_amount);

            // return;
          }
        }
      }
    }

    if (productVariant) {
      if (productVariant?.quantity <= value) {
        event.target.value = productVariant?.quantity;
        value = productVariant?.quantity;
      }
    } else if (product) {
      if (product?.quantity <= value) {
        event.target.value = product?.quantity;
        value = product?.quantity;
      }
    }

    if (cart && !cart.fetching && !cart.data) {
      let payload = {
        user_id: user?.id,
        cart_items: [
          {
            product_id: product.id,
            product_variant_id: productVariant.id,
            quantity: Number(value),
          },
        ],
      };
      // doLoadingOnCartItems();
      doCreateCart({ token, payload });
      // doLoadingOffCartItems();
    } else if (
      (parseInt(value) === 0 || value === '' || value === null) &&
      cart &&
      !cart.fetching &&
      cart.data &&
      cart.data.cart_items &&
      cart.data.cart_items.length &&
      cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id)
    ) {
      let cartItem = cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id);
      let id = cartItem.id;
      // doLoadingOnCartItems();
      doDeleteCartItem({ token, id });
      // doLoadingOffCartItems();
    } else if (
      cart &&
      cart.data &&
      cart.data.cart_items &&
      cart.data.cart_items.length &&
      cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id)
    ) {
      let cartItem = cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id);
      let id = cartItem.id;
      let quantity = cartItem.quantity;
      if (Number(quantity) === Number(value)) {
        doLoadingOffCartItems();
        return;
      }
      let payload = {
        quantity: Number(value),
      };
      // doLoadingOnCartItems();
      doUpdateCartItem({ token, id, payload });
      // doLoadingOffCartItems();
    } else {
      if (parseInt(value) === 0 || value === '' || value === null) {
        doLoadingOffCartItems();
      } else {
        if (cart?.data?.id) {
          let payload = {
            cart_id: cart?.data?.id,
            product_id: product.id,
            product_variant_id: productVariant.id,
            quantity: Number(value),
          };
          // doLoadingOnCartItems();
          doCreateCartItem({ token, payload });
        } else {
          doLoadingOffCartItems();
        }

        // doLoadingOffCartItems();
      }
    }
  };

  function isAndroidDevice() {
    return /Android/i.test(window?.navigator?.appVersion);
  }

  const quantityChanged = (event, product, productVariant) => {
    let input = event.target.value;
    if (cart?.data?.checkout_override) {
      history.push('/catalog/payment');
      Swal.fire({
        icon: 'error',
        text: 'Finish payment before adding anything to the cart.',
      });
      doLoadingOffCartItems();
      return;
    }
    if (cart?.data?.checkout_override) {
      doLoadingOffCartItems();
      return;
    }
    if (cartCreate.fetching) {
      return;
    }
    if (input.match(/\D+/)) {
      event.target.value = 0;
    }
    // setTimeout(function() {
    //   if (cartItemCreate.fetching || cartItemUpdate.fetching) {
    //     doLoadingOffCartItems();
    //   }
    // }, 10000);
    // doLoadingOnCartItems();
    setSearchEvent(event);
    setSearchTerm(event.target.value);

    setInternalProductVariant(productVariant);
    setInternalProduct(product);

    // if (!internalSearchEventQueue.includes(event)) {
    //
    // }
    if (!internalProductVariantQueue.includes(productVariant)) {
      setInternalSearchEventQueue([...internalSearchEventQueue, event]);
      setInternalProductQueue([...internalProductQueue, product]);
      setInternalProductVariantQueue([...internalProductVariantQueue, productVariant]);
    }
    // if (!internalProductQueue.includes(product)) {

    // }
    // clearTimeout(typingObj.typingTimeout);
    // typingObj.typing = true;
    // typingObj.typingTimeout = setTimeout(
    //   () => {
    //     if (!typingObj.typing) return;
    //     typingObj.typing = false;
    //   },
    //   event.type === 'blur' ? 0 : 500,
    // );
  };

  function logAndReportToBugsnag(message, data) {
    // console.log(message, data);

    // Notify Bugsnag of a custom event
    Bugsnag.notify(new Error(message), event => {
      event.addMetadata('data', data);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!categoryList.fetching && !categoryList.data) {
      let query = '';
      let direction = '';
      let order = '';
      let limit = 20;
      let page = 1;
      doGetCategories({ token, query, direction, order, limit, page });
    }
  }, [doGetCategories]);

  useEffect(() => {
    if (
      (cartItemDelete && !cartItemDelete.fetching && cartItemDelete.errors) ||
      (cartItemCreate && !cartItemCreate.fetching && cartItemCreate.errors) ||
      (cartItemUpdate && !cartItemUpdate.fetching && cartItemUpdate.errors)
    ) {
      doLoadingOffCartItems();
    }
  }, [token, cartItemDelete, cartItemCreate, cartItemUpdate]);

  useEffect(() => {
    if (
      ((cartItemDelete && !cartItemDelete.fetching && cartItemDelete.complete) ||
        (cartItemCreate && !cartItemCreate.fetching && cartItemCreate.data) ||
        (cartItemUpdate && !cartItemUpdate.fetching && cartItemUpdate.data)) &&
      cart &&
      !cart.fetching &&
      cart.data
    ) {
      doResetCartItem();
      // let id = cart?.data?.id;
      // doReadCart({ token, id });
      doLoadingOffCartItems();
    }
  }, [token, cartItemDelete, cartItemCreate, cartItemUpdate]);

  useEffect(() => {
    if (cartItemDelete && !cartItemDelete.fetching && cartItemDelete.complete && cart && !cart.fetching && cart.data) {
      doResetCartItem();
      let id = cart?.data?.id;
      doReadCart({ token, id });
    }
  }, [token, cartItemDelete, cartItemCreate, cartItemUpdate]);

  useEffect(() => {
    if (cart && !cart.fetching && !cart.data && !cart.errors) {
      // if (cartItemCreate.fetching || cartItemUpdate.fetching || cartItemDelete.fetching) {
      doReadLastCart({ token });
      // }
    }
  }, [token, user, cart]);

  useEffect(() => {
    if (cart && !cart.fetching && (cart.data || cart.errors)) {
      // if (cartItemCreate.fetching || cartItemUpdate.fetching || cartItemDelete.fetching) {
      doLoadingOffCartItems();
      // }
    }
  }, [token, cart]);

  useEffect(() => {
    if (
      (cart && !cart.fetching && cart.data && cart.data.cart_items && cart.data.cart_items.length === 0) ||
      (cart && !cart.fetching && cart.data && user?.role?.name === 'sales')
      // (cart && !cart.fetching && cart.data && cart?.data?.purchased)
    ) {
      doResetCart();
      doResetCartItem();
    }
  }, [cart]);

  useEffect(() => {
    if (!impersonate?.fetching && impersonate?.data) {
      doResetImpersonate({});
      doClearUserContact({});
      doResetSaleOrder(SALEORDER_RESET);
      doResetCart({});
      doResetCategories();
      doClearCustomerPaymentMethods();
      doClearUserGatewayProfile();
      let query = '';
      let direction = '';
      let order = '';
      let limit = 20;
      let page = 1;
      doGetCategories({ token, query, direction, order, limit, page });
    }
  }, [impersonate]);

  useEffect(() => {
    if (!saleOrderConfirm?.fetching && saleOrderConfirm?.data) {
      history.push('/catalog/thankyou');
      doResetSaleOrder(SALEORDER_RESET);
      doResetCart();
      doResetCategories();
      doClearCustomerPaymentMethods();
      doClearUserGatewayProfile();
      let query = '';
      let direction = '';
      let order = '';
      let limit = 20;
      let page = 1;
      doGetCategories({ token, query, direction, order, limit, page });
    }
  }, [saleOrderConfirm, history]);

  useEffect(() => {
    if (cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length === 0) {
      doResetSaleOrder(SALEORDER_RESET);
    }
  }, [cart]);

  useEffect(() => {
    if (saleOrderRead && saleOrderRead?.data && saleOrderRead?.data?.confirmed && saleOrderRead?.data?.payment_registered) {
      doResetCart();
      doResetCartItem();
      doResetSaleOrder(SALEORDER_RESET);
      Swal.fire({
        icon: 'success',
        text: 'Sale order has been confirmed and payment has been registered.',
        showConfirmButton: false,
        timer: 3500,
        didClose: function() {
          history.push('/catalog');
        },
      });
    }
  }, [saleOrderRead]);

  const onFocusFunction = () => {
    // do whatever when focus is gained
    setFocused(true);
  };

  const onBlurFunction = () => {
    // do whatever when focus is lost
    setFocused(false);
  };

  useEffect(() => {
    onFocusFunction();

    window.addEventListener('focus', onFocusFunction);
    window.addEventListener('blur', onBlurFunction);

    return () => {
      onBlurFunction();

      window.removeEventListener('focus', onFocusFunction);
      window.removeEventListener('blur', onBlurFunction);
    };
  }, []);

  useEffect(() => {
    const onPause = () => {};

    const onResume = () => {};

    document.addEventListener('pause', onPause, false);
    document.addEventListener('resume', onResume, false);

    // Cleanup function
    return () => {
      document.removeEventListener('pause', onPause, false);
      document.removeEventListener('resume', onResume, false);
    };
  }, []);

  // useEffect(() => {
  //   let touchStartX = 0;
  //   let touchStartY = 0;
  //   let touchEndX = 0;
  //   let touchEndY = 0;
  //
  //   function handleTouchStart(event) {
  //     touchStartX = event.touches[0].clientX;
  //     touchStartY = event.touches[0].clientY;
  //   }
  //
  //   function handleTouchMove(event) {
  //     touchEndX = event.touches[0].clientX;
  //     touchEndY = event.touches[0].clientY;
  //   }
  //
  //   function handleTouchEnd() {
  //     let deltaX = touchEndX - touchStartX;
  //     let deltaY = touchEndY - touchStartY;
  //     let minDistance = 50; // Minimum distance to consider it a swipe
  //
  //     if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > minDistance) {
  //       // Horizontal movement is greater than vertical movement and exceeds minimum distance
  //       if (deltaX > 0) {
  //         // console.log('Swipe Right - Going Back');
  //         // history.goBack();
  //       } else {
  //         // console.log('Swipe Left - Going Forward');
  //         // history.goForward();
  //       }
  //     }
  //   }
  //   document.addEventListener('touchstart', handleTouchStart, false);
  //   document.addEventListener('touchmove', handleTouchMove, false);
  //   document.addEventListener('touchend', handleTouchEnd, false);
  //
  //   return () => {
  //     document.removeEventListener('touchstart', handleTouchStart, false);
  //     document.removeEventListener('touchmove', handleTouchMove, false);
  //     document.removeEventListener('touchend', handleTouchEnd, false);
  //   };
  // }, [history]); // Adding history to the dependency array ensures the effect uses the latest history instance

  const [firebaseTokenPayload, setFirebaseTokenPayload] = useState(null);
  const [firebaseTokenRequest, setFirebaseTokenRequest] = useState(false);
  const [notificationPermissionCheck, setNotificationPermissionCheck] = useState(false);
  const [hasNotificationPermission, setHasNotificationPermission] = useState(false);

  useEffect(() => {
    if (window?.cordova && window?.cordova?.platformId === 'ios') {
      if (window?.FCM && !notificationPermissionCheck && !hasNotificationPermission) {
        setNotificationPermissionCheck(true);
        window?.FCM.requestPushPermission()
          .then(function(didIt) {
            if (didIt) {
              setHasNotificationPermission(true);
            }
          })
          .catch(function(error) {
            console.log(error);
            setHasNotificationPermission(false);
          });
      }
    }
  }, [notificationPermissionCheck, hasNotificationPermission]);

  useEffect(() => {
    const fetchFirebaseToken = async () => {
      if (window?.FCM && notificationPermissionCheck && hasNotificationPermission) {
        try {
          const fcmToken = await window?.FCM.getToken();
          if (fcmToken) {
            setFirebaseTokenPayload(fcmToken);
            await window?.FCM?.createNotificationChannel({
              id: 'sound_alert7',
              name: 'Sound Alert7',
              // description: "Useless",
              importance: 'high',
              // visibility: "public",
              // sound: 'elet_mp3',
              lights: true,
              vibration: true,
            });
          }
        } catch (error) {
          // Handle error if FCM.getToken() fails
          console.error('Error fetching FCM token:', error);
        }
      }
    };
    if (window?.cordova && window?.cordova?.platformId === 'ios') {
      if (firebaseTokenPayload == null && user?.impersonator_id == null) {
        fetchFirebaseToken();
      }
    }
  }, [firebaseTokenPayload, notificationPermissionCheck, hasNotificationPermission, user]);

  useEffect(() => {
    if (!firebaseTokenRequest && firebaseTokenPayload && user && token && firebaseTokenCreate && !firebaseTokenCreate.fetching && !firebaseTokenCreate.data && !firebaseTokenCreate.errors) {
      setFirebaseTokenRequest(true);
      let user_id = user?.id;
      let payload = {
        user_id: user_id,
        firebase_token: firebaseTokenPayload,
      };
      doCreateFirebaseToken({ token, payload });
    }
  }, [firebaseTokenRequest, firebaseTokenPayload, user, token, firebaseTokenCreate, doCreateFirebaseToken]);

  useEffect(() => {
    // if (window?.cordova && window?.cordova?.platformId === 'ios') {
    if (window?.FCM) {
      window?.FCM.getInitialPushPayload()
        .then(payload => {
          if (payload) {
            const url = payload?.url;
            if (url) {
              history.push(url);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
      // }
    }
  }, [focused]);

  if (!window?.initialLoad) {
    if (window?.FCM) {
      window?.FCM.getInitialPushPayload()
        .then(payload => {
          if (payload) {
            const url = payload?.url;
            if (url) {
              history.push(url);
              // forceUpdate();
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
      window.initialLoad = true;
    }
  }
  const [versionNotification, setVersionNotification] = useState(false);

  useEffect(() => {
    // Early return if notification has already been set
    if (versionNotification) return;

    // Function to check app version and notify user
    const checkVersion = () => {
      if (typeof window.APP_VERSION !== 'undefined' && user.appVersion && user.appVersion?.version !== window.APP_VERSION) {
        setVersionNotification(true); // Set the notification state to prevent future checks
        notifyUpdate(); // Trigger the notification only if the plugin exists
      }
    };

    // Function to trigger local notification
    const notifyUpdate = () => {
      // if (window.cordova && cordova.plugins && cordova.plugins.notification && cordova.plugins.notification.local) {
      //   cordova.plugins.notification.local.schedule({
      //     title: 'Update Available',
      //     text: 'A new version of the app is available. Tap to update now!',
      //     foreground: true, // Ensures it is a heads-up notification
      //     onClick: () => {
      //       // Open the App Store link when the notification is clicked
      //       let appStoreUrl = window.APP_STORE_URL;
      //       window.open(appStoreUrl, '_system');
      //     },
      //   });
      // }
    };

    document.addEventListener(
      'deviceready',
      () => {
        // if (window.cordova && cordova.plugins && cordova.plugins.notification && cordova.plugins.notification.local) {
        //   cordova.plugins.notification.local.on('click', function() {
        //     let appStoreUrl = window.APP_STORE_URL;
        //     window.open(appStoreUrl, '_system');
        //   });
        // }
      },
      false,
    );

    checkVersion();
  }, [versionNotification, user]); // Dependencies on versionNotification and user

  return (
    <Catalog
      isAndroidDevice={isAndroidDevice}
      versionNotification={versionNotification}
      token={token}
      user={user}
      impersonate={impersonate}
      match={match}
      history={history}
      customerCreate={customerCreate}
      categoryList={categoryList}
      contactList={contactList}
      contactCreate={contactCreate}
      contactUpdate={contactUpdate}
      contactDelete={contactDelete}
      cartItemCreate={cartItemCreate}
      cartItemUpdate={cartItemUpdate}
      cartItemDelete={cartItemDelete}
      saleOrderCreate={saleOrderCreate}
      saleOrderConfirm={saleOrderConfirm}
      saleOrderVerify={saleOrderVerify}
      saleOrderUpdate={saleOrderUpdate}
      saleOrderCancel={saleOrderCancel}
      saleOrderRead={saleOrderRead}
      customers={customers}
      customerRead={customerRead}
      saleOrders={saleOrders}
      cart={cart}
      quantityChanged={quantityChanged}
      doImpersonate={doImpersonate}
      doMe={doMe}
      doLogout={doLogout}
      doCreateCart={doCreateCart}
      doRefreshCart={doRefreshCart}
      doOverrideCart={doOverrideCart}
      doReadCart={doReadCart}
      doResetCart={doResetCart}
      doGetSavedPricing={doGetSavedPricing}
      doReadSavedPricing={doReadSavedPricing}
      doUpdateSavedPricing={doUpdateSavedPricing}
      doUpdateAllSavedPricing={doUpdateAllSavedPricing}
      doSavedPricingReset={doSavedPricingReset}
      doUpdatePricingModel={doUpdatePricingModel}
      doResetCategories={doResetCategories}
      savedPricings={savedPricings}
      savedPricingRead={savedPricingRead}
      savedPricingUpdate={savedPricingUpdate}
      doCreateSaleOrder={doCreateSaleOrder}
      doConfirmSaleOrder={doConfirmSaleOrder}
      doVerifySaleOrder={doVerifySaleOrder}
      doCancelSaleOrder={doCancelSaleOrder}
      doUpdateSaleOrder={doUpdateSaleOrder}
      doGetSaleOrders={doGetSaleOrders}
      doReadSaleOrder={doReadSaleOrder}
      doResetSaleOrder={doResetSaleOrder}
      doGetUserContacts={doGetUserContacts}
      doCreateUserContact={doCreateUserContact}
      doUpdateUserContact={doUpdateUserContact}
      doDeleteUserContact={doDeleteUserContact}
      doClearUserContact={doClearUserContact}
      doCreateCartItem={doCreateCartItem}
      doUpdateCartItem={doUpdateCartItem}
      doDeleteCartItem={doDeleteCartItem}
      doResetCartItem={doResetCartItem}
      doLoadingOnCartItems={doLoadingOnCartItems}
      doLoadingOffCartItems={doLoadingOffCartItems}
      doGetCategories={doGetCategories}
      doUpdateUser={doUpdateUser}
      doEmptyCart={doEmptyCart}
      doReadCustomer={doReadCustomer}
      doCreateCustomer={doCreateCustomer}
      doCustomerReset={doCustomerReset}
      doGetCustomers={doGetCustomers}
      doUpdateCart={doUpdateCart}
      cartUpdate={cartUpdate}
      doResetCartUpdate={doResetCartUpdate}
      userUpdate={userUpdate}
      internalProduct={internalProduct}
      internalProductVariant={internalProductVariant}
      focused={focused}
      cartItemLoading={cartItemLoading}
      gateway={gateway}
      doReadUserGatewayProfile={doReadUserGatewayProfile}
      doClearUserGatewayProfile={doClearUserGatewayProfile}
      dynamicPageRead={dynamicPageRead}
      doReadDynamicPage={doReadDynamicPage}
      doClearDynamicPage={doClearDynamicPage}
      doReadPurchaseOrderAutomation={doReadPurchaseOrderAutomation}
      doCreatePurchaseOrderAutomation={doCreatePurchaseOrderAutomation}
      doGetPurchaseOrderAutomations={doGetPurchaseOrderAutomations}
      purchaseOrderAutomationList={purchaseOrderAutomationList}
      purchaseOrderAutomationRead={purchaseOrderAutomationRead}
      purchaseOrderAutomationCreate={purchaseOrderAutomationCreate}
      doPurchaseOrderAutomationReset={doPurchaseOrderAutomationReset}
      setPurchaseOrderAutomationTrigger={setPurchaseOrderAutomationTrigger}
      notificationCampaignTrigger={notificationCampaignTrigger}
      doReadNotificationCampaign={doReadNotificationCampaign}
      doCreateNotificationCampaign={doCreateNotificationCampaign}
      doGetNotificationCampaigns={doGetNotificationCampaigns}
      notificationCampaignList={notificationCampaignList}
      notificationCampaignRead={notificationCampaignRead}
      notificationCampaignCreate={notificationCampaignCreate}
      doNotificationCampaignReset={doNotificationCampaignReset}
      setNotificationCampaignTrigger={setNotificationCampaignTrigger}
      purchaseOrderAutomationTrigger={purchaseOrderAutomationTrigger}
      bluepayCustomerPaymentMethodList={bluepayCustomerPaymentMethodList}
      doGetCustomerPaymentMethods={doGetCustomerPaymentMethods}
      doClearCustomerPaymentMethods={doClearCustomerPaymentMethods}
      userUpdateDelete={userUpdateDelete}
      doUpdateDeleteUser={doUpdateDeleteUser}
      doClearUser={doClearUser}
      doClearUpdateUser={doClearUpdateUser}
      doDuplicateCart={doDuplicateCart}
    />
  );
};
// REDUX
// --------------------------------------------------------
/**
 * Maps Redux State To Component
 * @param {Object} state main redux state
 * @returns {Object} Redux Reducer States
 */
// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  // Login Reducer
  login: state.authReducer.login,
  user: state.authReducer.user,
  token: state.authReducer.token,
  impersonate: state.authReducer.impersonate,

  // Dynamic Page
  dynamicPageRead: state.dynamicPageReducer.read,

  // Saved Pricing
  savedPricingRead: state.savedPricingReducer.read,
  savedPricings: state.savedPricingReducer.list,
  savedPricingUpdate: state.savedPricingReducer.update,

  // Sale Order Reducer
  saleOrders: state.saleOrderReducer.list,
  saleOrderRead: state.saleOrderReducer.read,
  saleOrderCreate: state.saleOrderReducer.create,
  saleOrderUpdate: state.saleOrderReducer.update,
  saleOrderDelete: state.saleOrderReducer.delete,
  saleOrderConfirm: state.saleOrderReducer.confirm,
  saleOrderVerify: state.saleOrderReducer.verify,
  saleOrderCancel: state.saleOrderReducer.cancel,

  //User Contact Reducer
  contactList: state.userContactReducer.list,
  contactCreate: state.userContactReducer.create,
  contactUpdate: state.userContactReducer.update,
  contactDelete: state.userContactReducer.delete,

  //Categories Reducer
  categoryList: state.categoryReducer.list,

  //Cart
  cartCreate: state.cartReducer.create,

  //CartItem Reducer
  cartItemCreate: state.cartItemReducer.create,
  cartItemUpdate: state.cartItemReducer.update,
  cartItemDelete: state.cartItemReducer.delete,
  cartItemLoading: state.cartItemReducer.loading,

  //Customer Reducer
  customers: state.customerReducer.list,
  customerCreate: state.customerReducer.create,
  customerRead: state.customerReducer.read,

  userUpdate: state.userReducer.update,
  userUpdateDelete: state.userReducer.updateDelete,

  // Cart Reducer
  cart: state.cartReducer.cart,
  cartUpdate: state.cartReducer.update,

  // Authorize Reducer
  gateway: state.authorizeReducer.gateway,

  // Purchase Order Automation Reducer
  purchaseOrderAutomationList: state.purchaseOrderAutomationReducer.list,
  purchaseOrderAutomationRead: state.purchaseOrderAutomationReducer.read,
  purchaseOrderAutomationCreate: state.purchaseOrderAutomationReducer.create,

  // Notification Campaign Reducer
  notificationCampaignList: state.notificationCampaignReducer.list,
  notificationCampaignRead: state.notificationCampaignReducer.read,
  notificationCampaignCreate: state.notificationCampaignReducer.create,

  bluepayCustomerPaymentMethodList: state.bluepayReducer.bluepayCustomerPaymentMethodList,

  firebaseTokenRead: state.firebaseTokenReducer.read,
  firebaseTokenCreate: state.firebaseTokenReducer.create,
  firebaseTokenUpdate: state.firebaseTokenReducer.update,
});

/**
 * Maps Redux Actions To Component
 * @param {*} dispatch main redux dispatch event
 * @returns {{}} Redux Dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //Login
      doMe,
      doLoginReset,
      doLogout,
      doImpersonate,
      doResetImpersonate,

      //DYNAMIC PAGE
      doClearDynamicPage,
      doReadDynamicPage,

      //SAVED PRICING
      doGetSavedPricing,
      doReadSavedPricing,
      doUpdateSavedPricing,
      doUpdateAllSavedPricing,
      doUpdatePricingModel,
      doSavedPricingReset,

      //User
      doUpdateUser,
      doUpdateDeleteUser,
      doClearUser,
      doClearUpdateUser,

      //Customers
      doGetCustomers,
      doReadCustomer,
      doCreateCustomer,
      doCustomerReset,

      //Categories
      doGetCategories,
      doResetCategories,

      // Cart
      doReadCart,
      doDuplicateCart,
      doCreateCart,
      doEmptyCart,
      doResetCart,
      doUpdateCart,
      doRefreshCart,
      doResetCartUpdate,
      doOverrideCart,
      doReadLastCart,

      // User Contact
      doGetUserContacts,
      doCreateUserContact,
      doUpdateUserContact,
      doDeleteUserContact,
      doClearUserContact,

      // Cart Item
      doCreateCartItem,
      doUpdateCartItem,
      doDeleteCartItem,
      doResetCartItem,
      doLoadingOnCartItems,
      doLoadingOffCartItems,

      // Sales Order
      doGetSaleOrders,
      doReadSaleOrder,
      doCreateSaleOrder,
      doUpdateSaleOrder,
      doResetSaleOrder,
      doConfirmSaleOrder,
      doVerifySaleOrder,
      doCancelSaleOrder,

      //Authorize
      doGetUserGatewayProfiles,
      doReadUserGatewayProfile,
      doGetUserPaymentProfiles,
      doReadUserPaymentProfile,
      doClearUserGatewayProfile,

      //PurchaseOrderAutomation
      doCreatePurchaseOrderAutomation,
      doGetPurchaseOrderAutomations,
      doReadPurchaseOrderAutomation,
      doPurchaseOrderAutomationReset,

      //NotificationCampaign
      doCreateNotificationCampaign,
      doGetNotificationCampaigns,
      doReadNotificationCampaign,
      doNotificationCampaignReset,

      doGetCustomerPaymentMethods,
      doClearCustomerPaymentMethods,

      //FirebaseToken
      doReadFirebaseToken,
      doCreateFirebaseToken,
      doUpdateFirebaseToken,
    },
    dispatch,
  );

// PROPS TYPES
// --------------------------------------------------------
CatalogContainer.propTypes = {
  // Login
  doMe: PropTypes.func,
  doLogout: PropTypes.func,
  doImpersonate: PropTypes.func,
  doResetImpersonate: PropTypes.func,

  //Dynamic Pages
  doClearDynamicPage: PropTypes.func,
  doReadDynamicPage: PropTypes.func,
  dynamicPageRead: PropTypes.object,

  //Categories
  doGetCategories: PropTypes.func,
  doResetCategories: PropTypes.func,

  //Saved Pricing
  doGetSavedPricing: PropTypes.func,
  doReadSavedPricing: PropTypes.func,
  doUpdateSavedPricing: PropTypes.func,
  doUpdateAllSavedPricing: PropTypes.func,
  doUpdatePricingModel: PropTypes.func,
  doSavedPricingReset: PropTypes.func,
  savedPricings: PropTypes.object,
  savedPricingRead: PropTypes.object,
  savedPricingUpdate: PropTypes.object,

  //Authorize
  doClearUserGatewayProfile: PropTypes.func,
  doReadUserGatewayProfile: PropTypes.func,

  //PurchaseOrderAutomation
  doCreatePurchaseOrderAutomation: PropTypes.func,
  doGetPurchaseOrderAutomations: PropTypes.func,
  doReadPurchaseOrderAutomation: PropTypes.func,
  doPurchaseOrderAutomationReset: PropTypes.func,
  purchaseOrderAutomationList: PropTypes.object,
  purchaseOrderAutomationRead: PropTypes.object,
  purchaseOrderAutomationCreate: PropTypes.object,

  // Cart
  doReadCart: PropTypes.func,
  doCreateCart: PropTypes.func,
  doEmptyCart: PropTypes.func,
  doResetCart: PropTypes.func,
  doUpdateCart: PropTypes.func,
  doRefreshCart: PropTypes.func,
  doResetCartUpdate: PropTypes.func,
  doOverrideCart: PropTypes.func,
  doReadLastCart: PropTypes.func,

  // Sale Order
  doGetSaleOrders: PropTypes.func,
  doCreateSaleOrder: PropTypes.func,
  doConfirmSaleOrder: PropTypes.func,
  doVerifySaleOrder: PropTypes.func,
  doUpdateSaleOrder: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  doCancelSaleOrder: PropTypes.func,
  doReadSaleOrder: PropTypes.func,

  // User Contacts
  doGetUserContacts: PropTypes.func,
  doCreateUserContact: PropTypes.func,
  doUpdateUserContact: PropTypes.func,
  doDeleteUserContact: PropTypes.func,
  doClearUserContact: PropTypes.func,

  // Cart Item
  doCreateCartItem: PropTypes.func,
  doUpdateCartItem: PropTypes.func,
  doDeleteCartItem: PropTypes.func,
  doResetCartItem: PropTypes.func,
  doLoadingOffCartItems: PropTypes.func,
  doLoadingOnCartItems: PropTypes.func,

  // User
  doUpdateUser: PropTypes.func,

  // Customer
  doGetCustomers: PropTypes.func,
  doCreateCustomer: PropTypes.func,
  doCustomerReset: PropTypes.func,
  doReadCustomer: PropTypes.func,

  customerRead: PropTypes.object,
  customerCreate: PropTypes.object,

  contactCreate: PropTypes.object,
  contactUpdate: PropTypes.object,
  contactList: PropTypes.object,
  contactDelete: PropTypes.object,
  categoryList: PropTypes.object,

  saleOrderCreate: PropTypes.object,
  saleOrderConfirm: PropTypes.object,
  saleOrderVerify: PropTypes.object,
  saleOrderCancel: PropTypes.object,
  saleOrderUpdate: PropTypes.object,
  saleOrderRead: PropTypes.object,

  gateway: PropTypes.object,

  cartCreate: PropTypes.object,

  cartItemCreate: PropTypes.object,
  cartItemUpdate: PropTypes.object,
  cartItemDelete: PropTypes.object,

  saleOrders: PropTypes.object,

  doClearUser: PropTypes.func,
  userUpdate: PropTypes.object,
  customers: PropTypes.object,
  cart: PropTypes.object,
  cartUpdate: PropTypes.object,
  user: PropTypes.object,
  impersonate: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string,
  cartItemLoading: PropTypes.bool,

  bluepayCustomerPaymentMethodList: PropTypes.object,
  doGetCustomerPaymentMethods: PropTypes.func,
  doClearCustomerPaymentMethods: PropTypes.func,

  doReadFirebaseToken: PropTypes.func,
  doCreateFirebaseToken: PropTypes.func,
  doUpdateFirebaseToken: PropTypes.func,

  userUpdateDelete: PropTypes.object,
  doUpdateDeleteUser: PropTypes.func,
  doClearUpdateUser: PropTypes.func,

  firebaseTokenRead: PropTypes.object,
  firebaseTokenCreate: PropTypes.object,
  firebaseTokenUpdate: PropTypes.object,

  doCreateNotificationCampaign: PropTypes.func,
  doReadNotificationCampaign: PropTypes.func,
  doGetNotificationCampaigns: PropTypes.func,
  doNotificationCampaignReset: PropTypes.func,
  notificationCampaignList: PropTypes.object,
  notificationCampaignRead: PropTypes.object,
  notificationCampaignCreate: PropTypes.object,
  notificationCampaignTrigger: PropTypes.bool,
  setNotificationCampaignTrigger: PropTypes.func,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(CatalogContainer);
