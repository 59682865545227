// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { LIST_CUSTOMER_PAYMENT_METHODS_REQUEST, LIST_CUSTOMER_PAYMENT_METHODS_FAILURE, LIST_CUSTOMER_PAYMENT_METHODS_SUCCESS } from './types';

// Actions
import { getCustomerPaymentMethods } from './actions';

/**
 * Function that handles listing User Gateway Profiles
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetCustomerPaymentMethods({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getCustomerPaymentMethods, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_CUSTOMER_PAYMENT_METHODS_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_CUSTOMER_PAYMENT_METHODS_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetCustomerPaymentMethods() {
  yield takeLatest(LIST_CUSTOMER_PAYMENT_METHODS_REQUEST, workerGetCustomerPaymentMethods);
}
