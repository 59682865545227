import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { CustomModal } from '../CustomModal';
import FormUtil from '../../../utils/FormUtil';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@mui/lab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CustomerAddressPage from '../../../views/Catalog/CustomerAddressPage';

const AddressCreateModal = props => {
  const { token, isOpen, handleClose, contactCreate, doCreateUserContact, doClearUserContact } = props;
  // Request State Variables
  const [saveRequest, setSaveRequest] = React.useState(false);

  const [values, setValues] = React.useState({
    company_name: '',
    phone: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zipcode: '',
    enabled: false,
  });

  const [validation, setValidation] = React.useState({
    company_name: {
      validated: false,
      valid: true,
      errors: [],
    },
    phone: {
      validated: false,
      valid: true,
      errors: [],
    },
    address_line_1: {
      validated: false,
      valid: true,
      errors: [],
    },
    address_line_2: {
      validated: false,
      valid: true,
      errors: [],
    },
    city: {
      validated: false,
      valid: true,
      errors: [],
    },
    state: {
      validated: false,
      valid: true,
      errors: [],
    },
    zipcode: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  useEffect(() => {
    if (saveRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setSaveRequest(false);
      if (values?.company_name === '') {
        setValidation(FormUtil.addError(validation, 'company_name', 'Company Name is blank!'));
      }
      if (values?.phone === '') {
        setValidation(FormUtil.addError(validation, 'phone', 'Phone is blank!'));
      }
      if (values?.address_line_1 === '') {
        setValidation(FormUtil.addError(validation, 'address_line_1', 'Address Line 1 is blank!'));
      }
      if (values?.city === '') {
        setValidation(FormUtil.addError(validation, 'city', 'City is blank!'));
      }
      if (values?.state === '') {
        setValidation(FormUtil.addError(validation, 'state', 'State is blank!'));
      }
      if (values?.zipcode === '') {
        setValidation(FormUtil.addError(validation, 'zipcode', 'ZIP Code is blank!'));
      } else if (!values?.zipcode.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) {
        setValidation(FormUtil.addError(validation, 'zipcode', 'Invalid ZIP Code!'));
      }

      // Backend API Call.
      if (FormUtil.validate(validation)) {
        let payload = {
          company_name: values?.company_name,
          phone: values?.phone,
          address_line_1: values?.address_line_1,
          address_line_2: values?.address_line_2,
          city: values?.city,
          state: values?.state,
          zipcode: values?.zipcode,
          enabled: values?.enabled,
        };
        doCreateUserContact({ token, payload });
        handleClose();
      }
    }
  }, [token, validation, saveRequest]);

  const onSubmitForm = event => {
    event.preventDefault();
    setSaveRequest(true);
  };

  const handleChange = (prop, type = 'text') => event => {
    if (type === 'text') {
      setValues({ ...values, [prop]: event.target.value });
    } else if (type === 'checkbox') {
      setValues({ ...values, [prop]: event.target.checked });
    }
  };

  return (
    <CustomModal
      props={props}
      isOpen={isOpen}
      handleClose={handleClose}
      /* Modal Title Section */
      title={
        <Grid item xs={12} align={'center'}>
          <Typography variant={'h4'}>Create Address</Typography>
        </Grid>
      }
      /* Modal Body Section */
      body={
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              error={FormUtil.renderError(validation, 'company_name')}
              helperText={FormUtil.renderHelperText(validation, 'company_name')}
              label="Company Name"
              value={values.company_name}
              onChange={handleChange('company_name')}
              placeholder={`Company Name`}
              variant="outlined"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              error={FormUtil.renderError(validation, 'phone')}
              helperText={FormUtil.renderHelperText(validation, 'phone')}
              label="Phone"
              value={values.phone}
              onChange={handleChange('phone')}
              placeholder={`Phone`}
              variant="outlined"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              error={FormUtil.renderError(validation, 'address_line_1')}
              helperText={FormUtil.renderHelperText(validation, 'address_line_1')}
              label="Address Line 1"
              value={values.address_line_1}
              onChange={handleChange('address_line_1')}
              placeholder={`Address Line 1`}
              variant="outlined"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TextField
              error={FormUtil.renderError(validation, 'address_line_2')}
              helperText={FormUtil.renderHelperText(validation, 'address_line_2')}
              label="Address Line 2"
              value={values.address_line_2}
              onChange={handleChange('address_line_2')}
              placeholder={`Address Line 2`}
              variant="outlined"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={4} md={4} lg={4}>
            <TextField
              error={FormUtil.renderError(validation, 'city')}
              helperText={FormUtil.renderHelperText(validation, 'city')}
              label="City"
              value={values.city}
              onChange={handleChange('city')}
              placeholder={`City`}
              variant="outlined"
              fullWidth={true}
            />
          </Grid>

          <Grid align={'left'} item xs={4} md={4} lg={4}>
            <TextField
              select
              label="State"
              defaultValue={values.state}
              value={values.state}
              onChange={handleChange('state')}
              fullWidth={true}
              error={FormUtil.renderError(validation, 'state')}
              helperText={FormUtil.renderHelperText(validation, 'state')}
            >
              <MenuItem value={'AL'}>Alabama</MenuItem>
              <MenuItem value={'AK'}>Alaska</MenuItem>
              <MenuItem value={'AZ'}>Arizona</MenuItem>
              <MenuItem value={'AR'}>Arkansas</MenuItem>
              <MenuItem value={'CA'}>California</MenuItem>
              <MenuItem value={'CO'}>Colorado</MenuItem>
              <MenuItem value={'CT'}>Connecticut</MenuItem>
              <MenuItem value={'DE'}>Delaware</MenuItem>
              <MenuItem value={'DC'}>District Of Columbia</MenuItem>
              <MenuItem value={'FL'}>Florida</MenuItem>
              <MenuItem value={'GA'}>Georgia</MenuItem>
              <MenuItem value={'HI'}>Hawaii</MenuItem>
              <MenuItem value={'ID'}>Idaho</MenuItem>
              <MenuItem value={'IL'}>Illinois</MenuItem>
              <MenuItem value={'IN'}>Indiana</MenuItem>
              <MenuItem value={'IA'}>Iowa</MenuItem>
              <MenuItem value={'KS'}>Kansas</MenuItem>
              <MenuItem value={'KY'}>Kentucky</MenuItem>
              <MenuItem value={'LA'}>Louisiana</MenuItem>
              <MenuItem value={'ME'}>Maine</MenuItem>
              <MenuItem value={'MD'}>Maryland</MenuItem>
              <MenuItem value={'MA'}>Massachusetts</MenuItem>
              <MenuItem value={'MI'}>Michigan</MenuItem>
              <MenuItem value={'MN'}>Minnesota</MenuItem>
              <MenuItem value={'MS'}>Mississippi</MenuItem>
              <MenuItem value={'MO'}>Missouri</MenuItem>
              <MenuItem value={'MT'}>Montana</MenuItem>
              <MenuItem value={'NE'}>Nebraska</MenuItem>
              <MenuItem value={'NV'}>Nevada</MenuItem>
              <MenuItem value={'NH'}>New Hampshire</MenuItem>
              <MenuItem value={'NJ'}>New Jersey</MenuItem>
              <MenuItem value={'NM'}>New Mexico</MenuItem>
              <MenuItem value={'NY'}>New York</MenuItem>
              <MenuItem value={'NC'}>North Carolina</MenuItem>
              <MenuItem value={'ND'}>North Dakota</MenuItem>
              <MenuItem value={'OH'}>Ohio</MenuItem>
              <MenuItem value={'OK'}>Oklahoma</MenuItem>
              <MenuItem value={'OR'}>Oregon</MenuItem>
              <MenuItem value={'PA'}>Pennsylvania</MenuItem>
              <MenuItem value={'RI'}>Rhode Island</MenuItem>
              <MenuItem value={'SC'}>South Carolina</MenuItem>
              <MenuItem value={'SD'}>South Dakota</MenuItem>
              <MenuItem value={'TN'}>Tennessee</MenuItem>
              <MenuItem value={'TX'}>Texas</MenuItem>
              <MenuItem value={'UT'}>Utah</MenuItem>
              <MenuItem value={'VT'}>Vermont</MenuItem>
              <MenuItem value={'VA'}>Virginia</MenuItem>
              <MenuItem value={'WA'}>Washington</MenuItem>
              <MenuItem value={'WV'}>West Virginia</MenuItem>
              <MenuItem value={'WI'}>Wisconsin</MenuItem>
              <MenuItem value={'WY'}>Wyoming</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4} md={4} lg={4}>
            <TextField
              error={FormUtil.renderError(validation, 'zipcode')}
              helperText={FormUtil.renderHelperText(validation, 'zipcode')}
              id="outlined-basic"
              label="ZIP Code"
              value={values.zipcode}
              onChange={handleChange('zipcode')}
              placeholder={`ZIP Code`}
              variant="outlined"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormControl fullWidth={true}>
              <FormControlLabel control={<Checkbox defaultChecked={Boolean(values?.enabled)} onChange={handleChange('enabled', 'checkbox')} />} label="Enabled" />
            </FormControl>
          </Grid>
        </Grid>
      }
      /* Modal Actions Section */
      actions={
        <Grid item xs={12}>
          <Button xs={4} fullWidth variant={'contained'} onClick={onSubmitForm} loading={contactCreate?.fetching} disabled={contactCreate?.fetching}>
            <Typography sx={{ textTransform: 'none' }}>Create</Typography>
          </Button>
        </Grid>
      }
    />
  );
};

AddressCreateModal.propTypes = {
  props: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  contactCreate: PropTypes.object,
  doCreateUserContact: PropTypes.func,
  doClearUserContact: PropTypes.func,
  token: PropTypes.string,
};

export default AddressCreateModal;
