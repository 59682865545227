// Imports

// action types
import {
  LIST_CATEGORY_REQUEST,
  LIST_CATEGORY_SUCCESS,
  LIST_CATEGORY_FAILURE,
  READ_CATEGORY_REQUEST,
  READ_CATEGORY_SUCCESS,
  READ_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  CATEGORY_RESET,
} from './types';

// Reducers Initial States.
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
};

/**
 * Category Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CATEGORY_REQUEST:
      return {
        ...state,
        list: {
          // fetching: true,
          errors: null,
          data: state.list.data,
          pagination: null,
        },
      };
    case LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_CATEGORY_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_CATEGORY_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_CATEGORY_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_CATEGORY_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CATEGORY_RESET:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
        },
        read: {
          fetching: false,
          errors: null,
          data: null,
        },
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
        update: {
          fetching: false,
          errors: null,
          data: null,
        },
        delete: {
          fetching: false,
          errors: null,
        },
      };

    default:
      return state;
  }
};

// Exports
export default categoryReducer;
