// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_USER_PAYMENT_PROFILE_REQUEST,
  LIST_USER_PAYMENT_PROFILE_FAILURE,
  LIST_USER_PAYMENT_PROFILE_SUCCESS,
  LIST_USER_GATEWAY_PROFILE_REQUEST,
  LIST_USER_GATEWAY_PROFILE_FAILURE,
  LIST_USER_GATEWAY_PROFILE_SUCCESS,
  READ_USER_PAYMENT_PROFILE_REQUEST,
  READ_USER_PAYMENT_PROFILE_FAILURE,
  READ_USER_PAYMENT_PROFILE_SUCCESS,
  READ_USER_GATEWAY_PROFILE_REQUEST,
  READ_USER_GATEWAY_PROFILE_FAILURE,
  READ_USER_GATEWAY_PROFILE_SUCCESS,
} from './types';

// Actions
import { getUserGatewayProfiles, getUserPaymentProfiles, readUserGatewayProfile, readUserPaymentProfile } from './actions';

/**
 * Function that handles listing User Gateway Profiles
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetUserGatewayProfiles({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getUserGatewayProfiles, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_USER_GATEWAY_PROFILE_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_USER_GATEWAY_PROFILE_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetUserGatewayProfiles() {
  yield takeLatest(LIST_USER_GATEWAY_PROFILE_REQUEST, workerGetUserGatewayProfiles);
}

/**
 * Function that handles getting a specific User Gateway Profile.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadUserGatewayProfile({ token, id }) {
  try {
    const response = yield call(readUserGatewayProfile, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_USER_GATEWAY_PROFILE_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_USER_GATEWAY_PROFILE_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadUserGatewayProfile() {
  yield takeLatest(READ_USER_GATEWAY_PROFILE_REQUEST, workerReadUserGatewayProfile);
}

/**
 * Function that handles listing User Payment Profiles
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetUserPaymentProfiles({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getUserPaymentProfiles, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_USER_PAYMENT_PROFILE_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_USER_PAYMENT_PROFILE_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetUserPaymentProfiles() {
  yield takeLatest(LIST_USER_PAYMENT_PROFILE_REQUEST, workerGetUserPaymentProfiles);
}

/**
 * Function that handles getting a specific User Gateway Profile.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadUserPaymentProfile({ token, id }) {
  try {
    const response = yield call(readUserPaymentProfile, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_USER_PAYMENT_PROFILE_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_USER_PAYMENT_PROFILE_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadUserPaymentProfile() {
  yield takeLatest(READ_USER_PAYMENT_PROFILE_REQUEST, workerReadUserPaymentProfile);
}
