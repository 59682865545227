// Imports
import storage from 'redux-persist/lib/storage';

// Cart Action Types
import {
  READ_CART_REQUEST,
  READ_CART_SUCCESS,
  READ_CART_FAILURE,
  CREATE_CART_REQUEST,
  CREATE_CART_SUCCESS,
  CREATE_CART_FAILURE,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  DELETE_CART_FAILURE,
  EMPTY_CART_FAILURE,
  EMPTY_CART_SUCCESS,
  EMPTY_CART_REQUEST,
  CART_RESET,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  CART_UPDATE_RESET,
  REFRESH_CART_REQUEST,
  REFRESH_CART_SUCCESS,
  REFRESH_CART_FAILURE,
  CART_REFRESH,
  OVERRIDE_CART_RESET,
  READ_LAST_CART_FAILURE,
  READ_LAST_CART_SUCCESS,
  READ_LAST_CART_REQUEST,
  DUPLICATE_CART_SUCCESS,
  DUPLICATE_CART_FAILURE,
  DUPLICATE_CART_REQUEST,
} from './types';

import { persistReducer } from 'redux-persist';

// Initial Cart Reducer States.
const initialState = {
  cart: {
    fetching: false,
    errors: null,
    data: null,
    message: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
  empty: {
    fetching: false,
    errors: null,
  },
};

const persistConfig = {
  key: 'cart',
  storage,
  whitelist: ['cart'],
};

/**
 * Cart Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_CART_REQUEST:
      return {
        ...state,
        cart: {
          fetching: true,
          errors: null,
          pagination: null,
          data: null,
          // data: state?.cart?.data,
        },
      };
    case REFRESH_CART_SUCCESS:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
          // data: state?.cart?.data,
        },
      };
    case REFRESH_CART_FAILURE:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
          // data: state?.cart?.data,
        },
      };
    case READ_LAST_CART_REQUEST:
      return {
        ...state,
        cart: {
          fetching: true,
          errors: action.error,
          data: null,
          // data: state?.cart?.data,
        },
      };
    case READ_LAST_CART_SUCCESS:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case READ_LAST_CART_FAILURE:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case DUPLICATE_CART_REQUEST:
      return {
        ...state,
        cart: {
          fetching: true,
          errors: null,
          data: null,
          // data: state?.cart?.data,
        },
      };
    case DUPLICATE_CART_SUCCESS:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case DUPLICATE_CART_FAILURE:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case READ_CART_REQUEST:
      return {
        ...state,
        cart: {
          fetching: true,
          errors: action.error,
          pagination: null,
          // data: null,
          data: state?.cart?.data,
        },
      };
    case READ_CART_SUCCESS:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_CART_FAILURE:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CREATE_CART_REQUEST:
      return {
        ...state,
        cart: {
          fetching: true,
          errors: null,
          pagination: null,
        },
        create: {
          fetching: true,
        },
      };
    case CREATE_CART_SUCCESS:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: action.data,
        },
        create: {
          fetching: false,
        },
      };
    case CREATE_CART_FAILURE:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: action.error,
          data: null,
        },
        create: {
          fetching: false,
        },
      };
    case UPDATE_CART_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          pagination: null,
        },
      };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case UPDATE_CART_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case DELETE_CART_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case DELETE_CART_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case DELETE_CART_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case EMPTY_CART_REQUEST:
      return {
        ...state,
        empty: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case EMPTY_CART_SUCCESS:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case EMPTY_CART_FAILURE:
      return {
        ...state,
        loading: false,
        cart: {
          fetching: false,
          data: null,
        },
        empty: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case CART_UPDATE_RESET:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case CART_REFRESH:
      return {
        ...state,
        cart: {
          data: action.data.cart,
        },
      };
    case OVERRIDE_CART_RESET:
      action.data.cart.checkout_override = true;
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: action.data.cart,
        },
      };
    case CART_RESET:
      return {
        ...state,
        cart: {
          fetching: false,
          errors: null,
          data: null,
          message: null,
        },
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
        update: {
          fetching: false,
          errors: null,
          data: null,
        },
        delete: {
          fetching: false,
          errors: null,
          complete: false,
        },
      };
    default:
      return state;
  }
};

const cartReducer = persistReducer(persistConfig, reducer);

// Exports
export default cartReducer;
