// Imports
import axios from 'axios';

// Sale Order Export Functions.
/**
 * Get all Sale Orders.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getSaleOrders = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}&order=created_at&direction=desc&with=contactAddress,cart.cartItems.product,cart.cartItems.productVariant` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/saleorders${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a Sales Order
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const readSaleOrder = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/saleorder/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create a Sale Order.
 * @param {Object} { token: string, payload: { cart_id : integer, invoice_contact_id : integer, deliver_contact_id : integer, notes: string } }
 * @return {AxiosPromise} Http request
 */
export const createSaleOrder = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/saleorder`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Confirm a Sale Order.
 * @param {Object} { token: string, payload: { sale_order_id : integer, square_token : string } }
 * @return {AxiosPromise} Http request
 */
export const confirmSaleOrder = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/saleorder/confirm`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Verify a Sale Order.
 * @param {Object} { token: string, payload: { sale_order_id : integer, square_token : string } }
 * @return {AxiosPromise} Http request
 */
export const verifySaleOrder = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/saleorder/verify`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Cancel a Sale Order.
 * @param {Object} { token: string, payload: { sale_order_id : integer } }
 * @return {AxiosPromise} Http request
 */
export const cancelSaleOrder = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/saleorder/cancel`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Update a Sale Order.
 * @param {Object} { token: string, payload: {  invoice_contact_id : integer, deliver_contact_id : integer, notes: string } }
}
 * @return {AxiosPromise} Http request
 */
export const updateSaleOrder = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/saleorder/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete a Cart Item.
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const deleteSaleOrder = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/saleorder/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
