// Import
import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import AccessDeniedStyles from './styles';

// Main Component

/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const AccessDenied = props => {
  // Props
  const { history } = props;

  return (
    <AccessDeniedStyles>
      <Container>
        <Grid container>
          <Paper style={{ padding: 50, margin: 'auto', marginTop: 50, marginBottom: 50, maxWidth: 600, textAlign: 'center', borderRadius: 0 }}>
            <div style={{ padding: 20 }}>
              <div
                style={{ textAlign: `center`, padding: 30, paddingTop: 0 }}
                onClick={() => {
                  history.push('/');
                }}
              >
                <img style={{ width: `100%` }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo.png`} alt={`logo`} />
              </div>
            </div>
            <div>Authorization Denied</div>
            <div style={{ marginTop: 15 }}>
              <a
                href={`#`}
                onClick={() => {
                  history.push('/');
                }}
              >
                Click here to navigate back.
              </a>
            </div>
          </Paper>
        </Grid>
      </Container>
    </AccessDeniedStyles>
  );
};

// Property Types
AccessDenied.propTypes = {
  history: PropTypes.object,
};

// Export
export default AccessDenied;
