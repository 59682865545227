// action types
import { APP_LOADING_START, APP_LOADING_STOP } from './types';

// reducer with initial state
const initialState = {
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADING_START:
      return {
        loading: true,
      };
    case APP_LOADING_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
