// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_CUSTOMER_REQUEST,
  LIST_CUSTOMER_SUCCESS,
  LIST_CUSTOMER_FAILURE,
  READ_CUSTOMER_REQUEST,
  READ_CUSTOMER_SUCCESS,
  READ_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
} from './types';

// Actions
import { getCustomers, createCustomer, updateCustomer, deleteCustomer, readCustomer } from './actions';

/**
 * Function that handles listing Customers.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetCustomers({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getCustomers, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_CUSTOMER_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_CUSTOMER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetCustomers() {
  yield takeLatest(LIST_CUSTOMER_REQUEST, workerGetCustomers);
}

/**
 * Function that handles getting a specific Customer.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadCustomer({ token, id }) {
  try {
    const response = yield call(readCustomer, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_CUSTOMER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_CUSTOMER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadCustomer() {
  yield takeLatest(READ_CUSTOMER_REQUEST, workerReadCustomer);
}

/**
 * Function that handles creating a specific Customer.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateCustomer({ token, payload }) {
  try {
    const response = yield call(createCustomer, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_CUSTOMER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_CUSTOMER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateCustomer() {
  yield takeLatest(CREATE_CUSTOMER_REQUEST, workerCreateCustomer);
}

/**
 * Function that handles updating a specific Customer.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateCustomer({ token, id, payload }) {
  try {
    const response = yield call(updateCustomer, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_CUSTOMER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_CUSTOMER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateCustomer() {
  yield takeLatest(UPDATE_CUSTOMER_REQUEST, workerUpdateCustomer);
}

/**
 * Function that handles deleting a specific Customer.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteCustomer({ token, id }) {
  try {
    const response = yield call(deleteCustomer, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_CUSTOMER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_CUSTOMER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteCustomer() {
  yield takeLatest(DELETE_CUSTOMER_REQUEST, workerDeleteCustomer);
}
