export const LIST_CARTITEM_REQUEST = 'LIST_CARTITEM_REQUEST';
export const LIST_CARTITEM_SUCCESS = 'LIST_CARTITEM_SUCCESS';
export const LIST_CARTITEM_FAILURE = 'LIST_CARTITEM_FAILURE';
export const UPDATE_CARTITEM_REQUEST = 'UPDATE_CARTITEM_REQUEST';
export const UPDATE_CARTITEM_SUCCESS = 'UPDATE_CARTITEM_SUCCESS';
export const UPDATE_CARTITEM_FAILURE = 'UPDATE_CARTITEM_FAILURE';
export const CREATE_CARTITEM_REQUEST = 'CREATE_CARTITEM_REQUEST';
export const CREATE_CARTITEM_SUCCESS = 'CREATE_CARTITEM_SUCCESS';
export const CREATE_CARTITEM_FAILURE = 'CREATE_CARTITEM_FAILURE';
export const DELETE_CARTITEM_REQUEST = 'DELETE_CARTITEM_REQUEST';
export const DELETE_CARTITEM_SUCCESS = 'DELETE_CARTITEM_SUCCESS';
export const DELETE_CARTITEM_FAILURE = 'DELETE_CARTITEM_FAILURE';
export const CARTITEM_RESET = 'CARTITEM_RESET';
export const CARTITEM_LOADING_ON = 'CARTITEM_LOADING_ON';
export const CARTITEM_LOADING_OFF = 'CARTITEM_LOADING_OFF';
