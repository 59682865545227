// Imports
import axios from 'axios';

// Export actions

/**
 * Get all Notification Campaign.
 * @param {Object} { token: string, user_id: number}
 * @return {AxiosPromise} Http request
 */
export const getNotificationCampaigns = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/notificationcampaigns${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a specific Notification Campaign.
 * @param {Object} { token: string, user_id: number}
 * @return {AxiosPromise} Http request
 */
export const readNotificationCampaign = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/notificationcampaign/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create a Notification Campaign.
 * @param {Object} { token: string, payload: { } }
 * @return {AxiosPromise} Http request
 */
export const createNotificationCampaign = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/notificationcampaign`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });
