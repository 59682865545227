// Imports

// Cart Item Action Types
import {
  LIST_CARTITEM_REQUEST,
  LIST_CARTITEM_SUCCESS,
  LIST_CARTITEM_FAILURE,
  UPDATE_CARTITEM_REQUEST,
  UPDATE_CARTITEM_SUCCESS,
  UPDATE_CARTITEM_FAILURE,
  CREATE_CARTITEM_REQUEST,
  CREATE_CARTITEM_SUCCESS,
  CREATE_CARTITEM_FAILURE,
  DELETE_CARTITEM_REQUEST,
  DELETE_CARTITEM_SUCCESS,
  DELETE_CARTITEM_FAILURE,
  CARTITEM_RESET,
  CARTITEM_LOADING_ON,
  CARTITEM_LOADING_OFF,
} from './types';

// Initial Reducer States.
const initialState = {
  loading: false,
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
};

/**
 * Cart Item Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const cartItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CARTITEM_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_CARTITEM_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_CARTITEM_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_CARTITEM_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UPDATE_CARTITEM_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
        },
        create: {
          fetching: false,
        },
        delete: {
          fetching: false,
        },
      };
    case UPDATE_CARTITEM_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case CREATE_CARTITEM_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CREATE_CARTITEM_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
        },
        update: {
          fetching: false,
        },
        delete: {
          fetching: false,
        },
      };
    case CREATE_CARTITEM_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case DELETE_CARTITEM_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          complete: false,
        },
      };
    case DELETE_CARTITEM_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          complete: true,
        },
        create: {
          fetching: false,
        },
        update: {
          fetching: false,
        },
      };
    case DELETE_CARTITEM_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          complete: false,
        },
      };

    case CARTITEM_RESET:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
        update: {
          fetching: false,
          errors: null,
          data: null,
        },
        delete: {
          fetching: false,
          errors: null,
          complete: false,
        },
      };
    case CARTITEM_LOADING_OFF:
      return {
        ...state,
        loading: false,
        create: {
          fetching: false,
        },
        update: {
          fetching: false,
        },
        delete: {
          fetching: false,
        },
      };
    case CARTITEM_LOADING_ON:
      return {
        ...state,
        loading: true,
        create: {
          fetching: true,
        },
        update: {
          fetching: true,
        },
        // delete: {
        //   fetching: false,
        //   errors: null,
        //   complete: false,
        // },
      };
    default:
      return state;
  }
};

// Exports
export default cartItemReducer;
