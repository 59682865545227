import React from 'react';

import { useAcceptJs } from 'react-acceptjs';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { CreditCardInput } from 'react-square-web-payments-sdk';
import { Alert } from '@mui/material';
import FormUtil from '../../utils/FormUtil';
import MenuItem from '@mui/material/MenuItem';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';

const authData = {
  apiLoginID: window.AUTHNET_API_LOGIN,
  clientKey: window.AUTHNET_CLIENT_KEY,
};

interface BasicCardInfo {
  cardNumber: string;
  cardCode: string;
  expMonth: string;
  expYear: string;
}

const AuthPaymentForm = props => {
  const { doConfirmSaleOrder, saleOrderRead, saleOrderCreate, saleOrderConfirm, token, setLoadingCard, loadingCard, gateway } = props;
  const { dispatchData, loading, error } = useAcceptJs({
    environment: window.PAYMENT_SANDBOX ? 'SANDBOX' : 'PRODUCTION',
    authData,
  });
  const [errorData, setErrorData] = React.useState({ messages: { message: [] } });
  const [chargeCard, setChargeCard] = React.useState(false);
  const [cardSelection, setCardSelection] = React.useState('new');
  const [cardData, setCardData] = React.useState({
    cardNumber: '',
    month: '',
    year: '',
    cardCode: '',
    //TESTING
    // cardNumber: '6011000000000012',
    // month: '08',
    // year: '33',
    // cardCode: '111',
  });
  const handleSubmit = async event => {
    event.preventDefault();
    setLoadingCard(true);
    // console.log('SUBMITTING');
    setErrorData({ messages: { message: [] } });
    let errorArr = [];

    if (cardSelection === 'new') {
      if (cardData.cardNumber === '') {
        errorArr.push({ text: 'Card number cannot be blank' });
      } else {
        if (
          !RegExp(/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/i).test(cardData.cardNumber)
        ) {
          errorArr.push({ text: 'Card number is invalid' });
        }
      }
      if (cardData.cardCode === '') {
        errorArr.push({ text: 'Card code cannot be blank' });
      }
      if (cardData.month === '') {
        errorArr.push({ text: 'Card Month cannot be blank' });
      }
      if (cardData.year === '') {
        errorArr.push({ text: 'Card Year cannot be blank' });
      }
      if (errorArr.length === 0) {
        const last4 = cardData.cardNumber.replace(/(.+)([0-9]{4})/, '$2');
        let cardType = 'VISA';
        if (RegExp(/^4[0-9]{12}(?:[0-9]{3})?$/i).test(cardData.cardNumber)) {
          cardType = 'VISA';
        } else if (RegExp(/^3[47][0-9]{13}$/i).test(cardData.cardNumber)) {
          cardType = 'AMEX';
        } else if (RegExp(/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/i).test(cardData.cardNumber)) {
          cardType = 'DISCOVER';
        } else if (RegExp(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/i).test(cardData.cardNumber)) {
          cardType = 'MASTERCARD';
        }
        const response = await dispatchData({ cardData }).catch(reason => {
          setErrorData(reason);
          setLoadingCard(false);
          // console.log(reason);
        });
        if (response !== undefined) {
          if (!saleOrderCreate?.fetching && !saleOrderRead?.fetching) {
            let payload = {
              cardSelection: cardSelection,
              // square_token: squareToken.token,
              cardType: cardType,
              last4: last4,
              dataDescriptor: response?.opaqueData?.dataDescriptor,
              dataValue: response?.opaqueData?.dataValue,
              sale_order_id: saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id,
            };
            doConfirmSaleOrder({ token, payload });
          }
        }
      } else {
        setLoadingCard(false);
        setErrorData({ messages: { message: errorArr } });
      }
    } else {
      if (!saleOrderCreate?.fetching && !saleOrderRead?.fetching) {
        let payload = {
          cardSelection: cardSelection,
          sale_order_id: saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id,
        };
        doConfirmSaleOrder({ token, payload });
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {saleOrderConfirm &&
            saleOrderConfirm.errors &&
            saleOrderConfirm.errors.length >= 1 &&
            Object.keys(saleOrderConfirm.errors).map(k =>
              saleOrderConfirm.errors[k].errorText ? (
                <Alert key={`payment error` + k} severity="error" style={{ marginBottom: 15 }}>
                  {saleOrderConfirm.errors[k].errorText}
                </Alert>
              ) : (
                ''
              ),
            )}
          {errorData &&
            errorData.messages &&
            errorData.messages.message &&
            Object.keys(errorData.messages.message).map(k => (
              <Alert key={`payment error` + k} severity="error" style={{ marginBottom: 15 }}>
                {errorData.messages.message[k].text ? errorData.messages.message[k].text : ''}
                {errorData.messages.message[k].description ? errorData.messages.message[k].description : ''}
              </Alert>
            ))}
        </Grid>

        <Grid item xs={12}>
          {gateway?.fetching ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel style={{ paddingBottom: 16 }}>Use Existing Cards</FormLabel>
                  <RadioGroup
                    row
                    value={cardSelection}
                    onChange={event => {
                      setCardSelection(event.target.value);
                      // console.log(event.target.value);
                    }}
                  >
                    {gateway?.data?.payments && gateway?.data?.payments.length >= 1
                      ? gateway?.data?.payments.map(payment => (
                          <Card key={payment.id} style={{ marginRight: 16 }}>
                            <CardContent style={{ paddingBottom: 16 }}>
                              <FormControlLabel
                                value={payment.payment_profile_id}
                                control={<Radio />}
                                label={
                                  {
                                    VISA: (
                                      <div>
                                        <img src="/imgs/visa.png" style={{ width: 100 }} />
                                        <div style={{ textAlign: `center` }}>{payment?.last_4}</div>
                                      </div>
                                    ),
                                    MASTERCARD: (
                                      <div>
                                        <img src="/imgs/mastercard.png" style={{ width: 100 }} />
                                        <div style={{ textAlign: `center` }}>{payment?.last_4}</div>
                                      </div>
                                    ),
                                    DISCOVER: (
                                      <div>
                                        <img src="/imgs/discover.png" style={{ width: 100 }} />
                                        <div style={{ textAlign: `center` }}>{payment?.last_4}</div>
                                      </div>
                                    ),
                                    AMEX: (
                                      <div>
                                        <img src="/imgs/amex.png" style={{ width: 100 }} />
                                        <div style={{ textAlign: `center` }}>{payment?.last_4}</div>
                                      </div>
                                    ),
                                  }[payment?.brand]
                                }
                              />
                            </CardContent>
                          </Card>
                        ))
                      : ''}
                    <Card key={'new'}>
                      <CardContent style={{ paddingBottom: 16 }}>
                        <FormControlLabel value={'new'} control={<Radio />} label={'New Card'} />
                      </CardContent>
                    </Card>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {cardSelection === 'new' ? (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{ maxLength: 16 }}
                        label="Card Number"
                        name="cardNumber"
                        type="text"
                        value={cardData.cardNumber}
                        // defaultValue={cardData.cardNumber}
                        onChange={event => {
                          const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                          if (onlyNums.length < 10) {
                            setCardData({ ...cardData, cardNumber: String(onlyNums) });
                          } else {
                            const number = onlyNums.replace(/(\d{16})/, '$1');
                            setCardData({ ...cardData, cardNumber: String(number) });
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                      />
                      {/*<input type="text" name="" value={cardData.cardNumber} onChange={event => setCardData({ ...cardData, cardNumber: String(event.target.value) })} />*/}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        select
                        label="Expiration Month"
                        name="expMonth"
                        value={cardData.month}
                        // defaultValue={cardData.month}
                        onChange={event => setCardData({ ...cardData, month: String(event.target.value) })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                      >
                        <MenuItem value={'01'}>01 - January</MenuItem>
                        <MenuItem value={'02'}>02 - February</MenuItem>
                        <MenuItem value={'03'}>03 - March</MenuItem>
                        <MenuItem value={'04'}>04 - April</MenuItem>
                        <MenuItem value={'05'}>05 - May</MenuItem>
                        <MenuItem value={'06'}>06 - June</MenuItem>
                        <MenuItem value={'07'}>07 - July</MenuItem>
                        <MenuItem value={'08'}>08 - August</MenuItem>
                        <MenuItem value={'09'}>09 - September</MenuItem>
                        <MenuItem value={'10'}>10 - October</MenuItem>
                        <MenuItem value={'11'}>11 - November</MenuItem>
                        <MenuItem value={'12'}>12 - December</MenuItem>
                      </TextField>
                      {/*</Grid>*/}
                      {/*<TextField*/}
                      {/*  label="Expiration Month"*/}
                      {/*  name="expMonth"*/}
                      {/*  type="text"*/}
                      {/*  value={cardData.month}*/}
                      {/*  defaultValue={cardData.month}*/}
                      {/*  onChange={event => setCardData({ ...cardData, month: String(event.target.value) })}*/}
                      {/*  InputLabelProps={{*/}
                      {/*    shrink: true,*/}
                      {/*  }}*/}
                      {/*  fullWidth={true}*/}
                      {/*/>*/}
                      {/*<input type="text" name="expMonth" value={cardData.month} onChange={event => setCardData({ ...cardData, month: String(event.target.value) })} />*/}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        select
                        label="Expiration Year"
                        name="expYear"
                        type="text"
                        value={cardData.year}
                        // defaultValue={cardData.year}
                        onChange={event => setCardData({ ...cardData, year: String(event.target.value) })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                      >
                        {[22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40].map(k => (
                          <MenuItem key={`ccyear` + k} value={k}>
                            20{k}
                          </MenuItem>
                        ))}
                      </TextField>
                      {/*<TextField*/}
                      {/*  label="Expiration Year"*/}
                      {/*  name="expYear"*/}
                      {/*  type="text"*/}
                      {/*  value={cardData.year}*/}
                      {/*  defaultValue={cardData.year}*/}
                      {/*  onChange={event => setCardData({ ...cardData, year: String(event.target.value) })}*/}
                      {/*  InputLabelProps={{*/}
                      {/*    shrink: true,*/}
                      {/*  }}*/}
                      {/*  fullWidth={true}*/}
                      {/*/>*/}
                      {/*<input type="text" name="expYear" value={cardData.year} onChange={event => setCardData({ ...cardData, year: String(event.target.value) })} />*/}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        inputProps={{ maxLength: 5 }}
                        label="Card Code"
                        name="cardCode"
                        type="text"
                        value={cardData.cardCode}
                        // defaultValue={cardData.cardCode}
                        onChange={event => setCardData({ ...cardData, cardCode: String(event.target.value) })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                      />
                      {/*<input type="text" name="cardCode" value={cardData.cardCode} onChange={event => setCardData({ ...cardData, cardCode: String(event.target.value) })} />*/}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          {/*<Button variant="contained" color="primary" type={`submit`} size={`medium`} disabled={loading || error} endIcon={<ArrowForwardIcon />}>*/}
          {/*</Button>*/}
          <div style={{ position: `relative`, width: `100%` }}>
            <div style={{ width: `100%` }}>
              <LoadingButton
                type={`submit`}
                loading={saleOrderConfirm?.fetching || loadingCard}
                disabled={saleOrderConfirm?.fetching || loadingCard}
                loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={64} />}
                size={`large`}
                variant="contained"
                color="primary"
                style={{ width: `100%` }}
                fullWidth={true}
              >
                {saleOrderConfirm?.fetching ? <CreditCardIcon /> : 'Pay'}
              </LoadingButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
// PROPS TYPES
// --------------------------------------------------------
AuthPaymentForm.propTypes = {
  history: PropTypes.object,
  token: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  gateway: PropTypes.object,
  saleOrderCreate: PropTypes.object,
  saleOrderConfirm: PropTypes.object,
  saleOrderRead: PropTypes.object,
  doConfirmSaleOrder: PropTypes.func,
  setLoadingCard: PropTypes.func,
  loadingCard: PropTypes.bool,
};
export default AuthPaymentForm;
