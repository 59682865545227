import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import PropTypes from 'prop-types';

const ThankYouPage = props => {
  const { token, history, cart, doDeleteCartItem, quantityChanged, totalItems, doEmptyCart, doReadCart, doClearUserGatewayProfile, gateway } = props;
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Alert severity="success">
        <AlertTitle>Thank you for your purchase.</AlertTitle>
        View the progress of your order by going to your orders in your account menu.
      </Alert>
    </Grid>
  );
};

// PROPS TYPES
// --------------------------------------------------------
ThankYouPage.propTypes = {
  history: PropTypes.object,
  cart: PropTypes.object,
  gateway: PropTypes.object,
  doDeleteCartItem: PropTypes.func,
  doEmptyCart: PropTypes.func,
  doReadCart: PropTypes.func,
  quantityChanged: PropTypes.func,
  totalItems: PropTypes.func,
  doClearUserGatewayProfile: PropTypes.func,
  token: PropTypes.string,
};

export default ThankYouPage;
