// Imports
import styled from 'styled-components';

// Main Component
const PasswordResetStyles = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  background: #dedede;

  .Mui-focused {
    color: #000 !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000 !important;
    border-width: 2px !important;
  }
`;

// Export
export default PasswordResetStyles;
