// Import types
import { CLEAR_CUSTOMER_PAYMENT_METHODS, LIST_CUSTOMER_PAYMENT_METHODS_REQUEST } from './types';
// Export operations
/**
 * Dispatch for a list of User Gateway Profiles
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetCustomerPaymentMethods = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_CUSTOMER_PAYMENT_METHODS_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

export const doClearCustomerPaymentMethods = () => ({
  type: CLEAR_CUSTOMER_PAYMENT_METHODS,
});
