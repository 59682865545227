// Imports

// FIREBASETOKEN Action Types.
import {
  READ_FIREBASETOKEN_REQUEST,
  READ_FIREBASETOKEN_SUCCESS,
  READ_FIREBASETOKEN_FAILURE,
  UPDATE_FIREBASETOKEN_REQUEST,
  UPDATE_FIREBASETOKEN_SUCCESS,
  UPDATE_FIREBASETOKEN_FAILURE,
  CREATE_FIREBASETOKEN_REQUEST,
  CREATE_FIREBASETOKEN_SUCCESS,
  CREATE_FIREBASETOKEN_FAILURE,
  DELETE_FIREBASETOKEN_REQUEST,
  DELETE_FIREBASETOKEN_SUCCESS,
  DELETE_FIREBASETOKEN_FAILURE,
  FIREBASETOKEN_RESET,
} from './types';

// Initial Reducer States.
const initialState = {
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
};

/**
 * FIREBASETOKEN Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const firebaseTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_FIREBASETOKEN_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_FIREBASETOKEN_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_FIREBASETOKEN_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_FIREBASETOKEN_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_FIREBASETOKEN_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case UPDATE_FIREBASETOKEN_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CREATE_FIREBASETOKEN_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case CREATE_FIREBASETOKEN_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case CREATE_FIREBASETOKEN_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case DELETE_FIREBASETOKEN_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case DELETE_FIREBASETOKEN_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case DELETE_FIREBASETOKEN_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case FIREBASETOKEN_RESET:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
        },
        read: {
          fetching: false,
          errors: null,
          data: null,
        },
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
        update: {
          fetching: false,
          errors: null,
          data: null,
        },
        delete: {
          fetching: false,
          errors: null,
        },
      };
    default:
      return state;
  }
};

// Exports
export default firebaseTokenReducer;
