// Imports
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// action types
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_RESET,
  ME_REQUEST,
  ME_SUCCESS,
  ME_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  UNSUBSCRIBE_FAILURE,
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_SUCCESS,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_RESET,
  REGISTER_SUCCESS,
  FORGOT_PASSWORD_RESET,
  UNSUBSCRIBE_RESET,
  PASSWORD_RESET_RESET,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAILURE,
  ACTIVATION_REQUEST,
  ACTIVATION_RESET,
  IMPERSONATE_REQUEST,
  IMPERSONATE_FAILURE,
  IMPERSONATE_SUCCESS,
  RESET_IMPERSONATE_REQUEST,
  SET_SITE,
} from './types';

// reducer with initial state
const initialState = {
  token: null,
  user: null,
  site: null,
  login: {
    fetching: false,
    errors: null,
    data: null,
  },
  register: {
    fetching: false,
    errors: null,
    success: false,
  },
  forgotPassword: {
    fetching: false,
    errors: null,
    data: null,
  },
  passwordReset: {
    fetching: false,
    errors: null,
    data: null,
  },
  activation: {
    fetching: false,
    errors: null,
    data: null,
  },
  impersonate: {
    fetching: false,
    errors: null,
    data: null,
  },
  unsubscribe: {
    fetching: false,
    errors: null,
    data: null,
  },
};

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'user', 'activation', 'site'],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ME_REQUEST:
      return {
        ...state,
        login: {
          fetching: true,
          errors: null,
        },
      };
    case ME_SUCCESS:
      return {
        ...state,
        login: {
          fetching: false,
          errors: null,
        },
        user: action.user,
      };
    case ME_FAILURE:
      return {
        ...state,
        login: {
          fetching: false,
          errors: action.errors,
        },
        user: null,
      };
    case LOGIN_RESET:
      return {
        ...state,
        login: {
          fetching: false,
          errors: null,
        },
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        login: {
          fetching: true,
          errors: null,
        },
        user: null,
        token: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        login: {
          fetching: false,
          errors: action.errors,
        },
        user: null,
        token: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          fetching: false,
          errors: null,
        },
        user: action.user,
        token: action.token,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: {
          fetching: false,
          errors: null,
        },
        token: null,
        user: null,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        login: {
          fetching: false,
          errors: null,
        },
      };
    case IMPERSONATE_REQUEST:
      return {
        ...state,
        impersonate: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case IMPERSONATE_FAILURE:
      return {
        ...state,
        impersonate: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case RESET_IMPERSONATE_REQUEST:
      return {
        ...state,
        impersonate: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case IMPERSONATE_SUCCESS:
      return {
        ...state,
        impersonate: {
          fetching: false,
          errors: null,
          data: action.data,
        },
        login: {
          fetching: false,
          errors: null,
        },
        user: action.data,
        token: action.token,
      };
    case ACTIVATION_REQUEST:
      return {
        ...state,
        activation: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case ACTIVATION_FAILURE:
      return {
        ...state,
        activation: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case ACTIVATION_SUCCESS:
      return {
        ...state,
        activation: {
          fetching: false,
          errors: null,
          data: action.data,
        },
        login: {
          fetching: false,
          errors: null,
        },
        user: action.data,
        token: action.token,
      };

    case ACTIVATION_RESET:
      return {
        ...state,
        activation: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        passwordReset: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        passwordReset: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordReset: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case PASSWORD_RESET_RESET:
      return {
        ...state,
        passwordReset: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        unsubscribe: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UNSUBSCRIBE_FAILURE:
      return {
        ...state,
        unsubscribe: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        unsubscribe: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case FORGOT_PASSWORD_RESET:
      return {
        ...state,
        forgotPassword: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case UNSUBSCRIBE_RESET:
      return {
        ...state,
        unsubscribe: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        register: {
          fetching: true,
          errors: null,
          success: false,
        },
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        register: {
          fetching: false,
          errors: action.errors,
          success: false,
        },
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        register: {
          fetching: false,
          errors: null,
          success: true,
        },
      };
    case REGISTER_RESET:
      return {
        ...state,
        register: {
          fetching: false,
          errors: null,
          success: false,
        },
      };
    case SET_SITE:
      return {
        ...state,
        site: action.site,
      };
    default:
      return state;
  }
};

const authReducer = persistReducer(persistConfig, reducer);

export default authReducer;
