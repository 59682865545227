// Imports
import { createGlobalStyle } from 'styled-components';
// import 'typeface-lato';

// Main Component
const GlobalStyles = createGlobalStyle`
  body {
    height: 100vh;
    background-color: rgb(222 222 222);
  }
  //.MuiFormControl-root {
  //  width: 100% !important;
  //}
  .MuiFormHelperText-contained {
    margin-left: 0 !important;
  }

  .MuiChip-colorSecondary {
    background-color: #212121 !important;
  }

  .MuiButton-containedPrimary {
    background-color: #212121 !important;
  }

  .dashboard-active {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  .link-pointer {
    cursor: pointer;
  }

  .active-page {
    background-color: #e2e5e8 !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 0 !important;
  }

  .center-input input {
    text-align: center !important;
  }

  .Mui-disabled {
    color: rgba(229, 229, 229, 0.40) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12)
  }
  .Mui-checked {
    color: #1d1d1f !important;
  }
  .app-bar {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensure it stays on top of other content */
  }
`;

// Export

export default GlobalStyles;
