import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Checkbox, Skeleton, TableFooter, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormUtil from '../../../utils/FormUtil';
import MenuItem from '@mui/material/MenuItem';
import { Alert, LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { getCustomers } from '../../../containers/Customer/actions';
import Table from '@mui/material/Table';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

const CustomersPage = props => {
  const { user, token, match, history, doCreateCustomer, doCustomerReset, customerCreate, doGetCustomers, customers } = props;
  const { page } = match?.params;
  const [saveRequest, setSaveRequest] = React.useState(false);
  const [customerFilter, setCustomerFilter] = React.useState('');
  const [values, setValues] = React.useState({
    company_name: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zipcode: '',
  });

  const [validation, setValidation] = React.useState({
    company_name: {
      validated: false,
      valid: true,
      errors: [],
    },
    firstname: {
      validated: false,
      valid: true,
      errors: [],
    },
    lastname: {
      validated: false,
      valid: true,
      errors: [],
    },
    email: {
      validated: false,
      valid: true,
      errors: [],
    },
    phone: {
      validated: false,
      valid: true,
      errors: [],
    },
    address_line_1: {
      validated: false,
      valid: true,
      errors: [],
    },
    address_line_2: {
      validated: false,
      valid: true,
      errors: [],
    },
    city: {
      validated: false,
      valid: true,
      errors: [],
    },
    state: {
      validated: false,
      valid: true,
      errors: [],
    },
    zipcode: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  useEffect(() => {
    if (saveRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setSaveRequest(false);
      // let firstnameBlank = false;
      // let lastnameBlank = false;
      // let companyBlank = false;
      // if (values?.firstname === '') {
      //   firstnameBlank = true;
      // }
      // if (values?.company_name === '') {
      //   companyBlank = true;
      // }
      // if (values?.lastname === '') {
      //   lastnameBlank = true;
      // }
      //
      // if (firstnameBlank && lastnameBlank && companyBlank) {
      //   setValidation(FormUtil.addError(validation, 'company_name', 'Company Name is blank!!'));
      //   setValidation(FormUtil.addError(validation, 'firstname', 'First name is blank!!'));
      //   setValidation(FormUtil.addError(validation, 'lastname', 'Last name is blank!!'));
      // } else {
      //   if (!firstnameBlank && !lastnameBlank && companyBlank) {
      //     // setValidation(FormUtil.addError(validation, 'lastname', 'Last name is blank!!'));
      //   } else if (!companyBlank && !firstnameBlank && lastnameBlank) {
      //     setValidation(FormUtil.addError(validation, 'lastname', 'TLast name is blank!!'));
      //   } else if (!companyBlank && firstnameBlank && !lastnameBlank) {
      //     setValidation(FormUtil.addError(validation, 'lastname', 'First name is blank!!'));
      //   }
      // }
      // if (!companyBlank) {
      //
      // } else if(!lastnameBlank && ) {
      //
      // }
      // if(!firstnameBlank && !lastnameBlank) {
      //
      // } else {
      //     if(!companyBlank) {
      //
      //     }
      // }
      if (values?.lastname === '') {
        setValidation(FormUtil.addError(validation, 'lastname', 'Last name is blank!!'));
      }
      if (values?.firstname === '') {
        setValidation(FormUtil.addError(validation, 'firstname', 'First name is blank!!'));
      }
      if (values?.company_name === '') {
        setValidation(FormUtil.addError(validation, 'company_name', 'Company Name is blank!!'));
      }

      //
      // if (values?.company_name === '' && values?.firstname === '' && values?.lastname === '') {
      //
      //
      //   setValidation(FormUtil.addError(validation, 'lastname', 'Last name is blank!!'));
      // } else {
      //   if (values?.company_name !== '' && values?.firstname === '' && values?.lastname === '') {
      //   } else if (values?.company_name === '' && values?.firstname === '' && values?.lastname !== '') {
      //     setValidation(FormUtil.addError(validation, 'firstname', 'First name is blank!!'));
      //   } else if (values?.company_name === '' && values?.firstname !== '' && values?.lastname === '') {
      //     setValidation(FormUtil.addError(validation, 'lastname', 'Last name is blank!!'));
      //   }
      // }

      // if (values?.company_name === '' && values?.firstname === '' && values?.lastname === '') {
      //   if (values?.firstname === '' && values?.lastname === '') {
      //     setValidation(FormUtil.addError(validation, 'company_name', 'Company Name is blank!!'));
      //     // setValidation(FormUtil.addError(validation, 'firstname', 'First name is blank!!'));
      //     // setValidation(FormUtil.addError(validation, 'lastname', 'Last name is blank!!'));
      //   } else {
      //   }
      // } else {
      //   if (values?.firstname === '') {
      //     setValidation(FormUtil.addError(validation, 'firstname', 'First name is blank!!'));
      //   }
      //   if (values?.lastname === '') {
      //     setValidation(FormUtil.addError(validation, 'lastname', 'Last name is blank!!'));
      //   }
      // }
      //
      // if (values?.firstname === '' && values?.lastname === '' && values?.company_name === '') {
      //   if (values?.firstname === '') {
      //     setValidation(FormUtil.addError(validation, 'firstname', 'First name is blank!!'));
      //   }
      //   if (values?.lastname === '') {
      //     setValidation(FormUtil.addError(validation, 'lastname', 'Last name is blank!!'));
      //   }
      // } else {
      //   if (values?.company_name === '') {
      //     setValidation(FormUtil.addError(validation, 'company_name', 'Company Name is blank!!'));
      //   }
      // }

      if (values?.email === '') {
        setValidation(FormUtil.addError(validation, 'email', 'Email is blank!'));
      }
      if (values?.phone === '') {
        setValidation(FormUtil.addError(validation, 'phone', 'Phone is blank!'));
      }
      if (values?.address_line_1 === '') {
        setValidation(FormUtil.addError(validation, 'address_line_1', 'Address line 1 is blank!'));
      }
      // if (values?.address_line_2 === '') {
      //   setValidation(FormUtil.addError(validation, 'address_line_2', 'Address line 2 is blank!'));
      // }
      if (values?.city === '') {
        setValidation(FormUtil.addError(validation, 'city', 'City is blank!'));
      }
      if (values?.state === '') {
        setValidation(FormUtil.addError(validation, 'state', 'State is blank!'));
      }
      if (values?.zipcode === '') {
        setValidation(FormUtil.addError(validation, 'zipcode', 'Zipcode is blank!'));
      }

      // Backend API Call.
      if (FormUtil.validate(validation)) {
        let payload = {
          company_name: values?.company_name ? values?.company_name : ``,
          firstname: values?.firstname ? values?.firstname : ``,
          lastname: values?.lastname ? values?.lastname : ``,
          phone: values?.phone ? values?.phone : ``,
          email: values?.email ? values?.email : ``,
          address_line_1: values?.address_line_1 ? values?.address_line_1 : ``,
          address_line_2: values?.address_line_2 ? values?.address_line_2 : ``,
          city: values?.city ? values?.city : ``,
          state: values?.state ? values?.state : ``,
          zipcode: values?.zipcode ? values?.zipcode : ``,
        };
        doCreateCustomer({ token, payload });
      }
    }
  }, [validation, saveRequest]);

  useEffect(() => {
    if (!customerCreate.fetching) {
      if (customerCreate.errors && customerCreate.errors.errors) {
        let errorMessages = '';
        if (typeof customerCreate.errors === 'object' && typeof customerCreate.errors.errors === 'object') {
          Object.entries(customerCreate.errors.errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              console.log(value);
              value.forEach(errorMessage => {
                errorMessages += `${key}: ${errorMessage}\n`;
              });
            } else {
              errorMessages += `${value}\n`;
            }
          });
        } else if (typeof customerCreate.errors === 'object') {
          Object.entries(customerCreate.errors.errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach(errorMessage => {
                errorMessages += `${key}: ${errorMessage}\n`;
              });
            }
          });
        }
        if (errorMessages) {
          Swal.fire('Customer Error', `There was an error:\n${errorMessages}`, 'error');
        } else {
          Swal.fire('Customer Error', 'There was an error', 'error');
        }
      } else if (customerCreate.data) {
        Swal.fire('User Updated', 'User information has been updated', 'success');
      }
    }
  }, [customerCreate]);

  const onSubmitForm = event => {
    event.preventDefault();
    setSaveRequest(true);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    if (!customerCreate?.fetching && customerCreate?.data) {
      Swal.fire({
        icon: 'success',
        text: 'Customer has been sent activation.',
        showConfirmButton: false,
        timer: 3500,
        didClose: function() {
          setValues({
            firstname: '',
            lastname: '',
            company_name: '',
            email: '',
            phone: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            zipcode: '',
          });
          doCustomerReset();
        },
      });
    }
  }, [customerCreate]);

  useEffect(() => {
    if (page !== 'create') {
      let query = customerFilter;
      let direction = '';
      let order = '';
      let limit = 100;
      let page = 1;
      doGetCustomers({ token, query, direction, order, limit, page });
    }
  }, [token, customerFilter]);
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} md={12} lg={12}>
        {
          {
            undefined:
              (user && user?.role && user?.role?.name === 'sales') || (user && user?.role && user?.role?.name === 'admin') ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography align="left" variant="h4" sx={{ fontWeight: 'bold' }}>
                      Customers
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      size={'small'}
                      variant="outlined"
                      label={'Search'}
                      onChange={event => {
                        setCustomerFilter(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Table aria-label="spanning table">
                      <TableHead>
                        {/* Ordered Items Headers */}
                        <TableRow>
                          <TableCell align="left" width={'90%'} style={{ fontWeight: `bold` }}>
                            Customer
                          </TableCell>
                          {/*<TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>*/}
                          {/*  Total Spent*/}
                          {/*</TableCell>*/}
                          <TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customers && customers?.data && !customers?.fetching ? (
                          customers?.data.map(customer => (
                            <TableRow>
                              <TableCell align="left" width={'90%'} style={{ fontWeight: `bold` }}>
                                {customer?.user?.company_name}
                              </TableCell>
                              {/*<TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>*/}
                              {/*  Total Spent*/}
                              {/*</TableCell>*/}
                              <TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>
                                <IconButton
                                  color="primary"
                                  aria-label="Edit Saved Pricing"
                                  component="span"
                                  onClick={() => {
                                    history.push(`/catalog/savedpricing/${customer?.id}`);
                                    doCustomerReset();
                                  }}
                                >
                                  <PointOfSaleIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center" width={'100%'} style={{ fontWeight: `bold` }}>
                              Loading
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              ) : (
                <Alert severity="error">Permission Denied</Alert>
              ),
            create:
              (user && user?.role && user?.role?.name === 'sales') || (user && user?.role && user?.role?.name === 'admin') ? (
                <Grid container spacing={2}>
                  {/* Page Title */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography align="left" variant="h4" sx={{ fontWeight: 'bold' }}>
                      New Customer
                    </Typography>
                  </Grid>

                  {/* Name Text Field */}
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={FormUtil.renderError(validation, 'company_name')}
                      helperText={FormUtil.renderHelperText(validation, 'company_name')}
                      label="Company Name"
                      value={values.company_name}
                      onChange={handleChange('company_name')}
                      placeholder={`Company Name`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <TextField
                      error={FormUtil.renderError(validation, 'firstname')}
                      helperText={FormUtil.renderHelperText(validation, 'firstname')}
                      label="First Name"
                      value={values.firstname}
                      onChange={handleChange('firstname')}
                      placeholder={`First Name`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <TextField
                      error={FormUtil.renderError(validation, 'lastname')}
                      helperText={FormUtil.renderHelperText(validation, 'lastname')}
                      label="Last Name"
                      value={values.lastname}
                      onChange={handleChange('lastname')}
                      placeholder={`Last Name`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={FormUtil.renderError(validation, 'email')}
                      helperText={FormUtil.renderHelperText(validation, 'email')}
                      label="Email"
                      value={values.email}
                      onChange={handleChange('email')}
                      placeholder={`Email`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={FormUtil.renderError(validation, 'phone')}
                      helperText={FormUtil.renderHelperText(validation, 'phone')}
                      label="Phone"
                      value={values.phone}
                      onChange={handleChange('phone')}
                      placeholder={`Phone`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={FormUtil.renderError(validation, 'address_line_1')}
                      helperText={FormUtil.renderHelperText(validation, 'address_line_1')}
                      label="Address Line 1"
                      value={values.address_line_1}
                      onChange={handleChange('address_line_1')}
                      placeholder={`Address Line 1`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={FormUtil.renderError(validation, 'address_line_2')}
                      helperText={FormUtil.renderHelperText(validation, 'address_line_2')}
                      label="Address Line 2"
                      value={values.address_line_2}
                      onChange={handleChange('address_line_2')}
                      placeholder={`Address Line 2`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      error={FormUtil.renderError(validation, 'city')}
                      helperText={FormUtil.renderHelperText(validation, 'city')}
                      label="City"
                      value={values.city}
                      onChange={handleChange('city')}
                      placeholder={`City`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid align={'left'} item xs={12} md={4} lg={4}>
                    <TextField
                      select
                      label="State"
                      defaultValue={values.state}
                      value={values.state}
                      onChange={handleChange('state')}
                      fullWidth={true}
                      error={FormUtil.renderError(validation, 'state')}
                      helperText={FormUtil.renderHelperText(validation, 'state')}
                    >
                      <MenuItem value={'AL'}>Alabama</MenuItem>
                      <MenuItem value={'AK'}>Alaska</MenuItem>
                      <MenuItem value={'AZ'}>Arizona</MenuItem>
                      <MenuItem value={'AR'}>Arkansas</MenuItem>
                      <MenuItem value={'CA'}>California</MenuItem>
                      <MenuItem value={'CO'}>Colorado</MenuItem>
                      <MenuItem value={'CT'}>Connecticut</MenuItem>
                      <MenuItem value={'DE'}>Delaware</MenuItem>
                      <MenuItem value={'DC'}>District Of Columbia</MenuItem>
                      <MenuItem value={'FL'}>Florida</MenuItem>
                      <MenuItem value={'GA'}>Georgia</MenuItem>
                      <MenuItem value={'HI'}>Hawaii</MenuItem>
                      <MenuItem value={'ID'}>Idaho</MenuItem>
                      <MenuItem value={'IL'}>Illinois</MenuItem>
                      <MenuItem value={'IN'}>Indiana</MenuItem>
                      <MenuItem value={'IA'}>Iowa</MenuItem>
                      <MenuItem value={'KS'}>Kansas</MenuItem>
                      <MenuItem value={'KY'}>Kentucky</MenuItem>
                      <MenuItem value={'LA'}>Louisiana</MenuItem>
                      <MenuItem value={'ME'}>Maine</MenuItem>
                      <MenuItem value={'MD'}>Maryland</MenuItem>
                      <MenuItem value={'MA'}>Massachusetts</MenuItem>
                      <MenuItem value={'MI'}>Michigan</MenuItem>
                      <MenuItem value={'MN'}>Minnesota</MenuItem>
                      <MenuItem value={'MS'}>Mississippi</MenuItem>
                      <MenuItem value={'MO'}>Missouri</MenuItem>
                      <MenuItem value={'MT'}>Montana</MenuItem>
                      <MenuItem value={'NE'}>Nebraska</MenuItem>
                      <MenuItem value={'NV'}>Nevada</MenuItem>
                      <MenuItem value={'NH'}>New Hampshire</MenuItem>
                      <MenuItem value={'NJ'}>New Jersey</MenuItem>
                      <MenuItem value={'NM'}>New Mexico</MenuItem>
                      <MenuItem value={'NY'}>New York</MenuItem>
                      <MenuItem value={'NC'}>North Carolina</MenuItem>
                      <MenuItem value={'ND'}>North Dakota</MenuItem>
                      <MenuItem value={'OH'}>Ohio</MenuItem>
                      <MenuItem value={'OK'}>Oklahoma</MenuItem>
                      <MenuItem value={'OR'}>Oregon</MenuItem>
                      <MenuItem value={'PA'}>Pennsylvania</MenuItem>
                      <MenuItem value={'RI'}>Rhode Island</MenuItem>
                      <MenuItem value={'SC'}>South Carolina</MenuItem>
                      <MenuItem value={'SD'}>South Dakota</MenuItem>
                      <MenuItem value={'TN'}>Tennessee</MenuItem>
                      <MenuItem value={'TX'}>Texas</MenuItem>
                      <MenuItem value={'UT'}>Utah</MenuItem>
                      <MenuItem value={'VT'}>Vermont</MenuItem>
                      <MenuItem value={'VA'}>Virginia</MenuItem>
                      <MenuItem value={'WA'}>Washington</MenuItem>
                      <MenuItem value={'WV'}>West Virginia</MenuItem>
                      <MenuItem value={'WI'}>Wisconsin</MenuItem>
                      <MenuItem value={'WY'}>Wyoming</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      error={FormUtil.renderError(validation, 'zipcode')}
                      helperText={FormUtil.renderHelperText(validation, 'zipcode')}
                      id="outlined-basic"
                      label="ZIP Code"
                      value={values.zipcode}
                      onChange={handleChange('zipcode')}
                      placeholder={`ZIP Code`}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>

                  {/* Save Changes Button */}
                  <Grid item xs={12} md={12} lg={12}>
                    <LoadingButton
                      onClick={onSubmitForm}
                      loading={customerCreate?.fetching}
                      disabled={customerCreate?.fetching}
                      loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                      size={`large`}
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      sx={{ textTransform: 'none' }}
                    >
                      Create Customer
                    </LoadingButton>
                  </Grid>
                </Grid>
              ) : (
                <Alert severity="error">Permission Denied</Alert>
              ),
          }[page]
        }
      </Grid>
    </Grid>
  );
};

CustomersPage.propTypes = {
  customers: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string,
  doCreateCustomer: PropTypes.func,
  doGetCustomers: PropTypes.func,
  doCustomerReset: PropTypes.func,
  customerCreate: PropTypes.object,
};

export default CustomersPage;
