import DynamicContent from '../../components/DynamicContent';
import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { doClearDynamicPage, doReadDynamicPage } from './operations';

const DynamicContainer = props => {
  const { doClearDynamicPage, doReadDynamicPage, dynamicPageRead, token } = props;
  return (
    <DynamicContent
      contentKey={'privacy_page'}
      token={token !== undefined ? token : ''}
      doClearDynamicPage={doClearDynamicPage}
      doReadDynamicPage={doReadDynamicPage}
      dynamicPageRead={dynamicPageRead}
    />
  );
};

// --------------------------------------------------------
/**
 * Maps Redux State To Component
 * @param {Object} state main redux state
 * @returns {Object} Redux Reducer States
 */
// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  // Dynamic Page
  dynamicPageRead: state.dynamicPageReducer.read,
});
/**
 * Maps Redux Actions To Component
 * @param {*} dispatch main redux dispatch event
 * @returns {{}} Redux Dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //DYNAMIC PAGE
      doClearDynamicPage,
      doReadDynamicPage,
    },
    dispatch,
  );

DynamicContainer.propTypes = {
  doClearDynamicPage: PropTypes.func,
  doReadDynamicPage: PropTypes.func,
  dynamicPageRead: PropTypes.object,
  token: PropTypes.string,
};
// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(DynamicContainer);
