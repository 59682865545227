export const LIST_CUSTOMER_REQUEST = 'LIST_CUSTOMER_REQUEST';
export const LIST_CUSTOMER_SUCCESS = 'LIST_CUSTOMER_SUCCESS';
export const LIST_CUSTOMER_FAILURE = 'LIST_CUSTOMER_FAILURE';
export const READ_CUSTOMER_REQUEST = 'READ_CUSTOMER_REQUEST';
export const READ_CUSTOMER_SUCCESS = 'READ_CUSTOMER_SUCCESS';
export const READ_CUSTOMER_FAILURE = 'READ_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
export const CUSTOMER_RESET = 'CUSTOMER_RESET';
