export const READ_CART_REQUEST = 'READ_CART_REQUEST';
export const READ_CART_SUCCESS = 'READ_CART_SUCCESS';
export const READ_CART_FAILURE = 'READ_CART_FAILURE';
export const READ_LAST_CART_REQUEST = 'READ_LAST_CART_REQUEST';
export const READ_LAST_CART_SUCCESS = 'READ_LAST_CART_SUCCESS';
export const READ_LAST_CART_FAILURE = 'READ_LAST_CART_FAILURE';
export const DUPLICATE_CART_REQUEST = 'DUPLICATE_LAST_CART_REQUEST';
export const DUPLICATE_CART_SUCCESS = 'DUPLICATE_LAST_CART_SUCCESS';
export const DUPLICATE_CART_FAILURE = 'DUPLICATE_LAST_CART_FAILURE';
export const REFRESH_CART_REQUEST = 'REFRESH_CART_REQUEST';
export const REFRESH_CART_SUCCESS = 'REFRESH_CART_SUCCESS';
export const REFRESH_CART_FAILURE = 'REFRESH_CART_FAILURE';
export const CREATE_CART_REQUEST = 'CREATE_CART_REQUEST';
export const CREATE_CART_SUCCESS = 'CREATE_CART_SUCCESS';
export const CREATE_CART_FAILURE = 'CREATE_CART_FAILURE';
export const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE';
export const DELETE_CART_REQUEST = 'DELETE_CART_REQUEST';
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS';
export const DELETE_CART_FAILURE = 'DELETE_CART_FAILURE';
export const EMPTY_CART_REQUEST = 'EMPTY_CART_REQUEST';
export const EMPTY_CART_SUCCESS = 'EMPTY_CART_SUCCESS';
export const EMPTY_CART_FAILURE = 'EMPTY_CART_FAILURE';
export const CART_UPDATE_RESET = 'CART_UPDATE_RESET';
export const CART_REFRESH = 'CART_REFRESH';
export const CART_RESET = 'CART_RESET';
export const OVERRIDE_CART_RESET = 'OVERRIDE_CART_RESET';
