import {
  REGISTER_REQUEST,
  REGISTER_RESET,
  LOGIN_REQUEST,
  LOGIN_RESET,
  LOGOUT_REQUEST,
  ME_REQUEST,
  UNSUBSCRIBE_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  PASSWORD_RESET_REQUEST,
  FORGOT_PASSWORD_RESET,
  PASSWORD_RESET_RESET,
  ACTIVATION_RESET,
  ACTIVATION_REQUEST,
  IMPERSONATE_REQUEST,
  RESET_IMPERSONATE_REQUEST,
  SET_SITE,
} from './types';

export const doLogin = ({ email, password }) => ({
  type: LOGIN_REQUEST,
  email,
  password,
});
export const doMe = ({ token }) => ({ type: ME_REQUEST, token });

export const doLogout = ({ token, redirect }) => ({ type: LOGOUT_REQUEST, token, redirect });

export const doPasswordReset = ({ password, password_confirmation, token, validator }) => ({
  type: PASSWORD_RESET_REQUEST,
  password,
  password_confirmation,
  token,
  validator,
});

export const doCustomerActivation = ({ password, password_confirmation, token, validator }) => ({
  type: ACTIVATION_REQUEST,
  password,
  password_confirmation,
  token,
  validator,
});

export const doSalesRepActivation = ({ password, password_confirmation, token, validator }) => ({
  type: ACTIVATION_REQUEST,
  password,
  password_confirmation,
  token,
  validator,
});

export const doImpersonate = ({ token, user_id }) => ({
  type: IMPERSONATE_REQUEST,
  token,
  user_id,
});

export const doResetImpersonate = ({}) => ({
  type: RESET_IMPERSONATE_REQUEST,
});

export const doForgotPassword = ({ email }) => ({
  type: FORGOT_PASSWORD_REQUEST,
  email,
});

export const doUnsubscribe = ({ email }) => ({
  type: UNSUBSCRIBE_REQUEST,
  email,
});

export const doRegister = payload => ({
  type: REGISTER_REQUEST,
  ...payload,
});

export const doRegisterReset = () => ({
  type: REGISTER_RESET,
});

export const doLoginReset = () => ({
  type: LOGIN_RESET,
});

export const doForgotPasswordReset = () => ({
  type: FORGOT_PASSWORD_RESET,
});

export const doUnsubscribeReset = () => ({
  type: UNSUBSCRIBE_RESET,
});

export const doPasswordResetReset = () => ({
  type: PASSWORD_RESET_RESET,
});

export const doActivationReset = () => ({
  type: ACTIVATION_RESET,
});

export const doSetSite = site => ({
  type: SET_SITE,
  site: site,
});
