// Import
import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import CatalogFooterStyles from './styles';
import PropTypes from 'prop-types';
import FooterSub from './FooterSub';
import DynamicContent from '../DynamicContent';

/**
 * CATALOG FOOTER COMPONENT
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const CatalogFooter = props => {
  const { width, token, dynamicPageRead, doReadDynamicPage, doClearDynamicPage, isAndroidDevice } = props;
  return (
    <CatalogFooterStyles>
      <Grid item xs={12} md={12} lg={12} pt={1}>
        <hr />
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <DynamicContent contentKey={'verification'} token={token} dynamicPageRead={dynamicPageRead} doReadDynamicPage={doReadDynamicPage} doClearDynamicPage={doClearDynamicPage} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <div style={{ width: `100%`, textAlign: width < 600 ? 'center' : 'left', fontSize: 12 }}>
              <DynamicContent contentKey={'disclaimer'} token={token} dynamicPageRead={dynamicPageRead} doReadDynamicPage={doReadDynamicPage} doClearDynamicPage={doClearDynamicPage} />
            </div>
          </Grid>
          <Grid item md={2} lg={2} xs={12}>
            <div style={{ width: `100%`, textAlign: width < 600 ? 'center' : 'left', fontSize: 12 }}>
              <div style={{ fontWeight: `bold` }}>{window.COMPANY_NAME}</div>
              <div>{window.COMPANY_ADDRESS}</div>
              <div>{window.COMPANY_EMAIL}</div>
              <div>{window.COMPANY_PHONE}</div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div style={{ width: `100%`, textAlign: `center`, fontSize: 12, marginTop: 10 }}>
              <div style={{ fontWeight: `bold`, fontSize: 14, marginBottom: 3 }}>Accepted Payment Types</div>
              <div>
                <img className={'approved-payments'} src={`/approved-payment-types.png`} style={{ width: 350 }} alt={`Approved Payment Types`} />
              </div>
            </div>
          </Grid>
        </Grid>

        <hr />
      </Grid>
      <Grid item xs={12} md={12} lg={12} pt={1}>
        <div style={{ width: `100%`, textAlign: `center`, marginBottom: isAndroidDevice() ? 25 : 0 }}>© 2024 {window.COMPANY_NAME}</div>
      </Grid>
    </CatalogFooterStyles>
  );
};

// Property Types
CatalogFooter.propTypes = {
  width: PropTypes.number,
};

// Export
export default CatalogFooter;
