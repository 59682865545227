export const LIST_USER_GATEWAY_PROFILE_REQUEST = 'LIST_USER_GATEWAY_PROFILE_REQUEST';
export const LIST_USER_GATEWAY_PROFILE_SUCCESS = 'LIST_USER_GATEWAY_PROFILE_SUCCESS';
export const LIST_USER_GATEWAY_PROFILE_FAILURE = 'LIST_USER_GATEWAY_PROFILE_FAILURE';
export const READ_USER_GATEWAY_PROFILE_REQUEST = 'READ_USER_GATEWAY_PROFILE_REQUEST';
export const READ_USER_GATEWAY_PROFILE_SUCCESS = 'READ_USER_GATEWAY_PROFILE_SUCCESS';
export const READ_USER_GATEWAY_PROFILE_FAILURE = 'READ_USER_GATEWAY_PROFILE_FAILURE';
export const LIST_USER_PAYMENT_PROFILE_REQUEST = 'LIST_USER_PAYMENT_PROFILE_REQUEST';
export const LIST_USER_PAYMENT_PROFILE_SUCCESS = 'LIST_USER_PAYMENT_PROFILE_SUCCESS';
export const LIST_USER_PAYMENT_PROFILE_FAILURE = 'LIST_USER_PAYMENT_PROFILE_FAILURE';
export const READ_USER_PAYMENT_PROFILE_REQUEST = 'READ_USER_PAYMENT_PROFILE_REQUEST';
export const READ_USER_PAYMENT_PROFILE_SUCCESS = 'READ_USER_PAYMENT_PROFILE_SUCCESS';
export const READ_USER_PAYMENT_PROFILE_FAILURE = 'READ_USER_PAYMENT_PROFILE_FAILURE';
export const CLEAR_USER_GATEWAY_PROFILE = 'CLEAR_USER_GATEWAY_PROFILE';
