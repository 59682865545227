// Imports

// Order Action Types.
import {
  LIST_USER_GATEWAY_PROFILE_REQUEST,
  LIST_USER_GATEWAY_PROFILE_FAILURE,
  LIST_USER_GATEWAY_PROFILE_SUCCESS,
  LIST_USER_PAYMENT_PROFILE_FAILURE,
  LIST_USER_PAYMENT_PROFILE_SUCCESS,
  LIST_USER_PAYMENT_PROFILE_REQUEST,
  READ_USER_GATEWAY_PROFILE_REQUEST,
  READ_USER_GATEWAY_PROFILE_SUCCESS,
  READ_USER_GATEWAY_PROFILE_FAILURE,
  READ_USER_PAYMENT_PROFILE_FAILURE,
  READ_USER_PAYMENT_PROFILE_SUCCESS,
  READ_USER_PAYMENT_PROFILE_REQUEST,
  CLEAR_USER_GATEWAY_PROFILE,
} from './types';
import { READ_CART_FAILURE, READ_CART_REQUEST, READ_CART_SUCCESS } from '../Cart/types';

// Initial Reducer States.
const initialState = {
  gateways: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  gateway: {
    fetching: false,
    errors: null,
    data: null,
  },
  payments: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  payment: {
    fetching: false,
    errors: null,
    data: null,
  },
};

/**
 * Order Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const authorizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USER_GATEWAY_PROFILE_REQUEST:
      return {
        ...state,
        gateways: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_USER_GATEWAY_PROFILE_SUCCESS:
      return {
        ...state,
        gateways: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_USER_GATEWAY_PROFILE_FAILURE:
      return {
        ...state,
        gateways: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_USER_GATEWAY_PROFILE_REQUEST:
      return {
        ...state,
        gateway: {
          fetching: true,
          errors: null,
          pagination: null,
          data: null,
          // data: state?.cart?.data,
        },
      };
    case READ_USER_GATEWAY_PROFILE_SUCCESS:
      return {
        ...state,
        gateway: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case READ_USER_GATEWAY_PROFILE_FAILURE:
      return {
        ...state,
        gateway: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case LIST_USER_PAYMENT_PROFILE_REQUEST:
      return {
        ...state,
        payments: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_USER_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        payments: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_USER_PAYMENT_PROFILE_FAILURE:
      return {
        ...state,
        payments: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_USER_PAYMENT_PROFILE_REQUEST:
      return {
        ...state,
        payment: {
          fetching: true,
          errors: null,
          pagination: null,
          data: null,
          // data: state?.cart?.data,
        },
      };
    case READ_USER_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        payment: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case READ_USER_PAYMENT_PROFILE_FAILURE:
      return {
        ...state,
        payment: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case CLEAR_USER_GATEWAY_PROFILE:
      return {
        ...state,
        gateway: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    default:
      return state;
  }
};

// Exports
export default authorizeReducer;
