import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import App from '../../components/App';

// MAIN COMPONENT
/**
 * Main app component
 * @param {*} props default component props
 * @returns {JSX.Element} react component
 */
const AppContainer = props => {
  const { children } = props;
  return <App>{children}</App>;
};
//REDUX
/**
 * Maps redux state to local component state
 * @param {*} state incoming redux state
 * @return {Object} redux state
 */
// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({});

/**
 * Maps redux actions to local component state
 * @param {*} dispatch incoming redux dispatch
 * @return {Object} redux actions
 */
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

// PROP TYPES
AppContainer.propTypes = {
  children: PropType.any,
};

//EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppContainer));
