// Imports

// Customer Action Types.
import {
  LIST_CUSTOMER_REQUEST,
  LIST_CUSTOMER_SUCCESS,
  LIST_CUSTOMER_FAILURE,
  READ_CUSTOMER_REQUEST,
  READ_CUSTOMER_SUCCESS,
  READ_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  CUSTOMER_RESET,
} from './types';

// Initial Reducer States.
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
};

/**
 * Customer Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CUSTOMER_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_CUSTOMER_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_CUSTOMER_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_CUSTOMER_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_CUSTOMER_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CUSTOMER_RESET:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
        },
        read: {
          fetching: false,
          errors: null,
          data: null,
        },
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
        update: {
          fetching: false,
          errors: null,
          data: null,
        },
        delete: {
          fetching: false,
          errors: null,
        },
      };
    default:
      return state;
  }
};

// Exports
export default customerReducer;
