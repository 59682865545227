// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_USER_REQUEST,
  LIST_USER_SUCCESS,
  LIST_USER_FAILURE,
  READ_USER_REQUEST,
  READ_USER_SUCCESS,
  READ_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_DELETE_USER_SUCCESS,
  UPDATE_DELETE_USER_FAILURE,
  UPDATE_DELETE_USER_REQUEST,
} from './types';

// Actions
import { getUsers, getUser, createUser, updateUser, deleteUser, updateDeleteUser } from './actions';

/**
 * Function that handles listing users
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetUsers({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getUsers, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_USER_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_USER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetUsers() {
  yield takeLatest(LIST_USER_REQUEST, workerGetUsers);
}

/**
 * Function that handles getting a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetUser({ token, id }) {
  try {
    const response = yield call(getUser, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_USER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_USER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetUser() {
  yield takeLatest(READ_USER_REQUEST, workerGetUser);
}

/**
 * Function that handles creating a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateUser({ token, payload }) {
  try {
    const response = yield call(createUser, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_USER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_USER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateUser() {
  yield takeLatest(CREATE_USER_REQUEST, workerCreateUser);
}

/**
 * Function that handles updating a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateDeleteUser({ token, id, payload }) {
  try {
    const response = yield call(updateDeleteUser, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_DELETE_USER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_DELETE_USER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateDeleteUser() {
  yield takeLatest(UPDATE_DELETE_USER_REQUEST, workerUpdateDeleteUser);
}

/**
 * Function that handles updating a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateUser({ token, id, payload }) {
  try {
    const response = yield call(updateUser, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_USER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_USER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, workerUpdateUser);
}

/**
 * Function that handles deleting a single user
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteUser({ token, id }) {
  try {
    const response = yield call(deleteUser, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_USER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_USER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteUser() {
  yield takeLatest(DELETE_USER_REQUEST, workerDeleteUser);
}
