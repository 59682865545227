import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { TextField, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import FormUtil from '../../../utils/FormUtil';
import Swal from 'sweetalert2';

const SettingsPage = props => {
  const { user, userUpdate, userUpdateDelete, doUpdateUser, doUpdateDeleteUser, doClearUpdateUser, doClearUser, doLogout, token, history } = props;

  const [updateUserDetailsRequest, setUpdateUserDetailsRequest] = useState(false);
  const [updatePasswordRequest, setUpdatePasswordRequest] = useState(false);
  const [deleteAccountRequest, setDeleteAccountRequest] = useState(false);

  const [values, setValues] = React.useState({
    firstname: user?.firstname,
    lastname: user?.lastname,
    email: user?.email,
    password: ``,
    password_confirmation: ``,
  });

  const [validation, setValidation] = React.useState({
    password: {
      validated: false,
      valid: true,
      errors: [],
    },
    password_confirmation: {
      validated: false,
      valid: true,
      errors: [],
    },
    firstname: {
      validated: false,
      valid: true,
      errors: [],
    },
    lastname: {
      validated: false,
      valid: true,
      errors: [],
    },
    email: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  useEffect(() => {
    if (deleteAccountRequest) {
      setDeleteAccountRequest(false);
      let id = user?.id;
      let payload = {
        password: 'DELETED',
        password_confirmation: 'DELETED',
      };
      doUpdateDeleteUser({ token, id, payload });
    }
  }, [deleteAccountRequest]);

  useEffect(() => {
    if (updateUserDetailsRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setUpdateUserDetailsRequest(false);

      if (values?.firstname === '') {
        setValidation(FormUtil.addError(validation, 'firstname', 'First Name is blank!'));
      }
      if (values?.lastname === '') {
        setValidation(FormUtil.addError(validation, 'lastname', 'Last Name is blank!'));
      }
      if (values?.email === '') {
        setValidation(FormUtil.addError(validation, 'email', 'Email is blank!'));
      }

      // Backend API Call.
      if (FormUtil.validate(validation)) {
        let id = user?.id;
        let payload = {
          firstname: values?.firstname ? values?.firstname : ``,
          lastname: values?.lastname ? values?.lastname : ``,
          email: values?.email ? values?.email : ``,
        };
        // Swal.fire('User Updated', 'User information has been updated', 'success');
        doUpdateUser({ token, id, payload });
      }
    }
  }, [values, updateUserDetailsRequest]);

  useEffect(() => {
    if (!userUpdateDelete.fetching) {
      if (userUpdateDelete.errors && userUpdateDelete.errors.errors) {
        let errorMessages = '';
        if (typeof userUpdateDelete.errors === 'object' && typeof userUpdateDelete.errors.errors === 'object') {
          Object.entries(userUpdateDelete.errors.errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              //console.log(value);
              value.forEach(errorMessage => {
                errorMessages += `${key}: ${errorMessage}\n`;
              });
            }
          });
        }
        if (errorMessages) {
          Swal.fire('User Error', `There was an error:\n${errorMessages}`, 'error');
        } else {
          Swal.fire('User Error', 'There was an error', 'error');
        }
      } else if (userUpdateDelete.data) {
        Swal.fire('User Deleted', 'User has been removed from system.', 'success').then(result => {
          // Code to execute after the user interacts with the notification
          if (result.isConfirmed) {
            // Code to execute if the user clicks the "OK" button
            // console.log('User clicked OK');
            if (doLogout) {
              doLogout({ token, redirect: null });
            }
            doClearUser();
            history.push('/');
          } else {
            // Code to execute if the user clicks outside of the notification
            // console.log('User clicked outside of the notification or pressed Escape key');
            if (doLogout) {
              doLogout({ token, redirect: null });
            }
            doClearUser();
            history.push('/');
          }
        });
      }
    }
  }, [userUpdateDelete, doLogout]);

  useEffect(() => {
    if (!userUpdate.fetching) {
      if (userUpdate.errors && userUpdate.errors.errors) {
        let errorMessages = '';
        if (typeof userUpdate.errors === 'object' && typeof userUpdate.errors.errors === 'object') {
          Object.entries(userUpdate.errors.errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              //console.log(value);
              value.forEach(errorMessage => {
                errorMessages += `${key}: ${errorMessage}\n`;
              });
            }
          });
        }
        if (errorMessages) {
          Swal.fire('User Error', `There was an error:\n${errorMessages}`, 'error');
        } else {
          Swal.fire('User Error', 'There was an error', 'error');
        }
      } else if (userUpdate.data) {
        Swal.fire('User Updated', 'User information has been updated', 'success').then(result => {
          // Code to execute after the user interacts with the notification
          if (result.isConfirmed) {
            // Code to execute if the user clicks the "OK" button
            // console.log('User clicked OK');
            doClearUpdateUser();
            setValidation(FormUtil.clearValidation(validation));
          } else {
            doClearUpdateUser();
            setValidation(FormUtil.clearValidation(validation));
          }
        });
      }
    }
  }, [userUpdate]);

  useEffect(() => {
    if (updatePasswordRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setUpdatePasswordRequest(false);

      if (values.password === '') {
        setValidation(FormUtil.addError(validation, 'password', 'Password is blank!'));
      }
      if (values?.password_confirmation === '') {
        setValidation(FormUtil.addError(validation, 'password_confirmation', 'Password Confirmation is blank!'));
      }
      if (values.password !== values?.password_confirmation) {
        setValidation(FormUtil.addError(validation, 'password_confirmation', 'Passwords do not match'));
      }

      // Backend API Call.
      if (FormUtil.validate(validation)) {
        let id = user?.id;
        let payload = {
          password: values.password,
          password_confirmation: values.password_confirmation,
        };
        // Swal.fire('User Updated', 'User password has been updated', 'success');
        doUpdateUser({ token, id, payload });
      }
    }
  }, [values, updatePasswordRequest]);

  const handleChange = (prop, type = 'text') => event => {
    if (type === 'text') {
      setValues({ ...values, [prop]: event.target.value });
    } else if (type === 'checkbox') {
      setValues({ ...values, [prop]: event.target.checked });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography align="left" variant="h4" sx={{ fontWeight: 'bold' }}>
          Settings
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              error={FormUtil.renderError(validation, 'firstname')}
              helperText={FormUtil.renderHelperText(validation, 'firstname')}
              onChange={handleChange('firstname')}
              label="First Name"
              fullWidth={true}
              value={values?.firstname}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={FormUtil.renderError(validation, 'lastname')}
              helperText={FormUtil.renderHelperText(validation, 'lastname')}
              onChange={handleChange('lastname')}
              label="Last Name"
              fullWidth={true}
              value={values?.lastname}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={FormUtil.renderError(validation, 'email')}
              helperText={FormUtil.renderHelperText(validation, 'email')}
              onChange={handleChange('email')}
              label="Login Email"
              fullWidth={true}
              value={values?.email}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip title="Save Changes">
              <Button variant="contained" fullWidth onClick={() => setUpdateUserDetailsRequest(true)}>
                Save Changes
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              error={FormUtil.renderError(validation, 'password')}
              helperText={FormUtil.renderHelperText(validation, 'password')}
              onChange={handleChange('password')}
              label="Password"
              fullWidth={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={FormUtil.renderError(validation, 'password_confirmation')}
              helperText={FormUtil.renderHelperText(validation, 'password_confirmation')}
              onChange={handleChange('password_confirmation')}
              label="Confirm Password"
              fullWidth={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip title="Change your Password">
              <Button variant="contained" fullWidth={true} onClick={() => setUpdatePasswordRequest(true)}>
                Change Password
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title="Delete Account">
              <Button
                variant="contained"
                fullWidth={true}
                onClick={() =>
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'This action cannot be undone.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, keep it',
                  }).then(result => {
                    if (result.isConfirmed) {
                      setDeleteAccountRequest(true);
                    }
                  })
                }
              >
                Delete Account
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
SettingsPage.propTypes = {
  token: PropTypes.string,
  user: PropTypes.object,
  userUpdate: PropTypes.object,
  history: PropTypes.object,
  userUpdateDelete: PropTypes.object,
  doUpdateUser: PropTypes.func,
  doUpdateDeleteUser: PropTypes.func,
  doLogout: PropTypes.func,
  doClearUser: PropTypes.func,
  doClearUpdateUser: PropTypes.func,
};
export default SettingsPage;
