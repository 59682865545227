import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { Checkbox, Skeleton } from '@mui/material';

import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import AddressCreateModal from '../../../components/Modals/AddressCreate';
import AddressEditModal from '../../../components/Modals/AddressEdit';

const CustomerAddressPage = props => {
  // Import Props
  const {
    token,
    match,
    user,
    contactList,
    contactDelete,
    contactCreate,
    contactUpdate,
    doGetUserContacts,
    doCreateUserContact,
    doClearUserContact,
    doUpdateUserContact,
    doDeleteUserContact,
    history,
  } = props;

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { page } = match?.params;

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  // Contact Us Modal States
  const [addressCreateModalOpen, setAddressCreateModalOpen] = React.useState(page === 'create');
  const [contactData, setContactData] = React.useState(null);
  const [addressEditModalOpen, setAddressEditModalOpen] = React.useState(!!page);

  useEffect(() => {
    if (
      (!contactList.fetching && !contactList.data) ||
      (!contactCreate?.fetching && contactCreate?.data) ||
      (!contactUpdate?.fetching && contactUpdate?.data) ||
      (!contactDelete?.fetching && contactDelete?.success)
    ) {
      doClearUserContact();
      let query = '';
      let direction = '';
      let order = '';
      let limit = 10;
      let page = 1;
      doGetUserContacts({ token, query, direction, order, limit, page });
    }
  }, [doGetUserContacts, doClearUserContact, contactDelete, contactCreate, contactUpdate, contactList]);

  return (
    <Grid container spacing={1.5}>
      {/* Page Title */}
      <Grid item xs={12} md={12} lg={12}>
        <Typography align="left" variant="h4" sx={{ fontWeight: 'bold', marginBottom: 0 }}>
          Addresses
          <div style={{ float: `right`, position: `relative` }}>
            <Button
              size="small"
              variant={'contained'}
              onClick={() => {
                setAddressCreateModalOpen(true);
                history.push('/catalog/addresses/create');
              }}
            >
              Create
            </Button>
          </div>
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {contactList && !contactList?.fetching && !contactCreate?.fetching && !contactUpdate?.fetching && contactList.data ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: `bold`, width: 50 }}>
                    Primary
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: `bold` }}>
                    Address
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: `bold`, width: 100 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {contactList && !contactList?.fetching && contactList?.data && contactList?.data.length ? (
                  contactList?.data.map(row => (
                    <TableRow key={'address' + row.id}>
                      <TableCell align="left" style={{ width: 50 }}>
                        <Checkbox
                          label={``}
                          defaultChecked={Boolean(row.enabled)}
                          onChange={() => {
                            let id = row?.id;
                            let payload = {
                              enabled: !row?.enabled,
                            };
                            doUpdateUserContact({ token, id, payload });
                          }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row.company_name} <br />
                        {row.address_line_1} {row.address_line_2} <br /> {row.city} {row.state} {row.zipcode}
                      </TableCell>
                      <TableCell align="right" style={{ width: 100 }}>
                        <IconButton
                          variant={'contained'}
                          size="small"
                          onClick={() => {
                            setContactData(row);
                            setAddressEditModalOpen(true);
                            history.push('/catalog/addresses/' + row.id);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align={`center`}>
                      <Typography variant="p" style={{ fontWeight: 'bold' }}>
                        There are currently no addresses
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Grid>
          </Grid>
        )}
        {/*<TablePagination component="div" count={rows.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />*/}

        {/* Address Edit Modal */}
        <AddressEditModal
          props={props}
          token={token}
          contactDelete={contactDelete}
          contactData={contactData}
          isOpen={addressEditModalOpen}
          doUpdateUserContact={doUpdateUserContact}
          doDeleteUserContact={doDeleteUserContact}
          contactUpdate={contactUpdate}
          doClearUserContact={doClearUserContact}
          deletingAllowed={true}
          handleClose={event => {
            setAddressEditModalOpen(false);
            setAddressCreateModalOpen(false);
            history.push('/catalog/addresses');
          }}
        />

        {/* Address Create Modal */}
        <AddressCreateModal
          props={props}
          token={token}
          contactCreate={contactCreate}
          doCreateUserContact={doCreateUserContact}
          doClearUserContact={doClearUserContact}
          isOpen={addressCreateModalOpen}
          handleClose={event => {
            setAddressCreateModalOpen(false);
            setAddressEditModalOpen(false);
            history.push('/catalog/addresses');
          }}
        />
      </Grid>
    </Grid>
  );
};
// PROPS TYPES
// --------------------------------------------------------
CustomerAddressPage.propTypes = {
  token: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  contactCreate: PropTypes.object,
  contactDelete: PropTypes.object,
  contactUpdate: PropTypes.object,
  contactList: PropTypes.object,
  doCreateUserContact: PropTypes.func,
  doDeleteUserContact: PropTypes.func,
  doGetUserContacts: PropTypes.func,
  doClearUserContact: PropTypes.func,
  doUpdateUserContact: PropTypes.func,
};
export default CustomerAddressPage;
