// IMPORTS
import React from 'react';
import PropType from 'prop-types';

// STYLES
import {} from './styles';

// MAIN COMPONENT
/**
 * Main app component
 * @param {*} props default component props
 * @returns {JSX.Element} react component
 */
const App = props => {
  // State / Props
  const { children } = props;

  // Hooks

  // Render
  return (
    <div>
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      {children}
    </div>
  );
};

// PROP TYPES
App.propTypes = {
  children: PropType.any,
};

// EXPORTS
export default App;
