// Import
import React from 'react';
import FormHelperTextStyles from './styles';
import PropTypes from 'prop-types';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Chip from '@mui/material/Chip';

/**
 * Mui FormHelperText Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const FormHelperText = props => {
  const { label, color, size } = props;
  return <Chip icon={<ErrorOutlineOutlinedIcon />} component={FormHelperTextStyles} label={label} color={color} size={size} />;
};

// Property Types
FormHelperText.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

// Export
export default FormHelperText;
