import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableCell, TextField, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import PropTypes from 'prop-types';
import FireProductStyles from './styles';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel';
import Button from '@mui/material/Button';

const FireProductBoostPage = props => {
  const { category, product, history, cart, quantityChanged, width, height, cartItemUpdate, cartItemCreate, internalProduct, internalProductVariant, user, cartItemLoading } = props;
  const [productFilter, setProductFilter] = React.useState('');

  const smokeColors = [
    [243, 215, 0],
    [221, 81, 122],
    [244, 122, 61],
    [154, 0, 13],
    [42, 216, 57],
    [72, 68, 255],
    [227, 107, 43],
    [239, 63, 66],
    [156, 217, 176],
    [88, 179, 79],
    [234, 111, 112],
    [151, 212, 219],
    [231, 195, 191],
    [160, 200, 61],
    [129, 87, 162],
  ];
  // const [smokeInterval, setSmokeInterval] = useState(0);
  let smokeInterval = 0;
  useEffect(() => {
    let canvas = document.getElementById('canvas');
    let ctx = canvas.getContext('2d');
    if (innerWidth < 600) {
      canvas.width = innerWidth;
    } else {
      canvas.width = innerWidth - 40;
    }

    canvas.height = innerHeight;
    var smokeColor = smokeColors[Math.floor(Math.random() * smokeColors.length)];
    // party.addSmoke(width / 2, 500, 10);
    let party = smokemachine(ctx, [smokeColor[0], smokeColor[1], smokeColor[2]]);
    const interval = setInterval(() => {
      if (smokeInterval > 10) {
        party.stop();
        smokeColor = smokeColors[Math.floor(Math.random() * smokeColors.length)];
        // party.addSmoke(width / 2, 500, 10);
        party = smokemachine(ctx, [smokeColor[0], smokeColor[1], smokeColor[2]]);
        smokeInterval = 0;
      }
      // party.stop(); // stop animating

      party.addSmoke(250, height, 5);
      party.addSmoke(width - 250, height, 5);
      // party.addSmoke((width / 4) * 3, height, 20);
      // party.addSmoke((width / 4) * 3, height, 20);

      for (var i = 0; i < 10; i++) {
        party.step(5); // pretend 10 ms pass and rerender
      }

      party.start();
      smokeInterval++;
    }, 1000);
    return () => clearInterval(interval);
  }, [width]);
  const [isMuted, setIsMuted] = useState(true);

  // Function to toggle the mute state
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };
  return (
    <FireProductStyles container spacing={5} pt={5} key={`table-product-${product.id}`}>
      <div style={{ position: `relative` }}>
        <canvas style={{ position: `absolute` }} id="canvas" />
      </div>
      <Grid item xs={12}>
        <Typography variant="h1" style={{ fontSize: 32, fontWeight: `bold` }}>
          {product.product_name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ marginBottom: 15 }}>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <div style={{ padding: 10 }}>
                <img src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/nicotine.svg`} alt={'alpina fire nicotine'} />
              </div>

              <Typography variant="h2" style={{ fontSize: width < 600 ? 12 : 18, fontWeight: `bold` }}>
                12,000 Puffs
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <div style={{ padding: 10 }}>
                <img src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/battery.svg`} alt={'alpina fire battery'} />
              </div>
              <Typography variant="h2" style={{ fontSize: width < 600 ? 12 : 18, fontWeight: `bold` }}>
                Rechargeable
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <div style={{ padding: 10 }}>
                <img src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/compact.svg`} alt={'alpina fire compact'} />
              </div>
              <Typography variant="h2" style={{ fontSize: width < 600 ? 12 : 18, fontWeight: `bold` }}>
                Boost Mode
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <div style={{ padding: 10 }}>
                <img src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/mesh.svg`} alt={'alpina fire mesh'} />
              </div>
              <Typography variant="h2" style={{ fontSize: width < 600 ? 12 : 18, fontWeight: `bold` }}>
                Dual Coil
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container>
          <Grid item xs={12}>
            {/*<div className={`img`}>*/}
            {/*  <div style={{ position: 'relative', width: `100%` }}>*/}
            {/*    <video className="videoTag" style={{ width: `100%` }} autoPlay loop muted>*/}
            {/*      <source src={'/Fire_website_16x9_b88eabfd50.mp4'} type="video/mp4" />*/}
            {/*    </video>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={`img`}>
              <video
                className="videoTag"
                style={{ width: `100%` }}
                autoPlay
                loop
                playsInline
                muted={isMuted} // Control the muted property
                onClick={toggleMute} // Optional: Toggle mute on click
              >
                <source src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/fire-boost-ad.mp4`} type="video/mp4" />
              </video>
            </div>
            <Button size={`small`} variant="contained" color="primary" onClick={toggleMute}>
              {isMuted ? 'Unmute' : 'Mute'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          {/*<Grid item xs={12}>*/}
          {/*  <div style={{ float: 'left', padding: 1, width: '49%' }}>*/}
          {/*    <Typography align="left" variant="h5" sx={{ fontWeight: 'bold' }}>*/}
          {/*      {product.product_name}*/}
          {/*    </Typography>*/}
          {/*    <Typography align="left" sx={{ fontSize: 13, fontWeight: 'bold', padding: 0.25 }}>*/}
          {/*      {product.product_variants.map(item => item.variant_name + ' (' + item.attribute_value + ')')}*/}
          {/*    </Typography>*/}
          {/*    <Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>*/}
          {/*      Types: {product.product_variants.length}*/}
          {/*    </Typography>*/}
          {/*    <Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>*/}
          {/*      Unit Price: ${product.unit_price}*/}
          {/*    </Typography>*/}
          {/*    /!*<Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>*!/*/}
          {/*    /!*  MSRP: ${item.msrp}*!/*/}
          {/*    /!*</Typography>*!/*/}
          {/*  </div>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              placeholder={'Search Products'}
              onChange={event => {
                setProductFilter(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody style={{ overflowX: `hidden` }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: `bold`,
                        minWidth: 100,
                        maxWidth: 150,
                        width: '100%',
                        padding: 15,
                      }}
                    >
                      Code
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: `bold`,
                        minWidth: 150,
                        maxWidth: 300,
                        padding: 15,
                        width: '100%',
                        display: width < 600 ? 'none' : 'table-cell',
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: `bold`, minWidth: 50, maxWidth: 50, padding: 15 }}>
                      {width < 600 ? `Price` : `Unit $`}
                    </TableCell>
                    {/*<TableCell*/}
                    {/*  align="left"*/}
                    {/*  style={{*/}
                    {/*    fontWeight: `bold`,*/}
                    {/*    width: '100%',*/}
                    {/*    maxWidth: 50,*/}
                    {/*    padding: 15,*/}
                    {/*    display: width < 600 ? 'none' : 'table-cell',*/}
                    {/*  }}*/}
                    {/*/>*/}
                    {product?.multiple ? (
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: `bold`,
                          width: '100%',
                          minWidth: 50,
                          maxWidth: 150,
                          padding: 15,
                          display: width < 600 ? 'none' : 'table-cell',
                        }}
                      >
                        Multiple
                      </TableCell>
                    ) : (
                      ''
                    )}
                    <TableCell align="center" style={{ fontWeight: `bold`, width: '100%', minWidth: 50, maxWidth: 150, padding: 15 }}>
                      Qty
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: `bold`,
                        width: '100%',
                        maxWidth: 100,
                        padding: 15,
                        display: width < 600 ? 'none' : 'table-cell',
                      }}
                    >
                      Stock
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  {product &&
                    product?.product_variants
                      // .sort((a, b) => b.quantity - a.quantity)
                      .sort((a, b) => {
                        if (category?.id === window.SORT_CATEGORY) {
                          if (a.quantity === b.quantity) {
                            return 0;
                          }
                          if (a.quantity === 0) {
                            return 1;
                          }
                          if (b.quantity === 0) {
                            return -1;
                          }
                          return a.quantity - b.quantity;
                        } else {
                          return a.product_code - b.product_code;
                        }
                      })
                      .filter(
                        productVariant =>
                          productFilter === '' ||
                          (productFilter !== '' && productVariant.variant_name.toLowerCase().includes(productFilter.toLowerCase())) ||
                          // productVariant.variant_code.includes(productFilter) ||
                          productVariant.product_attributes.some(attribute => attribute.attribute_value.toLowerCase().includes(productFilter.toLowerCase())),
                      )
                      .map(productVariant => {
                        return (
                          <TableRow key={`product-attribute-${productVariant.id}`}>
                            <TableCell align="left" style={{ minWidth: 100, maxWidth: 150, width: `100%`, paddingRight: 0 }}>
                              {width < 600 ? (
                                <div>
                                  <div style={{ marginBottom: 3 }}>{productVariant.variant_code}</div>
                                  <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                                    {productVariant.product_attributes.map(attribute => {
                                      return (
                                        <Chip
                                          className={`fire-chip`}
                                          key={`attribute-${attribute?.id}`}
                                          label={attribute.attribute_value}
                                          // style={{ backgroundColor: allProducts.find(item => item.name === attribute.attribute_value).color, marginRight: 5, fontSize: 10 }}
                                          style={{ marginRight: 5, fontSize: 10 }}
                                          size={`small`}
                                        />
                                      );
                                    })}
                                  </div>
                                  {product?.multiple ? (
                                    <div style={{ fontWeight: `bold`, marginBottom: 5 }}>
                                      Multiple: {` `}
                                      <Tooltip title={`You need to purchase this product in multiples of ${product?.multiple_amount}`} placement="top">
                                        <Chip className={`fire-chip`} label={`${product?.multiple_amount}`} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    ``
                                  )}
                                  <div style={{ fontWeight: `bold`, marginBottom: 5 }}>
                                    Stock:{' '}
                                    {productVariant && productVariant?.quantity >= 1 ? (
                                      <span style={{ color: `green` }}>{productVariant?.quantity}</span>
                                    ) : (
                                      <span style={{ color: `red` }}>Not In Stock</span>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                productVariant.variant_code
                              )}
                              {}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                minWidth: 150,
                                maxWidth: 200,
                                width: '100%',
                                display: width < 600 ? 'none' : 'table-cell',
                              }}
                            >
                              {productVariant.product_attributes.map(attribute => {
                                return (
                                  <Chip
                                    className={'fire-chip'}
                                    key={`attribute-${attribute.id}`}
                                    label={attribute.attribute_value}
                                    // style={{ backgroundColor: allProducts.find(item => item.name === attribute.attribute_value).color, marginRight: 5 }}
                                    style={{ marginRight: 5 }}
                                    size={`small`}
                                  />
                                );
                              })}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                width: '100%',
                                minWidth: 30,
                                maxWidth: 50,
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                            >
                              {category?.price_override?.active === 1 ? (
                                <span>
                                  <span style={{ color: `red` }}>
                                    <s>{productVariant.unit_price}</s>
                                  </span>
                                  <span> ${product?.lowest_price?.lowest_total}</span>
                                </span>
                              ) : product?.customer_saved_pricing && product?.customer_saved_pricing?.item_total !== product.unit_price ? (
                                <span>
                                  <span style={{ color: `red` }}>
                                    <s>{productVariant.unit_price}</s>
                                  </span>
                                  <span> ${product?.customer_saved_pricing?.item_total}</span>
                                </span>
                              ) : (
                                <span>${productVariant.unit_price}</span>
                              )}
                            </TableCell>
                            {/*{productVariant?.last_saved_price && productVariant?.last_saved_price?.item_total !== productVariant.unit_price ? (*/}
                            {/*  <TableCell align="left" style={{ width: 50, maxWidth: 50 }}>*/}
                            {/*    <Chip*/}
                            {/*      color={`secondary`}*/}
                            {/*      // variant={`outlined`}*/}
                            {/*      label={`${Math.round((1 - productVariant?.last_saved_price?.item_total / productVariant.unit_price) * 100)}% DISCOUNT`}*/}
                            {/*      // color="success"*/}
                            {/*    />*/}
                            {/*  </TableCell>*/}
                            {/*) : (*/}
                            {/*  <TableCell align="left" style={{ width: '100%', maxWidth: 50, display: width < 600 ? 'none' : 'table-cell' }} />*/}
                            {/*)}*/}
                            {/*<TableCell align="left">{productVariant?.last_saved_price ? productVariant?.last_saved_price?.item_total : productVariant.unit_price}</TableCell>*/}
                            {product?.multiple ? (
                              <TableCell
                                align="center"
                                style={{
                                  width: '100%',
                                  minWidth: 50,
                                  maxWidth: 150,
                                  display: width < 600 ? 'none' : 'table-cell',
                                }}
                              >
                                {product.multiple ? (
                                  <Tooltip title={`You need to purchase this product in multiples of ${product?.multiple_amount}`} placement="top">
                                    <Chip className={'fire-chip'} label={`${product?.multiple_amount}`} size={`small`} />
                                  </Tooltip>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            ) : (
                              ''
                            )}
                            <TableCell align="center" style={{ width: '100%', minWidth: 50, maxWidth: 150 }}>
                              <TextField
                                size={`small`}
                                className={`center-input`}
                                style={{ textAlign: `center` }}
                                inputProps={{ style: { width: width < 600 ? (width < 400 ? `50px` : `75px`) : `100px` } }}
                                disabled={
                                  productVariant?.quantity <= 0 || productVariant !== internalProductVariant ? productVariant?.quantity === 0 || cartItemLoading || user?.role?.name === 'sales' : false
                                }
                                // disabled={
                                //   productVariant !== internalProductVariant
                                //     ? productVariant?.quantity === 0 || cartItemUpdate?.fetching || cartItemCreate?.fetching || cart?.fetching || cartItemLoading || user?.role?.name === 'sales'
                                //     : false
                                // }
                                defaultValue={
                                  cart &&
                                  cart?.data &&
                                  cart?.data?.cart_items &&
                                  cart?.data?.cart_items?.length >= 1 &&
                                  cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id)
                                    ? cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id).quantity
                                    : ``
                                }
                                onChange={event => quantityChanged(event, product, productVariant)}
                                // onBlur={event => quantityChanged(event, product, productVariant)}
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                width: '100%',
                                minWidth: 80,
                                maxWidth: 100,
                                display: width < 600 ? 'none' : 'table-cell',
                              }}
                            >
                              <div style={{ fontWeight: `bold` }}>
                                {productVariant && productVariant?.quantity >= 1 ? (
                                  <span style={{ color: `green` }}>{productVariant?.quantity}</span>
                                ) : (
                                  <span style={{ color: `red` }}>Not In Stock</span>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </FireProductStyles>
  );
};

FireProductBoostPage.propTypes = {
  user: PropTypes.object,
  cart: PropTypes.object,
  category: PropTypes.object,
  product: PropTypes.object,
  history: PropTypes.object,
  cartItemCreate: PropTypes.object,
  cartItemUpdate: PropTypes.object,
  internalProduct: PropTypes.object,
  internalProductVariant: PropTypes.object,
  quantityChanged: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  cartItemLoading: PropTypes.bool,
};
export default FireProductBoostPage;
