export const LIST_PURCHASEORDERAUTOMATION_REQUEST = 'LIST_PURCHASEORDERAUTOMATION_REQUEST';
export const LIST_PURCHASEORDERAUTOMATION_SUCCESS = 'LIST_PURCHASEORDERAUTOMATION_SUCCESS';
export const LIST_PURCHASEORDERAUTOMATION_FAILURE = 'LIST_PURCHASEORDERAUTOMATION_FAILURE';
export const READ_PURCHASEORDERAUTOMATION_REQUEST = 'READ_PURCHASEORDERAUTOMATION_REQUEST';
export const READ_PURCHASEORDERAUTOMATION_SUCCESS = 'READ_PURCHASEORDERAUTOMATION_SUCCESS';
export const READ_PURCHASEORDERAUTOMATION_FAILURE = 'READ_PURCHASEORDERAUTOMATION_FAILURE';
export const CREATE_PURCHASEORDERAUTOMATION_REQUEST = 'CREATE_PURCHASEORDERAUTOMATION_REQUEST';
export const CREATE_PURCHASEORDERAUTOMATION_SUCCESS = 'CREATE_PURCHASEORDERAUTOMATION_SUCCESS';
export const CREATE_PURCHASEORDERAUTOMATION_FAILURE = 'CREATE_PURCHASEORDERAUTOMATION_FAILURE';
export const PURCHASEORDERAUTOMATION_RESET = 'PURCHASEORDERAUTOMATION_RESET';
