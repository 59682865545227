// Imports
import styled from 'styled-components';

// Main Component
const VerificationStyles = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  background: #dedede;

  .Mui-focused {
    color: #000 !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000 !important;
    border-width: 2px !important;
  }

  @media (min-width: 1200px) {
    .MuiContainer-root {
      max-width: 1550px !important;
    }
  }
  // height: 100vh;
  // overlay-y: scroll;
  .verification-body {
    background-color: #fff !important;
    // position: relative;
    // top: 50px;
    position: relative;
    top: 1px;
  }
  .Mui-focused {
    color: #000 !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000 !important;
    border-width: 2px !important;
  }
  .dashboard-navbar {
    height: 50px !important;
  }
  .MuiToolbar-regular {
    min-height: 50px !important;
  }
  .MuiIconButton-root {
    border-radius: 0;
  }
`;

// Export
export default VerificationStyles;
