// Import types
import { LIST_SAVEDPRICING_REQUEST, READ_SAVEDPRICING_REQUEST, UPDATE_SAVEDPRICING_REQUEST, UPDATEALL_SAVEDPRICING_REQUEST, SAVEDPRICING_RESET, SAVEDPRICING_MODEL } from './types';

// Export operations
/**
 * Dispatch for getting a list of Customers.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetSavedPricing = ({ token, id, query, direction, order, limit, page }) => ({
  type: LIST_SAVEDPRICING_REQUEST,
  id,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for getting a specific Customer.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadSavedPricing = ({ token, id }) => ({
  type: READ_SAVEDPRICING_REQUEST,
  token,
  id,
});

/**
 * Dispatch for updating a Saved Pricing.
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doUpdateSavedPricing = ({ token, id, payload }) => ({
  type: UPDATE_SAVEDPRICING_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for updating all Saved Pricing.
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doUpdateAllSavedPricing = ({ token, payload }) => ({
  type: UPDATEALL_SAVEDPRICING_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for resetting saved pricing reducer
 * @param {Object} {  }
 * @return {Object} Dispatch
 */
export const doUpdatePricingModel = ({ list, update }) => ({
  type: SAVEDPRICING_MODEL,
  list,
  update,
});

/**
 * Dispatch for resetting saved pricing reducer
 * @param {Object} {  }
 * @return {Object} Dispatch
 */
export const doSavedPricingReset = () => ({
  type: SAVEDPRICING_RESET,
});
