// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_PRODUCT_REQUEST,
  LIST_PRODUCT_SUCCESS,
  LIST_PRODUCT_FAILURE,
  READ_PRODUCT_REQUEST,
  READ_PRODUCT_SUCCESS,
  READ_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
} from './types';

// Actions
import { getProducts, getProduct, createProduct, updateProduct, deleteProduct } from './actions';

/**
 * Function that handles a list of Products.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetProducts({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getProducts, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_PRODUCT_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_PRODUCT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetProducts() {
  yield takeLatest(LIST_PRODUCT_REQUEST, workerGetProducts);
}

/**
 * Function that handles getting a specific Product.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetProduct({ token, id }) {
  try {
    const response = yield call(getProduct, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_PRODUCT_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_PRODUCT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetProduct() {
  yield takeLatest(READ_PRODUCT_REQUEST, workerGetProduct);
}

/**
 * Function that handles creating a Product.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateProduct({ token, payload }) {
  try {
    const response = yield call(createProduct, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_PRODUCT_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_PRODUCT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateProduct() {
  yield takeLatest(CREATE_PRODUCT_REQUEST, workerCreateProduct);
}

/**
 * Function that handles updating a specific Product.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateProduct({ token, id, payload }) {
  try {
    const response = yield call(updateProduct, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_PRODUCT_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_PRODUCT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateProduct() {
  yield takeLatest(UPDATE_PRODUCT_REQUEST, workerUpdateProduct);
}

/**
 * Function that handles deleting a specific Product.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteProduct({ token, id }) {
  try {
    const response = yield call(deleteProduct, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_PRODUCT_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_PRODUCT_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteProduct() {
  yield takeLatest(DELETE_PRODUCT_REQUEST, workerDeleteProduct);
}
