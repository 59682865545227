// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { emphasize, styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material//Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import InputBase from '@mui/material/InputBase';
import CatalogStyles from './styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BusinessIcon from '@mui/icons-material/Business';
import OrdersPage from '../../views/Catalog/OrdersPage';
import CatalogPage from '../../views/Catalog/CatalogPage';
import CustomersPage from '../../views/Catalog/CustomersPage';
import ReportsPage from '../../views/Catalog/ReportsPage';
import SettingsPage from '../../views/Catalog/SettingsPage';
import BasketPage from '../../views/Catalog/BasketPage';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ProductPage from '../../views/Catalog/ProductPage';
import CheckoutPage from '../../views/Catalog/CheckoutPage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import CategoryViewPage from '../../views/Catalog/CategoryViewPage';
import AlpinaFooter from '../Footer';
import OrdersExportPage from '../../views/Catalog/OrdersExportPage';
import CustomerAddressPage from '../../views/Catalog/CustomerAddressPage';
import { CircularProgress } from '@mui/material';
import { ContactUsModal } from '../Modals/ContactUs';
import OrdersItemsPage from '../../views/Catalog/OrdersItemsPage';
import PaymentPage from '../../views/Catalog/PaymentPage';
import ThankYouPage from '../../views/Catalog/ThankYouPage';
import FaceIcon from '@mui/icons-material/Face';
import { CustomerSelectorModal } from '../Modals/CustomerSelector';
import LogoutIcon from '@mui/icons-material/Logout';
import { SALEORDER_RESET } from '../../containers/SaleOrder/types';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Policy from '../Policy';
import SavedPricingPage from '../../views/Catalog/SavedPricingPage';
import FormatUtil from '../../utils/FormatUtil';
import FireProductPage from '../../views/Catalog/FireProductPage';
import AutomationPage from '../../views/Catalog/AutomationPage';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { doPurchaseOrderAutomationReset } from '../../containers/PurchaseOrderAutomation/operations';
import { doClearCustomerPaymentMethods } from '../../containers/Bluepay/operations';
import EnterAd from '../EnterAd';
import FireProductBoostPage from '../../views/Catalog/FireProductBoostPage';
import Verification from '../Verification';
import DynamicContent from '../DynamicContent';
import { doClearUpdateUser } from '../../containers/User/operations';

import Hammer from 'hammerjs';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

/**
 * CATALOG COMPONENT
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
// eslint-disable-next-line no-unused-vars
const Catalog = props => {
  const {
    token,
    history,
    match,
    user,
    cartItemCreate,
    cartItemUpdate,
    impersonate,
    customerCreate,
    customerRead,
    contactList,
    contactCreate,
    contactUpdate,
    contactDelete,
    categoryList,
    saleOrderCreate,
    saleOrderConfirm,
    saleOrderVerify,
    saleOrderUpdate,
    saleOrderCancel,
    saleOrderRead,
    saleOrders,
    cart,
    cartUpdate,
    customers,
    quantityChanged,
    doResetCategories,
    cartItemLoading,
    //SALE ORDERS
    doCreateSaleOrder,
    doConfirmSaleOrder,
    doVerifySaleOrder,
    doCancelSaleOrder,
    doUpdateSaleOrder,
    doResetSaleOrder,
    doGetSaleOrders,
    doReadSaleOrder,
    //USER CONTACT
    doCreateUserContact,
    doUpdateUserContact,
    doDeleteUserContact,
    doGetUserContacts,
    doClearUserContact,
    //CUSTOMERS
    doReadCustomer,
    doCreateCustomer,
    doCustomerReset,
    doGetCustomers,
    //CART
    doEmptyCart,
    doReadCart,
    doUpdateCart,
    doResetCart,
    doResetCartUpdate,
    doOverrideCart,
    //CART ITEMS
    doDeleteCartItem,
    doResetCartItem,

    //SAVED PRICING
    doGetSavedPricing,
    doReadSavedPricing,
    doUpdateSavedPricing,
    doUpdateAllSavedPricing,
    doSavedPricingReset,
    doUpdatePricingModel,
    savedPricingRead,
    savedPricings,
    savedPricingUpdate,

    doGetCategories,

    doMe,
    doLogout,
    doImpersonate,

    //USER
    doUpdateUser,
    userUpdate,

    internalProduct,
    internalProductVariant,
    focused,
    gateway,
    doReadUserGatewayProfile,
    doClearUserGatewayProfile,
    doClearDynamicPage,
    doReadDynamicPage,
    dynamicPageRead,
    //PURCHASEORDERAUTOMATION
    doCreatePurchaseOrderAutomation,
    doReadPurchaseOrderAutomation,
    doGetPurchaseOrderAutomations,
    doPurchaseOrderAutomationReset,
    purchaseOrderAutomationList,
    purchaseOrderAutomationRead,
    purchaseOrderAutomationCreate,
    purchaseOrderAutomationTrigger,
    setPurchaseOrderAutomationTrigger,

    doCreateNotificationCampaign,
    doReadNotificationCampaign,
    doGetNotificationCampaigns,
    doNotificationCampaignReset,
    notificationCampaignList,
    notificationCampaignRead,
    notificationCampaignCreate,
    notificationCampaignTrigger,
    setNotificationCampaignTrigger,
    bluepayCustomerPaymentMethodList,
    doGetCustomerPaymentMethods,
    doClearCustomerPaymentMethods,
    doUpdateDeleteUser,
    userUpdateDelete,
    doClearUser,
    doClearUpdateUser,
    doDuplicateCart,

    versionNotification,
    isAndroidDevice,
  } = props;

  const { view, page, pagetype, pageid } = match?.params;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [tosAnchorEl, setTosAnchorEl] = React.useState(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = React.useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = React.useState(null);

  const ref = useRef();
  const timer = useRef(null);
  const mounted = useRef(false);
  const swipeAreaRef = useRef(null);

  // useEffect(() => {
  //   // Ensure the DOM element is loaded
  //   if (swipeAreaRef.current) {
  //     const mc = new Hammer(swipeAreaRef.current);
  //
  //     // Add recognition for the swipe left and right gestures
  //     mc.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
  //
  //     // Handle swipe left
  //     // mc.on('swipeleft', () => {
  //     //   console.log('Swiped left!');
  //     //   alert('Swiped left!');
  //     // });
  //
  //     // Handle swipe right
  //     mc.on('swiperight', () => {
  //       console.log('Swiped right!');
  //       history.goBack();
  //     });
  //
  //     // Cleanup function to remove event listeners
  //     return () => {
  //       mc.off('swiperight');
  //     };
  //   }
  // }, []);

  const handleClick = event => {
    if (anchorEl != null) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCategoryAnchorClick = event => {
    setCategoryAnchorEl(event.currentTarget);
  };

  const handleTosAnchorClick = event => {
    setTosAnchorEl(event.currentTarget);
  };

  const totalItems = cart => {
    return cart && cart?.data && cart?.data?.cart_items ? cart?.data?.cart_items.map(({ quantity }) => quantity).reduce((sum, i) => sum + i, 0) : 0;
  };

  const handleLogout = event => {
    event.preventDefault();
    // doResetCart();
    // doResetCartItem();
    doResetSaleOrder(SALEORDER_RESET);
    doSavedPricingReset({});
    if (doLogout) {
      doLogout({ token, redirect: null });
    }

    history.push('/');
  };

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const [width, height] = useWindowSize();

  // Adjusting 'mobile' initialState to 'false' instead of 'true' since it was messing with some of the formatting during testing.
  const [mobile, setMobile] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setCategoryAnchorEl(null);
    setMobileAnchorEl(null);
    setTosAnchorEl(null);
  };

  // Contact Us Modal States
  const [contactUsModalOpen, setContactUsModalOpen] = React.useState(false);
  const [customerSelectorModalOpen, setCustomerSelectorModalOpen] = React.useState(false);
  const handleContactUsModalOpen = () => setContactUsModalOpen(true);
  const handleContactUsModalClose = () => setContactUsModalOpen(false);

  const handleCustomerSelectorModalOpen = () => setCustomerSelectorModalOpen(true);
  const handleCustomerSelectorModalClose = () => setCustomerSelectorModalOpen(false);

  let isMobile = mobile || width < 600;

  const [deliverToAddressId, setDeliverToAddressId] = React.useState(0);
  const [invoiceToAddressId, setInvoiceToAddressId] = React.useState(0);

  const [catalogIntervalLoop, setCatalogIntervalLoop] = React.useState(0);
  const [cartIntervalLoop, setCartIntervalLoop] = React.useState(0);
  const [saleOrderIntervalLoop, setSaleOrderIntervalLoop] = React.useState(0);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      let id = setInterval(() => {
        savedCallback.current();
      }, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

  useInterval(() => {
    if (!mounted.current) {
      return;
    }
    setCartIntervalLoop(cartIntervalLoop + 1);
    if (cartIntervalLoop >= window.CART_INTERVAL_LOOP) {
      if (cart && cart.data) {
        let id = cart.data.id;
        if (view !== 'payment' && view !== 'basket' && view !== 'checkout') {
          doReadCart({ token, id });
        }
      }
      setCartIntervalLoop(0);
    }
    if (!focused) {
      return;
    }
    setCatalogIntervalLoop(catalogIntervalLoop + 1);
    setSaleOrderIntervalLoop(saleOrderIntervalLoop + 1);

    if (catalogIntervalLoop >= window.CATALOG_INTERVAL_LOOP) {
      if (!categoryList.fetching && !saleOrderCreate.fetching) {
        let query = '';
        let direction = '';
        let order = '';
        let limit = 20;
        let page = 1;
        doGetCategories({ token, query, direction, order, limit, page });
        setCatalogIntervalLoop(0);
      }
    }
    if (saleOrderIntervalLoop >= 60) {
      if ((saleOrderRead && saleOrderRead?.data) || (saleOrderCreate && saleOrderCreate.data)) {
        let id = saleOrderRead && saleOrderRead?.data && saleOrderRead?.data?.id ? saleOrderRead?.data?.id : saleOrderCreate.data.id;

        doReadSaleOrder({ token, id });
      }
      setSaleOrderIntervalLoop(0);
    }
  }, 1000);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  });
  const handleBannerClick = () => {
    if (versionNotification) {
      window.location.href = window.APP_STORE_URL;
    } else {
      if (typeof window.APP_STORE_URL !== 'undefined') {
        // console.log('App Store URL:', window.APP_STORE_URL);

        let appStoreUrl = window.APP_STORE_URL;
        if (cordova && cordova.InAppBrowser) {
          cordova.InAppBrowser.open(appStoreUrl, '_system');
        } else {
          window.open(appStoreUrl, '_system');
        }
      }
    }
  };
  const [enter, setEnter] = React.useState(user?.ad_viewed || user?.role?.name === 'admin' || user?.role?.name === 'sales' || user?.impersonator_id != null);

  const isIOSBrowser = () => {
    const userAgent = window.navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    // Checks if the application is running in a browser and not in standalone mode
    const isInBrowser = !window.navigator.standalone;

    return isIOS && isInBrowser;
  };

  return user && user.age_verified ? (
    <div ref={swipeAreaRef}>
      {/*<EnterAd*/}
      {/*  width={width}*/}
      {/*  height={height}*/}
      {/*  setEnter={setEnter}*/}
      {/*  enter={user?.ad_viewed || user?.role?.name === 'admin' || user?.role?.name === 'sales' || user?.impersonator_id != null || enter}*/}
      {/*  user={user}*/}
      {/*  doMe={doMe}*/}
      {/*  doUpdateUser={doUpdateUser}*/}
      {/*  userUpdate={userUpdate}*/}
      {/*  token={token}*/}
      {/*/>*/}
      <CatalogStyles
        // style={{ display: user?.ad_viewed || user?.impersonator_id != null || user?.role?.name == 'admin' || user?.role?.name == 'sales' || enter ? 'block' : 'none' }}
        // style={{ display: user?.ad_viewed || user?.impersonator_id != null || user?.role?.name == 'admin' || user?.role?.name == 'sales' || enter ? 'block' : 'none' }}
        fireTheme={(page === 'product' && parseInt(pageid) === window.FIRE_PRODUCT_ID) || parseInt(pageid) === window.FIRE_BOOST_PRODUCT_ID}
      >
        {page === 'product' && (parseInt(pageid) === window.FIRE_PRODUCT_ID || parseInt(pageid) === window.FIRE_BOOST_PRODUCT_ID) ? (
          <div style={{ position: `relative`, zIndex: 1 }}>
            <canvas style={{ position: `absolute` }} id="canvas"></canvas>
          </div>
        ) : (
          ''
        )}
        {/*{versionNotification ||*/}
        {/*  (isIOSBrowser() && window.APP_CHECK && (*/}
        {/*    <div*/}
        {/*      onClick={handleBannerClick}*/}
        {/*      style={{*/}
        {/*        position: 'fixed',*/}
        {/*        bottom: 0,*/}
        {/*        left: 0,*/}
        {/*        zIndex: 99999999,*/}
        {/*        width: '100%',*/}
        {/*        backgroundColor: 'rgb(29, 29, 31, 1)',*/}
        {/*        color: 'white',*/}
        {/*        textAlign: 'center',*/}
        {/*        paddingTop: '20px',*/}
        {/*        paddingBottom: '25px',*/}
        {/*        fontWeight: `bold`,*/}
        {/*        cursor: 'pointer',*/}
        {/*        textTransform: 'uppercase', // This will capitalize all the text*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {versionNotification ? `IOS Update Available` : `DOWNLOAD IOS APP`}*/}
        {/*    </div>*/}
        {/*  ))}*/}
        <Box ref={ref} sx={{ flexGrow: 1 }}>
          <AppBar
            position={'relative'}
            className={`dashboard-navbar`}
            style={{
              backgroundColor: 'rgb(29, 29, 31)',
              boxShadow: 0,
              zIndex: 99999,
              height: 80,
              position: `relative`,
              top: isAndroidDevice() ? 23 : 0,
            }}
          >
            <Container disableGutters={true}>
              <Toolbar disableGutters={true}>
                <List
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 0,
                    height: `100%`,
                  }}
                >
                  <ListItem style={{ maxWidth: 200, width: `auto`, paddingTop: 5, paddingBottom: 5 }}>
                    <IconButton edge="start" color="inherit" size={width < 600 ? 'small' : 'medium'} onClick={handleClick}>
                      <PersonIcon fontSize={width < 600 ? 'small' : 'medium'} style={{ marginRight: 5 }} />
                      <Typography variant="subtitle2" style={{ fontSize: width < 600 ? 12 : 16 }}>
                        {user?.impersonator && user?.impersonator_id
                          ? FormatUtil.nameShrinker(user?.impersonator.firstname + ' ' + user?.impersonator?.lastname)
                          : FormatUtil.nameShrinker(user?.company_name)
                          ? FormatUtil.nameShrinker(user?.company_name)
                          : FormatUtil.nameShrinker(user?.firstname + ' ' + user?.lastname)}
                      </Typography>
                      {anchorEl ? <KeyboardArrowUpIcon fontSize={`small`} /> : <KeyboardArrowDownIcon fontSize={`small`} />}
                    </IconButton>
                    <Menu id="categories-menu" anchorEl={categoryAnchorEl} keepMounted open={Boolean(categoryAnchorEl)} style={{ zIndex: 4 }} onClose={handleClose}>
                      {categoryList &&
                        categoryList?.data &&
                        categoryList.data.map(category => (
                          <MenuItem
                            key={`category-dropdown${category?.id}`}
                            onClick={() => {
                              handleClose();
                              history.push(`/catalog/${category?.url}`);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Typography variant="inherit">{category?.category_name}</Typography>
                          </MenuItem>
                        ))}
                    </Menu>
                    <Menu id="tos-menu" anchorEl={tosAnchorEl} keepMounted open={Boolean(tosAnchorEl)} style={{ zIndex: 4 }} onClose={handleClose}>
                      <MenuItem
                        key={`category-tos-dropdown`}
                        onClick={() => {
                          handleClose();
                          history.push(`/catalog/policy/tos`);
                        }}
                      >
                        <Typography variant="inherit">Terms of Service</Typography>
                      </MenuItem>
                      <MenuItem
                        key={`category-privacy-dropdown`}
                        onClick={() => {
                          handleClose();
                          history.push(`/catalog/policy/privacy`);
                        }}
                      >
                        <Typography variant="inherit">Privacy Policy</Typography>
                      </MenuItem>
                      <MenuItem
                        key={`category-return-dropdown`}
                        onClick={() => {
                          handleClose();
                          history.push(`/catalog/policy/return`);
                        }}
                      >
                        <Typography variant="inherit">Return Policy</Typography>
                      </MenuItem>
                      <MenuItem
                        key={`category-shipping-dropdown`}
                        onClick={() => {
                          handleClose();
                          history.push(`/catalog/policy/shipping`);
                        }}
                      >
                        <Typography variant="inherit">Shipping Policy</Typography>
                      </MenuItem>
                    </Menu>
                    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} style={{ zIndex: 9999 }} onClose={handleClose}>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          history.push('/catalog/saleorders');
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentTurnedInIcon fontSize={`small`} />
                        </ListItemIcon>
                        <Typography variant="inherit">Orders</Typography>
                      </MenuItem>

                      {/* Don't show to customer role. */}
                      {/*{user?.role?.name === 'customer' ? (*/}
                      {/*  ''*/}
                      {/*) : (*/}
                      {/*  <MenuItem*/}
                      {/*    onClick={() => {*/}
                      {/*      handleClose();*/}
                      {/*      // history.push('/catalog/customers');*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <ListItemIcon>*/}
                      {/*      <PeopleIcon fontSize={`small`} />*/}
                      {/*    </ListItemIcon>*/}
                      {/*    <Typography*/}
                      {/*      variant="inherit"*/}
                      {/*      onClick={() => {*/}
                      {/*        setCustomerSelectorModalOpen(true);*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      Customers*/}
                      {/*    </Typography>*/}
                      {/*  </MenuItem>*/}
                      {/*)}*/}

                      {/* Don't show to customer role. */}
                      {user.role.name === 'admin' ? (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            history.push('/catalog/automation');
                          }}
                        >
                          <ListItemIcon>
                            <AutoAwesomeIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">Automation</Typography>
                        </MenuItem>
                      ) : (
                        ''
                      )}
                      {user.role.name === 'customer' ? (
                        ''
                      ) : (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            history.push('/catalog/customers');
                          }}
                        >
                          <ListItemIcon>
                            <PersonOutlineIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">Customers</Typography>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          history.push('/catalog/addresses');
                        }}
                      >
                        <ListItemIcon>
                          <BusinessIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">Addresses</Typography>
                      </MenuItem>
                      {/*<MenuItem*/}
                      {/*  onClick={() => {*/}
                      {/*    handleClose();*/}
                      {/*    history.push('/catalog/contactus');*/}
                      {/*    handleContactUsModalOpen();*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <ListItemIcon>*/}
                      {/*    <ContactPageIcon fontSize="small" />*/}
                      {/*  </ListItemIcon>*/}
                      {/*  <Typography variant="inherit">Contact Us</Typography>*/}
                      {/*</MenuItem>*/}
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          history.push('/catalog/settings');
                        }}
                      >
                        <ListItemIcon>
                          <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">Settings</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">Logout</Typography>
                      </MenuItem>
                    </Menu>
                  </ListItem>

                  {/* Don't show to customer role. */}
                  {user?.role?.name === 'customer' ? (
                    user?.impersonator_id ? (
                      <ListItem
                        style={{ maxWidth: 200, width: `auto`, paddingTop: 5, paddingBottom: 5 }}
                        onClick={() => {
                          doImpersonate({ token, user_id: user?.impersonator_id });
                          doClearUserContact({});
                          doResetSaleOrder(SALEORDER_RESET);
                          doResetCart({});
                          doSavedPricingReset({});
                          doResetCategories({});
                          history.push('/catalog');
                        }}
                      >
                        <IconButton edge="start" color="inherit" size={width < 600 ? 'small' : 'medium'}>
                          <LogoutIcon fontSize={width < 600 ? 'small' : 'medium'} style={{ marginRight: 8, position: `relative` }} />
                          <Typography style={{ fontSize: width < 600 ? 12 : 16, fontWeight: 'bold' }} variant="subtitle2" color={'#0a930a'}>
                            {width < 600
                              ? 'Logout'
                              : user?.company_name
                              ? FormatUtil.nameShrinker(user?.company_name)
                              : user?.firstname != null
                              ? FormatUtil.nameShrinker(user?.firstname + ' ' + user?.lastname)
                              : 'Logout'}
                          </Typography>
                        </IconButton>
                      </ListItem>
                    ) : (
                      ''
                    )
                  ) : (
                    <ListItem
                      style={{ maxWidth: 200, width: `auto`, paddingTop: 5, paddingBottom: 5 }}
                      onClick={() => {
                        setCustomerSelectorModalOpen(true);
                      }}
                    >
                      {/*<Tooltip title="Switch Customer">*/}
                      <IconButton edge="start" color="inherit" size={width < 600 ? 'small' : 'medium'}>
                        <FaceIcon fontSize={`small`} style={{ marginRight: 8, position: `relative`, top: -1 }} />
                        <Typography style={{ fontSize: width < 600 ? 12 : 16 }} variant="subtitle2">
                          Customers
                        </Typography>
                      </IconButton>
                    </ListItem>
                  )}
                </List>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <List
                    style={{
                      flexDirection: 'row',
                      padding: 0,
                      position: `relative`,
                      top: -2,
                      // float: 'right',
                      display: 'flex',
                    }}
                  >
                    <ListItem
                      style={{ width: 'auto', maxWidth: 110, paddingTop: 5, paddingBottom: 5, paddingRight: 18 }}
                      onClick={() => {
                        history.push('/catalog/basket');
                      }}
                    >
                      <IconButton edge="start" color="inherit" size={`medium`}>
                        <ShoppingBasketIcon fontSize={width < 600 ? 'small' : 'medium'} style={{ marginRight: 8, position: `relative`, top: -1 }} />
                        <Typography
                          style={{ color: `rgb(245 244 244 / 100%)`, fontSize: 16 }}
                          disabled={!(cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1)}
                        >
                          {cart && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1 ? (
                            <span
                              style={{
                                color: `green`,
                                fontWeight: `bold`,
                              }}
                            >
                              ({cart?.data?.cart_items.map(item => item.quantity).reduce((partial_sum, a) => partial_sum + a, 0)})
                            </span>
                          ) : cart && cart?.fetching ? (
                            <CircularProgress size={15} />
                          ) : width < 600 ? (
                            ''
                          ) : (
                            `Empty`
                          )}
                        </Typography>
                      </IconButton>
                    </ListItem>
                    <ListItem
                      style={{
                        width: 'auto',
                        maxWidth: 125,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        backgroundColor: `green`,
                      }}
                      onClick={() => (cart && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1 ? history.push('/catalog/checkout') : history.push('/catalog/basket'))}
                    >
                      <IconButton edge="end" color="inherit" size={`medium`}>
                        <Typography
                          style={{ color: `rgb(245 244 244 / 100%)`, fontSize: 16, fontWeight: `bold` }}
                          disabled={!(cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1)}
                        >
                          Checkout
                        </Typography>
                        <NavigateNextIcon fontSize={width < 600 ? 'small' : 'medium'} style={{ marginRight: 8, position: `relative`, top: -1 }} />
                      </IconButton>
                    </ListItem>
                  </List>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <List
                    style={{
                      flexDirection: 'row',
                      padding: 0,
                      position: `relative`,
                      top: -1,
                      // float: 'right',
                      display: 'flex',
                    }}
                  >
                    <ListItem
                      style={{ width: 'auto', maxWidth: 110, paddingTop: 5, paddingBottom: 5, paddingRight: 18 }}
                      onClick={() => {
                        history.push('/catalog/basket');
                      }}
                    >
                      <IconButton edge="start" color="inherit" size={`medium`}>
                        <ShoppingBasketIcon fontSize={width < 600 ? 'small' : 'medium'} style={{ marginRight: 8, position: `relative`, top: -1 }} />
                        <Typography
                          style={{ color: `rgb(245 244 244 / 100%)`, fontSize: width < 600 ? 14 : 16 }}
                          disabled={!(cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1)}
                        >
                          {cart && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1 ? (
                            <span
                              style={{
                                color: `green`,
                                fontWeight: `bold`,
                              }}
                            >
                              ({cart?.data?.cart_items.map(item => item.quantity).reduce((partial_sum, a) => partial_sum + a, 0)})
                            </span>
                          ) : cart && cart?.fetching ? (
                            <CircularProgress size={15} />
                          ) : width < 600 ? (
                            '(0)'
                          ) : (
                            `Empty`
                          )}
                        </Typography>
                      </IconButton>
                    </ListItem>
                    <ListItem
                      style={{
                        width: 'auto',
                        maxWidth: 125,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        backgroundColor: `green`,
                        position: `relative`,
                        top: 1,
                      }}
                      onClick={() => (cart && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1 ? history.push('/catalog/checkout') : history.push('/catalog/basket'))}
                    >
                      <IconButton edge="end" color="inherit" size={width < 600 ? 'small' : `medium`}>
                        {width < 600 ? (
                          <ShoppingCartCheckoutIcon
                            fontSize={width < 600 ? 'small' : `medium`}
                            style={{
                              marginRight: width < 600 ? 0 : 8,
                              position: `relative`,
                              top: -1,
                              left: 3,
                            }}
                          />
                        ) : (
                          <>
                            <Typography
                              style={{
                                color: `rgb(245 244 244 / 100%)`,
                                fontSize: width < 600 ? 10 : 16,
                                fontWeight: `bold`,
                              }}
                              disabled={!(cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1)}
                            >
                              Checkout
                            </Typography>
                            <NavigateNextIcon fontSize={width < 600 ? 'small' : 'medium'} style={{ marginRight: 8, position: `relative`, top: -1 }} />
                          </>
                        )}
                      </IconButton>
                    </ListItem>
                  </List>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </Box>

        <Container disableGutters={true} className={`container-body`} style={{ position: 'relative', top: isAndroidDevice() ? 25 : 0 }}>
          <div className={'catalog-body'} key={`catalog-body`} style={{ textAlign: 'center', padding: 20 }}>
            <Grid container spacing={0}>
              <Grid item xs={6} md={4} lg={4}>
                <div
                  style={{ position: `relative`, zIndex: 4 }}
                  onClick={() => {
                    history.push('/catalog');
                    setTimeout(function() {
                      window.scrollTo(0, 0);
                    }, 30);
                  }}
                >
                  {page === 'product' && (parseInt(pageid) === window.FIRE_PRODUCT_ID || parseInt(pageid) == window.FIRE_BOOST_PRODUCT_ID) ? (
                    <img className={`logo`} style={{ width: `100%` }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo-fire-transparent.png`} alt={`logo`} />
                  ) : (
                    <img className={`logo`} style={{ width: `100%` }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo.png`} alt={`logo`} />
                  )}
                </div>
              </Grid>
              <Grid item xs={6} md={8} lg={8}>
                <div style={{ zIndex: 3, float: `right`, position: `relative`, top: isMobile ? 0 : 28 }}>
                  {width < 600 ? (
                    <div style={{ padding: 5 }}>
                      <Menu id="mobile-menu" anchorEl={mobileAnchorEl} keepMounted open={Boolean(mobileAnchorEl)} onClose={handleClose}>
                        <MenuItem
                          key={`mobile-home-dropdown`}
                          onClick={() => {
                            history.push('/catalog');
                          }}
                        >
                          <Typography variant="inherit">Home</Typography>
                        </MenuItem>
                        <MenuItem
                          key={`mobile-category-tos-dropdown`}
                          onClick={() => {
                            handleClose();
                            history.push(`/catalog/policy/tos`);
                          }}
                        >
                          <Typography variant="inherit">Terms of Service</Typography>
                        </MenuItem>
                        <MenuItem
                          key={`mobile-category-privacy-dropdown`}
                          onClick={() => {
                            handleClose();
                            history.push(`/catalog/policy/privacy`);
                          }}
                        >
                          <Typography variant="inherit">Privacy Policy</Typography>
                        </MenuItem>
                        <MenuItem
                          key={`mobile-category-return-dropdown`}
                          onClick={() => {
                            handleClose();
                            history.push(`/catalog/policy/return`);
                          }}
                        >
                          <Typography variant="inherit">Return Policy</Typography>
                        </MenuItem>
                        <MenuItem
                          key={`mobile-category-shipping-dropdown`}
                          onClick={() => {
                            handleClose();
                            history.push(`/catalog/policy/shipping`);
                          }}
                        >
                          <Typography variant="inherit">Shipping Policy</Typography>
                        </MenuItem>
                      </Menu>
                      <MenuIcon
                        size={`large`}
                        style={{ fontSize: 50 }}
                        onClick={event => {
                          setMobileAnchorEl(event.currentTarget);
                        }}
                      />
                    </div>
                  ) : (
                    <Breadcrumbs aria-label="breadcrumb">
                      <StyledBreadcrumb
                        component="a"
                        label="Home"
                        icon={<HomeIcon fontSize="small" />}
                        onClick={() => {
                          history.push('/catalog');
                        }}
                      />
                      <StyledBreadcrumb label="Categories" deleteIcon={<ExpandMoreIcon />} onDelete={handleCategoryAnchorClick} />
                      <StyledBreadcrumb label="Legal" deleteIcon={<ExpandMoreIcon />} onDelete={handleTosAnchorClick} />
                      {/*<StyledBreadcrumb*/}
                      {/*  component="a"*/}
                      {/*  label="Terms & Conditions"*/}
                      {/*  onClick={() => {*/}
                      {/*    history.push('/catalog/tos');*/}
                      {/*  }}*/}
                      {/*/>*/}
                    </Breadcrumbs>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <hr />
              </Grid>
              {categoryList && categoryList.data
                ? categoryList.data.map(category => {
                    if (category.url === view) {
                      if (page === 'product') {
                        return (
                          category &&
                          category?.products &&
                          category?.products.map(product => {
                            if (parseInt(product?.id) === window.FIRE_PRODUCT_ID && parseInt(pageid) === window.FIRE_PRODUCT_ID) {
                              return (
                                <FireProductPage
                                  key={`product-${product?.id}`}
                                  history={history}
                                  category={category}
                                  product={product}
                                  cart={cart}
                                  quantityChanged={quantityChanged}
                                  cartItemLoading={cartItemLoading}
                                  width={width}
                                  height={height}
                                  cartItemUpdate={cartItemUpdate}
                                  cartItemCreate={cartItemCreate}
                                  internalProduct={internalProduct}
                                  internalProductVariant={internalProductVariant}
                                  user={user}
                                />
                              );
                            } else if (parseInt(product?.id) === window.FIRE_BOOST_PRODUCT_ID && parseInt(pageid) === window.FIRE_BOOST_PRODUCT_ID) {
                              return (
                                <FireProductBoostPage
                                  key={`product-${product?.id}`}
                                  history={history}
                                  category={category}
                                  product={product}
                                  cart={cart}
                                  quantityChanged={quantityChanged}
                                  cartItemLoading={cartItemLoading}
                                  width={width}
                                  height={height}
                                  cartItemUpdate={cartItemUpdate}
                                  cartItemCreate={cartItemCreate}
                                  internalProduct={internalProduct}
                                  internalProductVariant={internalProductVariant}
                                  user={user}
                                />
                              );
                            } else if (parseInt(pageid) === parseInt(product?.id)) {
                              return (
                                <ProductPage
                                  key={`product-${product?.id}`}
                                  history={history}
                                  category={category}
                                  product={product}
                                  cart={cart}
                                  quantityChanged={quantityChanged}
                                  cartItemLoading={cartItemLoading}
                                  width={width}
                                  cartItemUpdate={cartItemUpdate}
                                  cartItemCreate={cartItemCreate}
                                  internalProduct={internalProduct}
                                  internalProductVariant={internalProductVariant}
                                  user={user}
                                />
                              );
                            }
                          })
                        );
                      } else {
                        return (
                          <CategoryViewPage
                            key={'catalog-view'}
                            history={history}
                            category={category}
                            cart={cart}
                            quantityChanged={quantityChanged}
                            cartItemLoading={cartItemLoading}
                            width={width}
                            cartItemUpdate={cartItemUpdate}
                            cartItemCreate={cartItemCreate}
                            internalProduct={internalProduct}
                            internalProductVariant={internalProductVariant}
                            user={user}
                          />
                        );
                      }
                    }
                  })
                : ''}
              {
                {
                  // Catalog Views
                  undefined: <CatalogPage history={history} categoryList={categoryList} />,
                  // contactus: <CatalogPage history={history} categoryList={categoryList} />,
                  // view: <CategoryViewPage history={history} match={match} width={width} />,
                  saleorders: <OrdersPage token={token} history={history} saleOrders={saleOrders} doGetSaleOrders={doGetSaleOrders} doResetSaleOrder={doResetSaleOrder} width={width} />,
                  saleorder: (
                    <OrdersItemsPage
                      token={token}
                      history={history}
                      match={match}
                      user={user}
                      isMobile={width < 600}
                      saleOrders={saleOrders}
                      doGetSaleOrders={doGetSaleOrders}
                      doCancelSaleOrder={doCancelSaleOrder}
                      saleOrderCancel={saleOrderCancel}
                      saleOrderUpdate={saleOrderUpdate}
                      doResetSaleOrder={doResetSaleOrder}
                      doReadCart={doReadCart}
                      saleOrderRead={saleOrderRead}
                      doReadSaleOrder={doReadSaleOrder}
                      doUpdateSaleOrder={doUpdateSaleOrder}
                      doResetCartUpdate={doResetCartUpdate}
                      doResetCart={doResetCart}
                      doUpdateCart={doUpdateCart}
                      cart={cart}
                      cartUpdate={cartUpdate}
                      width={width}
                      doDuplicateCart={doDuplicateCart}
                    />
                  ),
                  export_orders: <OrdersExportPage history={history} />,
                  customers: (
                    <CustomersPage
                      user={user}
                      token={token}
                      match={match}
                      history={history}
                      doCreateCustomer={doCreateCustomer}
                      customerCreate={customerCreate}
                      doCustomerReset={doCustomerReset}
                      doGetCustomers={doGetCustomers}
                      customers={customers}
                    />
                  ),
                  automation: (
                    <AutomationPage
                      user={user}
                      token={token}
                      match={match}
                      history={history}
                      doGetCustomers={doGetCustomers}
                      customers={customers}
                      doReadNotificationCampaign={doReadNotificationCampaign}
                      doCreateNotificationCampaign={doCreateNotificationCampaign}
                      doGetNotificationCampaigns={doGetNotificationCampaigns}
                      notificationCampaignList={notificationCampaignList}
                      notificationCampaignRead={notificationCampaignRead}
                      notificationCampaignCreate={notificationCampaignCreate}
                      doNotificationCampaignReset={doNotificationCampaignReset}
                      setNotificationCampaignTrigger={setNotificationCampaignTrigger}
                      notificationCampaignTrigger={notificationCampaignTrigger}
                      doReadPurchaseOrderAutomation={doReadPurchaseOrderAutomation}
                      doCreatePurchaseOrderAutomation={doCreatePurchaseOrderAutomation}
                      doGetPurchaseOrderAutomations={doGetPurchaseOrderAutomations}
                      purchaseOrderAutomationList={purchaseOrderAutomationList}
                      purchaseOrderAutomationRead={purchaseOrderAutomationRead}
                      purchaseOrderAutomationCreate={purchaseOrderAutomationCreate}
                      doPurchaseOrderAutomationReset={doPurchaseOrderAutomationReset}
                      setPurchaseOrderAutomationTrigger={setPurchaseOrderAutomationTrigger}
                      purchaseOrderAutomationTrigger={purchaseOrderAutomationTrigger}
                    />
                  ),
                  savedpricing: (
                    <SavedPricingPage
                      user={user}
                      token={token}
                      match={match}
                      history={history}
                      customerRead={customerRead}
                      doReadCustomer={doReadCustomer}
                      doCustomerReset={doCustomerReset}
                      doGetSavedPricing={doGetSavedPricing}
                      doReadSavedPricing={doReadSavedPricing}
                      doUpdateSavedPricing={doUpdateSavedPricing}
                      doUpdateAllSavedPricing={doUpdateAllSavedPricing}
                      doSavedPricingReset={doSavedPricingReset}
                      doUpdatePricingModel={doUpdatePricingModel}
                      savedPricings={savedPricings}
                      savedPricingRead={savedPricingRead}
                      savedPricingUpdate={savedPricingUpdate}
                      width={width}
                    />
                  ),
                  reports: <ReportsPage />,
                  settings: (
                    <SettingsPage
                      user={user}
                      userUpdate={userUpdate}
                      userUpdateDelete={userUpdateDelete}
                      doLogout={doLogout}
                      doUpdateDeleteUser={doUpdateDeleteUser}
                      doUpdateUser={doUpdateUser}
                      doClearUser={doClearUser}
                      token={token}
                      history={history}
                      doClearUpdateUser={doClearUpdateUser}
                    />
                  ),

                  thankyou: (
                    <ThankYouPage
                      match={match}
                      history={history}
                      cart={cart}
                      quantityChanged={quantityChanged}
                      totalItems={totalItems}
                      token={token}
                      user={user}
                      contactCreate={contactCreate}
                      contactUpdate={contactUpdate}
                      contactDelete={contactDelete}
                      contactList={contactList}
                      saleOrderCreate={saleOrderCreate}
                      saleOrderConfirm={saleOrderConfirm}
                      doConfirmSaleOrder={doConfirmSaleOrder}
                      doResetSaleOrder={doResetSaleOrder}
                      doCreateUserContact={doCreateUserContact}
                      doUpdateUserContact={doUpdateUserContact}
                      doDeleteUserContact={doDeleteUserContact}
                      doGetUserContacts={doGetUserContacts}
                      doClearUserContact={doClearUserContact}
                      setDeliverToAddressId={setDeliverToAddressId}
                      deliverToAddressId={deliverToAddressId}
                      setInvoiceToAddressId={setInvoiceToAddressId}
                      invoiceToAddressId={invoiceToAddressId}
                      doClearUserGatewayProfile={doClearUserGatewayProfile}
                      doClearCustomerPaymentMethods={doClearCustomerPaymentMethods}
                    />
                  ),
                  payment: (
                    <PaymentPage
                      match={match}
                      history={history}
                      cart={cart}
                      quantityChanged={quantityChanged}
                      totalItems={totalItems}
                      token={token}
                      user={user}
                      focused={focused}
                      contactCreate={contactCreate}
                      contactUpdate={contactUpdate}
                      contactDelete={contactDelete}
                      contactList={contactList}
                      saleOrderCreate={saleOrderCreate}
                      saleOrderConfirm={saleOrderConfirm}
                      saleOrderVerify={saleOrderVerify}
                      saleOrderRead={saleOrderRead}
                      doReadCart={doReadCart}
                      doResetCart={doResetCart}
                      doConfirmSaleOrder={doConfirmSaleOrder}
                      doVerifySaleOrder={doVerifySaleOrder}
                      doResetSaleOrder={doResetSaleOrder}
                      doCreateUserContact={doCreateUserContact}
                      doUpdateUserContact={doUpdateUserContact}
                      doDeleteUserContact={doDeleteUserContact}
                      doGetUserContacts={doGetUserContacts}
                      doReadSaleOrder={doReadSaleOrder}
                      doClearUserContact={doClearUserContact}
                      setDeliverToAddressId={setDeliverToAddressId}
                      deliverToAddressId={deliverToAddressId}
                      setInvoiceToAddressId={setInvoiceToAddressId}
                      invoiceToAddressId={invoiceToAddressId}
                      width={width}
                      doReadUserGatewayProfile={doReadUserGatewayProfile}
                      doClearUserGatewayProfile={doClearUserGatewayProfile}
                      gateway={gateway}
                      isAndroidDevice={isAndroidDevice}
                      bluepayCustomerPaymentMethodList={bluepayCustomerPaymentMethodList}
                      doGetCustomerPaymentMethods={doGetCustomerPaymentMethods}
                      doClearCustomerPaymentMethods={doClearCustomerPaymentMethods}
                    />
                  ),
                  basket: (
                    <BasketPage
                      token={token}
                      history={history}
                      cart={cart}
                      doDeleteCartItem={doDeleteCartItem}
                      doEmptyCart={doEmptyCart}
                      quantityChanged={quantityChanged}
                      totalItems={totalItems}
                      doReadCart={doReadCart}
                      doResetSaleOrder={doResetSaleOrder}
                      width={width}
                      cartItemUpdate={cartItemUpdate}
                      cartItemCreate={cartItemCreate}
                      user={user}
                      cartItemLoading={cartItemLoading}
                      isAndroidDevice={isAndroidDevice}
                    />
                  ),

                  checkout: (
                    <CheckoutPage
                      match={match}
                      history={history}
                      cart={cart}
                      quantityChanged={quantityChanged}
                      totalItems={totalItems}
                      token={token}
                      user={user}
                      saleOrderCreate={saleOrderCreate}
                      saleOrderUpdate={saleOrderUpdate}
                      contactCreate={contactCreate}
                      contactUpdate={contactUpdate}
                      saleOrderRead={saleOrderRead}
                      contactDelete={contactDelete}
                      contactList={contactList}
                      doCreateUserContact={doCreateUserContact}
                      doUpdateUserContact={doUpdateUserContact}
                      doDeleteUserContact={doDeleteUserContact}
                      doGetUserContacts={doGetUserContacts}
                      doClearUserContact={doClearUserContact}
                      setDeliverToAddressId={setDeliverToAddressId}
                      deliverToAddressId={deliverToAddressId}
                      setInvoiceToAddressId={setInvoiceToAddressId}
                      invoiceToAddressId={invoiceToAddressId}
                      doCreateSaleOrder={doCreateSaleOrder}
                      doUpdateSaleOrder={doUpdateSaleOrder}
                      doResetSaleOrder={doResetSaleOrder}
                      doReadCart={doReadCart}
                      width={width}
                      doClearUserGatewayProfile={doClearUserGatewayProfile}
                      doClearCustomerPaymentMethods={doClearCustomerPaymentMethods}
                      doOverrideCart={doOverrideCart}
                      isAndroidDevice={isAndroidDevice}
                      gateway={gateway}
                    />
                  ),
                  // product: <ProductPage categoryList={categoryList} history={history} cart={cart} quantityChanged={quantityChanged} width={width} />,
                  // tos: <TermsOfServicePage />,
                  policy: <DynamicContent key={page} token={token} contentKey={page} dynamicPageRead={dynamicPageRead} doReadDynamicPage={doReadDynamicPage} doClearDynamicPage={doClearDynamicPage} />,
                  addresses: (
                    <CustomerAddressPage
                      token={token}
                      match={match}
                      history={history}
                      user={user}
                      contactCreate={contactCreate}
                      contactUpdate={contactUpdate}
                      contactDelete={contactDelete}
                      contactList={contactList}
                      doCreateUserContact={doCreateUserContact}
                      doUpdateUserContact={doUpdateUserContact}
                      doDeleteUserContact={doDeleteUserContact}
                      doGetUserContacts={doGetUserContacts}
                      doClearUserContact={doClearUserContact}
                    />
                  ),
                }[view]
              }
            </Grid>

            {/* Contact Us Modal */}
            <ContactUsModal props={props} handleClose={handleContactUsModalClose} isOpen={contactUsModalOpen} width={width} />
            {/* Customer Selector Modal */}
            <CustomerSelectorModal
              props={props}
              handleClose={handleCustomerSelectorModalClose}
              isOpen={customerSelectorModalOpen}
              history={history}
              token={token}
              doGetCustomers={doGetCustomers}
              customers={customers}
              impersonate={impersonate}
              doImpersonate={doImpersonate}
              doClearUserContact={doClearUserContact}
              doResetSaleOrder={doResetSaleOrder}
              doResetCart={doResetCart}
              width={width}
              isAndroidDevice={isAndroidDevice}
            />
            {/* Imported Alpina Footer component. */}
            <AlpinaFooter
              width={width}
              token={token}
              isAndroidDevice={isAndroidDevice}
              dynamicPageRead={dynamicPageRead}
              doReadDynamicPage={doReadDynamicPage}
              doClearDynamicPage={doClearDynamicPage}
            />
          </div>
        </Container>
      </CatalogStyles>
    </div>
  ) : (
    <Verification history={history} token={token} doUpdateUser={doUpdateUser} doMe={doMe} user={user} userUpdate={userUpdate} />
  );
};

// PROPS TYPES
// --------------------------------------------------------
Catalog.defaultProps = {
  view: '',
};

Catalog.propTypes = {
  versionNotification: PropTypes.string,
  focused: PropTypes.bool,
  doMe: PropTypes.func,
  doLogout: PropTypes.func,
  doImpersonate: PropTypes.func,
  doGetCustomers: PropTypes.func,
  doCustomerReset: PropTypes.func,
  doReadCustomer: PropTypes.func,
  doCreateCustomer: PropTypes.func,
  doCreateSaleOrder: PropTypes.func,
  doReadSaleOrder: PropTypes.func,
  doConfirmSaleOrder: PropTypes.func,
  doVerifySaleOrder: PropTypes.func,
  doCancelSaleOrder: PropTypes.func,
  doUpdateSaleOrder: PropTypes.func,
  doGetSaleOrders: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  doCreateCart: PropTypes.func,
  //Saved Pricing
  doGetSavedPricing: PropTypes.func,
  doReadSavedPricing: PropTypes.func,
  doUpdateSavedPricing: PropTypes.func,
  doUpdateAllSavedPricing: PropTypes.func,
  savedPricings: PropTypes.object,
  savedPricingRead: PropTypes.object,
  savedPricingUpdate: PropTypes.object,
  doResetCartUpdate: PropTypes.func,
  doSavedPricingReset: PropTypes.func,
  doUpdatePricingModel: PropTypes.func,

  doUpdateCart: PropTypes.func,
  doDeleteCartItem: PropTypes.func,
  doGetUserContacts: PropTypes.func,
  doCreateUserContact: PropTypes.func,
  doUpdateUserContact: PropTypes.func,
  doDeleteUserContact: PropTypes.func,
  doClearUserContact: PropTypes.func,
  doUpdateUser: PropTypes.func,
  doEmptyCart: PropTypes.func,
  doResetCart: PropTypes.func,
  doReadCart: PropTypes.func,
  doResetCartItem: PropTypes.func,
  quantityChanged: PropTypes.func,
  doResetCategories: PropTypes.func,
  doReadUserGatewayProfile: PropTypes.func,
  doClearUserGatewayProfile: PropTypes.func,
  doOverrideCart: PropTypes.func,
  doGetCategories: PropTypes.func,
  totalItems: PropTypes.func,
  cart: PropTypes.object,
  cartUpdate: PropTypes.object,
  customers: PropTypes.object,
  customerRead: PropTypes.object,
  saleOrderCreate: PropTypes.object,
  saleOrderConfirm: PropTypes.object,
  saleOrderVerify: PropTypes.object,
  saleOrderCancel: PropTypes.object,
  saleOrderUpdate: PropTypes.object,
  saleOrderRead: PropTypes.object,
  saleOrders: PropTypes.object,
  gateway: PropTypes.object,
  contactList: PropTypes.object,
  contactCreate: PropTypes.object,
  contactUpdate: PropTypes.object,
  contactDelete: PropTypes.object,
  categoryList: PropTypes.object,
  customerCreate: PropTypes.object,
  cartItemCreate: PropTypes.object,
  cartItemUpdate: PropTypes.object,
  internalProduct: PropTypes.object,
  internalProductVariant: PropTypes.object,
  user: PropTypes.object,
  impersonate: PropTypes.object,
  userUpdate: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
  view: PropTypes.string,
  cartItemLoading: PropTypes.bool,
  doClearDynamicPage: PropTypes.func,
  doReadDynamicPage: PropTypes.func,
  dynamicPageRead: PropTypes.object,
  //PurchaseOrderAutomation
  doCreatePurchaseOrderAutomation: PropTypes.func,
  doGetPurchaseOrderAutomations: PropTypes.func,
  doReadPurchaseOrderAutomation: PropTypes.func,
  doPurchaseOrderAutomationReset: PropTypes.func,
  purchaseOrderAutomationList: PropTypes.object,
  purchaseOrderAutomationRead: PropTypes.object,
  purchaseOrderAutomationCreate: PropTypes.object,

  purchaseOrderAutomationTrigger: PropTypes.bool,
  setPurchaseOrderAutomationTrigger: PropTypes.func,

  doCreateNotificationCampaign: PropTypes.func,
  doReadNotificationCampaign: PropTypes.func,
  doGetNotificationCampaigns: PropTypes.func,
  doNotificationCampaignReset: PropTypes.func,
  notificationCampaignList: PropTypes.object,
  notificationCampaignRead: PropTypes.object,
  notificationCampaignCreate: PropTypes.object,
  notificationCampaignTrigger: PropTypes.bool,
  setNotificationCampaignTrigger: PropTypes.func,
  bluepayCustomerPaymentMethodList: PropTypes.object,
  doGetCustomerPaymentMethods: PropTypes.func,
  doClearCustomerPaymentMethods: PropTypes.func,
  doUpdateDeleteUser: PropTypes.func,
  userUpdateDelete: PropTypes.object,
  doClearUser: PropTypes.func,
  doClearUpdateUser: PropTypes.func,
};

// EXPORTS
// --------------------------------------------------------
// Export
export default Catalog;
