// IMPORTS
// --------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccessDenied from '../../views/Errors/AccessDenied';
import NotFound from '../../views/Errors/NotFound';
import Maintenance from '../../views/Errors/Maintenance';

// Operations

// ERROR CONTAINER
// --------------------------------------------------------
/**
 * ErrorContainer
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
const ErrorContainer = props => {
  const { status, history } = props;
  return {
    undefined: <NotFound history={history} />,
    404: <NotFound history={history} />,
    403: <AccessDenied history={history} />,
    503: <Maintenance history={history} />,
  }[status];
};

// REDUX
// --------------------------------------------------------
/**
 * Maps Redux State To Component
 * @param {Object} state main redux state
 * @returns {Object} Redux Reducer States
 */
const mapStateToProps = state => ({});

/**
 * Maps Redux Actions To Component
 * @param {*} dispatch main redux dispatch event
 * @returns {{}} Redux Dispatch
 */
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

// PROPS TYPES
// --------------------------------------------------------
ErrorContainer.propTypes = {
  doLogin: PropTypes.func,
  doLoginReset: PropTypes.func,
  history: PropTypes.object,
  status: PropTypes.number,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer);
