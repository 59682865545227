export const LIST_SALEORDER_REQUEST = 'LIST_SALEORDER_REQUEST';
export const LIST_SALEORDER_SUCCESS = 'LIST_SALEORDER_SUCCESS';
export const LIST_SALEORDER_FAILURE = 'LIST_SALEORDER_FAILURE';
export const UPDATE_SALEORDER_REQUEST = 'UPDATE_SALEORDER_REQUEST';
export const UPDATE_SALEORDER_SUCCESS = 'UPDATE_SALEORDER_SUCCESS';
export const UPDATE_SALEORDER_FAILURE = 'UPDATE_SALEORDER_FAILURE';
export const CREATE_SALEORDER_REQUEST = 'CREATE_SALEORDER_REQUEST';
export const CREATE_SALEORDER_SUCCESS = 'CREATE_SALEORDER_SUCCESS';
export const CREATE_SALEORDER_FAILURE = 'CREATE_SALEORDER_FAILURE';
export const READ_SALEORDER_REQUEST = 'READ_SALEORDER_REQUEST';
export const READ_SALEORDER_SUCCESS = 'READ_SALEORDER_SUCCESS';
export const READ_SALEORDER_FAILURE = 'READ_SALEORDER_FAILURE';
export const DELETE_SALEORDER_REQUEST = 'DELETE_SALEORDER_REQUEST';
export const DELETE_SALEORDER_SUCCESS = 'DELETE_SALEORDER_SUCCESS';
export const DELETE_SALEORDER_FAILURE = 'DELETE_SALEORDER_FAILURE';
export const CONFIRM_SALEORDER_REQUEST = 'CONFIRM_SALEORDER_REQUEST';
export const CONFIRM_SALEORDER_SUCCESS = 'CONFIRM_SALEORDER_SUCCESS';
export const CONFIRM_SALEORDER_FAILURE = 'CONFIRM_SALEORDER_FAILURE';
export const VERIFY_SALEORDER_REQUEST = 'VERIFY_SALEORDER_REQUEST';
export const VERIFY_SALEORDER_SUCCESS = 'VERIFY_SALEORDER_SUCCESS';
export const VERIFY_SALEORDER_FAILURE = 'VERIFY_SALEORDER_FAILURE';
export const CANCEL_SALEORDER_REQUEST = 'CANCEL_SALEORDER_REQUEST';
export const CANCEL_SALEORDER_SUCCESS = 'CANCEL_SALEORDER_SUCCESS';
export const CANCEL_SALEORDER_FAILURE = 'CANCEL_SALEORDER_FAILURE';
export const SALEORDER_RESET = 'SALEORDER_RESET';
export const SALEORDER_READ_RESET = 'SALEORDER_READ_RESET';
export const SALEORDER_LIST_RESET = 'SALEORDER_LIST_RESET';
export const SALEORDER_CANCEL_RESET = 'SALEORDER_CANCEL_RESET';
export const SALEORDER_CREATE_RESET = 'SALEORDER_CREATE_RESET';
export const SALEORDER_CONFIRM_RESET = 'SALEORDER_CONFIRM_RESET';
