// Imports
import axios from 'axios';

// Export actions
/**
 * Get a list of User Gateway Profiles
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getUserGatewayProfiles = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/usergatewayprofiles${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a specific User Gateway Profile
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const readUserGatewayProfile = ({ token }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/usergatewayprofile`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a list of User Payment Profiles
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getUserPaymentProfiles = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/userpaymentprofiles${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a specific User Payment Profile
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const readUserPaymentProfile = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/userpaymentprofile/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
