// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import EnterAdStyles from './styles';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FireLoadingButton from '../FireLoadingButton';

/**
 * ENTER AD COMPONENT
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
// eslint-disable-next-line no-unused-vars
const EnterAd = props => {
  const { token, history, match, width, height, enter, setEnter, doMe, doUpdateUser, userUpdate, user } = props;
  const [verificationRequest, setVerificationRequest] = useState(false);
  const [resetRequest, setResetRequest] = useState(false);
  useEffect(() => {
    if (resetRequest && !userUpdate?.fetching) {
      setResetRequest(false);
      doMe({ token });
    }
  }, [resetRequest, token, userUpdate]);

  useEffect(() => {
    if (verificationRequest && user) {
      setVerificationRequest(false);
      let id = user?.id;
      let payload = {};
      payload['ad_viewed'] = true;
      doUpdateUser({ token, id, payload });
      setResetRequest(true);
    }
  }, [verificationRequest, user, token]);

  return (
    <EnterAdStyles style={{ overflowY: `scroll`, display: enter ? `none` : `block`, zIndex: enter ? 0 : 999999, height: height }}>
      {/*<div style={{ position: `relative` }}>*/}
      {/*  <canvas style={{ position: `absolute` }} id="canvas" />*/}
      {/*</div>*/}

      <Container disableGutters={true} className={`container-body`} style={{ position: 'relative', top: '0%' }}>
        <div style={{ color: '#ffffff', fontSize: 60, textAlign: `center`, marginBottom: 15, paddingTop: 15 }} className={`bebas-neue-regular`}>
          Now In Stock
        </div>
        <div className={'enter-body'} key={`enter-body`} style={{ textAlign: 'center', padding: 20, paddingTop: 0 }}>
          <div style={{ position: `relative` }}>
            <div className={`img`}>
              <video
                className="videoTag"
                style={{ width: width > 600 ? 350 - 50 : 300 }}
                autoPlay
                loop
                playsInline
                muted={true} // Control the muted property
                onClick={() => {}} // Optional: Toggle mute on click
              >
                <source src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/fire-boost-ad.mp4`} type="video/mp4" />
              </video>
            </div>
            {/* Center the button in the middle of the image */}
            <div style={{ zIndex: 99999999, marginTop: 30, height: `100%` }}>
              <FireLoadingButton height={`100%`} width={width} setEnter={setEnter} enter={enter} setVerificationRequest={setVerificationRequest} />
              {/*<LoadingButton*/}
              {/*  loading={loadingEnter}*/}
              {/*  style={{ fontSize: width < 431 ? 15 : 25 }}*/}
              {/*  className={`ad-btn`}*/}
              {/*  loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}*/}
              {/*  variant="contained"*/}
              {/*  color="primary"*/}
              {/*  onClick={() => {*/}
              {/*    setEnter(true);*/}
              {/*  }}*/}
              {/*  endIcon={<ArrowRightIcon />}*/}
              {/*>*/}
              {/*  ENTER NOW*/}
              {/*</LoadingButton>*/}
            </div>
          </div>
        </div>
      </Container>
    </EnterAdStyles>
  );
};

// PROPS TYPES
// --------------------------------------------------------
EnterAd.defaultProps = {
  view: '',
};

EnterAd.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  userUpdate: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
  doUpdateUser: PropTypes.func,
  doMe: PropTypes.func,
};

// EXPORTS
// --------------------------------------------------------
// Export
export default EnterAd;
