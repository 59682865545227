import Grid from '@mui/material/Grid';
import React from 'react';
import Chip from '@mui/material/Chip';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableCell, TextField, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import PropTypes from 'prop-types';

const ProductPage = props => {
  const { category, product, history, cart, quantityChanged, width, cartItemUpdate, cartItemCreate, internalProduct, internalProductVariant, user, cartItemLoading } = props;
  const [productFilter, setProductFilter] = React.useState('');
  return (
    <Grid container spacing={5} pt={5} key={`table-product-${product.id}`}>
      <Grid item xs={12} md={4}>
        <Grid container>
          <Grid item xs={12}>
            <div className={`img`}>
              <Zoom>
                <img
                  style={{ width: `100%`, width: 256, height: 256 }}
                  alt={product.product_name}
                  src={window.S3_ENABLED ? `https://${window.AWS_BUCKET}.s3.amazonaws.com${product?.asset?.url}` : `${window.API_URL}/asset/${product?.asset?.id}/file`}
                />
              </Zoom>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography variant="h1" style={{ fontSize: 18, fontWeight: `bold` }}>
                {product.product_name}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          {/*<Grid item xs={12}>*/}
          {/*  <div style={{ float: 'left', padding: 1, width: '49%' }}>*/}
          {/*    <Typography align="left" variant="h5" sx={{ fontWeight: 'bold' }}>*/}
          {/*      {product.product_name}*/}
          {/*    </Typography>*/}
          {/*    <Typography align="left" sx={{ fontSize: 13, fontWeight: 'bold', padding: 0.25 }}>*/}
          {/*      {product.product_variants.map(item => item.variant_name + ' (' + item.attribute_value + ')')}*/}
          {/*    </Typography>*/}
          {/*    <Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>*/}
          {/*      Types: {product.product_variants.length}*/}
          {/*    </Typography>*/}
          {/*    <Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>*/}
          {/*      Unit Price: ${product.unit_price}*/}
          {/*    </Typography>*/}
          {/*    /!*<Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>*!/*/}
          {/*    /!*  MSRP: ${item.msrp}*!/*/}
          {/*    /!*</Typography>*!/*/}
          {/*  </div>*/}
          {/*</Grid>*/}

          <Grid item xs={12}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              label={'Search'}
              onChange={event => {
                setProductFilter(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody style={{ overflowX: `hidden` }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: `bold`,
                        minWidth: 100,
                        maxWidth: 150,
                        width: '100%',
                        padding: 15,
                      }}
                    >
                      Code
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: `bold`,
                        minWidth: 150,
                        maxWidth: 300,
                        padding: 15,
                        width: '100%',
                        display: width < 600 ? 'none' : 'table-cell',
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: `bold`, minWidth: 50, maxWidth: 50, padding: 15 }}>
                      {width < 600 ? `Price` : `Unit $`}
                    </TableCell>
                    {/*<TableCell*/}
                    {/*  align="left"*/}
                    {/*  style={{*/}
                    {/*    fontWeight: `bold`,*/}
                    {/*    width: '100%',*/}
                    {/*    maxWidth: 50,*/}
                    {/*    padding: 15,*/}
                    {/*    display: width < 600 ? 'none' : 'table-cell',*/}
                    {/*  }}*/}
                    {/*/>*/}
                    {product?.multiple ? (
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: `bold`,
                          width: '100%',
                          minWidth: 50,
                          maxWidth: 150,
                          padding: 15,
                          display: width < 600 ? 'none' : 'table-cell',
                        }}
                      >
                        Multiple
                      </TableCell>
                    ) : (
                      ''
                    )}
                    <TableCell align="center" style={{ fontWeight: `bold`, width: '100%', minWidth: 50, maxWidth: 150, padding: 15 }}>
                      Qty
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: `bold`,
                        width: '100%',
                        maxWidth: 100,
                        padding: 15,
                        display: width < 600 ? 'none' : 'table-cell',
                      }}
                    >
                      Stock
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  {product &&
                    product?.product_variants
                      // .sort((a, b) => b.quantity - a.quantity)
                      .sort((a, b) => {
                        if (category?.id === window.SORT_CATEGORY) {
                          if (a.quantity === b.quantity) {
                            return 0;
                          }
                          if (a.quantity === 0) {
                            return 1;
                          }
                          if (b.quantity === 0) {
                            return -1;
                          }
                          return a.quantity - b.quantity;
                        } else {
                          return a.product_code - b.product_code;
                        }
                      })
                      .filter(
                        productVariant =>
                          productFilter === '' ||
                          (productFilter !== '' && productVariant.variant_name.toLowerCase().includes(productFilter.toLowerCase())) ||
                          // productVariant.variant_code.includes(productFilter.toLowerCase()) ||
                          productVariant.product_attributes.some(attribute => attribute.attribute_value.toLowerCase().includes(productFilter.toLowerCase())),
                      )
                      .map(productVariant => {
                        return (
                          <TableRow key={`product-attribute-${productVariant.id}`}>
                            <TableCell
                              align="left"
                              style={{
                                minWidth: width < 600 ? 75 : 100,
                                maxWidth: 100,
                                width: `100%`,
                                paddingRight: 0,
                              }}
                            >
                              {width < 600 ? (
                                <div>
                                  <div style={{ marginBottom: 3 }}>{productVariant.variant_code}</div>
                                  <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                                    {productVariant.product_attributes.map(attribute => {
                                      return <Chip key={`attribute-${attribute?.id}`} label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                                    })}
                                  </div>
                                  {product?.multiple ? (
                                    <div style={{ fontWeight: `bold`, marginBottom: 5 }}>
                                      Multiple: {` `}
                                      <Tooltip title={`You need to purchase this product in multiples of ${product?.multiple_amount}`} placement="top">
                                        <Chip label={`${product?.multiple_amount}`} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    ``
                                  )}
                                  <div style={{ fontWeight: `bold`, marginBottom: 5 }}>
                                    Stock:{' '}
                                    {productVariant && productVariant?.quantity >= 1 ? (
                                      <span style={{ color: `green` }}>{productVariant?.quantity}</span>
                                    ) : (
                                      <span style={{ color: `red` }}>Not In Stock</span>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                productVariant.variant_code
                              )}
                              {}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                minWidth: 150,
                                maxWidth: 200,

                                width: '100%',
                                display: width < 600 ? 'none' : 'table-cell',
                              }}
                            >
                              {productVariant.product_attributes.map(attribute => {
                                return <Chip key={`attribute-${attribute.id}`} label={attribute.attribute_value} style={{ marginRight: 5 }} size={`small`} />;
                              })}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                width: '100%',
                                minWidth: 30,
                                maxWidth: 50,
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                            >
                              {category?.price_override?.active === 1 ? (
                                <span>
                                  <span style={{ color: `red` }}>
                                    <s>{productVariant.unit_price}</s>
                                  </span>
                                  <span> ${product?.lowest_price?.lowest_total}</span>
                                </span>
                              ) : product?.customer_saved_pricing && product?.customer_saved_pricing?.item_total !== product.unit_price ? (
                                <span>
                                  <span style={{ color: `red` }}>
                                    <s>{productVariant.unit_price}</s>
                                  </span>
                                  <span> ${product?.customer_saved_pricing?.item_total}</span>
                                </span>
                              ) : (
                                <span>${productVariant.unit_price}</span>
                              )}
                            </TableCell>
                            {/*{productVariant?.last_saved_price && productVariant?.last_saved_price?.item_total !== productVariant.unit_price ? (*/}
                            {/*  <TableCell align="left" style={{ width: 50, maxWidth: 50 }}>*/}
                            {/*    <Chip*/}
                            {/*      color={`secondary`}*/}
                            {/*      // variant={`outlined`}*/}
                            {/*      label={`${Math.round((1 - productVariant?.last_saved_price?.item_total / productVariant.unit_price) * 100)}% DISCOUNT`}*/}
                            {/*      // color="success"*/}
                            {/*    />*/}
                            {/*  </TableCell>*/}
                            {/*) : (*/}
                            {/*  <TableCell align="left" style={{ width: '100%', maxWidth: 50, display: width < 600 ? 'none' : 'table-cell' }} />*/}
                            {/*)}*/}
                            {/*<TableCell align="left">{productVariant?.last_saved_price ? productVariant?.last_saved_price?.item_total : productVariant.unit_price}</TableCell>*/}
                            {product?.multiple ? (
                              <TableCell
                                align="center"
                                style={{
                                  width: '100%',
                                  minWidth: 50,
                                  maxWidth: 150,
                                  display: width < 600 ? 'none' : 'table-cell',
                                }}
                              >
                                {product.multiple ? (
                                  <Tooltip title={`You need to purchase this product in multiples of ${product?.multiple_amount}`} placement="top">
                                    <Chip label={`${product?.multiple_amount}`} size={`small`} />
                                  </Tooltip>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            ) : (
                              ''
                            )}
                            <TableCell align="center" style={{ width: '100%', minWidth: 50, maxWidth: 150 }}>
                              <TextField
                                size={`small`}
                                className={`center-input`}
                                style={{ textAlign: `center` }}
                                inputProps={{ style: { width: width < 600 ? (width < 400 ? `50px` : `75px`) : `100px` } }}
                                disabled={
                                  productVariant?.quantity <= 0 || productVariant !== internalProductVariant ? productVariant?.quantity === 0 || cartItemLoading || user?.role?.name === 'sales' : false
                                }
                                // disabled={
                                //   productVariant?.quantity <= 0 || productVariant !== internalProductVariant
                                //     ? productVariant?.quantity === 0 || cartItemUpdate?.fetching || cartItemCreate?.fetching || cart?.fetching || cartItemLoading || user?.role?.name === 'sales'
                                //     : false
                                // }
                                // disabled={
                                //   productVariant !== internalProductVariant
                                //     ? productVariant?.quantity === 0 || cartItemUpdate?.fetching || cartItemCreate?.fetching || cart?.fetching || cartItemLoading || user?.role?.name === 'sales'
                                //     : false
                                // }
                                defaultValue={
                                  cart &&
                                  cart?.data &&
                                  cart?.data?.cart_items &&
                                  cart?.data?.cart_items?.length >= 1 &&
                                  cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id)
                                    ? cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id).quantity
                                    : ``
                                }
                                onChange={event => quantityChanged(event, product, productVariant)}
                                // onBlur={event => quantityChanged(event, product, productVariant)}
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                width: '100%',
                                minWidth: 80,
                                maxWidth: 100,
                                display: width < 600 ? 'none' : 'table-cell',
                              }}
                            >
                              <div style={{ fontWeight: `bold` }}>
                                {productVariant && productVariant?.quantity >= 1 ? (
                                  <span style={{ color: `green` }}>{productVariant?.quantity}</span>
                                ) : (
                                  <span style={{ color: `red` }}>Not In Stock</span>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  // return (
  //   <Grid container spacing={2}>
  //     {loadedCategoryItems
  //       .filter(p => p.id === Number(page))
  //       .map(item => (
  //         <Grid key={item.id} item lg={12}>
  //           <div style={{ float: 'left', padding: 1, width: '49%' }}>
  //             <Typography align="left" variant="h5" sx={{ fontWeight: 'bold' }}>
  //               {item.name}
  //             </Typography>
  //             <Typography align="left" sx={{ fontSize: 13, fontWeight: 'bold', padding: 0.25 }}>
  //               {loadedCategories[item.categoryId].name}
  //             </Typography>
  //             <Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>
  //               Flavors: {item.totalFlavors}
  //             </Typography>
  //             <Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>
  //               Unit Price: ${item.unitPrice}
  //             </Typography>
  //             <Typography align="left" sx={{ fontWeight: 'bold', padding: 0.25 }}>
  //               MSRP: ${item.msrp}
  //             </Typography>
  //           </div>
  //
  //           <div className={`img`} style={{ float: 'left', width: '50%' }}>
  //             {item ? (
  //               <Zoom>
  //                 <img
  //                   style={{
  //                     width: 400,
  //                     height: 400,
  //                   }}
  //                   alt={item.name}
  //                   src={item.image}
  //                 />
  //               </Zoom>
  //             ) : (
  //               <Skeleton variant="rectangular" width={400} height={400} />
  //             )}
  //           </div>
  //
  //           <TableContainer component={Paper} sx={{ paddingBottom: 2, marginBottom: 2 }}>
  //             <Table size="large" aria-label="simple table">
  //               <TableHead>
  //                 <TableRow>
  //                   <TableCell align="left" style={{ fontWeight: `bold` }}>
  //                     Code
  //                   </TableCell>
  //                   <TableCell align="left" style={{ fontWeight: `bold` }}>
  //                     Flavor
  //                   </TableCell>
  //                   <TableCell align="left" style={{ fontWeight: `bold` }}>
  //                     Unit $
  //                   </TableCell>
  //                   <TableCell align="center" style={{ fontWeight: `bold` }}>
  //                     Qty
  //                   </TableCell>
  //                   <TableCell align="center" style={{ fontWeight: `bold` }}>
  //                     Stock
  //                   </TableCell>
  //                 </TableRow>
  //               </TableHead>
  //               {product &&
  //                 product?.product_variants &&
  //                 ObjectUtil.groupBy(product?.product_variants, 'variant_name').map(productAttibuteType => {
  //                   return (
  //                     <TableBody key={`product-type-${productAttibuteType.key}`}>
  //                       <TableRow>
  //                         <TableCell align="left" style={{ fontWeight: `bold` }}>
  //                           Code
  //                         </TableCell>
  //                         <TableCell align="left" style={{ fontWeight: `bold` }}>
  //                           {productAttibuteType.key}
  //                         </TableCell>
  //                         <TableCell align="left" style={{ fontWeight: `bold` }}>
  //                           Unit $
  //                         </TableCell>
  //                         <TableCell align="left" style={{ fontWeight: `bold` }}></TableCell>
  //                         <TableCell align="center" style={{ fontWeight: `bold` }}>
  //                           Qty
  //                         </TableCell>
  //                         <TableCell align="right" style={{ fontWeight: `bold` }}>
  //                           Stock
  //                         </TableCell>
  //                       </TableRow>
  //                       {product &&
  //                         product?.product_variants
  //                           .filter(item => item.variant_name === productAttibuteType.key)
  //                           .map(productVariant => {
  //                             return (
  //                               <TableRow key={`product-attribute-${productVariant.id}`}>
  //                                 <TableCell align="left">{productVariant.variant_code}</TableCell>
  //                                 <TableCell align="left">{productVariant.attribute_value}</TableCell>
  //                                 <TableCell align="left">
  //                                   {productVariant?.last_saved_price && productVariant?.last_saved_price?.item_total !== productVariant.unit_price ? (
  //                                     <span>
  //                                       <span style={{ color: `red` }}>
  //                                         <s>{productVariant.unit_price}</s>
  //                                       </span>
  //                                       <span> {productVariant?.last_saved_price?.item_total}</span>
  //                                     </span>
  //                                   ) : (
  //                                     <span>{productVariant.unit_price}</span>
  //                                   )}
  //                                 </TableCell>
  //                                 {productVariant?.last_saved_price && productVariant?.last_saved_price?.item_total !== productVariant.unit_price ? (
  //                                   <TableCell align="left">
  //                                     <Chip
  //                                       color={`secondary`}
  //                                       // variant={`outlined`}
  //                                       label={`${Math.round((1 - productVariant?.last_saved_price?.item_total / productVariant.unit_price) * 100)}% DISCOUNT`}
  //                                       // color="success"
  //                                     />
  //                                   </TableCell>
  //                                 ) : (
  //                                   <TableCell align="left" />
  //                                 )}
  //                                 {/*<TableCell align="left">{productVariant?.last_saved_price ? productVariant?.last_saved_price?.item_total : productVariant.unit_price}</TableCell>*/}
  //                                 <TableCell align="center" sx={{ width: '10%' }}>
  //                                   <TextField
  //                                     size={`small`}
  //                                     className={`center-input`}
  //                                     style={{ textAlign: `center` }}
  //                                     disabled={productVariant?.quantity === 1}
  //                                     defaultValue={
  //                                       cart &&
  //                                       cart?.data &&
  //                                       cart?.data?.cart_items &&
  //                                       cart?.data?.cart_items?.length >= 1 &&
  //                                       cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id)
  //                                         ? cart?.data?.cart_items.find(item => item.product_id === product.id && item.product_variant_id === productVariant.id).quantity
  //                                         : ``
  //                                     }
  //                                     onChange={event => quantityChanged(event, product, productVariant)}
  //                                     // onBlur={event => quantityChanged(event, product, productVariant)}
  //                                     variant="outlined"
  //                                   />
  //                                 </TableCell>
  //                                 <TableCell align="right">
  //                                   {productVariant && productVariant?.quantity >= 1 ? <Chip label={'In'} color="success" /> : <Chip label={'Out Of Stock'} color="error" />}
  //                                 </TableCell>
  //                               </TableRow>
  //                             );
  //                           })}
  //                     </TableBody>
  //                   );
  //                 })}
  //             </Table>
  //           </TableContainer>
  //         </Grid>
  //       ))}
  //   </Grid>
  // );
};

ProductPage.propTypes = {
  user: PropTypes.object,
  cart: PropTypes.object,
  category: PropTypes.object,
  product: PropTypes.object,
  history: PropTypes.object,
  cartItemCreate: PropTypes.object,
  cartItemUpdate: PropTypes.object,
  internalProduct: PropTypes.object,
  internalProductVariant: PropTypes.object,
  quantityChanged: PropTypes.func,
  width: PropTypes.number,
  cartItemLoading: PropTypes.bool,
};
export default ProductPage;
