// Imports
import styled from 'styled-components';

// Main Component
const PolicyStyles = styled.div`
  width: 100%;
  .legal-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .legal-div {
    margin-bottom: 10px;
  }
`;

// Export
export default PolicyStyles;
