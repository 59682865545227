// Imports
import axios from 'axios';

// Export actions
/**
 * Main request to get all user contacts
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getUserContacts = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/usercontacts${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Main request to get a single user contact
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const getUserContact = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/usercontact/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Main request to create new user contact
 * @param {Object} { token: string, payload: {company_name: string, phone: string, address_line_1: string, address_line_2: string, city: string, state: string, zipcode: string} }
 * @return {AxiosPromise} Http request
 */
export const createUserContact = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/usercontact`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Main request to update existing user contact
 * @param {Object} { token: string, id: number, payload: { company_name: string, phone: string, address_line_1: string, address_line_2: string, city: string, state: string, zipcode: string } }
 * @return {AxiosPromise} Http request
 */
export const updateUserContact = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/usercontact/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete user contact
 * @param {Object} { token: string, id: number }
 * @return {Promise} axios promise
 */
export const deleteUserContact = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/usercontact/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
