import Grid from '@mui/material/Grid';
import React, { useEffect, useRef, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Alert, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import FormatUtil from '../../../utils/FormatUtil';

import FormControl from '@mui/material/FormControl';
import SPaymentForm from '../../../components/SPaymentForm';
import BluePaymentForm from '../../../components/BluePaymentForm';
import AuthPaymentForm from '../../../components/AuthPaymentForm';
import { SALEORDER_CONFIRM_RESET, SALEORDER_LIST_RESET, SALEORDER_RESET } from '../../../containers/SaleOrder/types';
import Chip from '@mui/material/Chip';
import Swal from 'sweetalert2';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { getCustomerPaymentMethods } from '../../../containers/Bluepay/actions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { doClearCustomerPaymentMethods } from '../../../containers/Bluepay/operations';

const PaymentPage = props => {
  const {
    cart,
    quantityChanged,
    totalItems,
    token,
    match,
    user,
    contactList,
    contactDelete,
    contactCreate,
    contactUpdate,
    saleOrderRead,
    saleOrderCreate,
    saleOrderConfirm,
    saleOrderVerify,
    doGetUserContacts,
    doCreateUserContact,
    doClearUserContact,
    doUpdateUserContact,
    doDeleteUserContact,
    doConfirmSaleOrder,
    doResetCart,
    doVerifySaleOrder,
    doResetSaleOrder,
    doReadSaleOrder,
    doReadCart,
    history,
    deliverToAddressId,
    invoiceToAddressId,
    setDeliverToAddressId,
    setInvoiceToAddressId,
    width,
    gateway,
    doReadUserGatewayProfile,
    doClearUserGatewayProfile,
    doGetCustomerPaymentMethods,
    doClearCustomerPaymentMethods,
    bluepayCustomerPaymentMethodList,
    isAndroidDevice,
  } = props;

  const [verificationCheck, setVerificationCheck] = useState(false);
  const ref = useRef();
  const timer = useRef(null);
  const mounted = useRef(false);

  const [accountHolderName, setAccountHolderName] = useState(user?.company_name);
  const [initBlue, setInitBlue] = React.useState(false);
  const [focused, setFocused] = useState(false);
  const [addressObj, setAddressObj] = useState(null);
  const [paymentType, setPaymentType] = React.useState('card');

  const [paymentIntervalLoop, setPaymentIntervalLoop] = React.useState(0);

  const [loadingCard, setLoadingCard] = React.useState(false);

  const onFocusFunction = () => {
    // do whatever when focus is gained
    setFocused(true);
  };

  const onBlurFunction = () => {
    // do whatever when focus is lost
    setFocused(false);
  };

  useEffect(() => {
    onFocusFunction();

    window.addEventListener('focus', onFocusFunction);
    window.addEventListener('blur', onBlurFunction);

    return () => {
      onBlurFunction();

      window.removeEventListener('focus', onFocusFunction);
      window.removeEventListener('blur', onBlurFunction);
    };
  }, []);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      let id = setInterval(() => {
        savedCallback.current();
      }, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

  useInterval(() => {
    if (!mounted.current) {
      // console.log('[PAYMENT] NOT MOUNTED');
      return;
    }
    if (!focused) {
      // console.log('[PAYMENT] NOT FOCUSED');
      return;
    }

    if (saleOrderConfirm) {
      if (saleOrderConfirm.fetching) {
        return;
      }
      if (saleOrderConfirm.data) {
        return;
      }
    }

    setPaymentIntervalLoop(paymentIntervalLoop + 1);
    // console.log('PAYMENT LOOP: ' + paymentIntervalLoop);
    if (paymentIntervalLoop >= window.SALEORDER_VERIFY_INTERVAL_LOOP) {
      // console.log('We are doing the payment loop');
      let payload = {
        sale_order_id: saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id,
      };
      //TODO:: TAKING OUT THE VERIFY ORDER
      // doVerifySaleOrder({ token, payload });
      setPaymentIntervalLoop(0);
    }
    // console.log('TICK TIMER');
    // if (!mounted.current) {
    //   console.log('MOUNT IS NOT CURRENT');
    //   clearInterval(timer.current);
    // }
    /*    if (!focused) {
      console.log('NOT FOCUSED');
      clearInterval(timer.current);
    }*/
    // if (saleOrderConfirm) {
    //   if (saleOrderConfirm.fetching) {
    //     clearInterval(timer.current);
    //   }
    //   if (saleOrderConfirm.data) {
    //     clearInterval(timer.current);
    //   }
    // }
    //
    // let payload = {
    //   sale_order_id: saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id,
    // };
    // doVerifySaleOrder({ token, payload });
    // let id = saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id;
    // doReadSaleOrder({ token, id });
  }, 1000);

  useEffect(() => {
    if ((!saleOrderCreate.fetching && saleOrderCreate?.data) || (!saleOrderRead?.fetching && saleOrderRead?.data)) {
      doResetSaleOrder(SALEORDER_LIST_RESET);
      doClearUserGatewayProfile();
      console.log('CLEARING PAYMENT METHODS');
      doClearCustomerPaymentMethods();
    }
  }, [doResetSaleOrder, doClearUserGatewayProfile, doClearCustomerPaymentMethods]);

  useEffect(() => {
    if (!gateway.fetching && !gateway.data && !gateway.errors) {
      doReadUserGatewayProfile({ token });
    }
  }, [gateway, token]);

  useEffect(() => {
    if (!bluepayCustomerPaymentMethodList.fetching && !bluepayCustomerPaymentMethodList.data && !bluepayCustomerPaymentMethodList.errors) {
      doGetCustomerPaymentMethods({ token });
    }
  }, [bluepayCustomerPaymentMethodList, token]);

  useEffect(() => {
    if (!saleOrderConfirm.fetching && saleOrderConfirm.errors) {
      let output = '';
      for (let i = 0; i < saleOrderConfirm.errors.length; i++) {
        if (saleOrderConfirm.errors[i] && saleOrderConfirm.errors[i].text && saleOrderConfirm.errors[i].code) {
          output += '[' + saleOrderConfirm.errors[i].code + '] ' + saleOrderConfirm.errors[i].text + '';
        }
      }
      if (output !== '') {
        Swal.fire({
          icon: 'error',
          text: output,
        });
        setTimeout(() => doResetSaleOrder(SALEORDER_CONFIRM_RESET), 5000);
        for (let i = 0; i < saleOrderConfirm.errors.length; i++) {
          if (saleOrderConfirm.errors[i] && saleOrderConfirm.errors[i].text && saleOrderConfirm.errors[i].code) {
            if (saleOrderConfirm.errors[i].code === 'CANCELED') {
              doResetCart();

              doResetSaleOrder(SALEORDER_RESET);
              history.push('/catalog');
            }
          }
          if (saleOrderConfirm.errors[i] && saleOrderConfirm.errors[i].text && saleOrderConfirm.errors[i].code) {
            if (saleOrderConfirm.errors[i].code === 'CART_UPDATE') {
              let id = cart?.data?.id;
              doResetSaleOrder(SALEORDER_RESET);
              doReadCart({ token, id });
            }
          }
        }
      } else {
        Swal.fire({
          icon: 'error',
          text: 'There has been an error. Refresh the page and try again.',
        });
      }
      // doReadCart({ token, id });
      setLoadingCard(false);
    }
  }, [saleOrderConfirm, token, cart, doResetCart, doResetSaleOrder]);

  useEffect(() => {
    if (!contactList.fetching && !contactList?.data) {
      let query = '';
      let direction = '';
      let order = '';
      let limit = 100;
      let page = 1;
      doGetUserContacts({ token, query, direction, order, limit, page });
    }
  }, [doGetUserContacts]);

  const printAddress = addressData => {
    let line1 = `${addressData?.company_name ? addressData?.company_name : ``}`;
    let line2 = `${addressData?.address_line_1 ? addressData?.address_line_1 : ``}`;
    let line3 = `${addressData?.address_line_2 ? addressData?.address_line_2 : ``}`;
    let line4 = `${addressData?.city ? addressData?.city : ``} ${addressData?.state ? addressData?.state : ``} ${addressData?.zipcode ? addressData?.zipcode : ``}`;
    return (
      <>
        <div>{line1}</div>
        <div>{line2}</div>
        {line3 ? <div>{line3}</div> : ``}
        <div>{line4}</div>
      </>
    );
  };

  const getAddressData = (key, addressData) => {
    return addressData[key] ? addressData[key] : ``;
  };

  // const tickTimer = () => {
  //   console.log('TICK TIMER');
  //   if (!mounted.current) {
  //     console.log('MOUNT IS NOT CURRENT');
  //     clearInterval(timer.current);
  //   }
  //   /*    if (!focused) {
  //     console.log('NOT FOCUSED');
  //     clearInterval(timer.current);
  //   }*/
  //   if (saleOrderConfirm) {
  //     if (saleOrderConfirm.fetching) {
  //       clearInterval(timer.current);
  //     }
  //     if (saleOrderConfirm.data) {
  //       clearInterval(timer.current);
  //     }
  //   }
  //
  //   let payload = {
  //     sale_order_id: saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id,
  //   };
  //   doVerifySaleOrder({ token, payload });
  //   let id = saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id;
  //   doReadSaleOrder({ token, id });
  // };

  useEffect(() => {
    if (contactList?.data && !contactList?.fetching) {
      if (invoiceToAddressId !== 0) {
        setAddressObj(contactList?.data?.find(item => item.id === invoiceToAddressId));
      } else {
        setAddressObj(contactList?.data?.find(item => item.enabled));
      }
    }
  }, [invoiceToAddressId, addressObj, contactList]);

  useEffect(() => {
    if (saleOrderVerify && !saleOrderVerify?.fetching && saleOrderVerify?.data && cart && !cart?.fetching && cart?.data) {
      let id = cart?.data?.id;
      if (saleOrderVerify?.data?.changed) {
        doReadCart({ token, id });
      }
    }
  }, [saleOrderVerify]);

  // useEffect(() => {
  //   if (timer.current) {
  //     console.log('WE ARE SETTING CURRENT TIMER');
  //     clearInterval(timer.current);
  //     timer.current = setInterval(tickTimer, 20000);
  //   } else {
  //     console.log('NEW WE ARE SETTING CURRENT TIMER');
  //     timer.current = setInterval(tickTimer, 20000);
  //   }
  // }, [cart]);

  // useEffect(() => {
  //   if (focused) {
  //     if (timer.current == null) {
  //       console.log('FOCUSED AND NO TICK TIMER. SETTING');
  //       timer.current = setInterval(tickTimer, 20000);
  //     }
  //   }
  // }, [focused]);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  });
  const handlePaymentTypeChange = event => {
    setPaymentType(event.target.value);
    setInitBlue(false);
  };
  const authData = {
    apiLoginID: window.AUTHNET_API_LOGIN,
    clientKey: window.AUTHNET_CLIENT_KEY,
  };
  const handleSubmit = response => {
    //console.log('Received response:', response);
  };

  const processTotal = (paymentType, total, user) => {
    if (paymentType === 'card' && user?.customer && !user?.customer?.cc_exception) {
      return FormatUtil.numberWithCommas(roundToNearestHundredth(total * user?.customer?.cc_rate) + total);
    } else {
      return FormatUtil.numberWithCommas(total);
    }
  };

  function roundToNearestHundredth(number) {
    // Multiply by 100 to shift two decimal places to the right, round up, and then divide by 100 to shift back
    return Math.ceil(number * 100) / 100;
  }

  // const processSubTotal = (paymentType, total, subTotal, user, saleOrderRead) => {
  //   if (paymentType === 'card' && user?.customer && !user?.customer?.cc_exception) {
  //     return FormatUtil.numberWithCommas(roundToNearestHundredth(total * user?.customer?.cc_rate) + subTotal);
  //   } else {
  //     return FormatUtil.numberWithCommas(subTotal);
  //   }
  // };

  const processSubTotal = (paymentType, total, user, saleOrderPayments) => {
    const totalAmount = saleOrderPayments
      ? saleOrderPayments.reduce((sum, item) => {
          // Check if item.amount exists before adding it to the sum
          if (item.amount) {
            return sum + parseFloat(item.amount);
          } else {
            return sum; // Ignore items without an amount property
          }
        }, 0)
      : 0;
    // console.log(paymentType);
    if (paymentType === 'card' && user?.customer && !user?.customer?.cc_exception) {
      return FormatUtil.numberWithCommas(total * user?.customer?.cc_rate + total - totalAmount);
    } else {
      return FormatUtil.numberWithCommas(parseFloat(total) - totalAmount);
    }
  };
  // console.log(cart);
  return (
    <div ref={ref} style={{ width: `100%` }}>
      <Grid align="left" item xs={12} md={12} lg={12}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Payment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <TableContainer component={Paper}>
              <Table size={`small`}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: `bold`, padding: 15, fontSize: 16 }}>
                      Code
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: `bold`,
                        padding: 15,
                        fontSize: 16,
                        display: width < 600 ? 'none' : 'table-cell',
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: `bold`, padding: 15, fontSize: 16 }}>
                      Qty.
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: `bold`, padding: 15, fontSize: 16 }}>
                      Unit $
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: `bold`, padding: 15, fontSize: 16 }}>
                      Total $
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length ? (
                    cart?.data?.cart_items.map(cartItem => (
                      <TableRow key={cartItem?.product_variant ? cartItem?.product_variant?.variant_code : cartItem?.product?.product_code}>
                        <TableCell align="center" style={{ padding: 15, fontSize: 16 }}>
                          {width < 600 && cartItem?.product_variant ? (
                            <div>
                              <div style={{ marginBottom: 3 }}>{cartItem?.product_variant.variant_code}</div>
                              <div style={{ marginBottom: 3, fontSize: 11 }}>{cartItem?.product_variant.variant_name}</div>
                              <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                                {cartItem?.product_variant &&
                                  cartItem?.product_variant?.product_attributes.map(attribute => {
                                    // eslint-disable-next-line react/jsx-key
                                    return <Chip key={`attribute-${attribute?.id}`} label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                                  })}
                              </div>
                              <div style={{ fontWeight: `bold`, marginBottom: 5, display: `none` }}>
                                Stock:{' '}
                                {cartItem?.product_variant && cartItem?.product_variant?.quantity >= 1 ? (
                                  <span style={{ color: `green` }}>{cartItem?.product_variant?.quantity}</span>
                                ) : (
                                  <span style={{ color: `red` }}>Not In Stock</span>
                                )}
                              </div>
                            </div>
                          ) : cartItem?.product_variant ? (
                            cartItem?.product_variant?.variant_code
                          ) : (
                            cartItem?.product?.product_code
                          )}
                          {}
                        </TableCell>
                        <TableCell align="center" style={{ padding: 15, fontSize: 16, display: width < 600 ? 'none' : 'table-cell' }}>
                          {cartItem?.product_variant ? `${cartItem?.product_variant?.variant_name}` : cartItem?.product?.product_name}
                          <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                            {cartItem?.product_variant &&
                              cartItem?.product_variant?.product_attributes.map(attribute => {
                                // eslint-disable-next-line react/jsx-key
                                return <Chip key={`attribute-${attribute?.id}`} label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                              })}
                          </div>
                        </TableCell>
                        <TableCell align="center" style={{ padding: 15, fontSize: 16 }}>
                          {cartItem?.quantity}
                        </TableCell>
                        <TableCell align="center" style={{ padding: 15, fontSize: 16 }}>
                          ${cartItem?.item_total}
                        </TableCell>
                        <TableCell align="center" style={{ padding: 15, fontSize: 16 }}>
                          ${cartItem && cartItem?.item_total ? FormatUtil.numberWithCommas(cartItem?.item_total * cartItem?.quantity) : 0}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : cart && cart.fetching ? (
                    <TableRow key={`basket-loading`}>
                      <TableCell align="center" colSpan={width < 600 ? 4 : 5}>
                        <Skeleton animation="wave" style={{ width: `100%` }} />
                        <Skeleton animation="wave" style={{ width: `100%` }} />
                        <Skeleton animation="wave" style={{ width: `100%` }} />
                        <Skeleton animation="wave" style={{ width: `100%` }} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={`basket-loading`}>
                      <TableCell align="center" colSpan={width < 600 ? 4 : 5} style={{ fontSize: 16, padding: 15 }}>
                        <p>There is currently nothing in the cart.</p>
                      </TableCell>
                    </TableRow>
                  )}

                  {paymentType === 'card' && user?.customer && !user?.customer?.cc_exception ? (
                    <TableRow>
                      <TableCell colSpan={width < 600 ? 2 : 3} style={{ padding: 15 }} />
                      <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 12, padding: 15 }}>
                        Processing Fee
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 12, padding: 15 }}>
                        ${cart && cart?.data && cart?.data ? FormatUtil.numberWithCommas(roundToNearestHundredth(parseFloat(cart?.data?.cart_total) * user?.customer?.cc_rate)) : 0}
                      </TableCell>
                    </TableRow>
                  ) : (
                    ''
                  )}
                  <TableRow>
                    <TableCell colSpan={width < 600 ? 2 : 3} style={{ padding: 15 }} />
                    <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                      {isAndroidDevice() ? `(${totalItems(cart)})` : `Total (${totalItems(cart)}) Items`}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                      ${cart && cart?.data && cart?.data ? processTotal(paymentType, parseFloat(cart?.data?.cart_total), user) : 0}
                    </TableCell>
                  </TableRow>
                  {saleOrderRead &&
                    saleOrderRead?.data &&
                    saleOrderRead?.data?.sale_order_payments &&
                    saleOrderRead?.data?.sale_order_payments.map(item => (
                      <TableRow>
                        <TableCell colSpan={width < 600 ? 2 : 3} />
                        <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: 12, padding: 15 }}>
                          <div>{item.journal}</div>
                          {item.payment_date && <div style={{ fontSize: 10 }}>Date: {item.payment_date}</div>}
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 12, padding: 15 }}>
                          -${item?.amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell colSpan={width < 600 ? 2 : 3} />
                    <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                      {isAndroidDevice() ? 'Due:' : 'Amount Due:'}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                      {`$${processSubTotal(paymentType, parseFloat(cart?.data?.cart_total), user, saleOrderRead?.data?.sale_order_payments)}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ padding: 3 }}>
                  <Stack spacing={1}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Deliver To
                    </Typography>
                    {contactList && contactList?.data && deliverToAddressId === 0 && contactList?.data.length && contactList?.data?.find(item => item.enabled) ? (
                      printAddress(contactList?.data.find(item => item.enabled))
                    ) : contactList && contactList?.data ? (
                      printAddress(contactList?.data.find(item => item.id === deliverToAddressId))
                    ) : (
                      <Grid container>
                        <Grid item xs={12}>
                          <Skeleton />
                          <Skeleton animation="wave" />
                          <Skeleton animation={false} />
                          <Skeleton />
                          <Skeleton animation="wave" />
                          <Skeleton animation={false} />
                        </Grid>
                        <Grid item xs={12}>
                          <Alert severity="info">Updating your order...</Alert>
                        </Grid>
                      </Grid>
                    )}
                  </Stack>
                </Paper>
              </Grid>
              {/*<Grid item xs={6} md={6} lg={6}>*/}
              {/*    <Paper sx={{ padding: 3 }}>*/}
              {/*      <Stack spacing={1}>*/}
              {/*        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>*/}
              {/*          Invoice To*/}
              {/*        </Typography>*/}
              {/*        {contactList && contactList?.data && invoiceToAddressId === 0 && contactList?.data.length && contactList?.data?.find(item => item.enabled) ? (*/}
              {/*          printAddress(contactList?.data.find(item => item.enabled))*/}
              {/*        ) : contactList && contactList?.data ? (*/}
              {/*          printAddress(contactList?.data.find(item => item.id === invoiceToAddressId))*/}
              {/*        ) : (*/}
              {/*          <Grid container>*/}
              {/*            <Grid item xs={12}>*/}
              {/*              <Skeleton />*/}
              {/*              <Skeleton animation="wave" />*/}
              {/*              <Skeleton animation={false} />*/}
              {/*              <Skeleton />*/}
              {/*              <Skeleton animation="wave" />*/}
              {/*              <Skeleton animation={false} />*/}
              {/*            </Grid>*/}
              {/*          </Grid>*/}
              {/*        )}*/}
              {/*      </Stack>*/}
              {/*    </Paper>*/}
              {/*  </Grid>*/}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {saleOrderConfirm &&
              saleOrderConfirm.errors &&
              saleOrderConfirm.errors.length >= 1 &&
              Object.keys(saleOrderConfirm.errors).map(k =>
                saleOrderConfirm.errors[k].text ? (
                  <Alert key={`payment error` + k} severity="error" style={{ marginBottom: 15 }}>
                    {saleOrderConfirm.errors[k].text}
                  </Alert>
                ) : (
                  ''
                ),
              )}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {saleOrderConfirm?.fetching ? (
              <FormControl component="fieldset" style={{ width: `100%`, textAlign: `center` }}>
                <FormLabel component="legend">Processing...</FormLabel>
              </FormControl>
            ) : (
              <FormControl component="fieldset">
                <FormLabel component="legend">How would you like to pay?</FormLabel>
                <RadioGroup row value={paymentType} onChange={handlePaymentTypeChange} name="payment-type">
                  <FormControlLabel value="card" control={<Radio />} label="Card" />
                  <FormControlLabel value="ach" control={<Radio />} label="ACH" />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
          {
            {
              AUTHNET:
                paymentType === 'card' ? (
                  <Grid item xs={12} md={12} lg={12}>
                    <AuthPaymentForm {...props} setLoadingCard={setLoadingCard} loadingCard={loadingCard} />
                    {/*<HostedForm authData={authData} onSubmit={handleSubmit} />*/}
                  </Grid>
                ) : (
                  <Grid item xs={12} md={12} lg={12}>
                    {window.DISABLE_ACH === 1 || user?.customer?.ach_off ? (
                      <Alert variant={'filled'} color={'warning'} icon={<WarningAmberIcon />}>
                        {user?.customer?.ach_off
                          ? 'ACH Processing is not available.'
                          : window.DISABLE_ACH === 1
                          ? 'ACH Processing is temporarily disabled.'
                          : 'ACH Processing is temporarily disabled.'}
                      </Alert>
                    ) : (
                      {
                        AUTHNET: <SPaymentForm {...props} paymentType={paymentType} addressObj={addressObj} token={token} />,
                      }[window.ACH_SYSTEM] || null
                    )}
                  </Grid>
                ),
              SQUARE: (
                <Grid item xs={12} md={12} lg={12}>
                  <SPaymentForm {...props} paymentType={paymentType} addressObj={addressObj} token={token} />
                </Grid>
              ),
              BLUEPAY: (
                <Grid item xs={12} md={12} lg={12}>
                  <BluePaymentForm {...props} paymentType={paymentType} initBlue={initBlue} setInitBlue={setInitBlue} />{' '}
                </Grid>
              ),
            }[window.PAYMENT_SYSTEM]
          }
        </Grid>
      </Grid>
    </div>
  );
};
// PROPS TYPES
// --------------------------------------------------------
PaymentPage.propTypes = {
  focused: PropTypes.bool,
  history: PropTypes.object,
  cart: PropTypes.object,
  quantityChanged: PropTypes.func,
  totalItems: PropTypes.func,
  token: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  contactCreate: PropTypes.object,
  contactDelete: PropTypes.object,
  contactUpdate: PropTypes.object,
  gateway: PropTypes.object,
  contactList: PropTypes.object,
  saleOrderCreate: PropTypes.object,
  saleOrderConfirm: PropTypes.object,
  saleOrderVerify: PropTypes.object,
  saleOrderRead: PropTypes.object,
  doReadSaleOrder: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  doVerifySaleOrder: PropTypes.func,
  doConfirmSaleOrder: PropTypes.func,
  doCreateUserContact: PropTypes.func,
  doDeleteUserContact: PropTypes.func,
  doGetUserContacts: PropTypes.func,
  doClearUserContact: PropTypes.func,
  doUpdateUserContact: PropTypes.func,
  doReadCart: PropTypes.func,
  setDeliverToAddressId: PropTypes.func,
  setInvoiceToAddressId: PropTypes.func,
  doReadUserGatewayProfile: PropTypes.func,
  doClearUserGatewayProfile: PropTypes.func,
  doResetCart: PropTypes.func,
  deliverToAddressId: PropTypes.number,
  invoiceToAddressId: PropTypes.number,
  width: PropTypes.number,
  bluepayCustomerPaymentMethodList: PropTypes.object,
};
export default PaymentPage;
