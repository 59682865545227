// Import types
import { LIST_USERCONTACT_REQUEST, READ_USERCONTACT_REQUEST, UPDATE_USERCONTACT_REQUEST, CREATE_USERCONTACT_REQUEST, DELETE_USERCONTACT_REQUEST, CLEAR_USERCONTACT } from './types';
import { SALEORDER_RESET } from '../SaleOrder/types';

// Export operations
/**
 * Dispatch for getting users
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetUserContacts = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_USERCONTACT_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for getting specific user
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doGetUserContact = ({ token, id }) => ({
  type: READ_USERCONTACT_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating user
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doCreateUserContact = ({ token, payload }) => ({
  type: CREATE_USERCONTACT_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for updating user
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doUpdateUserContact = ({ token, id, payload }) => ({
  type: UPDATE_USERCONTACT_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for deleting specific user
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doDeleteUserContact = ({ token, id }) => ({
  type: DELETE_USERCONTACT_REQUEST,
  token,
  id,
});

/**
 * Dispatch user to initial state
 * @return {Object} Dispatch
 */
export const doClearUserContact = () => ({
  type: CLEAR_USERCONTACT,
});
