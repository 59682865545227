// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  LIST_PURCHASEORDERAUTOMATION_REQUEST,
  LIST_PURCHASEORDERAUTOMATION_SUCCESS,
  LIST_PURCHASEORDERAUTOMATION_FAILURE,
  READ_PURCHASEORDERAUTOMATION_REQUEST,
  READ_PURCHASEORDERAUTOMATION_SUCCESS,
  READ_PURCHASEORDERAUTOMATION_FAILURE,
  CREATE_PURCHASEORDERAUTOMATION_REQUEST,
  CREATE_PURCHASEORDERAUTOMATION_SUCCESS,
  CREATE_PURCHASEORDERAUTOMATION_FAILURE,
} from './types';

// Actions
import { getPurchaseOrderAutomations, readPurchaseOrderAutomation, createPurchaseOrderAutomation } from './actions';

/**
 * Function that handles listing Customers.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetPurchaseOrderAutomations({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getPurchaseOrderAutomations, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_PURCHASEORDERAUTOMATION_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_PURCHASEORDERAUTOMATION_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetPurchaseOrderAutomations() {
  yield takeLatest(LIST_PURCHASEORDERAUTOMATION_REQUEST, workerGetPurchaseOrderAutomations);
}

/**
 * Function that handles getting a specific Customer.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadPurchaseOrderAutomation({ token, id }) {
  try {
    const response = yield call(readPurchaseOrderAutomation, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_PURCHASEORDERAUTOMATION_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_PURCHASEORDERAUTOMATION_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadPurchaseOrderAutomation() {
  yield takeLatest(READ_PURCHASEORDERAUTOMATION_REQUEST, workerReadPurchaseOrderAutomation);
}

/**
 * Function that handles creating a specific Customer.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreatePurchaseOrderAutomation({ token, payload }) {
  try {
    const response = yield call(createPurchaseOrderAutomation, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_PURCHASEORDERAUTOMATION_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_PURCHASEORDERAUTOMATION_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreatePurchaseOrderAutomation() {
  yield takeLatest(CREATE_PURCHASEORDERAUTOMATION_REQUEST, workerCreatePurchaseOrderAutomation);
}
