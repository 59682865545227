import React from 'react';
export default {
  numberWithCommas: x => {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  nameShrinker: name => {
    // var file_ext = name.substring(file_name.lastIndexOf('.')+1);
    if (name.length > 15) {
      name = name.substring(0, 15) + '...';
    }
    return name;
  },
};
