export const LIST_ASSET_REQUEST = 'LIST_ASSET_REQUEST';
export const LIST_ASSET_SUCCESS = 'LIST_ASSET_SUCCESS';
export const LIST_ASSET_FAILURE = 'LIST_ASSET_FAILURE';
export const READ_ASSET_REQUEST = 'READ_ASSET_REQUEST';
export const READ_ASSET_SUCCESS = 'READ_ASSET_SUCCESS';
export const READ_ASSET_FAILURE = 'READ_ASSET_FAILURE';
export const CREATE_ASSET_REQUEST = 'CREATE_ASSET_REQUEST';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_FAILURE = 'CREATE_ASSET_FAILURE';
export const DELETE_ASSET_REQUEST = 'DELETE_ASSET_REQUEST';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE';
