// Property Types
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import PasswordResetStyles from './styles';
import EasyErrors from '../../../utils/EasyErrors';
import FormUtil from '../../../utils/FormUtil';
import Swal from 'sweetalert2';

/**
 * ACTIVATION VIEW
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Activation = props => {
  // Props
  const { match, history, activation, doCustomerActivation, doSalesRepActivation, doActivationReset } = props;

  const { type, token, validator } = match.params;

  const [activationRequest, setActivationRequest] = React.useState(false);

  const [values, setValues] = React.useState({
    password: '',
    password_confirmation: '',
  });

  const [validation, setValidation] = React.useState({
    password: {
      validated: false,
      valid: true,
      errors: [],
    },
    password_confirmation: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    setActivationRequest(true);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setActivationRequest(true);
    }
  };

  useEffect(() => {
    doActivationReset();
  }, [doActivationReset]);

  useEffect(() => {
    if (activationRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setActivationRequest(false);
      if (values?.password === '') {
        setValidation(FormUtil.addError(validation, 'password', 'Password is blank'));
      }
      if (values?.password_confirmation === '') {
        setValidation(FormUtil.addError(validation, 'password_confirmation', 'Password Confirm is blank'));
      }
      if (values?.password_confirmation !== values?.password) {
        setValidation(FormUtil.addError(validation, 'password_confirmation', 'Passwords do not match'));
      }
      if (token === '' || validator === '') {
        setValidation(FormUtil.addError(validation, 'password', 'Password reset is corrupt'));
      }
      if (FormUtil.validate(validation)) {
        if (type === 'customer') {
          doCustomerActivation({ password: values.password, password_confirmation: values.password_confirmation, token, validator });
        } else if (type === 'salesrep') {
          doSalesRepActivation({ password: values.password, password_confirmation: values.password_confirmation, token, validator });
        }
      }
    }
  }, [validation, activationRequest]);

  useEffect(() => {
    if (!activation?.fetching && activation?.data) {
      Swal.fire({
        icon: 'success',
        text: 'Account activation is complete.',
        showConfirmButton: false,
        timer: 3500,
        didClose: function() {
          doActivationReset();
          history.push('/auth/login');
        },
      });
    }
  }, [activation]);

  return (
    <PasswordResetStyles>
      <Container>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Paper style={{ padding: 50, margin: 'auto', maxWidth: 600, textAlign: 'center', borderRadius: 0 }}>
            <div style={{ padding: 20 }}>
              <div
                style={{ textAlign: `center`, padding: 30, paddingTop: 0 }}
                onClick={() => {
                  history.push('/');
                }}
              >
                <img style={{ width: `100%` }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo.png`} alt={``} />
              </div>
              <div>{EasyErrors.render(activation?.errors)}</div>
              <form style={{ flexGrow: 1 }} noValidate autoComplete="off">
                <FormGroup style={{ marginBottom: 15 }}>
                  <TextField
                    error={FormUtil.renderError(validation, 'password')}
                    helperText={FormUtil.renderHelperText(validation, 'password')}
                    variant="outlined"
                    placeholder={`Password`}
                    type={'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: 15 }}>
                  <TextField
                    error={FormUtil.renderError(validation, 'password_confirmation')}
                    helperText={FormUtil.renderHelperText(validation, 'password_confirmation')}
                    variant="outlined"
                    placeholder={`Password Confirm`}
                    type={'password'}
                    value={values.password_confirmation}
                    onChange={handleChange('password_confirmation')}
                    onKeyPress={handleKeyPress}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: 15 }}>
                  <LoadingButton
                    onClick={onSubmitForm}
                    loading={activation?.fetching}
                    loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                    disabled={activation?.fetching}
                    size={`large`}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                  >
                    Activate Account
                  </LoadingButton>
                </FormGroup>
              </form>
            </div>
          </Paper>
        </Grid>
      </Container>
    </PasswordResetStyles>
  );
};

Activation.propTypes = {
  doSalesRepActivation: PropTypes.func,
  doCustomerActivation: PropTypes.func,
  doActivationReset: PropTypes.func,
  handleSubmit: PropTypes.func,
  activation: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  token: PropTypes.string,
  validator: PropTypes.string,
};

// Export
export default Activation;
