// Imports

// NOTIFICATION CAMPAIGN Action Types.
import {
  LIST_NOTIFICATION_CAMPAIGN_REQUEST,
  LIST_NOTIFICATION_CAMPAIGN_SUCCESS,
  LIST_NOTIFICATION_CAMPAIGN_FAILURE,
  READ_NOTIFICATION_CAMPAIGN_REQUEST,
  READ_NOTIFICATION_CAMPAIGN_SUCCESS,
  READ_NOTIFICATION_CAMPAIGN_FAILURE,
  CREATE_NOTIFICATION_CAMPAIGN_REQUEST,
  CREATE_NOTIFICATION_CAMPAIGN_SUCCESS,
  CREATE_NOTIFICATION_CAMPAIGN_FAILURE,
  NOTIFICATION_CAMPAIGN_RESET,
} from './types';

// Initial Reducer States.
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
};

/**
 * NOTIFICATION_CAMPAIGN Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const notificationCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_NOTIFICATION_CAMPAIGN_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_NOTIFICATION_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_NOTIFICATION_CAMPAIGN_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_NOTIFICATION_CAMPAIGN_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_NOTIFICATION_CAMPAIGN_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_NOTIFICATION_CAMPAIGN_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CREATE_NOTIFICATION_CAMPAIGN_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case CREATE_NOTIFICATION_CAMPAIGN_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case CREATE_NOTIFICATION_CAMPAIGN_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case NOTIFICATION_CAMPAIGN_RESET:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: null,
        },
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    default:
      return state;
  }
};

// Exports
export default notificationCampaignReducer;
