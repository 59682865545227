// Import
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import { withRouter } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import RegisterStyles from './styles';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormUtil from '../../../utils/FormUtil';
import EasyErrors from '../../../utils/EasyErrors';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

/**
 * Register Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Register = props => {
  // Props
  const { doRegister, doRegisterReset, register, history } = props;

  const [registerRequest, setRegisterRequest] = React.useState(false);

  const [values, setValues] = React.useState({
    company_name: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    zipcode: '',
    state: '',
    phone: '',
    firstname: '',
    lastname: '',
    email: '',
    // password: '',
    // password_confirmation: '',
  });

  const [validation, setValidation] = React.useState({
    company_name: {
      validated: false,
      valid: true,
      errors: [],
    },
    address_line_1: {
      validated: false,
      valid: true,
      errors: [],
    },
    address_line_2: {
      validated: false,
      valid: true,
      errors: [],
    },
    city: {
      validated: false,
      valid: true,
      errors: [],
    },
    zipcode: {
      validated: false,
      valid: true,
      errors: [],
    },
    state: {
      validated: false,
      valid: true,
      errors: [],
    },
    phone: {
      validated: false,
      valid: true,
      errors: [],
    },
    firstname: {
      validated: false,
      valid: true,
      errors: [],
    },
    lastname: {
      validated: false,
      valid: true,
      errors: [],
    },
    email: {
      validated: false,
      valid: true,
      errors: [],
    },
    password: {
      validated: false,
      valid: true,
      errors: [],
    },
    password_confirmation: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    setRegisterRequest(true);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setRegisterRequest(true);
    }
  };

  useEffect(() => {
    doRegisterReset();
  }, [doRegisterReset]);

  useEffect(() => {
    if (register && !register.fetching && register?.success) {
      Swal.fire({
        icon: 'success',
        text: 'Your account is pending approval',
        showConfirmButton: false,
        timer: 3500,
        didClose: function() {
          doRegisterReset();
          history.push('/auth/login');
        },
      });
    }
  }, [register]);

  useEffect(() => {
    if (registerRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setRegisterRequest(false);
      if (values?.company_name === '') {
        setValidation(FormUtil.addError(validation, 'company_name', 'Company Name is blank'));
      }
      if (values?.address_line_1 === '') {
        setValidation(FormUtil.addError(validation, 'address_line_1', 'Address Line 1 is blank'));
      }
      if (values?.zipcode === '') {
        setValidation(FormUtil.addError(validation, 'zipcode', 'Zipcode is blank'));
      }
      if (values?.city === '') {
        setValidation(FormUtil.addError(validation, 'city', 'City is blank'));
      }
      if (values?.state === '') {
        setValidation(FormUtil.addError(validation, 'state', 'State is blank'));
      }
      if (values?.phone === '') {
        setValidation(FormUtil.addError(validation, 'phone', 'Phone is blank'));
      }
      if (values?.firstname === '') {
        setValidation(FormUtil.addError(validation, 'firstname', 'First Name is blank'));
      }
      if (values?.lastname === '') {
        setValidation(FormUtil.addError(validation, 'lastname', 'Last Name is blank'));
      }
      if (values?.email === '') {
        setValidation(FormUtil.addError(validation, 'email', 'Email is blank'));
      }
      // if (values?.password === '') {
      //   setValidation(FormUtil.addError(validation, 'password', 'Password is blank'));
      // }
      // if (values?.password_confirmation === '') {
      //   setValidation(FormUtil.addError(validation, 'password_confirmation', 'Confirm Password is blank'));
      // }
      // if (values?.password_confirmation !== values?.password) {
      //   setValidation(FormUtil.addError(validation, 'password_confirmation', 'Passwords do not match'));
      // }

      if (!values?.email.match(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)) {
        setValidation(FormUtil.addError(validation, 'email', 'Email is not valid'));
      }

      if (!values?.zipcode.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) {
        setValidation(FormUtil.addError(validation, 'zipcode', 'Zipcode is not valid'));
      }

      if (!values?.phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)) {
        setValidation(FormUtil.addError(validation, 'phone', 'Phone is not valid'));
      }

      if (FormUtil.validate(validation)) {
        doRegister(values);
      }
    }
  }, [validation, registerRequest]);

  return (
    <RegisterStyles>
      <Container>
        <Paper
          style={{
            padding: 10,
            margin: 'auto',
            marginTop: 10,
            marginBottom: 10,
            maxWidth: 600,
            textAlign: 'center',
            borderRadius: 0,
          }}
        >
          <div style={{ padding: 20 }}>
            <div
              style={{ textAlign: `center`, padding: 30, paddingTop: 0 }}
              onClick={() => {
                history.push('/');
              }}
            >
              <img style={{ width: `100%` }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo.png`} />
            </div>
            <div>{EasyErrors.render(register?.errors)}</div>
            <Box component="form" noValidate autoComplete="off">
              <Grid container spacing={2} columns={12}>
                <Grid item columns={16} xs={12}>
                  <legend>1. Business Address</legend>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <TextField
                      error={FormUtil.renderError(validation, 'company_name')}
                      helperText={FormUtil.renderHelperText(validation, 'company_name')}
                      label="Company Name"
                      value={values.company_name}
                      onChange={handleChange('company_name')}
                      placeholder={`Company Name`}
                      variant="outlined"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <TextField
                      error={FormUtil.renderError(validation, 'address_line_1')}
                      helperText={FormUtil.renderHelperText(validation, 'address_line_1')}
                      label="Address Line 1"
                      value={values.address_line_1}
                      onChange={handleChange('address_line_1')}
                      placeholder={`Address Line 1`}
                      variant="outlined"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <TextField
                      error={FormUtil.renderError(validation, 'address_line_2')}
                      helperText={FormUtil.renderHelperText(validation, 'address_line_2')}
                      label="Address Line 2"
                      value={values.address_line_2}
                      onChange={handleChange('address_line_2')}
                      placeholder={`Address Line 2`}
                      variant="outlined"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} columns={12}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth={true}
                        error={FormUtil.renderError(validation, 'city')}
                        helperText={FormUtil.renderHelperText(validation, 'city')}
                        label="City"
                        value={values.city}
                        onChange={handleChange('city')}
                        placeholder={`city`}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Select
                        displayEmpty={true}
                        defaultValue={values.state}
                        value={values.state}
                        onChange={handleChange('state')}
                        fullWidth={true}
                        error={FormUtil.renderError(validation, 'state')}
                        // helperText={FormUtil.renderHelperText(validation, 'state')}
                        style={{ textAlign: `left` }}
                      >
                        <MenuItem value={''}>Select State</MenuItem>
                        <MenuItem value={'AL'}>Alabama</MenuItem>
                        <MenuItem value={'AK'}>Alaska</MenuItem>
                        <MenuItem value={'AZ'}>Arizona</MenuItem>
                        <MenuItem value={'AR'}>Arkansas</MenuItem>
                        <MenuItem value={'CA'}>California</MenuItem>
                        <MenuItem value={'CO'}>Colorado</MenuItem>
                        <MenuItem value={'CT'}>Connecticut</MenuItem>
                        <MenuItem value={'DE'}>Delaware</MenuItem>
                        <MenuItem value={'DC'}>District Of Columbia</MenuItem>
                        <MenuItem value={'FL'}>Florida</MenuItem>
                        <MenuItem value={'GA'}>Georgia</MenuItem>
                        <MenuItem value={'HI'}>Hawaii</MenuItem>
                        <MenuItem value={'ID'}>Idaho</MenuItem>
                        <MenuItem value={'IL'}>Illinois</MenuItem>
                        <MenuItem value={'IN'}>Indiana</MenuItem>
                        <MenuItem value={'IA'}>Iowa</MenuItem>
                        <MenuItem value={'KS'}>Kansas</MenuItem>
                        <MenuItem value={'KY'}>Kentucky</MenuItem>
                        <MenuItem value={'LA'}>Louisiana</MenuItem>
                        <MenuItem value={'ME'}>Maine</MenuItem>
                        <MenuItem value={'MD'}>Maryland</MenuItem>
                        <MenuItem value={'MA'}>Massachusetts</MenuItem>
                        <MenuItem value={'MI'}>Michigan</MenuItem>
                        <MenuItem value={'MN'}>Minnesota</MenuItem>
                        <MenuItem value={'MS'}>Mississippi</MenuItem>
                        <MenuItem value={'MO'}>Missouri</MenuItem>
                        <MenuItem value={'MT'}>Montana</MenuItem>
                        <MenuItem value={'NE'}>Nebraska</MenuItem>
                        <MenuItem value={'NV'}>Nevada</MenuItem>
                        <MenuItem value={'NH'}>New Hampshire</MenuItem>
                        <MenuItem value={'NJ'}>New Jersey</MenuItem>
                        <MenuItem value={'NM'}>New Mexico</MenuItem>
                        <MenuItem value={'NY'}>New York</MenuItem>
                        <MenuItem value={'NC'}>North Carolina</MenuItem>
                        <MenuItem value={'ND'}>North Dakota</MenuItem>
                        <MenuItem value={'OH'}>Ohio</MenuItem>
                        <MenuItem value={'OK'}>Oklahoma</MenuItem>
                        <MenuItem value={'OR'}>Oregon</MenuItem>
                        <MenuItem value={'PA'}>Pennsylvania</MenuItem>
                        <MenuItem value={'RI'}>Rhode Island</MenuItem>
                        <MenuItem value={'SC'}>South Carolina</MenuItem>
                        <MenuItem value={'SD'}>South Dakota</MenuItem>
                        <MenuItem value={'TN'}>Tennessee</MenuItem>
                        <MenuItem value={'TX'}>Texas</MenuItem>
                        <MenuItem value={'UT'}>Utah</MenuItem>
                        <MenuItem value={'VT'}>Vermont</MenuItem>
                        <MenuItem value={'VA'}>Virginia</MenuItem>
                        <MenuItem value={'WA'}>Washington</MenuItem>
                        <MenuItem value={'WV'}>West Virginia</MenuItem>
                        <MenuItem value={'WI'}>Wisconsin</MenuItem>
                        <MenuItem value={'WY'}>Wyoming</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth={true}
                        error={FormUtil.renderError(validation, 'zipcode')}
                        helperText={FormUtil.renderHelperText(validation, 'zipcode')}
                        label="ZIP Code"
                        value={values.zipcode}
                        onChange={handleChange('zipcode')}
                        placeholder={`ZIP Code`}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <legend>2. Company Details</legend>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    error={FormUtil.renderError(validation, 'phone')}
                    helperText={FormUtil.renderHelperText(validation, 'phone')}
                    label="Phone"
                    value={values.phone}
                    onChange={handleChange('phone')}
                    placeholder={`Phone`}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <legend>3. User Details</legend>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} columns={16}>
                    <Grid item columns={8} xs={8}>
                      <TextField
                        fullWidth={true}
                        error={FormUtil.renderError(validation, 'firstname')}
                        helperText={FormUtil.renderHelperText(validation, 'firstname')}
                        label="First Name"
                        value={values.firstname}
                        onChange={handleChange('firstname')}
                        placeholder={`First Name`}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item columns={8} xs={8}>
                      <TextField
                        fullWidth={true}
                        error={FormUtil.renderError(validation, 'lastname')}
                        helperText={FormUtil.renderHelperText(validation, 'lastname')}
                        label="Last Name"
                        value={values.lastname}
                        onChange={handleChange('lastname')}
                        placeholder={`Last Name`}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    error={FormUtil.renderError(validation, 'email')}
                    helperText={FormUtil.renderHelperText(validation, 'email')}
                    label="Email"
                    value={values.email}
                    onChange={handleChange('email')}
                    placeholder={`Email`}
                    variant="outlined"
                  />
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*  <TextField*/}
                {/*    fullWidth={true}*/}
                {/*    type={`password`}*/}
                {/*    error={FormUtil.renderError(validation, 'password')}*/}
                {/*    helperText={FormUtil.renderHelperText(validation, 'password')}*/}
                {/*    label="New Password"*/}
                {/*    value={values.password}*/}
                {/*    onChange={handleChange('password')}*/}
                {/*    placeholder={`New Password`}*/}
                {/*    variant="outlined"*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*  <TextField*/}
                {/*    fullWidth={true}*/}
                {/*    type={`password`}*/}
                {/*    error={FormUtil.renderError(validation, 'password_confirmation')}*/}
                {/*    helperText={FormUtil.renderHelperText(validation, 'password_confirmation')}*/}
                {/*    label="Confirm Password"*/}
                {/*    value={values.password_confirmation}*/}
                {/*    onChange={handleChange('password_confirmation')}*/}
                {/*    placeholder={`Confirm Password`}*/}
                {/*    variant="outlined"*/}
                {/*  />*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                  <FormGroup>
                    <Grid container>
                      <Grid item xs={6} md={6}>
                        <Button
                          size={`large`}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            history.push('/auth/login');
                          }}
                        >
                          Go Back
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <LoadingButton
                          onClick={onSubmitForm}
                          loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                          loading={register?.fetching}
                          disabled={register?.fetching}
                          size={`large`}
                          variant="contained"
                          color="primary"
                          fullWidth={false}
                        >
                          Register
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Paper>
      </Container>
    </RegisterStyles>
  );
};

// Property Types
Register.propTypes = {
  doRegister: PropTypes.func,
  doRegisterReset: PropTypes.func,
  doMe: PropTypes.func,
  handleSubmit: PropTypes.func,
  register: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
};

// Export
export default withRouter(Register);
