// Import types
import {
  READ_CART_REQUEST,
  CREATE_CART_REQUEST,
  DELETE_CART_REQUEST,
  EMPTY_CART_REQUEST,
  CART_RESET,
  UPDATE_CART_REQUEST,
  CART_UPDATE_RESET,
  REFRESH_CART_REQUEST,
  OVERRIDE_CART_RESET,
  READ_LAST_CART_REQUEST,
  DUPLICATE_CART_REQUEST,
} from './types';

// Export operations

/**
 * Dispatch for getting a specific Cart
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadCart = ({ token, id }) => ({
  type: READ_CART_REQUEST,
  token,
  id,
});

export const doDuplicateCart = ({ token, id }) => ({
  type: DUPLICATE_CART_REQUEST,
  token,
  id,
});

/**
 * Dispatch for getting a specific Cart
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doReadLastCart = ({ token }) => ({
  type: READ_LAST_CART_REQUEST,
  token,
});

/**
 * Dispatch for refreshing a specific Cart
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doRefreshCart = ({ token, id }) => ({
  type: REFRESH_CART_REQUEST,
  token,
  id,
});

/**
 * Dispatch for creating a Cart
 * @param {Object} { token: string, payload: object }
 * @return {Object} Dispatch
 */
export const doCreateCart = ({ token, payload }) => ({
  type: CREATE_CART_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for updating a Cart.
 * @param {Object} { token: string, payload: { checkout_override: boolean } }
 * @return {Object} Dispatch
 */
export const doUpdateCart = ({ token, id, payload }) => ({
  type: UPDATE_CART_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for deleting a specific Cart
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doDeleteCart = ({ token, id }) => ({
  type: DELETE_CART_REQUEST,
  token,
  id,
});

/**
 * Dispatch for emptying a specific Cart
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doEmptyCart = ({ token, id }) => ({
  type: EMPTY_CART_REQUEST,
  token,
  id,
});

export const doResetCartUpdate = () => ({
  type: CART_UPDATE_RESET,
});

/**
 * Dispatch for resetting cart reducer
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doResetCart = () => ({
  type: CART_RESET,
});

/**
 * Dispatch for updating cart reducer
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doOverrideCart = () => ({
  type: OVERRIDE_CART_RESET,
});
