// Imports
import axios from 'axios';

// Export actions
/**
 * Get a list of Products.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getProducts = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/products${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a specific Product.
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const getProduct = ({ token, id }) => {
  const params = {
    id,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/products${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create a Product.
 * @param {Object} { token: string, payload: { name, product_code, unit_price, description, active, category, attributes } }
 * @return {AxiosPromise} Http request
 */
export const createProduct = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/products`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Update a Product.
 * @param {Object} { token: string, payload: { name, product_code, unit_price, description, active, category, attributes } }
 * @return {AxiosPromise} Http request
 */
export const updateProduct = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/products/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete a Product.
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const deleteProduct = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/products/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
