// Imports
import axios from 'axios';

// Cart Item Export Functions.
/**
 * Get all Cart Items.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getCartItems = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/cartitem${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create a Cart Item.
 * @param {Object} { token: string, payload: { cart_id : integer, product_id : integer, quantity : integer } }
 * @return {AxiosPromise} Http request
 */
export const createCartItem = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/cartitem`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Update a Cart Item.
 * @param {Object} { token: string, payload: { cart_id : integer, product_id : integer, quantity : integer } }
 * @return {AxiosPromise} Http request
 */
export const updateCartItem = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/cartitem/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete a Cart Item.
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const deleteCartItem = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/cartitem/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
