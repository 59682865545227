// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Cart Item Action Types
import {
  LIST_SALEORDER_REQUEST,
  LIST_SALEORDER_SUCCESS,
  LIST_SALEORDER_FAILURE,
  UPDATE_SALEORDER_REQUEST,
  UPDATE_SALEORDER_SUCCESS,
  UPDATE_SALEORDER_FAILURE,
  CREATE_SALEORDER_REQUEST,
  CREATE_SALEORDER_SUCCESS,
  CREATE_SALEORDER_FAILURE,
  DELETE_SALEORDER_REQUEST,
  DELETE_SALEORDER_SUCCESS,
  DELETE_SALEORDER_FAILURE,
  READ_SALEORDER_FAILURE,
  READ_SALEORDER_REQUEST,
  READ_SALEORDER_SUCCESS,
  CONFIRM_SALEORDER_FAILURE,
  CONFIRM_SALEORDER_SUCCESS,
  CONFIRM_SALEORDER_REQUEST,
  VERIFY_SALEORDER_REQUEST,
  VERIFY_SALEORDER_SUCCESS,
  VERIFY_SALEORDER_FAILURE,
  CANCEL_SALEORDER_FAILURE,
  CANCEL_SALEORDER_REQUEST,
  CANCEL_SALEORDER_SUCCESS,
} from './types';

// Actions
import { getSaleOrders, createSaleOrder, updateSaleOrder, deleteSaleOrder, readSaleOrder, confirmSaleOrder, verifySaleOrder, cancelSaleOrder } from './actions';

/**
 * Function that handles listing Cart Items.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetSaleOrders({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getSaleOrders, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_SALEORDER_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_SALEORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetSaleOrders() {
  yield takeLatest(LIST_SALEORDER_REQUEST, workerGetSaleOrders);
}

/**
 * Function that handles creating a Cart Item.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerReadSaleOrder({ token, id }) {
  try {
    const response = yield call(readSaleOrder, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: READ_SALEORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_SALEORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherReadSaleOrder() {
  yield takeLatest(READ_SALEORDER_REQUEST, workerReadSaleOrder);
}

/**
 * Function that handles creating a Sale Order.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateSaleOrder({ token, payload }) {
  try {
    const response = yield call(createSaleOrder, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_SALEORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_SALEORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateSaleOrder() {
  yield takeLatest(CREATE_SALEORDER_REQUEST, workerCreateSaleOrder);
}

/**
 * Function that handles confirming a Sale Order.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerConfirmSaleOrder({ token, payload }) {
  try {
    const response = yield call(confirmSaleOrder, {
      token,
      payload,
    });
    //console.log(response);
    const { data } = response.data;

    yield put({
      type: CONFIRM_SALEORDER_SUCCESS,
      data,
    });
  } catch (error) {
    // console.log('SAGA ERROR');
    // console.log(error.response);
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CONFIRM_SALEORDER_FAILURE, ...{ error: message.error } });
  }
}

// Exports
export function* watcherConfirmSaleOrder() {
  yield takeLatest(CONFIRM_SALEORDER_REQUEST, workerConfirmSaleOrder);
}

/**
 * Function that handles verifying a Sale Order.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerVerifySaleOrder({ token, payload }) {
  try {
    const response = yield call(verifySaleOrder, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: VERIFY_SALEORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: VERIFY_SALEORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherVerifySaleOrder() {
  yield takeLatest(VERIFY_SALEORDER_REQUEST, workerVerifySaleOrder);
}

/**
 * Function that handles verifying a Sale Order.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCancelSaleOrder({ token, payload }) {
  try {
    const response = yield call(cancelSaleOrder, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CANCEL_SALEORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CANCEL_SALEORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCancelSaleOrder() {
  yield takeLatest(CANCEL_SALEORDER_REQUEST, workerCancelSaleOrder);
}

/**
 * Function that handles updating a Cart Item.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateSaleOrder({ token, id, payload }) {
  try {
    const response = yield call(updateSaleOrder, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_SALEORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_SALEORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateSaleOrder() {
  yield takeLatest(UPDATE_SALEORDER_REQUEST, workerUpdateSaleOrder);
}

/**
 * Function that handles deleting a Cart Item.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteSaleOrder({ token, id }) {
  try {
    const response = yield call(deleteSaleOrder, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_SALEORDER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_SALEORDER_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteSaleOrder() {
  yield takeLatest(DELETE_SALEORDER_REQUEST, workerDeleteSaleOrder);
}
