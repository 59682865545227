// Imports

// PurchaseOrderAutomation Action Types.
import {
  LIST_PURCHASEORDERAUTOMATION_REQUEST,
  LIST_PURCHASEORDERAUTOMATION_SUCCESS,
  LIST_PURCHASEORDERAUTOMATION_FAILURE,
  READ_PURCHASEORDERAUTOMATION_REQUEST,
  READ_PURCHASEORDERAUTOMATION_SUCCESS,
  READ_PURCHASEORDERAUTOMATION_FAILURE,
  CREATE_PURCHASEORDERAUTOMATION_REQUEST,
  CREATE_PURCHASEORDERAUTOMATION_SUCCESS,
  CREATE_PURCHASEORDERAUTOMATION_FAILURE,
  PURCHASEORDERAUTOMATION_RESET,
} from './types';

// Initial Reducer States.
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
};

/**
 * Customer Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const purchaseOrderAutomationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PURCHASEORDERAUTOMATION_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_PURCHASEORDERAUTOMATION_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_PURCHASEORDERAUTOMATION_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_PURCHASEORDERAUTOMATION_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_PURCHASEORDERAUTOMATION_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_PURCHASEORDERAUTOMATION_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CREATE_PURCHASEORDERAUTOMATION_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case CREATE_PURCHASEORDERAUTOMATION_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case CREATE_PURCHASEORDERAUTOMATION_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case PURCHASEORDERAUTOMATION_RESET:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
        },
        read: {
          fetching: false,
          errors: null,
          data: null,
        },
        create: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    default:
      return state;
  }
};

// Exports
export default purchaseOrderAutomationReducer;
