// Property Types
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import PasswordResetStyles from './styles';
import FormUtil from '../../../utils/FormUtil';
import EasyErrors from '../../../utils/EasyErrors';
import Swal from 'sweetalert2';

/**
 * UNSUBSCRIBE VIEW
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Unsubscribe = props => {
  // Props
  const { doUnsubscribe, doUnsubscribeReset, unsubscribe, history, match } = props;

  const { email } = match.params;

  const [unsubscribeRequest, setUnsubscribeRequest] = React.useState(false);

  const [values, setValues] = React.useState({
    email: email ? email : '',
  });

  const [validation, setValidation] = React.useState({
    email: {
      validated: false,
      valid: true,
      errors: [],
    },
  });

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    setUnsubscribeRequest(true);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setUnsubscribeRequest(true);
    }
  };

  useEffect(() => {
    if (unsubscribeRequest) {
      setValidation(FormUtil.clearValidation(validation));
      setUnsubscribeRequest(false);
      if (values?.email === '') {
        setValidation(FormUtil.addError(validation, 'email', 'Email is blank'));
      }
      if (FormUtil.validate(validation)) {
        let email = values.email;
        doUnsubscribe({ email });
      }
    }
  }, [validation, unsubscribeRequest]);

  useEffect(() => {
    if (!unsubscribe?.fetching && unsubscribe?.data) {
      Swal.fire({
        icon: 'success',
        text: 'Your email has been taken off our list.',
        showConfirmButton: false,
        timer: 3500,
        didClose: function() {
          doUnsubscribeReset();
          history.push('/auth/login');
        },
      });
    }
  }, [unsubscribe]);

  return (
    <PasswordResetStyles>
      <Container>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Paper style={{ padding: 50, margin: 'auto', maxWidth: 600, textAlign: 'center', borderRadius: 0 }}>
            <div style={{ padding: 20 }}>
              <div
                style={{ textAlign: `center`, padding: 30, paddingTop: 0 }}
                onClick={() => {
                  history.push('/');
                }}
              >
                <img style={{ width: `100%` }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo.png`} alt={`Logo`} />
              </div>
              <div>{EasyErrors.render(unsubscribe?.errors)}</div>
              <div>
                {' '}
                <p align={`left`}>Enter the email address associated with your account, and we’ll take it off our list.</p>
              </div>
              <form style={{ flexGrow: 1 }} noValidate autoComplete="off">
                <FormGroup style={{ marginBottom: 15 }}>
                  <TextField
                    error={FormUtil.renderError(validation, 'email')}
                    helperText={FormUtil.renderHelperText(validation, 'email')}
                    label="Email"
                    value={values.email}
                    onChange={handleChange('email')}
                    placeholder={`Email address`}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: 15 }}>
                  <LoadingButton
                    onClick={onSubmitForm}
                    loading={unsubscribe?.fetching}
                    loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                    disabled={unsubscribe?.fetching}
                    size={`large`}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                  >
                    Unsubscribe
                  </LoadingButton>
                </FormGroup>
              </form>
            </div>
          </Paper>
        </Grid>
      </Container>
    </PasswordResetStyles>
  );
};

Unsubscribe.propTypes = {
  doUnsubscribe: PropTypes.func,
  doUnsubscribeReset: PropTypes.func,
  unsubscribe: PropTypes.object,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
};

// Export
export default Unsubscribe;
