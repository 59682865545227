import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Skeleton, TableFooter, TextField, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import FormatUtil from '../../../utils/FormatUtil';
import AddressSelectorModal from '../../../components/Modals/AddressSelector';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Swal from 'sweetalert2';

const CheckoutPage = props => {
  const {
    cart,
    quantityChanged,
    totalItems,
    token,
    match,
    user,
    saleOrderCreate,
    saleOrderUpdate,
    saleOrderRead,
    contactList,
    contactDelete,
    contactCreate,
    contactUpdate,
    doGetUserContacts,
    doCreateUserContact,
    doClearUserContact,
    doUpdateUserContact,
    doDeleteUserContact,
    doCreateSaleOrder,
    doUpdateSaleOrder,
    doResetSaleOrder,
    history,
    deliverToAddressId,
    invoiceToAddressId,
    setDeliverToAddressId,
    setInvoiceToAddressId,
    doOverrideCart,
    doReadCart,
    doClearUserGatewayProfile,
    doClearCustomerPaymentMethods,
    isAndroidDevice,
    gateway,
    width,
  } = props;

  const [deliverToAddressSelectorModal, setDeliverToAddressSelectorModal] = React.useState(false);
  const [invoiceToAddressSelectorModal, setInvoiceToAddressSelectorModal] = React.useState(false);
  const [notes, setNotes] = React.useState('');

  const [confirmOrderRequest, setConfirmOrderRequest] = React.useState(false);
  const [updateOrderRequest, setUpdateOrderRequest] = React.useState(false);

  const [paymentPageCreateRedirect, setPaymentPageCreateRedirect] = React.useState(false);
  const [paymentPageUpdateRedirect, setPaymentPageUpdateRedirect] = React.useState(false);
  const [paymentPageUpdateLoading, setPaymentPageUpdateLoading] = React.useState(false);

  const [invoiceToggle, setInvoiceToggle] = React.useState(true);
  const handleInvoiceToggle = () => {
    setInvoiceToggle(!invoiceToggle);
  };

  useEffect(() => {
    if (!contactList.fetching && !contactList?.data) {
      let query = '';
      let direction = '';
      let order = '';
      let limit = 10;
      let page = 1;
      doGetUserContacts({ token, query, direction, order, limit, page });
    }
  }, [doGetUserContacts]);

  useEffect(() => {
    if (confirmOrderRequest) {
      setConfirmOrderRequest(false);
      // let invoiceAddressData =
      //   contactList && contactList?.data && invoiceToAddressId === 0 && contactList?.data.length && contactList?.data?.find(item => item.enabled)
      //     ? contactList?.data.find(item => item.enabled)
      //     : contactList && contactList?.data
      //     ? contactList?.data.find(item => item.id === invoiceToAddressId)
      //     : null;
      let deliverAddressData =
        contactList && contactList?.data && deliverToAddressId === 0 && contactList?.data.length && contactList?.data?.find(item => item.enabled)
          ? contactList?.data.find(item => item.enabled)
          : contactList && contactList?.data
          ? contactList?.data.find(item => item.id === deliverToAddressId)
          : null;
      let payload = {
        cart_id: cart?.data?.id,
        deliver_contact_id: deliverAddressData?.id,
        invoice_contact_id: deliverAddressData?.id,
        notes: notes,
      };
      doCreateSaleOrder({ token, payload });
      // doOverrideCart();
    }
  }, [token, cart, saleOrderCreate, confirmOrderRequest]);
  // useEffect(() => {
  //   if (!gateway?.fetching && gateway.data) {
  //     doClearUserGatewayProfile();
  //   }
  // }, [gateway]);
  useEffect(() => {
    if (updateOrderRequest) {
      setUpdateOrderRequest(false);
      // let invoiceAddressData =
      //   contactList && contactList?.data && invoiceToAddressId === 0 && contactList?.data.length && contactList?.data?.find(item => item.enabled)
      //     ? contactList?.data.find(item => item.enabled)
      //     : contactList && contactList?.data
      //     ? contactList?.data.find(item => item.id === invoiceToAddressId)
      //     : null;
      let deliverAddressData =
        contactList && contactList?.data && deliverToAddressId === 0 && contactList?.data.length && contactList?.data?.find(item => item.enabled)
          ? contactList?.data.find(item => item.enabled)
          : contactList && contactList?.data
          ? contactList?.data.find(item => item.id === deliverToAddressId)
          : null;
      let id = saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id;
      let payload = {
        cart_id: cart?.data?.id,
        deliver_contact_id: deliverAddressData?.id,
        invoice_contact_id: deliverAddressData?.id,
        notes: notes,
      };
      doUpdateSaleOrder({ token, id, payload });
    }
  }, [token, cart, saleOrderCreate, updateOrderRequest]);

  useEffect(() => {
    if (saleOrderUpdate?.fetching) {
      setPaymentPageUpdateRedirect(true);
      // history.push('/catalog/payment');
    }
  }, [saleOrderUpdate]);

  useEffect(() => {
    if (saleOrderCreate?.fetching || saleOrderRead?.fetching) {
      setPaymentPageCreateRedirect(true);
      // history.push('/catalog/payment');
    }
  }, [saleOrderCreate, saleOrderRead]);

  useEffect(() => {
    if (!saleOrderCreate?.fetching && saleOrderCreate?.data && cart?.data && paymentPageCreateRedirect) {
      setPaymentPageCreateRedirect(false);
      let id = cart?.data?.id;
      doReadCart({ token, id });
      history.push('/catalog/payment');
    }
    if (!saleOrderRead?.fetching && saleOrderRead?.data && paymentPageCreateRedirect) {
      setPaymentPageCreateRedirect(false);
      let id = cart?.data?.id;
      doReadCart({ token, id });
      history.push('/catalog/payment');
    }
  }, [saleOrderCreate, saleOrderRead, cart, paymentPageCreateRedirect]);

  useEffect(() => {
    if (!saleOrderUpdate?.fetching && saleOrderUpdate?.data && paymentPageUpdateRedirect) {
      setPaymentPageUpdateRedirect(false);
      setPaymentPageUpdateLoading(true);
      setTimeout(function() {
        setPaymentPageUpdateLoading(false);
        history.push('/catalog/payment');
      }, 3000);
    }
  }, [saleOrderUpdate, paymentPageUpdateRedirect]);

  // useEffect(() => {
  //   if (!saleOrderCreate?.fetching && saleOrderCreate.data) {
  //     setPaymentPageRedirect(true);
  //     // history.push('/catalog/payment');
  //   }
  // }, [saleOrderCreate]);

  const printAddress = addressData => {
    let line1 = `${addressData?.company_name ? addressData?.company_name : ``}`;
    let line2 = `${addressData?.address_line_1 ? addressData?.address_line_1 : ``}`;
    let line3 = `${addressData?.address_line_2 ? addressData?.address_line_2 : ``}`;
    let line4 = `${addressData?.city ? addressData?.city : ``} ${addressData?.state ? addressData?.state : ``} ${addressData?.zipcode ? addressData?.zipcode : ``}`;
    return (
      <>
        <div>{line1}</div>
        <div>{line2}</div>
        {line3 ? <div>{line3}</div> : ``}
        <div>{line4}</div>
      </>
    );
  };

  return (
    <Grid align="left" item xs={12} md={12} lg={12}>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        Checkout
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Stack spacing={1}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Deliver To
            </Typography>
            {contactList && contactList?.data && deliverToAddressId === 0 && contactList?.data.length && contactList?.data?.find(item => item.enabled) ? (
              printAddress(contactList?.data.find(item => item.enabled))
            ) : contactList && contactList?.data ? (
              printAddress(contactList?.data.find(item => item.id === deliverToAddressId))
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Grid>
              </Grid>
            )}
            <Tooltip title={'Edit Invoice Information'}>
              <Button
                onClick={() => {
                  setDeliverToAddressSelectorModal(true);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
        {/*<Grid item xs={12} md={3} lg={3}>*/}
        {/*  <Stack spacing={1}>*/}
        {/*    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>*/}
        {/*      Invoice To*/}
        {/*    </Typography>*/}
        {/*    {contactList && contactList?.data && invoiceToAddressId === 0 && contactList?.data.length && contactList?.data?.find(item => item.enabled) ? (*/}
        {/*      printAddress(contactList?.data.find(item => item.enabled))*/}
        {/*    ) : contactList && contactList?.data ? (*/}
        {/*      printAddress(contactList?.data.find(item => item.id === invoiceToAddressId))*/}
        {/*    ) : (*/}
        {/*      <Grid container>*/}
        {/*        <Grid item xs={12}>*/}
        {/*          <Skeleton />*/}
        {/*          <Skeleton animation="wave" />*/}
        {/*          <Skeleton animation={false} />*/}
        {/*          <Skeleton />*/}
        {/*          <Skeleton animation="wave" />*/}
        {/*          <Skeleton animation={false} />*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*    )}*/}
        {/*    <Tooltip title={'Edit Invoice Information'}>*/}
        {/*      <Button*/}
        {/*        onClick={() => {*/}
        {/*          setInvoiceToAddressSelectorModal(true);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <EditIcon />*/}
        {/*      </Button>*/}
        {/*    </Tooltip>*/}
        {/*  </Stack>*/}
        {/*</Grid>*/}
        <Grid item xs={12} md={6} lg={6}>
          <TextField label="Comments" style={{ textAlign: 'left !important' }} fullWidth multiline rows={3} variant="outlined" onChange={event => setNotes(event.target.value)} />
        </Grid>

        <Grid item xs={12} md={12} lg={12} pt={2}>
          <TableContainer component={Paper}>
            <Table size={`small`}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                    Code
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: `bold`,
                      fontSize: 16,
                      padding: 15,
                      display: width < 600 ? 'none' : 'block',
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                    Qty.
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: `bold`,
                      fontSize: 16,
                      padding: 15,
                      display: width < 600 ? 'none' : 'table-cell',
                    }}
                  >
                    Unit $
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                    Total $
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length ? (
                  cart?.data?.cart_items.map(cartItem => (
                    <TableRow key={cartItem?.product_variant ? cartItem?.product_variant?.variant_code : cartItem?.product?.product_code}>
                      <TableCell align="center" style={{ fontSize: width < 600 ? 12 : 16, padding: width < 600 ? 5 : 15 }}>
                        {width < 600 && cartItem?.product_variant ? (
                          <div>
                            <div
                              style={{
                                marginBottom: 3,
                                padding: 2,
                                textAlign: `center`,
                              }}
                            >
                              {cartItem?.product_variant && cartItem?.product_variant?.variant_name}
                            </div>
                            <div style={{ marginBottom: 3 }}>{cartItem?.product_variant.variant_code}</div>
                            <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                              {cartItem?.product_variant &&
                                cartItem?.product_variant?.product_attributes.map(attribute => {
                                  // eslint-disable-next-line react/jsx-key
                                  return <Chip key={`attribute-${attribute.id}`} label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                                })}
                            </div>
                            <div style={{ fontWeight: `bold`, marginBottom: 5 }}>
                              Stock:{' '}
                              {cartItem?.product_variant && cartItem?.product_variant?.quantity >= 1 ? (
                                <span style={{ color: `green` }}>{cartItem?.product_variant?.quantity}</span>
                              ) : (
                                <span style={{ color: `red` }}>Not In Stock</span>
                              )}
                            </div>
                          </div>
                        ) : cartItem?.product_variant ? (
                          cartItem?.product_variant?.variant_code
                        ) : (
                          cartItem?.product?.product_code
                        )}
                        {}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: 16, padding: 15, display: width < 600 ? 'none' : 'table-cell' }}>
                        {cartItem?.product_variant ? `${cartItem?.product_variant?.variant_name}` : cartItem?.product?.product_name}
                        <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                          {cartItem?.product_variant &&
                            cartItem?.product_variant?.product_attributes.map(attribute => {
                              // eslint-disable-next-line react/jsx-key
                              return <Chip key={`attribute-${attribute?.id}`} label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                            })}
                        </div>
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: 16, padding: 15 }}>
                        {cartItem?.quantity}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: 16, padding: 15, display: width < 600 ? 'none' : 'table-cell' }}>
                        ${cartItem?.product_variant ? cartItem?.item_total : cartItem?.product?.unit_price}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: 16, padding: 15 }}>
                        $
                        {cartItem?.product_variant
                          ? FormatUtil.numberWithCommas(cartItem?.item_total * cartItem?.quantity)
                          : FormatUtil.numberWithCommas(cartItem?.item_total * cartItem?.quantity).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow key={`basket-loading`}>
                    <TableCell align="center" colSpan={width < 600 ? 3 : 5}>
                      <p>There is currently nothing in the cart.</p>
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell colSpan={width < 600 ? 1 : 3} />
                  <TableCell colSpan={1} align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                    {isAndroidDevice() ? `(${totalItems(cart)})` : `Total (${totalItems(cart)}) Items`}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 16, padding: 15 }}>
                    ${cart && cart?.data && cart?.data ? FormatUtil.numberWithCommas(parseFloat(cart?.data?.cart_total)) : 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={1} pt={2}>
            <Grid item xs={6} md={4} lg={4}>
              <Tooltip title="Edit your Basket">
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push('/catalog/basket');
                  }}
                  startIcon={<ArrowBackwardIcon />}
                >
                  Edit Basket
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={4} lg={4}>
              {cart?.data?.checkout_override ? (
                <LoadingButton
                  loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push('/catalog/payment');
                  }}
                  endIcon={<ArrowForwardIcon />}
                >
                  {isAndroidDevice() ? 'Payment' : 'Proceed To Payment'}
                </LoadingButton>
              ) : saleOrderCreate?.data || (saleOrderRead?.data && saleOrderRead?.data?.confirmed === 0) ? (
                <LoadingButton
                  loading={saleOrderUpdate?.fetching || paymentPageUpdateLoading}
                  loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // history.push('/catalog/payment');
                    setUpdateOrderRequest(true);
                  }}
                  endIcon={<ArrowForwardIcon />}
                  disabled={!(cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1) || paymentPageUpdateLoading || Boolean(cart?.data?.checkout_override)}
                >
                  Update Order
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={saleOrderCreate?.fetching}
                  loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    let cartTotal = cart && cart?.data && cart?.data ? parseFloat(cart?.data?.cart_total) : 0;
                    if (cartTotal < window.REQUIRED_SALE_AMOUNT) {
                      Swal.fire({
                        icon: 'error',
                        text: 'Your order needs to be more or equal to $' + window.REQUIRED_SALE_AMOUNT,
                      });
                    } else {
                      setConfirmOrderRequest(true);
                    }
                    // history.push('/catalog/payment');
                  }}
                  endIcon={<ArrowForwardIcon />}
                  disabled={!((cart && cart?.data && cart?.data && cart?.data?.cart_items && cart?.data?.cart_items.length >= 1) || Boolean(cart?.data?.checkout_override))}
                >
                  Draft Order
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {/* Address Create Modal */}
          <AddressSelectorModal
            isOpen={deliverToAddressSelectorModal || invoiceToAddressSelectorModal}
            handleClose={() => {
              setInvoiceToAddressSelectorModal(false);
              setDeliverToAddressSelectorModal(false);
            }}
            currentSelection={() => {
              if (deliverToAddressSelectorModal) {
                return deliverToAddressId;
              }
              if (invoiceToAddressSelectorModal) {
                return invoiceToAddressId;
              }
              return 0;
            }}
            handleSelection={id => {
              if (deliverToAddressSelectorModal) {
                setDeliverToAddressId(id);
              }
              if (invoiceToAddressSelectorModal) {
                setInvoiceToAddressId(id);
              }
            }}
            token={token}
            match={match}
            history={history}
            user={user}
            contactCreate={contactCreate}
            contactUpdate={contactUpdate}
            contactDelete={contactDelete}
            contactList={contactList}
            doCreateUserContact={doCreateUserContact}
            doUpdateUserContact={doUpdateUserContact}
            doDeleteUserContact={doDeleteUserContact}
            doGetUserContacts={doGetUserContacts}
            doClearUserContact={doClearUserContact}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
// PROPS TYPES
// --------------------------------------------------------
CheckoutPage.propTypes = {
  history: PropTypes.object,
  cart: PropTypes.object,
  quantityChanged: PropTypes.func,
  totalItems: PropTypes.func,
  token: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  saleOrderRead: PropTypes.object,
  saleOrderCreate: PropTypes.object,
  saleOrderUpdate: PropTypes.object,
  contactCreate: PropTypes.object,
  contactDelete: PropTypes.object,
  contactUpdate: PropTypes.object,
  contactList: PropTypes.object,
  doCreateSaleOrder: PropTypes.func,
  doUpdateSaleOrder: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  doCreateUserContact: PropTypes.func,
  doDeleteUserContact: PropTypes.func,
  doGetUserContacts: PropTypes.func,
  doClearUserContact: PropTypes.func,
  doUpdateUserContact: PropTypes.func,
  setDeliverToAddressId: PropTypes.func,
  setInvoiceToAddressId: PropTypes.func,
  doReadCart: PropTypes.func,
  doOverrideCart: PropTypes.func,
  deliverToAddressId: PropTypes.number,
  invoiceToAddressId: PropTypes.number,
  width: PropTypes.number,
};
export default CheckoutPage;
