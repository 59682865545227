import ShopSelectorStyles from './style';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import EasyErrors from '../../../utils/EasyErrors';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import FormUtil from '../../../utils/FormUtil';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useLayoutEffect, useState } from 'react';
const ShopSelector = props => {
  const { websiteSelectorId, setWebsiteSelectorId, doSetSite } = props;
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const [width, height] = useWindowSize();
  return (
    <ShopSelectorStyles>
      <Container>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Paper style={{ padding: width < 600 ? 10 : 50, margin: 'auto', maxWidth: 600, textAlign: 'center', borderRadius: 0 }}>
            <div style={{ padding: width < 600 ? 10 : 50 }}>
              <div
                style={{ textAlign: `center`, padding: 30, paddingTop: 0 }}
                onClick={() => {
                  history.push('/');
                }}
              >
                <img style={{ width: `100%` }} src={`https://${window.AWS_BUCKET}.s3.amazonaws.com/public/assets/logo.png`} alt={`logo`} />
              </div>
              <div style={{ textAlign: `center` }}>
                <p>Choose your location:</p>
              </div>
              <form style={{ flexGrow: 1 }} noValidate autoComplete="off">
                <FormGroup style={{ marginBottom: 15 }}>
                  <LoadingButton
                    onClick={event => {
                      doSetSite('Utah');
                      //console.log('UTAH SELECTED');
                      setWebsiteSelectorId('Utah');
                      window.BASE_URL = 'https://ut.alpinadistribution.com/v1/';
                      window.API_URL = 'https://ut.alpinadistribution.com/v1/api';
                    }}
                    loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                    size={`large`}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                  >
                    Utah
                  </LoadingButton>
                </FormGroup>
                <FormGroup style={{ marginBottom: 15 }}>
                  <LoadingButton
                    onClick={event => {
                      doSetSite('Colorado');
                      //setWebsiteSelectorId('Colorado');
                      window.BASE_URL = 'https://co.alpinadistribution.com/v1/';
                      window.API_URL = 'https://co.alpinadistribution.com/v1/api';
                    }}
                    loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                    size={`large`}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                  >
                    Colorado
                  </LoadingButton>
                </FormGroup>
              </form>
            </div>
          </Paper>
        </Grid>
      </Container>
    </ShopSelectorStyles>
  );
};
export default ShopSelector;
