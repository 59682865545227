// Imports

// Order Action Types.
import {
  LIST_ORDER_REQUEST,
  LIST_ORDER_SUCCESS,
  LIST_ORDER_FAILURE,
  READ_ORDER_REQUEST,
  READ_ORDER_SUCCESS,
  READ_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
} from './types';

// Initial Reducer States.
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  create: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    fetching: false,
    errors: null,
    data: null,
  },
  delete: {
    fetching: false,
    errors: null,
  },
};

/**
 * Order Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ORDER_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_ORDER_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_ORDER_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_ORDER_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_ORDER_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_ORDER_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_ORDER_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case UPDATE_ORDER_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case CREATE_ORDER_REQUEST:
      return {
        ...state,
        create: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        create: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        create: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case DELETE_ORDER_REQUEST:
      return {
        ...state,
        delete: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case DELETE_ORDER_FAILURE:
      return {
        ...state,
        delete: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    default:
      return state;
  }
};

// Exports
export default orderReducer;
