// Imports
import axios from 'axios';

// Export actions
/**
 * Get a list of Customers.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getCustomers = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/customers${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a specific Customer.
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const readCustomer = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/customer/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create a Customer.
 * @param {Object} { token: string, payload: { } }
 * @return {AxiosPromise} Http request
 */
export const createCustomer = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/customer`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Update a Customer.
 * @param {Object} { token: string, payload: { } }
 * @return {AxiosPromise} Http request
 */
export const updateCustomer = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/customer/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete a Customer.
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const deleteCustomer = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/customer/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
