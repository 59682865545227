// Imports
import axios from 'axios';

// Cart Export Actions

/**
 * Get a Cart
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const readCart = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/cart/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const duplicateCart = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/cart/duplicate/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a Cart
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const readLastCart = ({ token }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/lastcart`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create a Cart
 * @param {Object} { token: string, payload: object }
 * @return {AxiosPromise} Http request
 */
export const createCart = ({ token, payload }) => {
  return axios({
    method: 'post',
    url: `${window.API_URL}/cart`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Update a Cart.
 * @param {Object} { token: string, payload: { checkout_override: boolean } }
 * @return {AxiosPromise} Http request
 */
export const updateCart = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/cart/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete a Cart
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const deleteCart = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/cart/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const refreshCart = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/cart/${id}/refresh`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Empty a Cart
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const emptyCart = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${window.API_URL}/cart/${id}/emptycart`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
