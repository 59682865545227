// Imports
import styled from 'styled-components';

// Main Component
const EnterAdStyles = styled.div`
  display: flex;
  background: rgb(3, 3, 3);

  //height: 100vh;
  align-items: center;

  .bebas-neue-regular {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .Mui-focused {
    color: #000 !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000 !important;
    border-width: 2px !important;
  }
  .ad-btn {
    border: 2px white solid;
    font-size: 25px;
    border-radius: 0px;
  }
  @media (min-width: 1200px) {
    .MuiContainer-root {
      max-width: 1550px !important;
    }
  }
  // height: 100vh;
  // overlay-y: scroll;
  .enter-body {
    //background-color: #fff !important;
    // position: relative;
    // top: 50px;
    position: relative;
    top: 25%;
  }
  .Mui-focused {
    color: #000 !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000 !important;
    border-width: 2px !important;
  }
`;

// Export
export default EnterAdStyles;
