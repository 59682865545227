// Imports

// Customer Action Types.
import {
  LIST_SAVEDPRICING_REQUEST,
  LIST_SAVEDPRICING_SUCCESS,
  LIST_SAVEDPRICING_FAILURE,
  READ_SAVEDPRICING_REQUEST,
  READ_SAVEDPRICING_SUCCESS,
  READ_SAVEDPRICING_FAILURE,
  UPDATE_SAVEDPRICING_REQUEST,
  UPDATE_SAVEDPRICING_SUCCESS,
  UPDATE_SAVEDPRICING_FAILURE,
  CREATE_SAVEDPRICING_REQUEST,
  CREATE_SAVEDPRICING_SUCCESS,
  CREATE_SAVEDPRICING_FAILURE,
  DELETE_SAVEDPRICING_REQUEST,
  DELETE_SAVEDPRICING_SUCCESS,
  DELETE_SAVEDPRICING_FAILURE,
  SAVEDPRICING_RESET,
  UPDATEALL_SAVEDPRICING_REQUEST,
  UPDATEALL_SAVEDPRICING_SUCCESS,
  UPDATEALL_SAVEDPRICING_FAILURE,
  SAVEDPRICING_MODEL,
} from './types';

// Initial Reducer States.
const initialState = {
  list: {
    fetching: false,
    errors: null,
    data: null,
    pagination: null,
  },
  read: {
    fetching: false,
    errors: null,
    data: null,
  },
  update: {
    complete: false,
    fetching: false,
    errors: null,
    data: null,
  },
};

/**
 * Customer Reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const savedPricingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVEDPRICING_MODEL:
      // console.log(action);
      if (action.list !== undefined) {
        let list = action.list;
        return {
          ...state,
          list,
        };
      } else if (action.update !== undefined) {
        let update = action.update;
        return {
          ...state,
          update,
        };
      } else {
        return {
          ...state,
        };
      }

    case LIST_SAVEDPRICING_REQUEST:
      return {
        ...state,
        list: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case LIST_SAVEDPRICING_SUCCESS:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case LIST_SAVEDPRICING_FAILURE:
      return {
        ...state,
        list: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case READ_SAVEDPRICING_REQUEST:
      return {
        ...state,
        read: {
          fetching: true,
          errors: null,
          data: null,
          pagination: null,
        },
      };
    case READ_SAVEDPRICING_SUCCESS:
      return {
        ...state,
        read: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case READ_SAVEDPRICING_FAILURE:
      return {
        ...state,
        read: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case UPDATEALL_SAVEDPRICING_REQUEST:
      return {
        ...state,
        update: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UPDATEALL_SAVEDPRICING_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          complete: true,
        },
      };
    case UPDATEALL_SAVEDPRICING_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case UPDATE_SAVEDPRICING_SUCCESS:
      return {
        ...state,
        update: {
          fetching: false,
          errors: null,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case UPDATE_SAVEDPRICING_FAILURE:
      return {
        ...state,
        update: {
          fetching: false,
          errors: action.error,
          data: null,
          pagination: null,
        },
      };
    case SAVEDPRICING_RESET:
      return {
        ...state,
        list: {
          fetching: false,
          errors: null,
          data: null,
          pagination: null,
        },
        read: {
          fetching: false,
          errors: null,
          data: null,
        },
        update: {
          fetching: false,
          errors: null,
          data: null,
          complete: false,
        },
      };
    default:
      return state;
  }
};

// Exports
export default savedPricingReducer;
