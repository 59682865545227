// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Cart Item Action Types
import {
  LIST_CARTITEM_REQUEST,
  LIST_CARTITEM_SUCCESS,
  LIST_CARTITEM_FAILURE,
  UPDATE_CARTITEM_REQUEST,
  UPDATE_CARTITEM_SUCCESS,
  UPDATE_CARTITEM_FAILURE,
  CREATE_CARTITEM_REQUEST,
  CREATE_CARTITEM_SUCCESS,
  CREATE_CARTITEM_FAILURE,
  DELETE_CARTITEM_REQUEST,
  DELETE_CARTITEM_SUCCESS,
  DELETE_CARTITEM_FAILURE,
} from './types';

// Actions
import { getCartItems, createCartItem, updateCartItem, deleteCartItem } from './actions';
import { CART_REFRESH } from '../Cart/types';

/**
 * Function that handles listing Cart Items.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetCartItems({ token, query, direction, order, limit, page }) {
  try {
    const response = yield call(getCartItems, {
      token,
      query,
      direction,
      order,
      limit,
      page,
    });

    const { data, pagination } = response.data;

    yield put({
      type: LIST_CARTITEM_SUCCESS,
      data,
      pagination,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LIST_CARTITEM_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherGetCartItems() {
  yield takeLatest(LIST_CARTITEM_REQUEST, workerGetCartItems);
}

/**
 * Function that handles creating a Cart Item.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateCartItem({ token, payload }) {
  try {
    const response = yield call(createCartItem, {
      token,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: CREATE_CARTITEM_SUCCESS,
      data,
    });
    yield put({
      type: CART_REFRESH,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_CARTITEM_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherCreateCartItem() {
  yield takeLatest(CREATE_CARTITEM_REQUEST, workerCreateCartItem);
}

/**
 * Function that handles updating a Cart Item.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateCartItem({ token, id, payload }) {
  try {
    const response = yield call(updateCartItem, {
      token,
      id,
      payload,
    });

    const { data } = response.data;

    yield put({
      type: UPDATE_CARTITEM_SUCCESS,
      data,
    });
    yield put({
      type: CART_REFRESH,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_CARTITEM_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherUpdateCartItem() {
  yield takeLatest(UPDATE_CARTITEM_REQUEST, workerUpdateCartItem);
}

/**
 * Function that handles deleting a Cart Item.
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerDeleteCartItem({ token, id }) {
  try {
    const response = yield call(deleteCartItem, {
      token,
      id,
    });

    const { data } = response.data;

    yield put({
      type: DELETE_CARTITEM_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: DELETE_CARTITEM_FAILURE, ...{ error: message } });
  }
}

// Exports
export function* watcherDeleteCartItem() {
  yield takeLatest(DELETE_CARTITEM_REQUEST, workerDeleteCartItem);
}
