// Import types
// Cart Item Action Types
import { LIST_CARTITEM_REQUEST, UPDATE_CARTITEM_REQUEST, CREATE_CARTITEM_REQUEST, DELETE_CARTITEM_REQUEST, CARTITEM_RESET, CARTITEM_LOADING, CARTITEM_LOADING_ON, CARTITEM_LOADING_OFF } from './types';
import { FORGOT_PASSWORD_RESET } from '../Auth/types';
import { CART_UPDATE_RESET } from '../Cart/types';

// Export operations
/**
 * Dispatch for getting Cart Items.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number }
 * @return {Object} Dispatch
 */
export const doGetCartItems = ({ token, query, direction, order, limit, page }) => ({
  type: LIST_CARTITEM_REQUEST,
  token,
  query,
  direction,
  order,
  limit,
  page,
});

/**
 * Dispatch for creating a Cart Item.
 * @param {Object} { token: string, payload: { cart_id : integer, product_id : integer, quantity : integer } }
 * @return {Object} Dispatch
 */
export const doCreateCartItem = ({ token, payload }) => ({
  type: CREATE_CARTITEM_REQUEST,
  token,
  payload,
});

/**
 * Dispatch for updating a Cart Item.
 * @param {Object} { token: string, payload: { cart_id : integer, product_id : integer, quantity : integer } }
 * @return {Object} Dispatch
 */
export const doUpdateCartItem = ({ token, id, payload }) => ({
  type: UPDATE_CARTITEM_REQUEST,
  token,
  id,
  payload,
});

/**
 * Dispatch for deleting a Cart Item.
 * @param {Object} { token: string, id: string }
 * @return {Object} Dispatch
 */
export const doDeleteCartItem = ({ token, id }) => ({
  type: DELETE_CARTITEM_REQUEST,
  token,
  id,
});

export const doResetCartItem = () => ({
  type: CART_UPDATE_RESET,
});

export const doLoadingOnCartItems = () => ({
  type: CARTITEM_LOADING_ON,
});
export const doLoadingOffCartItems = () => ({
  type: CARTITEM_LOADING_OFF,
});
