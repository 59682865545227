// Imports
import axios from 'axios';

// Export actions
/**
 * Get all of the Categories.
 * @param {Object} { token: string, query: string, direction: string, order: string, limit: number, page: number}
 * @return {AxiosPromise} Http request
 */
export const getCategories = ({ token, query, direction, order, limit, page }) => {
  const params = {
    q: query || null,
    direction,
    order,
    limit,
    page,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/categories${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Get a Category.
 * @param {Object} { token: string, id: number}
 * @return {AxiosPromise} Http request
 */
export const getCategory = ({ token, id }) => {
  const params = {
    id,
  };

  let urlString = '';

  Object.keys(params).map(i => {
    urlString += params[i] ? `&${i}=${params[i]}` : '';
    return i;
  });

  urlString = urlString.length > 0 ? `?${urlString.slice(1)}` : '';

  return axios({
    method: 'get',
    url: `${window.API_URL}/categories${urlString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Create a Category.
 * @param {Object} { token: string, payload: { category_header: string, name: string, active: string, show_navigation: string, preview_images: string } }
 * @return {AxiosPromise} Http request
 */
export const createCategory = ({ token, payload }) =>
  axios({
    method: 'post',
    url: `${window.API_URL}/categories`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Update a Category.
 * @param {Object} { token: string, payload: { category_header: string, name: string, active: string, show_navigation: string, preview_images: string } }
 * @return {AxiosPromise} Http request
 */
export const updateCategory = ({ token, id, payload }) =>
  axios({
    method: 'put',
    url: `${window.API_URL}/categories/${id}`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Delete a Category.
 * @param {Object} { token: string, id: integer }
 * @return {Promise} axios promise
 */
export const deleteCategory = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${window.API_URL}/categories/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
