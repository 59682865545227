import { Alert } from '@mui/material';
import React from 'react';

export default {
  render: errors => {
    if (errors && Object.keys(errors)?.length) {
      return Object.keys(errors).map(k => (
        <Alert severity="error" style={{ marginBottom: 15 }}>
          {errors[k]}
        </Alert>
      ));
    }
    return '';
  },
};
