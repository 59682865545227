// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Errors from '../containers/Errors';

const ErrorRoute = props => {
  const { path, status } = props;
  return <Route {...props} path={path} component={props => <Errors {...props} status={status} />} />;
};

// Prop Types
ErrorRoute.propTypes = {
  status: PropTypes.number,
  path: PropTypes.string,
};

// Exports
export default ErrorRoute;
