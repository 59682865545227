// Imports
import axios from 'axios';

// Export actions

/**
 * Get a specific Dynamic Page
 * @param {Object} { token: string, id: string}
 * @return {AxiosPromise} Http request
 */
export const readDynamicPage = ({ token = '', id, public_page = false }) => {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  return axios({
    method: 'get',
    url: `${window.API_URL}/dynamicpage/${id}`,
    headers,
  });
};
