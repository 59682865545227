import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TableCell, TextField, Checkbox, TableHead, TableRow, Table, TableBody, CircularProgress } from '@mui/material';

const CustomerSelector = props => {
  const { token, doGetCustomers, customers, setSelectedUsers, selectedUsers } = props;

  const [customerFilter, setCustomerFilter] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (token) {
      doGetCustomers({
        token,
        query: customerFilter,
        direction: '',
        order: '',
        limit: 1000,
        page: 1,
      });
    }
  }, [customerFilter, token, doGetCustomers]);

  const handleSelectCustomer = customer => {
    const selectedIndex = selectedUsers.findIndex(selected => selected.user_id === customer.user_id);
    let newSelectedCustomers = [];

    if (selectedIndex === -1) {
      newSelectedCustomers = newSelectedCustomers.concat(selectedUsers, customer);
    } else if (selectedIndex === 0) {
      newSelectedCustomers = newSelectedCustomers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedCustomers = newSelectedCustomers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomers = newSelectedCustomers.concat(selectedUsers.slice(0, selectedIndex), selectedUsers.slice(selectedIndex + 1));
    }

    setSelectedUsers(newSelectedCustomers);
    // Optionally, trigger a callback or update here
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = customers.data.map(n => n);
      setSelectedUsers(newSelecteds);
      setSelectAll(true);
      return;
    }
    setSelectedUsers([]);
    setSelectAll(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField fullWidth size={'small'} variant="outlined" label={'Search'} onChange={event => setCustomerFilter(event.target.value)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox indeterminate={selectedUsers.length > 0 && selectedUsers.length < customers.data.length} checked={selectAll} onChange={handleSelectAllClick} />
              </TableCell>
              <TableCell>Customer</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <div style={{ height: 500, overflowY: `scroll` }}>
          <Table>
            <TableBody>
              {customers && customers?.fetching ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                customers &&
                customers?.data?.map(customer => (
                  <TableRow key={customer.id} hover role="checkbox" aria-checked={selectedUsers.includes(customer)} selected={selectedUsers.includes(customer)}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={selectedUsers.some(selected => selected.user.id === customer.user.id)} onChange={() => handleSelectCustomer(customer)} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {customer.user.company_name || `${customer.user.firstname} ${customer.user.lastname}`}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </Grid>
  );
};

CustomerSelector.propTypes = {
  token: PropTypes.string,
  doGetCustomers: PropTypes.func,
  setSelectedUsers: PropTypes.func,
  customers: PropTypes.object,
  selectedUsers: PropTypes.array,
};

export default CustomerSelector;
