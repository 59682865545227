import Grid from '@mui/material/Grid';
import React from 'react';

const ReportsPage = props => {
  return (
    <Grid item xs={12} md={12} lg={12}>
      Reports page
    </Grid>
  );
};
ReportsPage.propTypes = {};
export default ReportsPage;
