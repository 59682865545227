import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { CustomModal } from '../CustomModal';
import AddressSelectorElement from '../../Form/Elements/AddressSelector';
import AddressEditModal from '../AddressEdit';
import AddressCreateModal from '../AddressCreate';
import CloseIcon from '@mui/icons-material/Close';

const AddressSelectorModal = props => {
  // Import Props
  const {
    isOpen,
    handleClose,
    handleSelection,
    currentSelection,
    token,
    match,
    user,
    contactList,
    contactDelete,
    contactCreate,
    contactUpdate,
    doGetUserContacts,
    doCreateUserContact,
    doClearUserContact,
    doUpdateUserContact,
    doDeleteUserContact,
    history,
  } = props;

  const [addressCreateModalOpen, setAddressCreateModalOpen] = React.useState(false);
  const [contactData, setContactData] = React.useState(null);
  const [addressEditModalOpen, setAddressEditModalOpen] = React.useState(false);

  const onSubmitForm = event => {
    event.preventDefault();
  };

  const onDeletion = event => {
    event.preventDefault();
  };

  return (
    <CustomModal
      props={props}
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth={`1000px`}
      /* Modal Title Section */
      title={
        <Grid item xs={12} md={12} lg={12}>
          <Typography align="left" variant="h4" sx={{ fontWeight: 'bold', marginBottom: 0 }}>
            Addresses
            <div style={{ float: `right`, position: `relative` }}>
              <Button
                size="small"
                variant={'contained'}
                sx={{ mr: 2 }}
                onClick={() => {
                  setAddressCreateModalOpen(true);
                }}
              >
                Create
              </Button>
              <Button
                size="small"
                variant={'contained'}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </Button>
            </div>
          </Typography>
        </Grid>
      }
      /* Modal Body Section */
      body={
        <Grid container>
          <Grid item xs={12}>
            <AddressSelectorElement
              token={token}
              match={match}
              history={history}
              user={user}
              contactData={contactData}
              setContactData={setContactData}
              setAddressEditModalOpen={setAddressEditModalOpen}
              contactCreate={contactCreate}
              contactUpdate={contactUpdate}
              contactDelete={contactDelete}
              contactList={contactList}
              doCreateUserContact={doCreateUserContact}
              doUpdateUserContact={doUpdateUserContact}
              doDeleteUserContact={doDeleteUserContact}
              doGetUserContacts={doGetUserContacts}
              doClearUserContact={doClearUserContact}
              handleSelection={handleSelection}
              currentSelection={currentSelection}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Address Edit Modal */}
            <AddressEditModal
              props={props}
              token={token}
              contactData={contactData}
              isOpen={addressEditModalOpen}
              doUpdateUserContact={doUpdateUserContact}
              doDeleteUserContact={doDeleteUserContact}
              contactUpdate={contactUpdate}
              doClearUserContact={doClearUserContact}
              deletingAllowed={true}
              handleClose={event => {
                setAddressEditModalOpen(false);
                setAddressCreateModalOpen(false);
              }}
            />

            {/* Address Create Modal */}
            <AddressCreateModal
              props={props}
              token={token}
              contactCreate={contactCreate}
              doCreateUserContact={doCreateUserContact}
              doClearUserContact={doClearUserContact}
              isOpen={addressCreateModalOpen}
              handleClose={event => {
                setAddressCreateModalOpen(false);
                setAddressEditModalOpen(false);
              }}
            />
          </Grid>
        </Grid>
      }
      /* Modal Actions Section */
      actions={<div></div>}
    />
  );
};

AddressSelectorModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSelection: PropTypes.func,
  currentSelection: PropTypes.func,
  token: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  contactCreate: PropTypes.object,
  contactDelete: PropTypes.object,
  contactUpdate: PropTypes.object,
  contactList: PropTypes.object,
  doCreateUserContact: PropTypes.func,
  doDeleteUserContact: PropTypes.func,
  doGetUserContacts: PropTypes.func,
  doClearUserContact: PropTypes.func,
  doUpdateUserContact: PropTypes.func,
};

export default AddressSelectorModal;
